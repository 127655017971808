import React from 'react'

const TextButton = ({ className, children, onClick }) => {
	return (
		<div
			className={`text-md mb-8 font-semibold text-black dark:text-white md:mb-12 lg:text-lg ${
				className || ''
			}`}
			onClick={onClick}
		>
			{children}
		</div>
	)
}

export default TextButton

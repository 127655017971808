import { useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import useTheme from '../../hooks/useTheme'
import SystemButtonStyle from '../SystemButtonStyle'
import Tooltip from '../Tooltip'
import SmallUppercase from './text/SmallUppercase'

type Props = {
	tooltip?: string
	label?: string
	className?: string
	children?: React.ReactNode
	isOpen?: boolean
}

export default function ToolButton({
	className,
	children,
	tooltip,
	label,
	isOpen,
}: Props) {
	const anchorRef = useRef<HTMLDivElement>(null)
	const [showLabel, setShowLabel] = useState(false)
	const [isHovering, setIsHovering] = useState(false)
	const { primary } = useTheme()

	const getBackgroundColor = () => {
		if (isOpen) return primary
		if (isHovering) return `${primary}1A` // 1A is 10% opacity in hex
		return 'transparent'
	}

	return (
		<div
			className={twMerge(
				'pointer hover:backdrop-blur-md',
				SystemButtonStyle,
				className,
			)}
			style={{
				backgroundColor: getBackgroundColor(),
				color: isOpen ? 'black' : primary,
			}}
			onMouseEnter={() => setIsHovering(true)}
			onMouseLeave={() => setIsHovering(false)}
		>
			<div
				ref={anchorRef}
				onMouseEnter={() => setShowLabel(true)}
				onMouseLeave={() => setShowLabel(false)}
				className='absolute flex h-full w-full items-center justify-center'
			>
				{children}
			</div>

			{label && (
				<SmallUppercase
					className={twMerge(
						'absolute -bottom-5 transition-all duration-200',
						isHovering
							? 'translate-y-0 opacity-100'
							: 'translate-y-2 opacity-0',
					)}
					style={{
						color: primary,
					}}
				>
					{label}
				</SmallUppercase>
			)}

			{!label && tooltip && (
				<Tooltip open={showLabel} anchor={anchorRef} placement='bottom'>
					{tooltip}
				</Tooltip>
			)}
		</div>
	)
}

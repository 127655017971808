import React from 'react'

type Props = {
	children: React.ReactNode
}

const Accessory = ({ children }: Props) => {
	return (
		<div className='text-xs uppercase tracking-wider text-gray-500'>
			{children}
		</div>
	)
}

export default Accessory

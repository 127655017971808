import { Eraser } from 'lucide-react'
import { forwardRef, useContext, useRef, useState } from 'react'
import styled from 'styled-components'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'
import Popover from '../Popover'

const ClearDrawingsButton = forwardRef<HTMLButtonElement, Record<never, never>>(
	(_, forwardedRef) => {
		const { dispatch } = useContext(GameContext)
		const { usersState } = useContext(UsersContext)
		const scene = useGetActiveScene()
		const { userId, isGM, color } = useUser()
		const [showPopover, setShowPopover] = useState(false)
		const buttonRef = useRef<HTMLButtonElement>(null)

		const playerColors = Object.values(usersState.users)
			.filter(user => user.userSettings.role !== 'gm' && user.userId !== userId)
			.map(user => user.userSettings.color)
			.slice(0, 2)

		const handleClearDrawings = (clearType: 'all' | 'my' | 'players') => {
			if (!scene) return

			const currentMarks = scene.values.marks?.marks || []
			let updatedMarks = currentMarks

			switch (clearType) {
				case 'all':
					updatedMarks = []
					break
				case 'my':
					updatedMarks = currentMarks.filter(mark => mark.userId !== userId)
					break
				case 'players':
					updatedMarks = currentMarks.filter(mark => mark.userId === userId)
					break
			}

			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: {
					updatedDocument: {
						...scene,
						version: scene.version + 1,
						values: {
							...scene.values,
							marks: {
								marks: updatedMarks,
							},
						},
					},
				},
			})
			setShowPopover(false)
		}

		const handleNonGMClear = () => {
			if (!confirm('Are you sure you want to clear your drawings?')) return
			handleClearDrawings('my')
		}

		return (
			<>
				<ToolButton
					tooltip={isGM ? 'Clear Drawings' : 'Clear My Drawings'}
					onClick={() => (isGM ? setShowPopover(true) : handleNonGMClear())}
					ref={node => {
						if (forwardedRef) {
							if (typeof forwardedRef === 'function') {
								forwardedRef(node)
							} else {
								forwardedRef.current = node
							}
						}
						buttonRef.current = node
					}}
				>
					<Eraser className='h-5 w-5' aria-hidden='true' />
				</ToolButton>

				{isGM && (
					<Popover
						id='clear-drawings-popover'
						open={showPopover}
						anchor={buttonRef}
						onClose={() => setShowPopover(false)}
						placement='bottom'
					>
						<PopoverContent>
							<MenuItem onClick={() => handleClearDrawings('my')}>
								<span>Clear My Drawings</span>
								<ColorDots>
									<ColorDot color={color || '#FF0000'} />
								</ColorDots>
							</MenuItem>
							<MenuItem onClick={() => handleClearDrawings('players')}>
								<span>Clear All Player Drawings</span>
								<ColorDots>
									{playerColors.map((playerColor, index) => (
										<ColorDot key={index} color={playerColor || '#CCCCCC'} />
									))}
								</ColorDots>
							</MenuItem>
							<MenuItem onClick={() => handleClearDrawings('all')}>
								<span>Clear All Drawings</span>
								<ColorDots>
									<ColorDot color={color || '#FF0000'} />
									{playerColors.map((playerColor, index) => (
										<ColorDot key={index} color={playerColor || '#CCCCCC'} />
									))}
								</ColorDots>
							</MenuItem>
						</PopoverContent>
					</Popover>
				)}
			</>
		)
	},
)

const PopoverContent = styled.div`
	min-width: 240px;
	display: flex;
	flex-direction: column;
	gap: 4px;
`

const MenuItem = styled.button`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 8px 12px;
	text-align: left;
	border-radius: 4px;

	&:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}
`

const ColorDots = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
`

const ColorDot = styled.div<{ color: string }>`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${props => props.color};
`

ClearDrawingsButton.displayName = 'ClearDrawingsButton'

export default ClearDrawingsButton

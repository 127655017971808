import { PhotoIcon } from '@heroicons/react/24/solid'
import ToolbarButton from './ToolbarButton'
import useUser from '../../hooks/useUser'

type Props = {
	setShowCreateImage: (show: boolean) => void
}

const ImageEditorButton = ({ setShowCreateImage }: Props) => {
	const { isGM } = useUser()

	if (!isGM) return null

	return (
		<ToolbarButton label='Image' onClick={() => setShowCreateImage(true)}>
			<PhotoIcon className='h-5 w-5' />
		</ToolbarButton>
	)
}

export default ImageEditorButton

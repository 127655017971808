import { gql, useQuery } from '@apollo/client'

const GET_ADMIN_STATS = gql`
	query GetAdminStats {
		adminStats {
			totalUsers
			totalGames
			totalResources
		}
	}
`

interface AdminStats {
	totalUsers: number
	totalGames: number
	totalResources: number
}

export default function AdminDashboard() {
	const { data, loading, error } = useQuery<{ adminStats: AdminStats }>(
		GET_ADMIN_STATS,
	)

	if (loading) return <div>Loading stats...</div>
	if (error) return <div>Error loading stats</div>

	const stats = data?.adminStats

	return (
		<div>
			<h1 className='mb-8 text-2xl font-bold'>Dashboard</h1>

			<div className='grid grid-cols-1 gap-6 md:grid-cols-3'>
				<div className='rounded-lg bg-white p-6 shadow'>
					<h2 className='text-lg font-semibold text-gray-700'>Total Users</h2>
					<p className='mt-2 text-3xl font-bold'>{stats?.totalUsers || 0}</p>
				</div>

				<div className='rounded-lg bg-white p-6 shadow'>
					<h2 className='text-lg font-semibold text-gray-700'>Total Games</h2>
					<p className='mt-2 text-3xl font-bold'>{stats?.totalGames || 0}</p>
				</div>

				<div className='rounded-lg bg-white p-6 shadow'>
					<h2 className='text-lg font-semibold text-gray-700'>
						Total Resources
					</h2>
					<p className='mt-2 text-3xl font-bold'>
						{stats?.totalResources || 0}
					</p>
				</div>
			</div>
		</div>
	)
}

import React from 'react'
import Popover from '../Popover'
import ListItem from '../views/ListItem'

type Props = {
	showMenu: boolean
	setShowMenu: (show: boolean) => void
	onDelete: () => void
	messageRef: React.RefObject<HTMLDivElement>
}

const DocumentMenu = ({
	showMenu,
	setShowMenu,
	onDelete,
	messageRef,
}: Props) => {
	const menuItems = [
		{
			name: 'Delete',
			onOpen: () => onDelete(),
		},
	]

	return (
		<Popover
			id='message-menu'
			open={showMenu}
			anchor={messageRef}
			onClose={() => setShowMenu(false)}
			placement='right'
		>
			{menuItems.map(({ name, onOpen }) => (
				<ListItem
					name={name}
					className='text-red-700 hover:bg-gray-800'
					onOpen={() => {
						setShowMenu(false)
						setTimeout(() => onOpen(), 100) // magic number... :|
					}}
					key={name}
				/>
			))}
		</Popover>
	)
}

export default DocumentMenu

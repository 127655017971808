import { useQuery } from '@apollo/client'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useParams } from 'react-router-dom'
import PdfViewer from './components/resources/PDFViewer'
import AuthContext from './contexts/auth'
import PdfViewerContext from './contexts/book'
import { GET_RESOURCE } from './graphql/resources'
import reducer from './reducers/book'
import { getInitialState } from './utils/getInitialBookState'

const ResourceApp = () => {
	const { resourceId } = useParams()
	const [bookState, bookDispatch] = useReducer(
		reducer,
		getInitialState(resourceId),
	)
	const page = new URLSearchParams(window.location.search).get('page')
	const [resource, setResource] = useState(null)
	const { authState } = useContext(AuthContext)

	const { loading, error } = useQuery(GET_RESOURCE, {
		variables: { resourceId },
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			setResource(data.getResource)
		},
	})

	useEffect(() => {
		localStorage.setItem(
			`${resourceId}_pagesToDisplay`,
			bookState.pagesToDisplay.toString(),
		)
		localStorage.setItem(
			`${resourceId}_isCoverAlone`,
			JSON.stringify(bookState.isCoverAlone),
		)
		localStorage.setItem(
			`${resourceId}_outlineVisible`,
			JSON.stringify(bookState.isOutlineVisible),
		)
		localStorage.setItem(
			`${resourceId}_thumbnailVisible`,
			JSON.stringify(bookState.isThumbnailsVisible),
		)
	}, [
		resourceId,
		bookState.pagesToDisplay,
		bookState.isCoverAlone,
		bookState.isOutlineVisible,
		bookState.isThumbnailsVisible,
	])

	if (loading) {
		return <div>Loading...</div>
	}

	if (error) {
		console.error(error)
		return <div>Error...</div>
	}

	if (!authState.userId) {
		console.error('Error: Authstate user id missing...', authState)
		return (
			<div className='text-white'>
				Auth state User ID not found... Try to refresh or sign out and in again.
			</div>
		)
	}

	if (!resource) {
		return <div className='text-white'>No book found...</div>
	}

	return (
		<PdfViewerContext.Provider value={{ bookState, bookDispatch }}>
			<PdfViewer resource={resource} page={page} isGame={false} />
		</PdfViewerContext.Provider>
	)
}

export default ResourceApp

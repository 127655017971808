import { twMerge } from 'tailwind-merge'
import { IMessage } from '../../interfaces/chat'

type Props = {
	message: IMessage
	className?: string
}

const MediaMessage = ({ message, className }: Props) => {
	return (
		<div key={message._id} className={twMerge('mt-2', className)}>
			<img
				alt='message attachment'
				src={message.attachmentId}
				className='w-full rounded-lg shadow-md'
			/>
		</div>
	)
}

export default MediaMessage

import { Viewport } from 'pixi-viewport'
import { Point } from 'pixi.js'

export const screenToWorld = (
	viewport: Viewport,
	screenX: number,
	screenY: number,
): Point => {
	// Convert screen coordinates to viewport's world coordinates
	const worldPos = viewport.toWorld(new Point(screenX, screenY))
	return worldPos
}

export const worldToScreen = (
	viewport: Viewport,
	worldX: number,
	worldY: number,
): Point => {
	// Convert world coordinates to screen coordinates
	const screenPos = viewport.toScreen(new Point(worldX, worldY))
	return screenPos
}

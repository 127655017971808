import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import menuContentClasses from './menuContentClasses'

type Props = { children: React.ReactNode }

const SubMenuContent = ({ children }: Props) => {
	return (
		<DropdownMenu.Portal>
			<DropdownMenu.SubContent
				alignOffset={-5}
				className={menuContentClasses}
				style={{
					width: 'max-content',
					minWidth: '15rem',
				}}
			>
				{children}
			</DropdownMenu.SubContent>
		</DropdownMenu.Portal>
	)
}

export default SubMenuContent

import { useContext } from 'react'
import GameContext from '../../contexts/game'

type Props = {
	documentId: string
}

const useHandleGeneratorMessage = ({ documentId }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const doc = game.documents.byId[documentId]

	const handleGeneratorMessage = async (name: string, prompt: string) => {
		const apiResponse = await fetch('/api/gpt3', {
			method: 'POST',
			body: JSON.stringify({ prompt }),
			headers: { 'Content-Type': 'application/json' },
		})

		const responseData = await apiResponse.json()

		const newDoc = {
			...doc,
			values: {
				...doc.values,
				[name]: responseData.response,
			},
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument: newDoc,
			},
		})
	}

	return handleGeneratorMessage
}

export default useHandleGeneratorMessage

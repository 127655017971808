import { createContext } from 'react'
import { IResource } from '../../../shared/types/resources'

export interface IResourceState {
	[key: string]: IResource
}

const initialState: IResourceState = {}

export const ResourceContext = createContext<{
	resources: IResourceState
	dispatchResource: React.Dispatch<any>
}>({
	resources: initialState,
	dispatchResource: () => null,
})

import React from 'react'
import { twMerge } from 'tailwind-merge'
import TooltipWrapper from '../TooltipWrapper'

interface ToolbarButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
	disabled?: boolean
	label?: string
}

const ToolbarButton = ({
	label,
	className,
	children,
	disabled = false,
	...rest
}: ToolbarButtonProps) => {
	return (
		<TooltipWrapper label={label}>
			<button
				className={twMerge(
					'flex h-9 w-9 cursor-pointer items-center justify-center rounded-md p-2 hover:bg-gray-700 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-gray-700/50',
					className,
				)}
				disabled={disabled}
				{...rest}
			>
				{children}
			</button>
		</TooltipWrapper>
	)
}

export default ToolbarButton

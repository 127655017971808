import { UserPlus } from 'lucide-react'
import { useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import useUser from '../../hooks/useUser'
import InvitePlayerPopover from './InvitePlayerPopover'
import ToolButton from './toolbar/ToolButton'

export default function AddPlayerButtonSmall() {
	const [isOpen, setIsOpen] = useState(false)
	const { isGM } = useUser()
	const buttonRef = useRef<HTMLButtonElement>(null)

	if (!isGM) return null

	return (
		<>
			<ToolButton
				tooltip='Invite'
				isOpen={isOpen}
				ref={buttonRef}
				onClick={() => setIsOpen(true)}
			>
				<UserPlus className='h-5 w-5' aria-hidden='true' />
			</ToolButton>

			{isOpen &&
				createPortal(
					<InvitePlayerPopover
						isOpen={isOpen}
						setIsOpen={setIsOpen}
						anchorRef={buttonRef}
					/>,
					document.body,
				)}
		</>
	)
}

import { twMerge } from 'tailwind-merge'

interface FormMessageProps {
	type: 'success' | 'error'
	message: string
	className?: string
}

export default function FormMessage({
	type,
	message,
	className = '',
}: FormMessageProps) {
	const styles = {
		success: {
			bg: 'bg-green-50',
			text: 'text-green-800',
		},
		error: {
			bg: 'bg-red-50',
			text: 'text-red-800',
		},
	}

	return (
		<div className={twMerge(`rounded-md ${styles[type].bg} p-4`, className)}>
			<div className='flex'>
				<div className='ml-3'>
					<p className={`text-sm font-medium ${styles[type].text}`}>
						{message}
					</p>
				</div>
			</div>
		</div>
	)
}

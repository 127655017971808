// import { useContext, useEffect, useState } from 'react'
// import GameContext from '../../contexts/game'

// type Props = {
// 	frameIsLoaded: boolean
// 	messageToFrame: (type: string, payload: any) => void
// }

// const useSyncDataToFrame = ({ frameIsLoaded, messageToFrame }: Props) => {
// 	const { game } = useContext(GameContext)
// 	const [dataToSend, setDataToSend] = useState({
// 		documents: game.documents,
// 		assets: game.assets,
// 	})

// 	useEffect(() => {
// 		if (!frameIsLoaded) return

// 		setDataToSend({
// 			documents: game.documents,
// 			assets: game.assets,
// 		})
// 	}, [frameIsLoaded, game.documents, game.assets])

// 	useEffect(() => {
// 		if (!frameIsLoaded) return
// 		console.log('sending data to frame', dataToSend)
// 		messageToFrame('update data', dataToSend)
// 	}, [frameIsLoaded, dataToSend, messageToFrame])
// }

// export default useSyncDataToFrame

import { useCallback, useContext, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import GameContext from '../../contexts/game'

type Props = {
	frameIsLoaded: boolean
	messageToFrame: (type: string, payload: any) => void
}

const useSyncDataToFrame = ({ frameIsLoaded, messageToFrame }: Props) => {
	const { game } = useContext(GameContext)
	const [dataToSend, setDataToSend] = useState({
		documents: game.documents,
		assets: game.assets,
	})

	const setDataToSendDebounced = debounce(data => setDataToSend(data), 500)
	const debouncedSetDataToSend = useCallback(setDataToSendDebounced, [])

	useEffect(() => {
		if (!frameIsLoaded) return

		debouncedSetDataToSend({
			documents: game.documents,
			assets: game.assets,
		})
	}, [frameIsLoaded, game.documents, game.assets, debouncedSetDataToSend])

	useEffect(() => {
		if (!frameIsLoaded) return
		console.log('sending data to frame', dataToSend)
		messageToFrame('update data', dataToSend)
	}, [frameIsLoaded, dataToSend, messageToFrame])
}

export default useSyncDataToFrame

import { useContext } from 'react'
import GameContext from '../contexts/game'

const useGetAvatarUrl = () => {
	const { game } = useContext(GameContext)

	return (documentId: string): string => {
		const document = game?.documents.byId[documentId]
		if (!document) return ''

		const avatarAssetId = document.values?.portrait || document.values?.token
		if (!avatarAssetId) return ''

		const avatar = game?.assets.byId[avatarAssetId]
		return avatar?.fileurl || ''
	}
}

export default useGetAvatarUrl

// this component is used to fetch all users in a game, and update the usersState
//
import { useQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import GameContext from '../contexts/game'
import UsersContext from '../contexts/users'
import { GAME_USERS } from '../graphql/games'
import { IUserProfile } from '../interfaces/users'

export const useGameUsersQuery = () => {
	const { game } = useContext(GameContext)
	const { usersState, dispatchUsers } = useContext(UsersContext)

	const { refetch } = useQuery(GAME_USERS, {
		notifyOnNetworkStatusChange: true,
		variables: {
			gameId: game._id,
		},
		onCompleted: ({ gameUsers }) => {
			const newUsers = gameUsers.map((user: IUserProfile) => {
				const alreadyRegisteredUser = usersState.users.find(
					u => u.userId === user._id,
				)
				const userSettings = game.userSettings[user._id]

				return {
					userId: user._id,
					socketIds: alreadyRegisteredUser
						? alreadyRegisteredUser.socketIds
						: [],
					online: alreadyRegisteredUser ? alreadyRegisteredUser.online : false,
					userProfile: {
						name: user.name,
						email: user.email,
						avatar: user.avatar,
					},
					username: user.name,
					userSettings,
				}
			})

			dispatchUsers({ type: 'UPDATE_USERS', payload: { users: newUsers } })
		},
		onError: error => {
			console.error(error)
		},
	})

	useEffect(() => {
		refetch()
	}, [game.users, refetch])

	return { refetch }
}

import { ChatBubbleLeftIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import PdfViewerContext from '../../contexts/book'
import useMessageHandler from '../../hooks/useMessageHandler'
import ToolbarButton from './ToolbarButton'

const ShareSelection = () => {
	const { bookState } = useContext(PdfViewerContext)
	const { name, currentPage, pagesToDisplay } = bookState

	const handleNewMessage = useMessageHandler()

	const handleClick = () => {
		const selection = window.getSelection().toString()

		if (selection) {
			handleShareSelection()
		} else {
			handleSharePage()
		}
	}

	const handleSharePage = () => {
		const page =
			pagesToDisplay === 1 ? currentPage : `${currentPage - 1}-${currentPage}`
		handleNewMessage(`${name} (~page ${page})`)
	}

	const handleShareSelection = () => {
		const selection = window.getSelection()
		if (!selection) return
		const selectedText = selection.toString()
		if (!selectedText) return

		const anchorNode = selection.anchorNode
		if (!anchorNode) return

		const pageNumberElement = (anchorNode.parentNode as Element)?.closest(
			'[data-page-number]',
		)
		if (!pageNumberElement) return

		const pageNumber = pageNumberElement.getAttribute('data-page-number')

		handleNewMessage(`"${selectedText}"
  
${name} (~page ${pageNumber})
`)
	}

	const selection = window.getSelection().toString()
	const label = selection ? 'Share Selected Text' : 'Share Current Page'

	return (
		<ToolbarButton label={label} onClick={handleClick}>
			<ChatBubbleLeftIcon className='h-5 w-5' />
		</ToolbarButton>
	)
}

export default ShareSelection

import { useQuery } from '@apollo/client'
import { useContext, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import NoAccess from './NoAccess'
import Documents from './components/Documents'
import EventManager from './components/EventManager'
import MetaTags from './components/MetaTags'
import Misc from './components/Misc'
import ResourcesProvider from './components/ResourcesProvider'
import Sockets from './components/Sockets'
import ToolsManager from './components/ToolsManager'
import Users from './components/Users'
import StageContainer from './components/canvas/StageContainer'
import DragAndDropManager from './components/draganddrop/DragAndDropManager'
import Interface from './components/interface/Interface'
import VideoOverlayWrapper from './components/interface/video/VideoOverlayWrapper'
import Scrim from './components/scrim/Scrim'
import Windows from './components/window/Windows'
import { initialGameState } from './config'
import AuthContext from './contexts/auth'
import GameContext from './contexts/game'
import { GuideProvider } from './contexts/guideContext'
import { HAS_ACCESS_TO_GAME } from './graphql/games'
import useGetAssetById from './hooks/useGetAssetById'
import Reducer from './reducers/game'

const GameApp = () => {
	const [game, dispatch] = useReducer(Reducer, initialGameState)
	const { authState } = useContext(AuthContext)
	const { gameId } = useParams()
	// @ts-ignore
	const creatorId = game.creator._id
	const { asset: coverImage } = useGetAssetById(game.coverImage)

	const {
		loading: accessLoading,
		error: accessError,
		data: accessData,
		refetch: accessRefetch,
	} = useQuery(HAS_ACCESS_TO_GAME, {
		variables: { gameId },
	})

	if (!authState.userId) {
		console.error('Error: Authstate user id missing...', authState)
		return (
			<div className='text-white'>
				Auth state User ID not found... Try to refresh or sign out and in again.
			</div>
		)
	}

	if (accessLoading) {
		return <div>Loading...</div>
	}

	if (accessError) {
		console.error(accessError)
		return <div>Error...</div>
	}

	if (!accessData.hasAccessToGame) {
		console.error('Mo access:', accessData)
		return <NoAccess accessRefetch={accessRefetch} />
	}

	return (
		<>
			<MetaTags
				title={game.title || 'Game'}
				description={`Join this game on Realms Inc - ${
					game.description || 'Your tabletop home online.'
				}`}
				type='game'
				image={coverImage?.fileurl}
			/>
			<GameContext.Provider value={{ game, dispatch }}>
				<Sockets>
					<ResourcesProvider userId={creatorId}>
						<EventManager>
							<DragAndDropManager>
								<ToolsManager>
									<GuideProvider>
										<Windows>
											<Documents>
												<Users>
													<VideoOverlayWrapper />
													<StageContainer />
													<Scrim />
													{game._id && <Interface />}
												</Users>
											</Documents>
										</Windows>
									</GuideProvider>
								</ToolsManager>
							</DragAndDropManager>
						</EventManager>
						<Misc />
					</ResourcesProvider>
				</Sockets>
			</GameContext.Provider>
		</>
	)
}

export default GameApp

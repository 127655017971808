import useUser from '../../hooks/useUser'
import ExitButton from '../ExitButton'
import DebugButton from '../toolbar/DebugButton'
import SettingsButton from '../toolbar/SettingsButton'
import AddPlayerButtonSmall from './AddPlayerButtonSmall'
import Toolbar from './toolbar/Toolbar'

const LeftTools = () => {
	const { isGM } = useUser()
	const isDevelopment = process.env.NODE_ENV === 'development'

	return (
		<div className='mb-2 flex flex-row gap-2'>
			<Toolbar>
				<ExitButton />
			</Toolbar>
			{isGM && (
				<Toolbar>
					<SettingsButton />
					<AddPlayerButtonSmall />
				</Toolbar>
			)}
			{isDevelopment && (
				<Toolbar>
					<DebugButton />
				</Toolbar>
			)}
		</div>
	)
}

export default LeftTools

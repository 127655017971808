import ObjectId from 'bson-objectid'
import { Plus } from 'lucide-react'
import { useContext, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { IDocument } from '../../../../shared/types/document'
import DocumentsContext from '../../contexts/documents'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import BookPicker from '../pages/books/elements/BookPicker'
import ToolbarControlButton from '../toolbar/ControlButton'
import AddMenu from './AddMenu'

export default function AddDocumentButton() {
	const { game, dispatch } = useContext(GameContext)
	const { dispatchDocuments } = useContext(DocumentsContext)
	const [isOpen, setIsOpen] = useState(false)
	const addButtonRef = useRef<HTMLButtonElement>(null)
	const { userId, role } = useUser()
	const { control } = useFormContext()
	const isGM = role === 'gm'
	const collections = useMemo(
		() => game.system?.collections || [],
		[game.system?.collections],
	)
	const [showBookPicker, setShowBookPicker] = useState(false)
	const { primary } = useTheme()

	const selectedType = useWatch({
		control,
		name: 'selection',
		defaultValue: 'all',
	})

	const handleAddDocument = (type: string) => {
		const _id = ObjectId().toHexString()
		const collection = collections.find(t => t.type === type)
		const name = collection?.singularName
		const access = collection?.defaultAccess

		const document: IDocument = {
			_id,
			type,
			version: 1,
			parentId: 'library',
			creator: userId,
			access: access || 'private',
			accessList: [userId],
			values: {
				name: 'New ' + name,
			},
		}

		if (type === 'map') {
			// bring in default grid settings from the loaded system
			document.values.grid = game.system.grid
		}

		dispatch({
			type: 'ADD_DOCUMENT',
			payload: { document },
		})

		// open the document if type not a folder
		if (type !== 'folder') {
			dispatchDocuments({
				type: 'OPEN_DOCUMENT',
				payload: { documentId: _id },
			})
		}

		setIsOpen(false)
	}

	const handleAddBook = () => {
		setShowBookPicker(true)
	}

	const handleDoubleClick = () => {
		if (selectedType !== 'all' && selectedType !== 'Books') {
			handleAddDocument(selectedType)
		}
	}

	// user has permissions to add document to collection?
	const hasPermissions = useMemo(() => {
		const collection = collections.find(t => t.type === selectedType)

		if (isGM) return true

		// we know all users can add notes, so we can return true here
		if (selectedType === 'all') return true

		// collection allows players to create documents
		if (collection?.allowCreate === 'true') return true

		return false
	}, [collections, isGM, selectedType])

	if (!hasPermissions) return null
	if (collections.length < 1) return null

	return (
		<>
			<ToolbarControlButton
				ref={addButtonRef}
				label='Add'
				onClick={() => {
					setIsOpen(true)
				}}
				onDoubleClick={handleDoubleClick}
			>
				<Plus
					className='h-6 w-6'
					aria-hidden='true'
					style={{
						color: primary,
					}}
				/>
			</ToolbarControlButton>

			<AddMenu
				open={isOpen}
				onClose={() => setIsOpen(false)}
				onAddDocument={handleAddDocument}
				onAddBook={handleAddBook}
				collections={collections}
				anchorRef={addButtonRef}
			/>

			{showBookPicker && (
				<BookPicker onClose={() => setShowBookPicker(false)} />
			)}
		</>
	)
}

import { TrashIcon } from '@heroicons/react/24/outline'
import React, { useContext } from 'react'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import ToolbarControlButton from '../toolbar/ControlButton'

const ClearStageButton: React.FC = () => {
	const { dispatch } = useContext(GameContext)
	const { primary } = useTheme()

	const clearStage = () => {
		dispatch({
			type: 'SET_STAGE_DOCUMENTS',
			payload: { documents: [] },
		})
	}

	return (
		<ToolbarControlButton
			label='Clear Stage'
			onClick={clearStage}
			innerClassName='rounded-full m-0.5 h-8 m-0.5 w-8'
			innerStyle={{
				backgroundColor: primary,
			}}
		>
			<TrashIcon
				className='h-4 w-4'
				aria-hidden='true'
				style={{
					color: 'black',
				}}
			/>
		</ToolbarControlButton>
	)
}

export default ClearStageButton

import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import useGetLog from '../../hooks/useGetLog'
import useUser from '../../hooks/useUser'
import Button from '../FormComponents/Button'
import Translucency from '../Translucency'
import { FADE_TIME, LEGACY_MESSAGE_TIMESTAMP } from './constants'
import Message from './Message'
import useHandleDrop from './useHandleDrop'

const ITEMS_PER_PAGE = 20

const Log = () => {
	const { dispatch } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const { userId } = useUser()
	const { log } = useGetLog()
	const [visibleItems, setVisibleItems] = useState(ITEMS_PER_PAGE)
	const dropRef = useRef<HTMLDivElement>(null)
	const logContainerRef = useRef<HTMLDivElement>(null)
	const [isAtBottom, setIsAtBottom] = useState(true)
	const user = useMemo(
		() => usersState.users.find(u => u.userId === userId),
		[usersState.users, userId],
	)
	const drop = useHandleDrop({ userId, userProfile: user?.userProfile })
	const [loadedMessageIds, setLoadedMessageIds] = useState<Set<string>>(
		new Set(),
	)

	const handleDelete = useCallback(
		(messageId: string) => {
			dispatch({ type: 'DELETE_MESSAGE', payload: messageId })
		},
		[dispatch],
	)

	drop(dropRef)

	const visibleLog = useMemo(() => {
		return log.slice(Math.max(0, log.length - visibleItems))
	}, [log, visibleItems])

	const loadMore = useCallback(() => {
		const currentScrollHeight = logContainerRef.current?.scrollHeight || 0
		const newVisibleItems = Math.min(visibleItems + ITEMS_PER_PAGE, log.length)
		setVisibleItems(newVisibleItems)

		const newMessages = log.slice(
			log.length - newVisibleItems,
			log.length - visibleItems,
		)
		setLoadedMessageIds(prev => {
			const next = new Set(prev)
			newMessages.forEach(msg => next.add(msg._id))
			return next
		})

		requestAnimationFrame(() => {
			if (logContainerRef.current) {
				const newScrollHeight = logContainerRef.current.scrollHeight
				logContainerRef.current.scrollTop =
					newScrollHeight -
					currentScrollHeight +
					logContainerRef.current.scrollTop
			}
		})
	}, [log, visibleItems])

	const checkIfAtBottom = useCallback(() => {
		if (logContainerRef.current) {
			const { scrollTop, scrollHeight, clientHeight } = logContainerRef.current
			setIsAtBottom(scrollHeight - scrollTop <= clientHeight + 1)
		}
	}, [])

	useEffect(() => {
		const logContainer = logContainerRef.current
		if (logContainer) {
			logContainer.addEventListener('scroll', checkIfAtBottom)
			return () => logContainer.removeEventListener('scroll', checkIfAtBottom)
		}
	}, [checkIfAtBottom])

	useEffect(() => {
		if (isAtBottom && logContainerRef.current) {
			logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight
		}
	}, [log.length, isAtBottom])

	const hideLog = useCallback(() => {
		setVisibleItems(ITEMS_PER_PAGE)
		setLoadedMessageIds(new Set())

		// Reset scroll position to bottom
		requestAnimationFrame(() => {
			if (logContainerRef.current) {
				logContainerRef.current.scrollTop = logContainerRef.current.scrollHeight
			}
		})
	}, [])

	return (
		<div
			ref={node => {
				dropRef.current = node
				logContainerRef.current = node
			}}
			className='-mx-3 -mt-6 flex h-full flex-1 flex-col-reverse overflow-y-auto py-2 px-3'
		>
			{/* Load More button - will appear at top */}
			{visibleItems < log.length && (
				<div className='order-3 mb-4 text-center'>
					<Button
						onClick={loadMore}
						className={twMerge(
							'm-auto inline-block w-auto rounded-full',
							Translucency,
						)}
					>
						Load Older Messages...
					</Button>
				</div>
			)}

			{/* Messages */}
			<div className='order-2'>
				{visibleLog.map((message, index) => {
					let prevMessage = null
					for (let i = index - 1; i >= 0; i--) {
						const msg = visibleLog[i]
						const msgAge =
							Date.now() - (msg.createdAt || LEGACY_MESSAGE_TIMESTAMP)
						if (msgAge < FADE_TIME) {
							prevMessage = msg
							break
						}
					}

					const newSender =
						!prevMessage || prevMessage.sender !== message.sender
					const newAssumedCharacter =
						!prevMessage ||
						prevMessage.assumedCharacterId !== message.assumedCharacterId
					const showSender = newSender || newAssumedCharacter

					return (
						<Message
							key={message._id}
							message={message}
							onDelete={() => handleDelete(message._id)}
							showSender={showSender}
							noFade={loadedMessageIds.has(message._id)}
						/>
					)
				})}
			</div>

			{/* Hide Log button - will appear at bottom */}
			<div className='order-1 text-center'>
				{visibleItems > ITEMS_PER_PAGE && (
					<Button
						onClick={hideLog}
						className={twMerge(
							'm-auto inline-block w-auto rounded-full',
							Translucency,
						)}
					>
						Hide Log
					</Button>
				)}
			</div>
		</div>
	)
}

export default React.memo(Log)

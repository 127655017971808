import { useState } from 'react'
import Input from '../../../Input'
import PageHeader from '../../sections/PageHeader'
import AddBookButton from './AddBookButton'

interface Props {
	onSearch: (search: string) => void
	selectMode: boolean
	setSelectMode: (mode: boolean) => void
}

const BookHeader: React.FC<Props> = ({
	onSearch,
	selectMode,
	setSelectMode,
}) => {
	const [search, setSearch] = useState('')

	const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value)
		onSearch(event.target.value)
	}

	return (
		<PageHeader title='Books'>
			<div className='flex space-x-4'>
				<button onClick={() => setSelectMode(!selectMode)}>
					{selectMode ? 'Done' : 'Select'}
				</button>
				<AddBookButton />
				<Input
					type='text'
					placeholder='Search...'
					value={search}
					onChange={handleSearch}
					className='ml-4 pt-0'
					inputClassName='bg-gray-900 h-10'
				/>
			</div>
		</PageHeader>
	)
}

export default BookHeader

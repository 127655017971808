import { yupResolver } from '@hookform/resolvers/yup'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import Button from '../FormComponents/Button'
import FormMessage from '../FormComponents/FormMessage'
import Label from '../FormComponents/Label'
import Input from '../Input'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

interface IFormInputs {
	email: string
}

const schema = yup
	.object({
		email: yup
			.string()
			.email('Please enter a valid email address')
			.required('Email is required'),
	})
	.required()

export default function ForgotPassword() {
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	})

	const onSubmit = async (data: IFormInputs) => {
		setError('')
		setMessage('')

		try {
			const response = await fetch('/api/password-reset-request', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email: data.email }),
			})

			const responseData = await response.json()

			if (response.ok) {
				setMessage(
					'Check your email for password reset instructions. Return to Sign In?',
				)
			} else {
				setError(responseData.message || 'Something went wrong')
			}
		} catch (err) {
			setError('Failed to send reset email')
		}
	}

	return (
		<Card className='h-screen'>
			<PageHeader title='Reset Password' className='text-center' />

			<form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
				<div>
					<Label>Email</Label>
					<Input
						type='email'
						placeholder='Email address...'
						inputClassName='dark:bg-gray-900'
						error={errors.email?.message}
						{...register('email', { required: true })}
					/>
				</div>

				{message && (
					<div className='space-y-2'>
						<FormMessage type='success' message={message} />
						<Link
							to='/signin'
							className='text-sm font-semibold text-gray-500 hover:text-gray-500'
						>
							Back to Sign In
						</Link>
					</div>
				)}
				{error && <FormMessage type='error' message={error} />}

				<Button type='submit'>Send Reset Instructions</Button>
			</form>
		</Card>
	)
}

import produce from 'immer'
import { IResource } from '../../../shared/types/resources'
import { IResourceState } from '../contexts/resources'

export function resourceReducer(
	state: IResourceState,
	action:
		| {
				type: 'ADD_RESOURCES'
				payload: { resources: [IResource] }
		  }
		| {
				type: 'ADD_RESOURCE'
				payload: {
					resource: IResource
				}
		  }
		| {
				type: 'REMOVE_RESOURCE'
				payload: {
					resourceId: string
				}
		  }
		| {
				type: 'REMOVE_RESOURCES'
				payload: {
					resourceIds: string[]
				}
		  }
		| {
				type: 'ADD_TO_GAME'
				payload: {
					resourceIds: string[]
					gameId: string
				}
		  },
) {
	return produce(state, draft => {
		switch (action.type) {
			case 'ADD_RESOURCES': {
				const { resources } = action.payload
				for (const resource of resources) {
					draft[resource._id] = resource
				}
				break
			}

			case 'ADD_RESOURCE': {
				const { resource } = action.payload
				draft[resource._id] = resource
				break
			}

			case 'REMOVE_RESOURCE': {
				const { resourceId } = action.payload
				delete draft[resourceId]
				break
			}

			case 'REMOVE_RESOURCES': {
				const { resourceIds } = action.payload
				resourceIds.forEach(id => {
					delete draft[id]
				})
				break
			}

			case 'ADD_TO_GAME': {
				const { resourceIds, gameId } = action.payload
				resourceIds.forEach(id => {
					if (draft[id]) {
						if (!draft[id].usedInGames) {
							draft[id].usedInGames = []
						}
						draft[id].usedInGames.push(gameId)
					}
				})
				break
			}
		}
	})
}

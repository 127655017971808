import { useReducer } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DragStateContext } from '../../contexts/dragdrop'
import dragdropReducer from '../../reducers/dragdropReducer'

interface Props {
	children: React.ReactNode
}

const DragAndDropManager = ({ children }: Props) => {
	const [dragState, dragDispatch] = useReducer(dragdropReducer, {
		isDragging: false,
	})

	return (
		<DragStateContext.Provider value={{ dragState, dragDispatch }}>
			<DndProvider backend={HTML5Backend}>{children}</DndProvider>
		</DragStateContext.Provider>
	)
}

export default DragAndDropManager

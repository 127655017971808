import HeroBanner from '../../../../assets/frontsite/herobanner.png'
import HeroSection from '../HeroSection'

const Main = () => {
	return (
		<HeroSection
			backgroundImage={HeroBanner}
			eyebrow='Just Imagine'
			title='Online Roleplaying'
			body={
				<>
					But easy to prep, run and share.
					<br />
					Remotely, at the table, or anywhere.
				</>
			}
			buttonText='Get Started →'
			buttonLink='/signup'
			gradientPosition='right'
		/>
	)
}

export default Main

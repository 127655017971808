import { useContext, useEffect } from 'react'
import { WhoIAmMessage } from '../../../shared/types/socket'
import AuthContext from '../contexts/auth'
import { socket } from '../contexts/socket'
import UsersContext from '../contexts/users'

const useEmitOnlineStatus = () => {
	const { authState } = useContext(AuthContext)
	const { dispatchUsers } = useContext(UsersContext)

	useEffect(() => {
		if (!authState.userId) {
			console.log('WARNING THERE IS NO USER ID!!11')
			return
		}

		// Emit online status when the socket connection is established
		dispatchUsers({
			type: 'UPDATE_SOCKET_USER_CONNECTION',
			payload: {
				userId: authState.userId,
				socketId: socket.id,
			},
		})

		const message: WhoIAmMessage = {
			userId: authState.userId,
			socketId: socket.id,
		}
		socket.emit('who i am', message)

		// Clean up the interval when the component is unmounted
		return () => {
			socket.off('disconnect')
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useEmitOnlineStatus

import { Container, Graphics } from '@pixi/react'
import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import GameContext from '../../contexts/game'
import ToolsContext from '../../contexts/tools'
import UsersContext from '../../contexts/users'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'
import { IMark, Point } from '../../interfaces/draw'
import { screenToWorld } from '../../utils/coordinates'
import { smoothLine } from '../../utils/drawing'
import {
	DEFAULT_PRESSURE,
	DOUBLE_CLICK_TIME,
	DRAW_LINE_ALPHA,
	MAX_LINE_WIDTH,
	SNAP_DISTANCE,
	WIDTH_CHANGE_FACTOR,
	WIDTH_SMOOTHING_FACTOR,
} from '../../utils/drawingConstants'
import { generateUUID } from '../../utils/generateUUID'
import { calculateLineWidth } from '../../utils/lineWidth'
import CursorPreview from './CursorPreview'
import DrawingCursor from './DrawingCursor'
import ExistingMark from './ExistingMark'
import PolylineDrawing from './PolylineDrawing'

interface Props {
	viewport: Viewport
}

const DrawLayer = ({ viewport }: Props) => {
	const { toolsState } = useContext(ToolsContext)
	const { color, userId } = useUser()
	const { usersState } = useContext(UsersContext)
	const { dispatch } = useContext(GameContext)
	const scene = useGetActiveScene()
	const isDrawing = useRef(false)
	const isDrawingStraightLine = useRef(false)
	const points = useRef<Point[]>([])
	const graphicsRef = useRef<PIXI.Graphics>(null)
	const currentDrawingId = useRef<string | null>(null)
	const isShiftPressed = useRef(false)
	const lastAnchorPoint = useRef<Point | null>(null)
	const startingPoint = useRef<Point | null>(null)
	const lastClickTime = useRef<number>(0)
	const [currentPoint, setCurrentPoint] = useState<Point | null>(null)

	// Add shift key listeners
	useEffect(() => {
		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.key === 'Shift') {
				isShiftPressed.current = true
				if (isDrawing.current && !isDrawingStraightLine.current) {
					// Switch from freehand to polyline mode
					isDrawingStraightLine.current = true
					if (points.current.length > 0) {
						// Set lastAnchorPoint and startingPoint to the start point of the freehand line
						lastAnchorPoint.current = points.current[0]
						startingPoint.current = points.current[0]
						// Clear the freehand drawing without committing it
						points.current = [lastAnchorPoint.current]
						if (graphicsRef.current) {
							graphicsRef.current.clear()
						}
					}
				}
			}
		}
		const handleKeyUp = (e: KeyboardEvent) => {
			if (e.key === 'Shift') {
				isShiftPressed.current = false
				if (isDrawingStraightLine.current) {
					// End polyline mode
					isDrawingStraightLine.current = false
					isDrawing.current = false
					lastAnchorPoint.current = null
					startingPoint.current = null
					points.current = []
					setCurrentPoint(prev => (prev ? { ...prev, width: undefined } : null))
				}
			}
		}

		window.addEventListener('keydown', handleKeyDown)
		window.addEventListener('keyup', handleKeyUp)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
			window.removeEventListener('keyup', handleKeyUp)
		}
	}, [])

	// Clear current drawing when scene changes
	useEffect(() => {
		if (!scene?.values.marks?.marks && graphicsRef.current) {
			graphicsRef.current.clear()
			isDrawing.current = false
			isDrawingStraightLine.current = false
			points.current = []
			lastAnchorPoint.current = null
			startingPoint.current = null
		}
	}, [scene?.values.marks?.marks])

	// Simplified render mark function
	const renderMark = useCallback(
		(mark: IMark) => {
			if (mark.deleted) return null

			const user = usersState.users.find(u => u.userId === mark.userId)
			const userColor = user?.userSettings?.color || '#ffffff'

			return (
				<ExistingMark key={`mark-${mark.id}`} mark={mark} color={userColor} />
			)
		},
		[usersState.users],
	)

	// Filter out invalid marks
	const validMarks = scene?.values.marks?.marks.filter(
		(mark: IMark) => mark.points && mark.points.length > 0 && !mark.deleted,
	)

	// Update mark function
	const updateMark = useCallback(() => {
		if (points.current.length < 2 || !scene) return

		const currentMarks = scene.values.marks?.marks || []
		const newMark: IMark = {
			id: generateUUID(),
			userId,
			drawingId: currentDrawingId.current,
			points: [...points.current],
		}

		const updatedMarks = [...currentMarks, newMark]

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument: {
					...scene,
					version: scene.version + 1,
					values: {
						...scene.values,
						marks: {
							marks: updatedMarks,
						},
					},
				},
			},
		})
	}, [scene, userId, dispatch])

	const handlePointerDown = useCallback(
		(e: PIXI.FederatedPointerEvent) => {
			if (e.button !== 0 || toolsState.mode !== 'draw') return

			const worldPos = screenToWorld(viewport, e.globalX, e.globalY)
			const newPoint: Point = {
				x: worldPos.x,
				y: worldPos.y,
				timestamp: Date.now(),
			}

			const currentTime = Date.now()
			const timeSinceLastClick = currentTime - lastClickTime.current
			lastClickTime.current = currentTime

			if (isShiftPressed.current) {
				// Polyline mode
				if (!isDrawing.current) {
					// Start a new polyline
					isDrawing.current = true
					isDrawingStraightLine.current = true
					lastAnchorPoint.current = newPoint
					startingPoint.current = newPoint
					points.current = [newPoint]
					currentDrawingId.current = generateUUID()
					setCurrentPoint(newPoint)
				} else if (
					timeSinceLastClick < DOUBLE_CLICK_TIME &&
					startingPoint.current
				) {
					// Double-click detected - close the loop back to starting point
					if (lastAnchorPoint.current && startingPoint.current) {
						points.current = [lastAnchorPoint.current, startingPoint.current]
						updateMark()

						// End polyline mode
						isDrawing.current = false
						isDrawingStraightLine.current = false
						lastAnchorPoint.current = null
						startingPoint.current = null
						points.current = []
						setCurrentPoint(null)
					}
				} else {
					// Continue polyline - add new segment
					if (lastAnchorPoint.current) {
						points.current = [lastAnchorPoint.current, newPoint]
						updateMark()
						lastAnchorPoint.current = newPoint
						setCurrentPoint(newPoint)
					}
				}
			} else {
				// Freehand mode
				isDrawing.current = true
				isDrawingStraightLine.current = false
				points.current = [newPoint]
				currentDrawingId.current = generateUUID()
				setCurrentPoint(newPoint)
			}
		},
		[toolsState.mode, viewport, updateMark],
	)

	const handlePointerMove = useCallback(
		(e: PIXI.FederatedPointerEvent) => {
			const worldPos = screenToWorld(viewport, e.globalX, e.globalY)
			const newPoint: Point = {
				x: worldPos.x,
				y: worldPos.y,
				timestamp: Date.now(),
			}

			// Always update current point for cursor preview
			setCurrentPoint({ ...newPoint })

			if (!isDrawing.current) return

			if (isDrawingStraightLine.current) {
				// Check if near starting point
				const isNearStart =
					startingPoint.current &&
					Math.hypot(
						newPoint.x - startingPoint.current.x,
						newPoint.y - startingPoint.current.y,
					) < SNAP_DISTANCE

				if (lastAnchorPoint.current) {
					points.current = [
						lastAnchorPoint.current,
						isNearStart ? startingPoint.current : newPoint,
					]
				}
			} else {
				// Freehand drawing
				if (graphicsRef.current) {
					graphicsRef.current.clear()

					// Get pressure, defaulting to 0.5 if not available
					const pressure = (e as PointerEvent).pressure || DEFAULT_PRESSURE

					// Calculate line width based on drawing speed and pressure
					if (points.current.length > 0) {
						newPoint.width = calculateLineWidth(
							newPoint,
							points.current[points.current.length - 1],
							pressure,
						)

						// Smooth out the width transition
						const lastPoint = points.current[points.current.length - 1]
						if (lastPoint.width) {
							newPoint.width =
								lastPoint.width * WIDTH_SMOOTHING_FACTOR +
								newPoint.width * WIDTH_CHANGE_FACTOR
						}
					} else {
						newPoint.width = MAX_LINE_WIDTH * pressure
					}

					points.current.push(newPoint)

					// Draw smoothed line with variable width
					const smoothedPoints = smoothLine(points.current)

					// Draw each segment with interpolated widths
					for (let i = 1; i < smoothedPoints.length; i++) {
						const startWidth = smoothedPoints[i - 1].width || MAX_LINE_WIDTH
						const endWidth = smoothedPoints[i].width || MAX_LINE_WIDTH
						const interpolatedWidth = (startWidth + endWidth) / 2

						graphicsRef.current.lineStyle({
							width: interpolatedWidth,
							color: parseInt(color.replace('#', '0x')),
							alpha: DRAW_LINE_ALPHA,
							cap: PIXI.LINE_CAP.ROUND,
							join: PIXI.LINE_JOIN.ROUND,
						})
						graphicsRef.current.moveTo(
							smoothedPoints[i - 1].x,
							smoothedPoints[i - 1].y,
						)
						graphicsRef.current.lineTo(smoothedPoints[i].x, smoothedPoints[i].y)
					}
				}
			}
		},
		[toolsState.mode, viewport, color],
	)

	const handlePointerUp = useCallback(
		(e: PIXI.FederatedPointerEvent) => {
			if (!isDrawing.current || toolsState.mode !== 'draw') return

			const worldPos = screenToWorld(viewport, e.globalX, e.globalY)
			const newPoint: Point = {
				x: worldPos.x,
				y: worldPos.y,
				timestamp: Date.now(),
			}

			if (isDrawingStraightLine.current) {
				if (lastAnchorPoint.current) {
					points.current = [lastAnchorPoint.current, newPoint]
					updateMark()
					lastAnchorPoint.current = newPoint
				}
			} else {
				isDrawing.current = false
				updateMark()
				if (graphicsRef.current) {
					graphicsRef.current.clear()
				}
				points.current = []
				currentDrawingId.current = null
			}

			// Update current point for cursor preview
			setCurrentPoint(newPoint)
		},
		[toolsState.mode, updateMark, viewport, color],
	)

	return (
		<Container
			eventMode={toolsState.mode === 'draw' ? 'static' : 'none'}
			interactiveChildren={false}
			hitArea={
				new PIXI.Rectangle(
					-window.innerWidth * 2,
					-window.innerHeight * 2,
					window.innerWidth * 4,
					window.innerHeight * 4,
				)
			}
			pointerdown={handlePointerDown}
			pointermove={handlePointerMove}
			pointerup={handlePointerUp}
			pointerupoutside={handlePointerUp}
		>
			{validMarks?.map(renderMark)}
			<Graphics ref={graphicsRef} />
			{isDrawingStraightLine.current && (
				<PolylineDrawing
					lastAnchorPoint={lastAnchorPoint.current}
					currentPoint={currentPoint}
					color={color}
					startingPoint={startingPoint.current}
					isNearStart={
						startingPoint.current && currentPoint
							? Math.hypot(
									currentPoint.x - startingPoint.current.x,
									currentPoint.y - startingPoint.current.y,
							  ) < SNAP_DISTANCE
							: false
					}
				/>
			)}
			{!isDrawing.current && toolsState.mode === 'draw' && currentPoint && (
				<CursorPreview x={currentPoint.x} y={currentPoint.y} color={color} />
			)}
			<DrawingCursor
				viewport={viewport}
				color={color}
				isDrawing={isDrawing.current}
			/>
		</Container>
	)
}

export default DrawLayer

import { useContext } from 'react'
import { IDocument } from '../../../../../shared/types/document'
import GameContext from '../../../contexts/game'
import MenuItem from '../../ContextMenu/Item'

type Props = {
	document: IDocument
}

const RenameMenuItem = ({ document }: Props) => {
	const { dispatch } = useContext(GameContext)

	const handleRename = () => {
		const name = prompt('Enter a new name', document.values.name)
		if (!name) return
		dispatch({
			type: 'RENAME_DOCUMENT',
			payload: {
				documentId: document._id,
				name: name,
			},
		})
	}

	return <MenuItem onSelect={() => handleRename()}>Rename...</MenuItem>
}

export default RenameMenuItem

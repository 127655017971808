// this component is responsible for loading the system
// from the server during init and if the system is updated.
// This is handled separate from the game state due to size
// constraints with sockets
import { useQuery } from '@apollo/client'
import { useContext, useEffect } from 'react'
import { BUILT_IN_COLLECTIONS } from '../constants/systemCollections'
import GameContext from '../contexts/game'
import { socket } from '../contexts/socket'
import { LOAD_GAME_SYSTEM } from '../graphql/games'

const useSystemManager = () => {
	const { game, dispatch } = useContext(GameContext)
	const gameId = game?._id

	const { refetch } = useQuery(LOAD_GAME_SYSTEM, {
		skip: !gameId,
		variables: {
			gameId: gameId,
		},
		onCompleted: ({ loadGameSystem }) => {
			// console.log('loaded system', loadGameSystem)
			const collections = [
				...loadGameSystem.collections,
				...BUILT_IN_COLLECTIONS,
			]

			const system = {
				...loadGameSystem,
				collections,
			}

			dispatch({
				type: 'LOAD_SYSTEM',
				payload: {
					system,
				},
			})
		},
		onError: error => {
			console.error(error)
		},
	})

	useEffect(() => {
		if (!gameId) return

		socket.on('load system', () => {
			refetch()
		})

		return () => {
			socket.off('load system')
		}
	}, [game, refetch])
}

export default useSystemManager

// Import all video files
import atmosphere from '../../../assets/video/atmosphere.webm'
import bottomAtmosphere from '../../../assets/video/bottomatmosphere.webm'
import particles from '../../../assets/video/particles.webm'
import rain from '../../../assets/video/rain.webm'
import rain2 from '../../../assets/video/rain2.webm'
import snow from '../../../assets/video/Snow-003.mp4'
import snowWebm from '../../../assets/video/snow.webm'
import snow2 from '../../../assets/video/snow2.webm'

interface VideoConfig {
	src: string
	opacity: number
	name: string
}

export const videoSources: Record<string, VideoConfig> = {
	particles: { src: particles, opacity: 0.2, name: 'Particles' },
	particles2: { src: particles, opacity: 0.2, name: 'Particles 2' },
	atmosphere: { src: atmosphere, opacity: 0.3, name: 'Atmosphere' },
	heavyAtmosphere: { src: atmosphere, opacity: 0.4, name: 'Heavy Atmosphere' },
	bottomAtmosphere: {
		src: bottomAtmosphere,
		opacity: 0.3,
		name: 'Ground Fog',
	},
	rain: { src: rain, opacity: 0.25, name: 'Rain' },
	heavyRain: { src: rain2, opacity: 0.25, name: 'Heavy Rain' },
	snow: { src: snow, opacity: 0.3, name: 'Snow' },
	snow2: { src: snowWebm, opacity: 0.3, name: 'Snow 2' },
	snow3: { src: snow2, opacity: 0.3, name: 'Snow 3' },
}

export const videoOptions = Object.keys(videoSources).map(key => ({
	value: key,
	label: videoSources[key].name,
}))

import { ChevronUp } from 'lucide-react'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import SectionButton from './SectionButton'

interface PreviousButtonsProps {
	disabled?: boolean
}

const PreviousButtons = ({ disabled }: PreviousButtonsProps) => {
	const { game, dispatch } = useContext(GameContext)
	const { currentRound, currentTurnIndex } = game.turnTracker
	const isFirstTurn = currentRound === 1 && currentTurnIndex === 0

	const moveToPrevious = () => {
		dispatch({ type: 'TURN_ORDER_PREVIOUS' })
	}

	if (currentRound === 0) return null

	return (
		<SectionButton onClick={moveToPrevious} disabled={disabled || isFirstTurn}>
			<ChevronUp className='h-5 w-5' />
		</SectionButton>
	)
}

export default PreviousButtons

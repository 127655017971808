import styles from './ProgressWindow.module.css'

const ProgressWindow = props => {
  const progressBarStyle = {
    width: props.progress + '%',
  }

  if (props.progress === 0) {
    return <></>
  } else {
    return (
      <div className={styles.progressWindowContainer}>
        <div className={styles.ProgressWindow}>
          <div className={styles.title}>{props.title}</div>

          <div className={styles.progressTrack}>
            <div className={styles.progressBar} style={progressBarStyle}></div>
          </div>

          {props.cancel && (
            <div className={styles.button} onClick={props.cancel}>
              Cancel
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ProgressWindow

import { Undo2 } from 'lucide-react'
import { forwardRef, useContext } from 'react'
import GameContext from '../../contexts/game'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'

const UndoButton = forwardRef<HTMLButtonElement, Record<never, never>>(
	(_, ref) => {
		const { dispatch } = useContext(GameContext)
		const scene = useGetActiveScene()
		const { userId } = useUser()

		const currentMarks = scene?.values?.marks?.marks || []
		// Find the last non-deleted mark by current user
		const lastMarkIndex = currentMarks
			.map((mark, index) =>
				mark.userId === userId && !mark.deleted ? index : -1,
			)
			.filter(index => index !== -1)
			.pop()
		const canUndo = lastMarkIndex !== undefined

		const handleUndo = () => {
			if (!scene || !canUndo) return

			const updatedMarks = [...currentMarks]
			updatedMarks[lastMarkIndex] = {
				...updatedMarks[lastMarkIndex],
				deleted: true,
			}

			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: {
					updatedDocument: {
						...scene,
						version: scene.version + 1,
						values: {
							...scene.values,
							marks: {
								marks: updatedMarks,
							},
						},
					},
				},
			})
		}

		return (
			<ToolButton
				tooltip='Undo Last Drawing'
				onClick={handleUndo}
				disabled={!canUndo}
				ref={ref}
			>
				<Undo2 className='h-5 w-5' aria-hidden='true' />
			</ToolButton>
		)
	},
)

UndoButton.displayName = 'UndoButton'

export default UndoButton

import { useContext } from 'react'
import GameContext from '../contexts/game'

const useTheme = () => {
	const { game } = useContext(GameContext)
	const { theme } = game

	return theme
}

export default useTheme

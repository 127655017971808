import { FC, useContext, useState } from 'react'
import GameContext from '../../contexts/game'
import GameCard from '../pages/elements/GameCard'
import SectionDivider from '../SectionDivider'
import ModalWindow from '../window/ModalWindow'
import CoverImage from './CoverImage'
import DevelopmentMode from './DevelopmentMode'
import Logo from './Logo'
import SettingsFeatureFlags from './SettingsFeatureFlags'
import SettingsGameName from './SettingsGameName'
import SystemInfo from './SystemInfo'
import SystemLoader from './SystemLoader'
import SystemReset from './SystemReset'
import ThemeEditor from './ThemeEditor'

interface ISettings {
	onClose: () => void
}

const Settings: FC<ISettings> = ({ onClose }) => {
	const { game } = useContext(GameContext)
	const [enableClickOutside, setEnableClickOutside] = useState(false)

	return (
		<ModalWindow
			id='settings'
			title='Game Settings'
			onClose={onClose}
			size='medium'
			enableClickOutside={enableClickOutside}
		>
			<SectionDivider className='mt-4' label='Game Card Preview' />
			<GameCard className='mt-4' game={game} />

			<SectionDivider className='mt-4' label='Information' />
			<SettingsGameName />
			{/* <SettingsGameDescription /> */}
			<CoverImage />
			<Logo />

			<SectionDivider className='mt-4' label='Interface Colors' />
			<ThemeEditor setEnableClickOutside={setEnableClickOutside} />

			<SectionDivider className='mt-4' label='Beta Features' />
			<SettingsFeatureFlags />

			<SectionDivider className='mt-4' label='System' />
			<SystemLoader onClose={onClose} />
			<SystemInfo />

			<SectionDivider className='mt-4' label='Development Mode (Advanced)' />
			<DevelopmentMode setEnableClickOutside={setEnableClickOutside} />

			<SectionDivider className='mt-4 mb-4' label='Danger Zone' />
			<SystemReset />
		</ModalWindow>
	)
}

export default Settings

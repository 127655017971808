import { Stage as PixiStage } from '@pixi/react'
import { useEffect, useRef, useState } from 'react'
import { DndContext } from 'react-dnd'
import AuthContext from '../../contexts/auth'
import DocumentsContext from '../../contexts/documents'
import { DragStateContext } from '../../contexts/dragdrop'
import EventContext from '../../contexts/event'
import GameContext from '../../contexts/game'
import { GuideContext } from '../../contexts/guideContext'
import { SocketContext } from '../../contexts/socket'
import ToolsContext from '../../contexts/tools'
import UsersContext from '../../contexts/users'
import WindowsContext from '../../contexts/windows'
import ContextBridge from '../ContextBridge'
import RainDropEffect from './effects/RainDropEffect'

interface RainDrop {
	id: number
	x: number
	y: number
}

export const Stage = ({ children }) => {
	const [windowDimensions, setWindowDimensions] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	})
	const [rainDrops, setRainDrops] = useState<RainDrop[]>([])
	const [nextId, setNextId] = useState(0)
	const pressTimer = useRef<number | null>(null)
	const pressPosition = useRef<{ x: number; y: number } | null>(null)

	useEffect(() => {
		const handleResize = () => {
			setWindowDimensions({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])

	const handlePointerDown = (e: React.PointerEvent) => {
		if (e.button === 2) return

		pressPosition.current = { x: e.clientX, y: e.clientY }
		pressTimer.current = window.setTimeout(() => {
			if (pressPosition.current) {
				const newRainDrop = {
					id: nextId,
					x: pressPosition.current.x,
					y: pressPosition.current.y,
				}
				setRainDrops(prev => [...prev, newRainDrop])
				setNextId(prev => prev + 1)
			}
		}, 500) // 500ms long press
	}

	const handlePointerUp = () => {
		if (pressTimer.current) {
			clearTimeout(pressTimer.current)
			pressTimer.current = null
		}
		pressPosition.current = null
	}

	const handlePointerMove = (e: React.PointerEvent) => {
		// Cancel effect if pointer moves too far from initial position
		if (pressPosition.current) {
			const dx = e.clientX - pressPosition.current.x
			const dy = e.clientY - pressPosition.current.y
			const distance = Math.sqrt(dx * dx + dy * dy)
			if (distance > 5) {
				// 5px threshold
				if (pressTimer.current) {
					clearTimeout(pressTimer.current)
					pressTimer.current = null
				}
				pressPosition.current = null
			}
		}
	}

	const handleEffectComplete = (id: number) => {
		setRainDrops(prev => prev.filter(drop => drop.id !== id))
	}

	return (
		<ContextBridge
			Context={AuthContext}
			render={authChildren => (
				<ContextBridge
					Context={EventContext}
					render={eventChildren => (
						<ContextBridge
							Context={GameContext}
							render={gameChildren => (
								<ContextBridge
									Context={SocketContext}
									render={socketChildren => (
										<ContextBridge
											Context={DragStateContext}
											render={dragAndDropChildren => (
												<ContextBridge
													Context={ToolsContext}
													render={toolsChildren => (
														<ContextBridge
															Context={UsersContext}
															render={usersChildren => (
																<ContextBridge
																	Context={DndContext}
																	render={dndChildren => (
																		<ContextBridge
																			Context={WindowsContext}
																			render={windowsChildren => (
																				<ContextBridge
																					Context={DocumentsContext}
																					render={documentsChildren => (
																						<ContextBridge
																							Context={GuideContext}
																							render={guideChildren => (
																								<PixiStage
																									id='pixi-canvas'
																									width={windowDimensions.width}
																									height={
																										windowDimensions.height
																									}
																									options={{
																										backgroundColor: 0x111111,
																										resolution:
																											window.devicePixelRatio ||
																											1,
																										eventMode: 'static',
																									}}
																									onPointerDown={
																										handlePointerDown
																									}
																									onPointerUp={handlePointerUp}
																									onPointerMove={
																										handlePointerMove
																									}
																								>
																									{guideChildren}
																									{rainDrops.map(drop => (
																										<RainDropEffect
																											key={drop.id}
																											x={drop.x}
																											y={drop.y}
																											onComplete={() =>
																												handleEffectComplete(
																													drop.id,
																												)
																											}
																										/>
																									))}
																								</PixiStage>
																							)}
																						>
																							{documentsChildren}
																						</ContextBridge>
																					)}
																				>
																					{windowsChildren}
																				</ContextBridge>
																			)}
																		>
																			{dndChildren}
																		</ContextBridge>
																	)}
																>
																	{usersChildren}
																</ContextBridge>
															)}
														>
															{toolsChildren}
														</ContextBridge>
													)}
												>
													{dragAndDropChildren}
												</ContextBridge>
											)}
										>
											{socketChildren}
										</ContextBridge>
									)}
								>
									{gameChildren}
								</ContextBridge>
							)}
						>
							{eventChildren}
						</ContextBridge>
					)}
				>
					{authChildren}
				</ContextBridge>
			)}
		>
			{children}
		</ContextBridge>
	)
}

export default Stage

import React from 'react'

interface EyebrowProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
}

const Eyebrow: React.FC<EyebrowProps> = ({ className, children, ...props }) => {
	return (
		<div
			className={`lg:text-md mb-4 text-sm font-semibold uppercase tracking-wider text-white md:mb-6 ${
				className || ''
			}`}
			{...props}
		>
			{children}
		</div>
	)
}

export default Eyebrow

import produce from 'immer'
import clamp from 'lodash/clamp'
import { Reducer } from 'react'
import { IWindows, IWindowsReducerAction } from '../interfaces/game'

const WindowReducer: Reducer<IWindows, IWindowsReducerAction> = (
	state,
	action,
) => {
	return produce(state, draft => {
		const payload = action.payload

		switch (action.type) {
			case 'OPEN_WINDOW': {
				const existingWindowIndex = draft.windows.findIndex(w => {
					return w?.documentId === payload.documentId
				})

				if (existingWindowIndex !== -1) {
					// Bring existing window to the front (end of array)
					const existingWindow = draft.windows[existingWindowIndex]
					draft.windows.splice(existingWindowIndex, 1)
					draft.windows.push(existingWindow)
				} else {
					// Insert new window
					draft.windows.push(payload)
					draft.position = {
						x: clamp(draft.position.x + 55, 0, window.innerWidth - 300),
						y: clamp(draft.position.y + 55, 0, window.innerWidth - 300),
					}
				}
				break
			}

			case 'MOVE_WINDOW_TO_FRONT': {
				if (!draft.windows.length) return
				const windowToMoveIndex = draft.windows.findIndex(w =>
					w ? w.documentId === payload.documentId : false,
				)
				if (windowToMoveIndex !== -1) {
					const windowToMove = draft.windows[windowToMoveIndex]
					draft.windows.splice(windowToMoveIndex, 1)
					draft.windows.push(windowToMove)
				}
				break
			}

			default:
				break
		}
	})
}

export default WindowReducer

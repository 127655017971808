import { useCallback, useState } from 'react'

const useApi = () => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<Error | null>(null)

	const request = useCallback(
		async (url: RequestInfo | URL, options: RequestInit = {}) => {
			console.log('useAPI request', url, options)

			const defaultOptions = {
				method: 'GET', // default method
				headers: {
					'Content-Type': 'application/json',
				},
			}

			const mergedOptions = {
				...defaultOptions,
				...options,
				headers: {
					...defaultOptions.headers,
					...options.headers,
				},
			}

			setLoading(true)
			setError(null)

			try {
				const response = await fetch(url, mergedOptions)

				if (!response.ok) {
					console.error(response)
					throw new Error('Network response was not ok')
				}

				const data = await response.json()
				return data
			} catch (err) {
				setError(err as Error)
				throw err
			} finally {
				setLoading(false)
			}
		},
		[],
	)

	return { request, loading, error }
}

export default useApi

import React, { useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'

interface MediaCrossfadeProps {
	mediaUrl: string
	duration?: number
	type?: 'image' | 'video'
	videoProps?: {
		muted?: boolean
		loop?: boolean
		autoPlay?: boolean
		playsInline?: boolean
	}
}

const MediaCrossfade: React.FC<MediaCrossfadeProps> = ({
	mediaUrl,
	duration = 1.5,
	type = 'image',
	videoProps = { muted: true, loop: true, autoPlay: true },
}) => {
	const [currentMedia, setCurrentMedia] = useState(mediaUrl)
	const [isTransitioning, setIsTransitioning] = useState(false)

	const fade = useSpring({
		opacity: isTransitioning ? 1 : 0,
		config: { duration: duration * 1000 },
		onRest: () => {
			if (isTransitioning) {
				setCurrentMedia(mediaUrl)
				setIsTransitioning(false)
			}
		},
	})

	useEffect(() => {
		if (mediaUrl !== currentMedia) {
			setIsTransitioning(true)
		}
	}, [mediaUrl, currentMedia])

	const MediaElement = ({ url }: { url: string }) => {
		if (type === 'video') {
			return (
				<video
					className='h-full w-full object-cover'
					src={url}
					{...videoProps}
				/>
			)
		}
		return (
			<div
				className='h-full w-full bg-cover bg-center bg-no-repeat'
				style={{ backgroundImage: `url(${url})` }}
			/>
		)
	}

	return (
		<div className='fixed inset-0'>
			<div className='absolute inset-0'>
				<MediaElement url={currentMedia} />
			</div>
			{isTransitioning && (
				<animated.div className='absolute inset-0' style={fade}>
					<MediaElement url={mediaUrl} />
				</animated.div>
			)}
		</div>
	)
}

export default MediaCrossfade

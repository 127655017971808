import { PlayIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useTheme from '../../hooks/useTheme'

type Props = {
	sceneId: string
}

const ActivateSceneButton = ({ sceneId }: Props) => {
	const { dispatch } = useContext(GameContext)
	const activeScene = useGetActiveScene()
	const { _id: activeSceneId } = activeScene || {}
	const isActive = sceneId === activeSceneId
	const { primary } = useTheme()

	const handleActivateScene = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		dispatch({
			type: 'SET_ACTIVE_SCENE',
			payload: { sceneId },
		})
	}

	return (
		<button
			onClick={handleActivateScene}
			className={twMerge(
				'w-8 rounded-full p-2 text-sm backdrop-blur-md transition-all duration-200 hover:text-white/80',
				// isActive
				// ? 'bg-green-700 text-green-200'
				// :
				'bg-white/10 text-white/60 hover:bg-white/20',
			)}
			style={{
				backgroundColor: isActive ? primary : '',
				color: isActive ? 'white' : '',
			}}
		>
			<PlayIcon className='ml-px h-4 w-4' />
		</button>
	)
}

export default ActivateSceneButton

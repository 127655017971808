import { Viewport } from 'pixi-viewport'
import { useContext, useEffect } from 'react'
import ToolsContext from '../contexts/tools'

export const useViewportControls = (viewport: Viewport | null) => {
	const { toolsState } = useContext(ToolsContext)

	useEffect(() => {
		if (!viewport) return

		const handleKeyDown = (e: KeyboardEvent) => {
			if (e.code === 'Space') {
				viewport.plugins.resume('drag')
			}
		}

		const handleKeyUp = (e: KeyboardEvent) => {
			if (e.code === 'Space') {
				if (toolsState.mode === 'draw') {
					viewport.plugins.pause('drag')
				}
			}
		}

		if (toolsState.mode === 'draw') {
			viewport.plugins.pause('drag')
		}

		window.addEventListener('keydown', handleKeyDown)
		window.addEventListener('keyup', handleKeyUp)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
			window.removeEventListener('keyup', handleKeyUp)
			viewport.plugins.resume('drag')
		}
	}, [viewport, toolsState.mode])
}

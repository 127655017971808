import { twMerge } from 'tailwind-merge'

interface IProps {
	children: React.ReactNode
	className?: string
	htmlFor?: string
}

const Label: React.FC<IProps> = ({ children, className = '', ...props }) => {
	return (
		<label
			className={twMerge(
				'mt-6 block text-sm font-medium text-gray-100 ',
				className,
			)}
			{...props}
		>
			{children}
		</label>
	)
}

export default Label

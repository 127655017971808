import { ShockwaveFilter } from '@pixi/filter-shockwave'
import { Container, useApp } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useEffect, useRef } from 'react'

interface Props {
	x: number
	y: number
	onComplete: () => void
}

const RainDropEffect = ({ x, y, onComplete }: Props) => {
	const app = useApp()
	const filterRef = useRef<ShockwaveFilter>()
	const startTime = useRef(Date.now())
	const DURATION = 3.0

	useEffect(() => {
		// Create new filter
		filterRef.current = new ShockwaveFilter()

		if (filterRef.current) {
			// Set center using Point
			filterRef.current.center = new PIXI.Point(x, y)

			// Configure other properties
			filterRef.current.amplitude = 15
			filterRef.current.wavelength = 80
			filterRef.current.speed = 150
			filterRef.current.brightness = 1
			filterRef.current.radius = -1
		}

		// Add filter to stage
		if (app.stage.filters) {
			app.stage.filters.push(filterRef.current)
		} else {
			app.stage.filters = [filterRef.current]
		}

		// Animation loop with easing
		const animate = () => {
			if (!filterRef.current) return

			const elapsed = (Date.now() - startTime.current) / 1000
			const progress = Math.min(elapsed / DURATION, 1)

			// Apply easing
			const easeOutQuart = 1 - Math.pow(1 - progress, 4)

			filterRef.current.time = elapsed
			filterRef.current.amplitude = 10 * (1 - easeOutQuart)
			filterRef.current.brightness = 1.5 * (1 - easeOutQuart) + 1

			// Remove effect after duration
			if (progress >= 1) {
				if (app.stage.filters) {
					app.stage.filters = app.stage.filters.filter(
						f => f !== filterRef.current,
					)
				}
				onComplete()
				return
			}

			requestAnimationFrame(animate)
		}

		animate()

		// Cleanup
		return () => {
			if (app.stage.filters && filterRef.current) {
				app.stage.filters = app.stage.filters.filter(
					f => f !== filterRef.current,
				)
			}
		}
	}, [app.stage, x, y, onComplete])

	return <Container />
}

export default RainDropEffect

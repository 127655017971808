import { useContext, useMemo } from 'react'
import GameContext from '../contexts/game'
import useUser from './useUser'

const useGetLog = () => {
	const { game } = useContext(GameContext)
	const { log } = game
	const { userId, isGM } = useUser()

	// Filter the log entries based on the user's role and access permissions
	const filteredLog = useMemo(() => {
		try {
			const result = log.allIds
				.map(id => {
					const entry = log.byId[id]
					if (!entry) {
						console.warn(`Log entry with id ${id} not found`)
						return null
					}
					return entry
				})
				.filter(entry => {
					if (!entry) return false
					const isSender = entry.sender === userId
					const isPublic = entry.access === 'public'
					const isPrivateAccessible =
						entry.access === 'private' &&
						Array.isArray(entry.accessList) &&
						entry.accessList.includes(userId)

					if (isGM) return true
					return isSender || isPublic || isPrivateAccessible
				})

			return result
		} catch (error) {
			console.error('Error filtering log entries:', error)
			return []
		}
	}, [log, isGM, userId])

	// Reverse the order of the filtered log entries
	const reverseOrderLog = useMemo(() => {
		try {
			const result = [...filteredLog].reverse()
			return result
		} catch (error) {
			console.error('Error reversing log entries:', error)
			return []
		}
	}, [filteredLog])

	return { log: filteredLog, reverseOrderLog }
}

export default useGetLog

import { useContext } from 'react'
import GameContext from '../contexts/game'
import useAssetUploader from './useAssetUploader'

export const useHandleBase64ImageUpload = (): (({
	name, // name of the 'document.values' key to save the asset id to
	documentId,
	base64Image,
}) => void) => {
	const { game, dispatch } = useContext(GameContext)
	const gameId = game._id
	const assetUploader = useAssetUploader()

	const handleUpload = async ({ name, documentId, base64Image }) => {
		console.log('handleUpload', { name, documentId })
		if (!documentId) return
		if (!name) return
		if (!base64Image) return
		const document = game.documents.byId[documentId]

		try {
			const imageType = base64Image.match(/data:image\/(.*);base64,/)[1]
			const byteCharacters = atob(base64Image.split(',')[1])
			const byteNumbers = new Array(byteCharacters.length)
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			const blob = new Blob([byteArray], { type: `image/${imageType}` })
			const file = new File([blob], `${name}.${imageType}`, {
				type: `image/${imageType}`,
			})
			const asset = await assetUploader(file, gameId)

			if (!asset) {
				console.error('Asset upload failed.')
				return
			}

			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: {
					updatedDocument: {
						...document,
						values: {
							...document.values,
							[name]: asset._id,
						},
					},
				},
			})

			console.log('asset', asset, 'document', document, 'name', name)
		} catch (error) {
			console.error('An error occurred during file upload:', error)
		}
	}

	return handleUpload
}

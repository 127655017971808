import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import { useGame } from '../../hooks/useGame'
import useRemoteRequestToOpenDocument from '../../hooks/useRemoteRequestToOpenDocument'
import useUser from '../../hooks/useUser'
import { Books } from '../../interfaces/book'
import { Documents } from '../../interfaces/game'
import Book from '../books/Book'
import Panel from '../interface/Panel'
import SectionDivider from '../SectionDivider'
import ListMessage from '../views/ListMessage'
import AddDocumentButton from './AddDocumentButton'
import DocumentList from './DocumentList'
import DocumentTypeList from './DocumentTypeList'
import FilterButton from './FilterButton'
import useUnpop from './useUnpop'

export type THoverItem = (
	dragIndexPath: number[],
	hoverIndexPath: number[],
) => void

export type TDragItem = {
	id: string
	indexPath: number[]
	children?: TDragItem[]
}

interface DocumentGroup {
	[key: string]: any[]
}

const groupDocumentsByType = (
	documents: any,
	collectionTypes: any[],
): DocumentGroup => {
	const groups: DocumentGroup = {}
	collectionTypes.forEach(type => {
		groups[type.type] = []
	})

	documents.allIds.forEach(id => {
		const doc = documents.byId[id]
		if (groups[doc.type]) {
			groups[doc.type].push(doc)
		}
	})

	return groups
}

const Library = () => {
	const { game, dispatch } = useGame()
	const { _id: gameId, documents, books } = game
	const { control, setValue } = useFormContext()
	const [dragIndex, setDragIndex] = useState(-1)
	const [hoverIndex, setHoverIndex] = useState(-1)
	const { userId, role } = useUser()
	const { showLibrary } = useUser()
	const collectionTypes = useCollectionTypes()
	useUnpop()
	useRemoteRequestToOpenDocument()

	const storageKey = `selectedType_${gameId}`
	const storedType = localStorage.getItem(storageKey)

	useEffect(() => {
		if (storedType) {
			setValue('selection', storedType)
		}
	}, [setValue, storedType])

	const selectedType = useWatch({
		control,
		name: 'selection',
		defaultValue: storedType || 'All',
	})

	useEffect(() => {
		localStorage.setItem(storageKey, selectedType)
	}, [selectedType, storageKey])

	const moveItem = useCallback(
		({ id, targetId, parentId, before = true }) => {
			const documentIndex = documents.allIds.indexOf(id)
			const targetIndex = documents.allIds.indexOf(targetId)
			if (documentIndex < 0 || targetIndex < 0) {
				console.error('Document or target not found!')
				return
			}
			const newAllIds = [...documents.allIds]
			newAllIds.splice(documentIndex, 1)
			const newIndex = before ? targetIndex : targetIndex + 1
			newAllIds.splice(newIndex, 0, id)
			dispatch({
				type: 'REORDER_DOCUMENTS',
				payload: {
					documentIds: newAllIds, // the reordered document IDs
					documentId: id, // the ID of the document that was moved
					parentId, // the new parentId of the moved document
				},
			})
		},
		[documents.allIds, dispatch],
	)

	const hoverItem = useCallback(
		(dragId: string, hoverId: string, before?: 'up' | 'down') => {
			const dragIndex = documents.allIds.findIndex(id => id === dragId)
			const hoverIndex = documents.allIds.findIndex(id => id === hoverId)

			setDragIndex(dragIndex)
			setHoverIndex(before === 'up' ? hoverIndex : hoverIndex + 1)
		},
		[documents.allIds],
	)

	const hasAccess = useCallback(
		(items: Documents | Books) => {
			const isGM = role === 'gm'
			return items.allIds.some(itemId => {
				const item = items.byId[itemId]
				const { access, accessList } = item
				const isPublic = access === 'public'
				return isGM || isPublic || accessList?.includes(userId)
			})
		},
		[role, userId],
	)

	const hasAccessToDocuments = useMemo(
		() => hasAccess(documents),
		[documents, hasAccess],
	)

	const hasAccessToBooks = useMemo(() => hasAccess(books), [books, hasAccess])

	const [showFilters, setShowFilters] = useState(false)

	const header = useMemo(
		() => (
			<div className='flex flex-col gap-3'>
				<SectionDivider
					label='Documents'
					children={
						<>
							<FilterButton
								isActive={showFilters}
								onClick={() => setShowFilters(!showFilters)}
							/>
							<AddDocumentButton />
						</>
					}
				/>

				{showFilters && <DocumentTypeList />}
			</div>
		),
		[showFilters],
	)

	const groupedDocuments = useMemo(
		() => groupDocumentsByType(documents, collectionTypes),
		[documents, collectionTypes],
	)

	const booksList = useMemo(
		() => (
			<ul
				className={twMerge(
					'grid grid-flow-row overflow-auto',
					hasAccessToBooks && 'grid-cols-2',
				)}
			>
				{!hasAccessToBooks ? (
					<ListMessage title='No Books' />
				) : (
					books.allIds.map(bookId => <Book key={bookId} bookId={bookId} />)
				)}
			</ul>
		),
		[books.allIds, hasAccessToBooks],
	)

	if (!showLibrary) return null

	return (
		<Panel header={header}>
			{selectedType === 'Books' && hasAccessToBooks ? (
				booksList
			) : (
				<>
					{!hasAccessToDocuments ? (
						<ListMessage title='No Documents' />
					) : (
						<DocumentList
							selectedType={selectedType}
							groupedDocuments={groupedDocuments}
							collectionTypes={collectionTypes}
							dragIndex={dragIndex}
							hoverIndex={hoverIndex}
							hoverItem={hoverItem}
							moveItem={moveItem}
						/>
					)}
				</>
			)}
		</Panel>
	)
}

export default Library

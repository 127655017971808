import { useContext } from 'react'
import GameContext from '../../contexts/game'
import Label from '../FormComponents/Label'
import ColorSwatch from './ColorSwatch'

type Props = {
	setEnableClickOutside: (enable: boolean) => void
}

const ThemeEditor = ({ setEnableClickOutside }: Props) => {
	const { game } = useContext(GameContext)
	const { theme } = game

	return (
		<div>
			<div className='center flex items-center space-x-2'>
				<ColorSwatch
					color={theme.primary}
					colorName='primary'
					setEnableClickOutside={setEnableClickOutside}
				/>
				<Label htmlFor='primary' className='mt-0 flex-grow'>
					Primary Color
				</Label>
			</div>

			<div className='center mt-0.5 flex items-center space-x-2'>
				<ColorSwatch
					color={theme.secondary}
					colorName='secondary'
					setEnableClickOutside={setEnableClickOutside}
				/>
				<Label htmlFor='primary' className='mt-0 flex-grow'>
					Secondary Color
				</Label>
			</div>
		</div>
	)
}

export default ThemeEditor

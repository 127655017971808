// modal window cannot move or resize, it's scrimmed and on top of everything else

import { animated } from '@react-spring/web'
import { ReactNode, useRef } from 'react'
import { createPortal } from 'react-dom'
import OutsideClickHandler from 'react-outside-click-handler'
import { twMerge } from 'tailwind-merge'
import { IDimensions, IModalSize } from '../../interfaces/game'
import CloseButton from '../FormComponents/CloseButton'
import DialogBody from './Body'
import DialogFooter from './Footer'
import DialogHeader from './Header'

export interface IModal {
	id: string
	// open?: boolean
	title: string
	children: React.ReactNode
	className?: string
	bodyClassName?: string
	// showPrimaryButton?: boolean
	// showSecondaryButton?: boolean
	// primaryButtonLabel?: string
	// secondaryButtonLabel?: string
	headerChildren?: React.ReactNode // pass function with () on end!
	footerChildren?: React.ReactNode
	size?: IModalSize
	headerIcon?: ReactNode
	// modal?: boolean
	enableClickOutside?: boolean
	// onPointerDown?: () => void
	onClose?: () => void
	// onSubmit?: () => void
	// closeWindow?: () => void
}

const ModalWindow = ({
	id,
	title,
	children,
	className,
	headerChildren,
	footerChildren,
	bodyClassName = '',
	size = 'small',
	headerIcon = '',
	enableClickOutside = true,
	onClose = () => {}, // how clients can call things when we're closing
}: IModal) => {
	const minW = 320
	const minH = 200
	const sizeMap: Record<IModalSize, IDimensions> = {
		small: { width: minW, height: minH },
		medium: { width: minW * 1.5, height: minH * 2 },
		large: { width: minW * 3, height: minH * 3 },
		full: { width: 'calc(100vw - 50px)', height: 'calc(100vh - 50px)' },
	}
	const windowRef = useRef(document.createElement('div'))

	const passedHeaderchildren = () => {
		if (headerChildren) {
			return headerChildren
		} else {
			return <CloseButton onClick={onClose} />
		}
	}

	return createPortal(
		<div
			className='fixed inset-0 flex items-center justify-center'
			style={{
				zIndex: 1000,
			}}
		>
			<div className='absolute inset-0 bg-black/70' />
			<animated.div
				id={id}
				className={twMerge(
					'absolute top-0 left-0 z-[200] flex h-full w-full items-center justify-center',
					// 'absolute top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black/50 backdrop-blur-sm backdrop-filter',
					className,
				)}
				style={{
					paddingTop: '40px',
					paddingBottom: '40px',
				}}
			>
				<OutsideClickHandler
					onOutsideClick={onClose}
					disabled={!enableClickOutside}
				>
					<div
						ref={windowRef}
						className={twMerge(
							'relative flex h-full flex-col overflow-hidden rounded-2xl bg-gray-900',
							className,
						)}
						style={{
							width: sizeMap[size].width,
							minHeight: sizeMap[size].height,
							maxHeight: 'calc(100vh - 80px)',
							touchAction: 'none',
							boxShadow:
								'0 1.7px 16.8px rgba(0,0,0,0.02), 0 4.3px 27.9px rgba(0,0,0,0.03), 0 8.7px 39.6px rgba(0,0,0,0.03), 0 17.9px 53.6px rgba(0,0,0,0.04), 0 49px 80px rgba(0,0,0,0.06), 0 0 0 1px rgba(255,255,255,.05)',
						}}
					>
						<DialogHeader title={title} headerIcon={headerIcon}>
							{passedHeaderchildren()}
						</DialogHeader>
						<DialogBody className={bodyClassName}>{children}</DialogBody>
						{footerChildren && <DialogFooter>{footerChildren}</DialogFooter>}
					</div>
				</OutsideClickHandler>
			</animated.div>
		</div>,
		document.body,
	)
}

export default ModalWindow

import { useMutation } from '@apollo/client'
import { UserDataUpdatedMessage } from '../../../../../shared/types/socket'
import { socket } from '../../../contexts/socket'
import { LEAVE_GAME } from '../../../graphql/games'
import useUser from '../../../hooks/useUser' // Import useUser to get userId
import DangerMenuItem from '../../dropdownmenu/DangerMenuItem'

type Props = {
	gameId: string
	refetch?: () => void
}

const LeaveGame = ({ gameId, refetch }: Props) => {
	const { userId } = useUser()
	const [leaveGame] = useMutation(LEAVE_GAME, {
		onCompleted: () => {
			const message: UserDataUpdatedMessage = { gameId }
			socket.emit('userDataUpdated', message)
			if (refetch) refetch()
		},
	})

	const handleLeave = () => {
		const confirmed = window.confirm(`Are you sure you want to leave the game?`)

		if (!confirmed) {
			return
		}

		leaveGame({
			variables: { gameId, userId },
		})
	}

	return <DangerMenuItem onSelect={handleLeave}>Leave Game</DangerMenuItem>
}

export default LeaveGame

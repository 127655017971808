import { yupResolver } from '@hookform/resolvers/yup'
import { useContext } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { NavLink, useNavigate } from 'react-router-dom'
import request from 'superagent'
import * as yup from 'yup'
import AuthContext from '../../contexts/auth'
import Button from '../FormComponents/Button'
import Label from '../FormComponents/Label'
import Input from '../Input'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

interface IFormInputs {
	name: string
	email: string
	password: string
}

const schema = yup
	.object({
		name: yup.string().required('Name is required'),
		email: yup.string().email().required(),
		password: yup.string().required(),
	})
	.required()

const AuthPage = () => {
	const navigate = useNavigate()
	const { authState } = useContext(AuthContext)

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	})

	const signUpHandler = (data: FieldValues) => {
		const { name, email, password } = data

		request
			.post('/api/createuser')
			.send({
				name: name,
				email: email,
				password: password,
			})
			.then(res => {
				if (!res.ok) {
					if (res.status === 400) {
						alert('Something went wrong.')
					}
					if (res.status === 409) {
						alert('Email address is already in use.')
					}
				}

				if (res.status !== 200 && res.status !== 201) {
					throw new Error('Failed!')
				}

				authState.login(res.body.token, res.body.user)
				localStorage.setItem('token', res.body.token)
				localStorage.setItem('user', res.body.user._id)
				navigate('/profile')
			})
			.catch(error => {
				alert(error)
				console.log('create user error', error)
			})
	}

	return (
		<Card className='h-screen'>
			<PageHeader title='Sign Up' className='text-center' />
			<div className='mb-12 text-center'>
				<NavLink to='/signin' className='text-gray-400 underline'>
					Sign In Instead?
				</NavLink>
			</div>

			<form onSubmit={handleSubmit(signUpHandler)}>
				<Label htmlFor='name'>Name</Label>
				<Input
					placeholder='Name...'
					inputClassName='dark:bg-gray-900'
					{...register('name', {
						required: true,
					})}
				/>
				{errors.name && <p className='text-red-500'>Name is required</p>}

				<Label htmlFor='email'>Email</Label>
				<Input
					type='email'
					placeholder='Email...'
					inputClassName='dark:bg-gray-900'
					{...register('email', { required: true })}
				/>
				{errors.email && <p className='text-red-500'>Email is required</p>}

				<Label htmlFor='password'>Password</Label>
				<Input
					type='password'
					placeholder='Password...'
					inputClassName='dark:bg-gray-900'
					{...register('password', { required: true })}
				/>
				{errors.password && (
					<p className='text-red-500'>Password is required</p>
				)}

				<Button className='mt-8' type='submit'>
					{'Sign Up'}
				</Button>
			</form>
		</Card>
	)
}

export default AuthPage

import { Pause, Play } from 'lucide-react'
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import SectionButton from './SectionButton'

const StartStopButton = () => {
	const { game, dispatch } = useContext(GameContext)
	const { currentRound, turnOrder } = game.turnTracker

	const handleStart = () => {
		dispatch({ type: 'START_TURN_TRACKER' })

		const firstPlayerId = turnOrder.allIds[0]
		const firstName = turnOrder.byId[firstPlayerId].name

		dispatch({
			type: 'ADD_SYSTEM_MESSAGE',
			payload: {
				message: `Turn tracking started. ${firstName} goes first.`,
			},
		})
	}

	const handleStop = () => {
		dispatch({ type: 'STOP_TURN_TRACKER' })

		dispatch({
			type: 'ADD_SYSTEM_MESSAGE',
			payload: {
				message: 'Turn tracking ended.',
			},
		})
	}

	const hasActiveItems = turnOrder.allIds.some(
		id => turnOrder.byId[id].isActive,
	)

	if (currentRound > 0) {
		return (
			<SectionButton onClick={handleStop}>
				<Pause className='h-5 w-5' />
			</SectionButton>
		)
	}

	return (
		<SectionButton onClick={handleStart} disabled={!hasActiveItems}>
			<Play className='h-5 w-5' />
		</SectionButton>
	)
}

export default StartStopButton

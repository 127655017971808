import { useState } from 'react'

const useBookSelection = <T extends string>(initialSelection: T[] = []) => {
	const [selectedIds, setSelectedIds] = useState<T[]>(initialSelection)

	const handleSelect = (id: T, selected: boolean) => {
		setSelectedIds(prevSelectedIds =>
			selected
				? [...prevSelectedIds, id]
				: prevSelectedIds.filter(i => i !== id),
		)
	}

	const handleDeselectAll = () => {
		setSelectedIds([])
	}

	return { selectedIds, handleSelect, handleDeselectAll }
}

export default useBookSelection

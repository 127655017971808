import React, { forwardRef, useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import { IDocument } from '../../../../shared/types/document'
import GameContext from '../../contexts/game'
import useGetCollectionName from '../../hooks/useGetCollectionName'
import Menu from '../documentmenu/Menu'
import BaseType from '../interface/text/BaseType'
import AccessDots from './AccessDots'
import ItemThumbnail from './ItemThumbnail'
import DocumentWindowMenuButton from './MenuButton'

type Props = {
	document: IDocument
	className?: string
	style?: React.CSSProperties
	showMenuButton?: boolean
}

const ItemInformation = forwardRef<HTMLDivElement, Props>(
	({ document, className, showMenuButton = true }, ref) => {
		const { game } = useContext(GameContext)
		const { name } = document.values
		const { singularName } = useGetCollectionName(document)
		const isCharacter =
			game.system?.collections?.find(c => c.type === document.type)
				.canAssumeAsCharacter === 'true'

		return (
			<div
				ref={ref}
				className={twMerge(
					'relative flex flex-auto cursor-pointer space-x-1 overflow-hidden py-1.5',
					className,
				)}
			>
				<ItemThumbnail id={document._id} round={isCharacter} />

				<div className='flex flex-1 flex-col overflow-hidden'>
					<div className='mt-0.5 truncate'>
						<BaseType>
							{name ? (
								name
							) : (
								<span className='italic text-gray-500'>Unnamed</span>
							)}
						</BaseType>
					</div>
					{singularName !== 'Folder' && (
						<div className='flex space-x-1'>
							<AccessDots document={document} />
						</div>
					)}
				</div>
				<div className='relative z-10'>
					<Menu document={document}>
						<DocumentWindowMenuButton
							className='bg-gray-500/10'
							show={showMenuButton}
						/>
					</Menu>
				</div>
			</div>
		)
	},
)

export default ItemInformation

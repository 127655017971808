import { forwardRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import Caption from '../Caption'
import Label from './Label'

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	label?: string
	description?: string
	defaultChecked?: boolean
	title?: string
	className?: string
}

const Checkbox = forwardRef<HTMLInputElement, IProps>(
	({ defaultChecked, className = '', label, description, ...props }, ref) => {
		const [isEnabled, setEnabled] = useState(defaultChecked)

		return (
			<div className='mt-1 flex items-start justify-between' {...props}>
				<input
					type='checkbox'
					className={twMerge(
						'my-1 mr-3 inline-flex h-4 w-4 flex-shrink rounded-md border-gray-300 bg-white shadow-sm dark:border-gray-700 dark:bg-gray-900',
						className,
					)}
					ref={ref}
					onChange={() => setEnabled(!isEnabled)}
					defaultChecked={defaultChecked}
					id={props.id || props.name || 'checkbox'}
					{...props}
				/>
				<span className='flex flex-grow flex-col'>
					<Label
						className='mt-0 pr-2 leading-6 text-gray-900 dark:text-white'
						htmlFor={props.id || props.name || 'checkbox'}
					>
						{label}
					</Label>
					<Caption>{description}</Caption>
				</span>
			</div>
		)
	},
)

Checkbox.displayName = 'Checkbox'

export default Checkbox

import { useContext, useEffect, useState } from 'react'
import { IDocument } from '../../../shared/types/document'
import GameContext from '../contexts/game'

const useGetCollectionName = (document: IDocument) => {
	const { game } = useContext(GameContext)
	const [singularName, setSingularName] = useState<string>('')

	const getCollectionSingularName = (document: any) => {
		const collection = game.system?.collections?.find(
			c => c.type === document.type,
		)
		const type = collection?.singularName ?? document.type
		return type
	}

	useEffect(() => {
		const singularName = getCollectionSingularName(document)
		setSingularName(singularName)
	}, [document])

	return { singularName, getCollectionSingularName }
}

export default useGetCollectionName

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { IResource } from '../../../../shared/types/resources'
import address from '../../config'
import { IBook } from '../../interfaces/book'
import BookMenu from './menu/BookMenu'
import ResourceMenu from './ResourceMenu'

type Props = {
	resource: IResource
	book?: IBook // book is an in-game data modal with access properties
	selectMode?: boolean
	selected?: boolean
	onSelect?: (selected: boolean) => void
}

const BookItem = ({
	resource,
	book,
	selectMode,
	selected = false,
	onSelect,
}: Props) => {
	const isGame = window.location.pathname.includes('game')
	const { gameId } = useParams()
	const [windows, setWindows] = useState<{ [key: string]: Window | null }>({})

	const handleSelect = () => onSelect?.(!selected)

	const handleOpen = () => {
		if (selectMode) {
			handleSelect()
		} else if (book?._id) {
			// for in-game books
			const bookId = book?._id
			if (!bookId) return
			if (windows[bookId] && !windows[bookId]?.closed) {
				windows[bookId]?.focus()
			} else {
				const newWindow = window.open(
					`${origin}/book/${gameId}/${bookId}`,
					'_blank',
					'',
				)
				setWindows(prevWindows => ({ ...prevWindows, [bookId]: newWindow }))
			}
		} else if (resource?._id) {
			// for user library
			const resourceId = resource._id
			if (!resourceId) return
			if (windows[resourceId] && !windows[resourceId]?.closed) {
				windows[resourceId]?.focus()
			} else {
				const newWindow = window.open(
					`${origin}/resource/${resourceId}`,
					'_blank',
					'',
				)
				setWindows(prevWindows => ({ ...prevWindows, [resourceId]: newWindow }))
			}
		}
	}

	return (
		<li
			className={twMerge(
				'rounded-lg p-2',
				selectMode && 'cursor-pointer',
				selected && 'bg-black/5 dark:bg-white/10',
			)}
		>
			<div className='relative aspect-[0.75]'>
				{selectMode && (
					<input
						type='checkbox'
						checked={selected}
						onChange={handleSelect}
						className='absolute top-2 right-2 z-10'
					/>
				)}
				<button onClick={handleOpen} className='relative h-full w-full'>
					<div className='relative flex h-full w-full items-end justify-center'>
						<div className='relative overflow-hidden rounded-sm'>
							<img
								src={`${address}${resource.thumbnailurl}`}
								alt={resource.name}
								className='origin-bottom object-contain shadow-md transition-all duration-700 ease-in-out hover:scale-105 hover:shadow-2xl'
								style={{ maxHeight: '100%', width: 'auto' }}
							/>
							<div
								className='absolute inset-0 mix-blend-multiply'
								style={{
									background:
										'linear-gradient(90deg, #AAA 0%, #DDD 1%, #DDD 2%, #999 3%, #FFF 4%, #FFF 22%, #FFF 100%)',
								}}
							></div>
						</div>
					</div>
				</button>
			</div>
			<div className='mt-2 flex w-full justify-between space-x-2'>
				<div className='flex flex-col'>
					<p className='line-clamp-2 w-full overflow-hidden text-ellipsis text-left text-xs text-black'>
						<a
							href={`${address}${resource.fileurl}`}
							target='_blank'
							rel='noreferrer'
						>
							{resource.resourceType === 'book'
								? resource.metadata.title
								: resource.name}
						</a>
					</p>
					{book && (
						<div className='flex items-center text-xs text-gray-500'>
							{book.access === 'private' ? (
								<>
									<EyeSlashIcon className='mr-1 h-3 w-3' />
									Private
								</>
							) : (
								<>
									<EyeIcon className='mr-1 h-3 w-3' />
									Public
								</>
							)}
						</div>
					)}
				</div>
				{!selectMode && !isGame && <ResourceMenu resource={resource} />}
				{!selectMode && isGame && book && <BookMenu book={book} />}
			</div>
			{/* <Metadata
				resourceType={resource.resourceType}
				metadata={resource.metadata}
			/> */}
		</li>
	)
}

export default BookItem

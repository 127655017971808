import { useContext, useMemo, useCallback } from 'react'
import GameContext from '../contexts/game'

const useGetUserSettings = (userId?: string) => {
	const { game } = useContext(GameContext)

	const fetchUserSettings = useCallback(
		(id: string) => game.userSettings[id],
		[game.userSettings],
	)

	const userSettings = useMemo(
		() => fetchUserSettings(userId),
		[fetchUserSettings, userId],
	)

	return { userSettings, fetchUserSettings }
}

export default useGetUserSettings

import { useEffect, useState } from 'react'
type NodeEnv = 'development' | 'production' | 'test' | 'staging'

const useProxy = () => {
	const [proxy, setProxy] = useState('')
	const env: NodeEnv = process.env.NODE_ENV as NodeEnv

	useEffect(() => {
		if (env === 'development') {
			const protocol = window.location.protocol
			const host = window.location.hostname
			setProxy(`${protocol}//${host}:3002`)
		} else if (env === 'staging') {
			setProxy('https://newrealms-aux-staging.herokuapp.com')
		} else {
			setProxy('https://newrealms-aux.herokuapp.com')
		}
	}, [])

	return proxy
}

export default useProxy

export class PriorityQueue<T> {
	private items: { element: T; priority: number }[] = []

	enqueue(element: T, priority: number): void {
		this.items.push({ element, priority })
		this.items.sort((a, b) => a.priority - b.priority)
	}

	dequeue(): T | undefined {
		return this.items.shift()?.element
	}

	isEmpty(): boolean {
		return this.items.length === 0
	}

	includes(element: T): boolean {
		return this.items.some(item => item.element === element)
	}
}

import { BookOpenIcon as BookOpenIconOutline } from '@heroicons/react/24/outline'
import { BookOpenIcon as BookOpenIconSolid } from '@heroicons/react/24/solid'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useContext, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import PdfViewerContext from '../../contexts/book'
import CheckboxItem from '../dropdownmenu/CheckboxItem'
import MenuContent from '../dropdownmenu/MenuContent'
import menuItemClasses from '../dropdownmenu/menuItemClasses'
import Separator from '../dropdownmenu/Separator'
import ToolButton from '../interface/ToolButton'

const zoomLevels = [0.5, 0.75, 1, 1.25, 1.5, 2]

export const PagesDisplayButton = () => {
	const { bookState, bookDispatch } = useContext(PdfViewerContext)
	const { pagesToDisplay, isCoverAlone, fitWidth, fitPage, zoomLevel } =
		bookState
	const [isOpen, setIsOpen] = useState(false)

	const onOpenChange = (open: boolean) => {
		setIsOpen(open)
	}

	const handleSwitchPagesToDisplay = (pages: number) => {
		bookDispatch({
			type: 'SET_PAGES_TO_DISPLAY',
			payload: pages,
		})
	}

	const handleCoverAlone = () => {
		bookDispatch({
			type: 'SET_IS_COVER_ALONE',
			payload: !isCoverAlone,
		})
	}

	const handleFitWidth = () => {
		bookDispatch({
			type: 'SET_FIT_WIDTH',
			payload: !fitWidth,
		})
	}

	const handleFitPage = () => {
		bookDispatch({
			type: 'SET_FIT_PAGE',
			payload: !fitPage,
		})
	}

	const handleZoomLevel = (level: number) => {
		bookDispatch({
			type: 'SET_ZOOM_LEVEL',
			payload: level,
		})
	}

	return (
		<DropdownMenu.Root onOpenChange={onOpenChange}>
			<DropdownMenu.Trigger>
				<ToolButton
					className={twMerge(
						'flex w-9 items-center justify-center text-[#A37F43]',
						isOpen ? 'bg-[#A37F4322]' : 'hover:bg-[#A37F4322]',
					)}
				>
					{pagesToDisplay === 1 ? (
						<BookOpenIconOutline className='h-5 w-5' />
					) : (
						<BookOpenIconSolid className='h-5 w-5' />
					)}
				</ToolButton>
			</DropdownMenu.Trigger>

			<MenuContent>
				<CheckboxItem
					checked={pagesToDisplay === 1}
					onCheckedChange={() => handleSwitchPagesToDisplay(1)}
				>
					Show One Page
				</CheckboxItem>
				<CheckboxItem
					checked={pagesToDisplay === 2}
					onCheckedChange={() => handleSwitchPagesToDisplay(2)}
				>
					Show Two Pages
				</CheckboxItem>

				{pagesToDisplay === 2 && (
					<>
						<Separator />

						<CheckboxItem
							checked={isCoverAlone}
							onCheckedChange={handleCoverAlone}
						>
							Show Cover Alone
						</CheckboxItem>
					</>
				)}

				<Separator />

				<CheckboxItem checked={fitWidth} onCheckedChange={handleFitWidth}>
					Fit to Width
				</CheckboxItem>
				<CheckboxItem checked={fitPage} onCheckedChange={handleFitPage}>
					Fit to Page
				</CheckboxItem>

				<Separator />

				<DropdownMenu.Label className='px-2 py-1 text-xs font-semibold text-gray-500'>
					Zoom Level
				</DropdownMenu.Label>
				{zoomLevels.map(level => (
					<DropdownMenu.Item
						key={level}
						className={twMerge(
							menuItemClasses,
							zoomLevel === level && 'bg-[#A37F4322]',
						)}
						onSelect={() => handleZoomLevel(level)}
					>
						{level * 100}%
					</DropdownMenu.Item>
				))}
			</MenuContent>
		</DropdownMenu.Root>
	)
}

// takes the document model, hands it to ChatGPT and asks for it to be filled out
import { useContext } from 'react'
import GameContext from '../../contexts/game'
import MenuItem from '../dropdownmenu/MenuItem'
import useUser from '../../hooks/useUser'

type Props = {
	documentId: string
}

const AIGenerator = ({ documentId }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const document = game.documents.byId[documentId]
	const { isGM } = useUser()

	const handleGeneratorMessage = async (prompt: string) => {
		const apiResponse = await fetch('/api/aigeneratecharacter', {
			method: 'POST',
			body: JSON.stringify({ prompt }),
			headers: { 'Content-Type': 'application/json' },
		})

		const responseData = await apiResponse.json()

		return responseData.response
	}

	const handleGenerateCharacter = async () => {
		// get a prompt from the user
		const prompt = window.prompt(
			'Enter a prompt for the AI to generate a character',
		)

		const response = await handleGeneratorMessage(
			`${JSON.stringify(document.values)}
			
			User promp: ${prompt}`,
		)

		let newValues = JSON.parse(response)
		newValues = {
			...document.values,
			...newValues,
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument: {
					...document,
					values: newValues,
				},
			},
		})
	}

	if (!isGM) return null

	return (
		<MenuItem onSelect={handleGenerateCharacter}>Generate Character</MenuItem>
	)
}

export default AIGenerator

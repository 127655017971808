import { useGame } from '../../hooks/useGame'
import ClearAllButton from './ClearAllButton'
import NextButtons from './NextButton'
import PreviousButtons from './PreviousButton'
import SortButton from './SortButton'
import StartStopButton from './StartStopButton'

const TurnTrackerControls = () => {
	const { game } = useGame()
	const { turnOrder, currentRound } = game.turnTracker

	const hasActiveItems = turnOrder.allIds.some(
		id => turnOrder.byId[id].isActive,
	)
	const isRunning = currentRound > 0

	if (!turnOrder.allIds.length) return null

	return (
		<div className='mb-2 flex justify-center'>
			<ClearAllButton />
			<SortButton />
			<StartStopButton />
			<PreviousButtons disabled={!hasActiveItems || !isRunning} />
			<NextButtons disabled={!hasActiveItems || !isRunning} />
		</div>
	)
}

export default TurnTrackerControls

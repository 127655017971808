import { twMerge } from 'tailwind-merge'
import useGetThumbnailAsset from '../../hooks/useGetThumbnailAsset'

type Props = React.HTMLAttributes<HTMLDivElement> & {
	id: string
	round?: boolean
}

export default function ItemThumbnail({
	id,
	round,
	className,
	...rest
}: Props) {
	const asset = useGetThumbnailAsset(id)

	if (!asset) return null

	return (
		<div
			{...rest}
			className={twMerge(
				'mr-2 aspect-square h-10 self-start overflow-hidden',
				round ? '-mt-0.5 mr-1.5 h-11 rounded-full' : 'rounded-lg',
				className,
			)}
			style={{
				backgroundImage: `url(${asset.fileurl})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		/>
	)
}

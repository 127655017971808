import { ICollection } from '../../../shared/types/system'
import { useGame } from './useGame'

const useCollectionDefinition = (): {
	getCollectionDefinition: (type: string) => ICollection | undefined
} => {
	const { game } = useGame()

	const getCollectionDefinition = (type: string): ICollection | undefined => {
		return game.system?.collections?.find(
			collection => collection.type === type,
		)
	}

	return { getCollectionDefinition }
}

export default useCollectionDefinition

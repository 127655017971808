import { PictureInPicture2 } from 'lucide-react'
import DocumentWindowHeaderButton from './DocumentWindowHeaderButton'

type Props = {
	handlePop: () => void
}

const PopoutButton = ({ handlePop }: Props) => {
	return (
		<DocumentWindowHeaderButton label='Popout as Window' onClick={handlePop}>
			<PictureInPicture2 className='h-5 w-5' />
		</DocumentWindowHeaderButton>
	)
}

export default PopoutButton

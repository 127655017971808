import { Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback } from 'react'
import { Point } from '../../interfaces/draw'
import {
	DRAW_LINE_ALPHA,
	POLYLINE_WIDTH,
	SNAP_DISTANCE,
	SNAP_PREVIEW_COLOR,
} from '../../utils/drawingConstants'

interface Props {
	lastAnchorPoint: Point | null
	currentPoint: Point | null
	color: string
	startingPoint: Point | null
	isNearStart?: boolean
}

const PolylineDrawing = ({
	lastAnchorPoint,
	currentPoint,
	color,
	startingPoint,
	isNearStart = false,
}: Props) => {
	const drawDashedLine = useCallback(
		(
			g: PIXI.Graphics,
			startX: number,
			startY: number,
			endX: number,
			endY: number,
			dashLength = 5,
			gapLength = 5,
		) => {
			const dx = endX - startX
			const dy = endY - startY
			const distance = Math.sqrt(dx * dx + dy * dy)
			const dashCount = Math.floor(distance / (dashLength + gapLength))
			const unitX = dx / distance
			const unitY = dy / distance

			let currentX = startX
			let currentY = startY

			for (let i = 0; i < dashCount; i++) {
				const dashStartX = currentX
				const dashStartY = currentY
				currentX += unitX * dashLength
				currentY += unitY * dashLength

				g.moveTo(dashStartX, dashStartY)
				g.lineTo(currentX, currentY)

				currentX += unitX * gapLength
				currentY += unitY * gapLength
			}

			// Draw remaining dash if any
			if (currentX < endX || currentY < endY) {
				g.moveTo(currentX, currentY)
				g.lineTo(endX, endY)
			}
		},
		[],
	)

	const draw = useCallback(
		(g: PIXI.Graphics) => {
			g.clear()

			if (!lastAnchorPoint || !currentPoint) return

			// Draw current line segment
			g.lineStyle({
				width: POLYLINE_WIDTH,
				color: parseInt(color.replace('#', '0x')),
				alpha: DRAW_LINE_ALPHA,
				cap: PIXI.LINE_CAP.ROUND,
				join: PIXI.LINE_JOIN.ROUND,
			})

			g.moveTo(lastAnchorPoint.x, lastAnchorPoint.y)
			g.lineTo(currentPoint.x, currentPoint.y)

			// If near starting point and we have a starting point, draw closing preview
			if (isNearStart && startingPoint) {
				g.lineStyle({
					width: POLYLINE_WIDTH,
					color: parseInt(SNAP_PREVIEW_COLOR.replace('#', '0x')),
					alpha: DRAW_LINE_ALPHA,
					cap: PIXI.LINE_CAP.ROUND,
					join: PIXI.LINE_JOIN.ROUND,
				})

				// Draw dashed preview line
				drawDashedLine(
					g,
					currentPoint.x,
					currentPoint.y,
					startingPoint.x,
					startingPoint.y,
				)

				// Draw highlight circle around start point
				g.lineStyle(0)
				g.beginFill(parseInt(SNAP_PREVIEW_COLOR.replace('#', '0x')), 0.3)
				g.drawCircle(startingPoint.x, startingPoint.y, SNAP_DISTANCE)
				g.endFill()
			}
		},
		[
			lastAnchorPoint,
			currentPoint,
			color,
			startingPoint,
			isNearStart,
			drawDashedLine,
		],
	)

	return <Graphics draw={draw} />
}

export default PolylineDrawing

import AIGamemaster from './FeatureFlags/AIGamemaster'
import Syrinscape from './FeatureFlags/Syrinscape'

const SettingsFeatureFlags = () => {
	return (
		<div>
			<div className='center mt-4 align-middle'>
				<Syrinscape />
				<AIGamemaster />
			</div>
		</div>
	)
}

export default SettingsFeatureFlags

import { PowerIcon } from '@heroicons/react/24/solid'
import { NavLink } from 'react-router-dom'
import ToolButton from '../interface/ToolButton'

export default function ExitButton() {
	return (
		<NavLink to='/profile/books'>
			<ToolButton className='mr-4 flex w-9 items-center justify-center text-[#A37F43] hover:bg-[#A37F4322]'>
				<PowerIcon className='h-5 w-5' aria-hidden='true' />
			</ToolButton>
		</NavLink>
	)
}

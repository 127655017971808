import { Viewport } from 'pixi-viewport'
import { Fragment } from 'react'
import { IActor, IActorLocation } from '../../../../shared/types/actor'
import useActorsInScene from '../../hooks/useActorsInScene'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import { TokenMenuState } from '../../hooks/useStageMenus'
import Token from './Token'

interface TokensProps {
	onShowMenu: (state: TokenMenuState) => void
	viewport: Viewport
	activeTokenId: string | null
	selectedTokenIds: string[]
	onTokenSelect: (id: string, isShiftKey: boolean) => void
}

const Tokens = ({
	onShowMenu,
	viewport,
	activeTokenId,
	selectedTokenIds,
	onTokenSelect,
}: TokensProps) => {
	const activeScene = useGetActiveScene()
	const actorsInScene = useActorsInScene(activeScene?._id)

	if (!activeScene?._id) {
		return null
	}

	return (
		<>
			{actorsInScene.map(document => {
				const location = document.values?.locations?.find(
					(loc: IActorLocation) => loc?.mapId === activeScene._id,
				)

				if (!location) {
					return null
				}

				return (
					<Fragment key={document._id}>
						<Token
							document={document as IActor}
							location={location}
							onShowMenu={onShowMenu}
							viewport={viewport}
							isActive={document._id === activeTokenId}
							isSelected={selectedTokenIds.includes(document._id)}
							onSelect={onTokenSelect}
						/>
					</Fragment>
				)
			})}
		</>
	)
}

export default Tokens

import DiceBox from '@3d-dice/dice-box'
import DiceParser from '@3d-dice/dice-parser-interface'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { DiceConfig } from '../../../../shared/types/dice'
import { ParsedNotation } from '../../interfaces/dice-parser'
import {
	DiceResult,
	ExpressionResult,
	RollResultArray,
} from '../../interfaces/dicebox'
import Overlapper from '../Overlapper'
import ColorSwatch from '../settings/ColorSwatch'
import Stepper from '../Stepper'
import Translucency from '../Translucency'
import AdvRollBtn from './AdvRollButton'
import './dice.css'

const DRP = new DiceParser()
const DICE_CANVAS_ID = 'dice-canvas'
const ASSET_PATH = '/assets/dice-box/'

const rpgDiceConfig: DiceConfig[] = [
	{ label: 'D4', notation: '1d4', icons: ['d4'] },
	{ label: 'D6', notation: '1d6', icons: ['d6'] },
	{ label: 'D8', notation: '1d8', icons: ['d8'] },
	{ label: 'D10', notation: '1d10', icons: ['d10'] },
	{ label: 'D12', notation: '1d12', icons: ['d12'] },
	{ label: 'D20', notation: '1d20', icons: ['d20'] },
	{ label: 'D100', notation: '1d100', icons: ['d10', 'd10'] },
]

interface DiceToolsProps {
	color?: string
	diceConfig?: DiceConfig[]
	showColorPicker?: boolean
	onColorChange?: (color: string) => void
	onRollResults?: (results: ExpressionResult) => void
	rollNotation?: string
}

function calculateDiceScale(viewportHeight: number): number {
	const baseHeight = 1080 // Reference height (e.g., 1080p)
	const baseScale = 3.25 // The scale you want at the reference height
	const scaleFactor = viewportHeight / baseHeight
	return baseScale / scaleFactor
}

export default function DiceTools({
	color = '#222222',
	diceConfig = rpgDiceConfig,
	showColorPicker = true,
	onColorChange,
	onRollResults = () => {},
	rollNotation,
}: DiceToolsProps) {
	const [modifier, setModifier] = useState(0)
	const [diceColor, setDiceColor] = useState(color)
	const Dice = useRef<any>(null)
	const [isInitialized, setIsInitialized] = useState(false)
	const [isRolling, setIsRolling] = useState(false)
	const [needsClear, setNeedsClear] = useState(false)

	const updateDiceTheme = useCallback(
		(newColor: string) => {
			if (Dice.current && isInitialized) {
				try {
					Dice.current.updateConfig({ themeColor: newColor })
				} catch (error) {
					console.error('Error updating dice theme:', error)
				}
			}
		},
		[isInitialized],
	)

	const handleColorChange = useCallback(
		(newColor: string) => {
			setDiceColor(newColor)
			updateDiceTheme(newColor)
			onColorChange?.(newColor)
		},
		[onColorChange, updateDiceTheme],
	)

	const handleRollComplete = useCallback(
		(results: DiceResult) => {
			console.log('Roll complete, raw results:', results)

			const rerolls = DRP.handleRerolls(results)
			console.log('Rerolls needed:', rerolls.length > 0 ? rerolls : 'none')

			if (rerolls.length) {
				rerolls.forEach((roll: RollResultArray) =>
					Dice.current.add(roll, roll.groupId),
				)
				return rerolls
			}

			const finalResults = DRP.parseFinalResults(results)
			console.log('Final parsed results:', finalResults)

			onRollResults(finalResults)
			setIsRolling(false)
			setNeedsClear(true)
		},
		[onRollResults],
	)

	const setupDiceBox = useCallback(async () => {
		try {
			const viewportHeight = window.innerHeight
			const calculatedScale = calculateDiceScale(viewportHeight)

			Dice.current = new DiceBox({
				id: DICE_CANVAS_ID,
				assetPath: ASSET_PATH,
				startingHeight: 4,
				throwForce: 5,
				spinForce: 3,
				lightIntensity: 1,
				scale: calculatedScale,
				friction: 1,
				linearDamping: 0.3,
				angularDamping: 0.3,
				restitution: 0.5,
				themeColor: diceColor,
			})

			await Dice.current.init()
			Dice.current.onRollComplete = handleRollComplete
			setIsInitialized(true)
		} catch (error) {
			console.error('Error setting up DiceBox:', error)
		}
	}, [diceColor, handleRollComplete])

	useEffect(() => {
		setupDiceBox()
	}, [setupDiceBox])

	useEffect(() => {
		if (isInitialized) {
			updateDiceTheme(diceColor)
		}
	}, [isInitialized, diceColor, updateDiceTheme])

	useEffect(() => {
		setDiceColor(color)
	}, [color])

	useEffect(() => {
		const handleClick = () => {
			if (needsClear && Dice.current) {
				Dice.current.clear()
				setNeedsClear(false)
			}
		}

		window.addEventListener('click', handleClick)
		return () => window.removeEventListener('click', handleClick)
	}, [needsClear])

	const rollDice = useCallback(
		(notation: string) => {
			console.log('rollDice called with notation:', notation)
			if (!isInitialized || isRolling) {
				console.log(
					'Roll blocked - initialized:',
					isInitialized,
					'isRolling:',
					isRolling,
				)
				return
			}

			setNeedsClear(false)

			if (modifier !== 0) {
				notation += `${modifier > 0 ? '+' : ''}${modifier}`
				console.log('Final notation with modifier:', notation)
			}

			try {
				const parsedNotation: ParsedNotation = DRP.parseNotation(notation)
				console.log('Parsed notation:', parsedNotation)

				setIsRolling(true)
				console.log('Starting roll animation')
				Dice.current
					.show()
					.roll(parsedNotation)
					.catch((error: any) => {
						console.error('Error rolling dice:', error)
						setIsRolling(false)
					})
			} catch (error) {
				console.error('Error initiating dice roll:', error)
				setIsRolling(false)
			}
		},
		[modifier, isInitialized, isRolling],
	)

	const buttonElements = useMemo(() => {
		const configToUse = diceConfig?.length ? diceConfig : rpgDiceConfig
		return configToUse.map((btn, index) => (
			<AdvRollBtn
				key={index}
				label={btn.label}
				tooltip={btn.notation}
				notation={btn.notation}
				onRoll={rollDice}
				disabled={!isInitialized || isRolling}
				icons={btn.icons}
			/>
		))
	}, [diceConfig, rollDice, isInitialized, isRolling])

	useEffect(() => {
		if (rollNotation && isInitialized && !isRolling) {
			rollDice(rollNotation)
		}
	}, [rollNotation, isInitialized, isRolling, rollDice])

	useEffect(() => {
		const handleResize = () => {
			if (Dice.current) {
				const newScale = calculateDiceScale(window.innerHeight)
				Dice.current.updateConfig({ scale: newScale })
			}
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return (
		<Overlapper className='max-w-xl gap-1'>
			{buttonElements}
			<Stepper value={modifier} onChange={setModifier} tooltip='Modifier' />
			{showColorPicker && (
				<div className={twMerge('rounded-full p-2', Translucency)}>
					<ColorSwatch
						color={diceColor}
						colorName='dice'
						setEnableClickOutside={() => {}}
						onChange={handleColorChange}
						className='flex aspect-square h-5 w-5 flex-shrink-0 items-center justify-center'
					/>
				</div>
			)}
		</Overlapper>
	)
}

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import menuItemClasses from './menuItemClasses'
import { CheckIcon } from '@heroicons/react/24/solid'
import { twMerge } from 'tailwind-merge'

const RadioItem = ({ children, value, ...rest }) => {
	return (
		<DropdownMenu.RadioItem
			value={value}
			className={twMerge(
				menuItemClasses,
				rest.disabled ? 'cursor-not-allowed opacity-50' : '',
			)}
			{...rest}
		>
			{children}
			<DropdownMenu.ItemIndicator>
				<CheckIcon className='h-4 w-4' />
			</DropdownMenu.ItemIndicator>
		</DropdownMenu.RadioItem>
	)
}

export default RadioItem

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useEffect, useState } from 'react'
import { IActor } from '../../../../../shared/types/actor'
import Separator from '../../dropdownmenu/Separator'
import SmallUppercase from '../../interface/text/SmallUppercase'
import RelationshipMenuItem from './RelationshipMenuItem'
import RemoveActorMenuItem from './RemoveActorMenuItem'

interface TokenContextMenuProps {
	position: { x: number; y: number } | null
	dimensions: { width: number; height: number } | null
	document: IActor | null
	onClose: () => void
	clearSelection: () => void
}

export default function TokenContextMenu({
	position,
	dimensions,
	document: doc,
	onClose,
	clearSelection,
}: TokenContextMenuProps) {
	const [open, setOpen] = useState(false)

	// Control open state based on position
	useEffect(() => {
		if (position) {
			setOpen(true)
		} else {
			setOpen(false)
		}
	}, [position])

	if (!doc || !dimensions) return null

	return (
		<DropdownMenu.Root
			open={open}
			onOpenChange={open => {
				setOpen(open)
				if (!open) {
					clearSelection()
					onClose()
				}
			}}
		>
			<DropdownMenu.Trigger asChild>
				<div
					style={{
						position: 'fixed',
						left: position?.x ?? 0,
						top: position?.y ?? 0,
						width: dimensions.width,
						height: dimensions.height,
						zIndex: 1000,
						pointerEvents: 'none',
					}}
				/>
			</DropdownMenu.Trigger>

			<DropdownMenu.Portal>
				<DropdownMenu.Content
					alignOffset={5}
					className='z-50 space-y-1 rounded-xl border border-gray-700/50 bg-gray-800 p-1 text-gray-200 shadow-xl'
				>
					<div className='px-2 py-1.5'>
						<div className='cursor-default'>
							<SmallUppercase>{doc.values?.name || 'Unnamed'}</SmallUppercase>
						</div>
					</div>

					<Separator />

					<RelationshipMenuItem doc={doc} onClose={onClose} />

					<RemoveActorMenuItem doc={doc} onClose={onClose} />
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	)
}

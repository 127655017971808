// This component is used to listen to socket events from users
// keeping track of who is online, and socket to user id mapping
import debounce from 'lodash/debounce'
import { useCallback, useContext, useEffect } from 'react'
import { socket } from '../contexts/socket'
import UsersContext from '../contexts/users'
import { IRegisteredUser } from '../interfaces/users'
import useUser from './useUser'

interface RoomStateUpdate {
	socketIds: string[]
	userMappings: Record<string, string[]>
}

const useUsersSocketListeners = () => {
	const { userId } = useUser()
	const { usersState, dispatchUsers } = useContext(UsersContext)

	const debouncedUpdateUsers = useCallback(
		debounce((newUsers: IRegisteredUser[]) => {
			dispatchUsers({
				type: 'UPDATE_USERS',
				payload: { users: newUsers },
			})
		}, 100),
		[dispatchUsers],
	)

	const handleRoomStateUpdate = useCallback(
		(update: RoomStateUpdate) => {
			const { socketIds, userMappings } = update

			const newUsers = usersState.users.map((user: IRegisteredUser) => {
				const userSocketIds = userMappings[user.userId] || []
				const isOnline =
					userSocketIds.some(socketId => socketIds.includes(socketId)) ||
					user.userId === userId

				return {
					...user,
					socketIds: userSocketIds,
					online: isOnline,
				}
			})

			debouncedUpdateUsers(newUsers)
		},
		[usersState.users, userId, debouncedUpdateUsers],
	)

	useEffect(() => {
		socket.on('room state update', handleRoomStateUpdate)

		return () => {
			socket.off('room state update', handleRoomStateUpdate)
			debouncedUpdateUsers.cancel()
		}
	}, [handleRoomStateUpdate, debouncedUpdateUsers])

	// Add an effect to ensure current user is always online
	useEffect(() => {
		if (!userId) return

		const currentUser = usersState.users.find(user => user.userId === userId)
		if (currentUser && !currentUser.online) {
			dispatchUsers({
				type: 'UPDATE_USERS',
				payload: {
					users: usersState.users.map(user =>
						user.userId === userId ? { ...user, online: true } : user,
					),
				},
			})
		}
	}, [userId, usersState.users, dispatchUsers])
}

export default useUsersSocketListeners

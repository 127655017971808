import { Container, TilingSprite } from '@pixi/react'
import * as PIXI from 'pixi.js'
import desktopWood from '../../assets/backgrounds/desktop-wood.jpg'

interface Props {
	onPointerDown?: (event: PIXI.FederatedPointerEvent) => void
}

const Tabletop = ({ onPointerDown }: Props) => {
	const size = 1000

	const handlePointerDown = (event: PIXI.FederatedPointerEvent) => {
		// Only clear selection if clicking directly on the tabletop (not bubbled from tokens)
		if (event.target === event.currentTarget) {
			onPointerDown?.(event)
		}
	}

	return (
		<Container eventMode='static' onpointerdown={handlePointerDown}>
			<TilingSprite
				image={desktopWood}
				width={size}
				height={size}
				x={-size / 2}
				y={-size / 2}
				tilePosition={{ x: 0, y: 0 }}
				tileScale={{ x: 0.05, y: 0.05 }}
			/>
		</Container>
	)
}

export default Tabletop

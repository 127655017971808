// This file defines the menu items for sharing a document with specific players
// GMs can share documents with anyone and they can rescind access from anyone
// (GMs always have access to everything)
// Players can only share with other players who do not already have access,
// they cannot rescind access from anyone
import { useContext } from 'react'
import { IDocument } from '../../../../../shared/types/document'
import UsersContext from '../../../contexts/users'
import useHandleShareDocumentWith from '../../../hooks/useHandleShareDocumentWith'
import useUser from '../../../hooks/useUser'
import UserDot from '../../UserDot'
import CheckboxItem from '../../dropdownmenu/CheckboxItem'
import Separator from '../../dropdownmenu/Separator'

type Props = {
	document: IDocument
}
const PlayerPermissions = ({ document }: Props) => {
	const { usersState } = useContext(UsersContext)
	const { users } = usersState
	const hasPlayers = users.length > 0
	const accessList = document.accessList
	const { handleShareWith } = useHandleShareDocumentWith()
	const { isGM } = useUser()

	if (!hasPlayers) return null

	return (
		<>
			<Separator />

			{users.map(user => {
				const playerHasAccess = accessList.includes(user.userId)
				const role = user.userSettings.role === 'gm' ? '(GM)' : ''

				return (
					<CheckboxItem
						key={user.userId}
						checked={playerHasAccess}
						onCheckedChange={() =>
							handleShareWith(document._id, user.userId, true)
						}
						disabled={
							user.userSettings.role === 'gm' || (playerHasAccess && !isGM)
						}
					>
						<div className='flex'>
							<UserDot
								color={user.userSettings.color}
								className='mr-2 self-center'
							/>
							{user.userProfile.name} {role}
						</div>
					</CheckboxItem>
				)
			})}
		</>
	)
}

export default PlayerPermissions

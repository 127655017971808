import { twMerge } from 'tailwind-merge'

interface IProps {
	children: React.ReactNode
	className?: string
}

const DialogBody: React.FC<IProps> = ({ children, className = '' }) => {
	return (
		<div
			className={twMerge(
				'relative flex-1 overflow-scroll px-4 pb-4 text-left text-black dark:text-white',
				className,
			)}
		>
			{children}
		</div>
	)
}

export default DialogBody

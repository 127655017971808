import React, {
	memo,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { Document } from 'react-pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { IResource } from '../../../../shared/types/resources'
import PdfViewerContext from '../../contexts/book'
import { configurePdfWorker } from '../../utils/pdfConfig'
import canvasesToImage from './CanvasesToImage'
import CreateImage from './CreateImage'
import DocumentOutline from './DocumentOutline'
import PDFLoading from './PDFLoading'
import './PDFViewer.css'
import ProgressBar from './ProgressBar'
import RenderPages from './RenderPages'
import Toolbar from './Toolbar'

configurePdfWorker()

const SIDEBAR_WIDTH = 300

type Props = {
	resource: IResource
	page: string
	isGame: boolean
}

const PdfViewer = ({ resource, page, isGame }: Props) => {
	const { bookState, bookDispatch } = useContext(PdfViewerContext)
	const { pagesToDisplay } = bookState
	const file = useMemo(() => resource.fileurl, [resource.fileurl])
	const [showCreateImage, setShowCreateImage] = useState(false)
	const containerRef = useRef<HTMLDivElement>(null)
	const pageOneRef = useRef<HTMLCanvasElement | null>(null)
	const pageTwoRef = useRef<HTMLCanvasElement | null>(null)
	// const { db, getPage, setPage } = usePdfDatabase()
	const observer = useRef<IntersectionObserver | null>(null)
	const handleIntersectRef =
		useRef<(entries: IntersectionObserverEntry[]) => void>()

	// Load cached pagesToDisplay and fitPage from localStorage
	useEffect(() => {
		const savedPagesToDisplay = localStorage.getItem('pagesToDisplay')
		const pagesToDisplay = savedPagesToDisplay
			? JSON.parse(savedPagesToDisplay)
			: 1
		bookDispatch({ type: 'SET_PAGES_TO_DISPLAY', payload: pagesToDisplay })

		const savedFitPage = localStorage.getItem('fitPage')
		const fitPage = savedFitPage ? JSON.parse(savedFitPage) : false
		bookDispatch({ type: 'SET_FIT_PAGE', payload: fitPage })
	}, [bookDispatch])

	// Save pagesToDisplay and fitPage to localStorage
	useEffect(() => {
		localStorage.setItem(
			'pagesToDisplay',
			JSON.stringify(bookState.pagesToDisplay),
		)
		localStorage.setItem('fitPage', JSON.stringify(bookState.fitPage))
	}, [bookState.pagesToDisplay, bookState.fitPage])

	const [pageSizes, setPageSizes] = useState<
		{ width: number; height: number }[]
	>([])

	const [isReady, setIsReady] = useState(false)

	const onDocumentLoadSuccess = useCallback(
		async (pdf: any) => {
			const numPages = pdf.numPages
			bookDispatch({ type: 'SET_NUM_PAGES', payload: numPages })

			// prefetch page sizes prior to rendering
			const sizes: { width: number; height: number }[] = []
			for (let i = 1; i <= numPages; i++) {
				const page = await pdf.getPage(i)
				const viewport = page.getViewport({ scale: 1 })
				sizes.push({ width: viewport.width, height: viewport.height })
			}

			setPageSizes(sizes)
			setIsReady(true)
		},
		[bookDispatch],
	)

	const goToPageNumber = useCallback(
		(pageNumber: number) => {
			console.log(`[goToPageNumber] Attempting to go to page ${pageNumber}`)
			const container = containerRef.current
			if (!container) {
				console.warn('[goToPageNumber] Container ref is null')
				return
			}
			console.log(container)
			const children = Array.from(container.children) as HTMLElement[]
			console.log(`[goToPageNumber] Container has ${children.length} children`)

			const pagesPerRow = bookState.pagesToDisplay
			const isCoverAlone = bookState.isCoverAlone

			let targetRowIndex
			if (isCoverAlone && pageNumber > 1) {
				targetRowIndex = Math.floor((pageNumber - 2) / pagesPerRow) + 1
			} else {
				targetRowIndex = Math.floor((pageNumber - 1) / pagesPerRow)
			}

			const targetRow = children[targetRowIndex]

			if (targetRow) {
				console.log(
					`[goToPageNumber] Scrolling to row containing page ${pageNumber}`,
				)
				targetRow.scrollIntoView({ behavior: 'auto' })
			} else {
				console.warn(
					`[goToPageNumber] Target row for page ${pageNumber} not found`,
				)
			}
		},
		[bookState.pagesToDisplay, bookState.isCoverAlone],
	)

	// Save canvases to refs for the image editor
	useEffect(() => {
		const { currentPage, pagesToDisplay } = bookState
		const isDoublePageView = pagesToDisplay === 2
		const firstPageNumber = currentPage
		const secondPageNumber = isDoublePageView ? currentPage + 1 : null

		const updateCanvasRef = (
			pageNumber: number | null,
			ref: React.MutableRefObject<HTMLCanvasElement | null>,
		) => {
			if (pageNumber !== null) {
				const pageElement = document.querySelector(
					`[data-page-number="${pageNumber}"]`,
				)
				if (pageElement) {
					const canvas = pageElement.querySelector(
						'canvas',
					) as HTMLCanvasElement
					if (canvas) ref.current = canvas
				}
			}
		}

		updateCanvasRef(firstPageNumber, pageOneRef)
		if (isDoublePageView) {
			updateCanvasRef(secondPageNumber, pageTwoRef)
		}
	}, [bookState.currentPage, bookState.pagesToDisplay])

	const clearRenderedPages = useCallback(() => {
		bookDispatch({ type: 'RESET_PAGE_SIZE_TO_DEFAULT' })
		bookDispatch({ type: 'CLEAR_RENDERED_PAGES' })
	}, [bookDispatch])

	const prevSizeRef = useRef<{ width: number; height: number } | null>(null)

	useEffect(() => {
		const resizeObserver = new ResizeObserver(entries => {
			for (const entry of entries) {
				const newWidth = entry.contentRect.width
				const newHeight = entry.contentRect.height
				const prevSize = prevSizeRef.current

				if (
					!prevSize ||
					prevSize.width !== newWidth ||
					prevSize.height !== newHeight
				) {
					clearRenderedPages()
					prevSizeRef.current = { width: newWidth, height: newHeight }
				}
			}
		})

		if (containerRef.current) {
			resizeObserver.observe(containerRef.current)
		}

		return () => {
			if (containerRef.current) {
				resizeObserver.unobserve(containerRef.current)
			}
		}
	}, [bookDispatch, clearRenderedPages])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			const container = containerRef.current
			if (!container) return

			const children = Array.from(container.children) as HTMLElement[]
			const rects = children.map(child => child.getBoundingClientRect())

			const firstFullyVisibleIndex = rects.findIndex(rect => rect.top >= 0)
			let newPage = null

			if (event.key === 'ArrowLeft') {
				event.preventDefault()
				// Scroll to the page before the first fully visible page
				newPage = Math.max(firstFullyVisibleIndex - 1, 0)
			} else if (event.key === 'ArrowRight') {
				event.preventDefault()
				// Scroll to the page after the first fully visible page
				newPage = Math.min(firstFullyVisibleIndex + 1, children.length - 1)
			}

			if (newPage !== null) {
				children[newPage].scrollIntoView({})
			}
		}

		document.addEventListener('keydown', handleKeyDown)

		return () => {
			document.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	useEffect(() => {
		bookDispatch({
			type: 'SET_NAME',
			payload: resource.name,
		})
	}, [bookDispatch, resource.name])

	const onRenderSuccess = useCallback(
		(pageNumber: number) => {
			// When a page is rendered, remove the placeholder page
			const placeholderPage = document.querySelector(
				`[data-placeholder-page-number="${pageNumber}"]`,
			)
			if (placeholderPage) placeholderPage.remove()

			// After page 2 is loaded, scroll to the page specified in the URL
			if (pageNumber === 2) {
				const pageElement = document.getElementById(`page_${page}`)
				const container = containerRef.current
				if (pageElement && container) {
					const rect = pageElement.getBoundingClientRect()
					const containerRect = container.getBoundingClientRect()
					container.scrollTop = rect.top - containerRect.top
				}
			}
		},
		[page, containerRef],
	)

	const handleIntersect = useCallback(
		(entries: IntersectionObserverEntry[]): void => {
			const visibleEntries = entries.filter(entry => entry.isIntersecting)
			if (visibleEntries.length > 0) {
				const mostVisibleEntry = visibleEntries.reduce((prev, current) =>
					prev.intersectionRatio > current.intersectionRatio ? prev : current,
				)
				const rowElement = mostVisibleEntry.target as HTMLElement
				const pageElements = rowElement.querySelectorAll('.pdf-page')
				if (pageElements.length > 0) {
					const firstPageElement = pageElements[0] as HTMLElement
					const page = parseInt(firstPageElement.id.split('_')[1], 10)
					if (bookState.currentPage !== page) {
						bookDispatch({
							type: 'SET_RENDERED_PAGES',
							payload: Array.from(
								{ length: pagesToDisplay === 1 ? 4 : 8 },
								(_, i) => page + i,
							),
						})
					}
				}
			}
		},
		[bookDispatch, bookState.currentPage, pagesToDisplay],
	)

	useEffect(() => {
		handleIntersectRef.current = handleIntersect
	}, [handleIntersect])

	useEffect(() => {
		const observerCallback = (entries: IntersectionObserverEntry[]) => {
			if (handleIntersectRef.current) {
				handleIntersectRef.current(entries)
			}
		}

		if (observer.current) {
			observer.current.disconnect() // Ensure no duplicate observers
		}

		observer.current = new IntersectionObserver(observerCallback, {
			threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
			root: containerRef.current,
			rootMargin: '0px',
		})

		const rows = containerRef.current?.querySelectorAll('.pdf-page-container')
		rows?.forEach(row => observer.current?.observe(row))

		return () => {
			if (observer.current) observer.current.disconnect()
		}
	}, [handleIntersect, containerRef.current])

	// Dispatch onRenderSuccess to the context
	useEffect(() => {
		bookDispatch({
			type: 'SET_ON_RENDER_SUCCESS',
			payload: onRenderSuccess,
		})
	}, [onRenderSuccess, bookDispatch])

	const showSidebar =
		bookState.isOutlineVisible || bookState.isThumbnailsVisible

	return (
		<div className='fixed inset-0 bg-gray-200 dark:bg-gray-800'>
			<ProgressBar />
			<Toolbar setShowCreateImage={setShowCreateImage} isGame={isGame} />
			<Document
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				className='absolute inset-0 top-14 mt-px flex'
				loading={<PDFLoading />}
			>
				<DocumentOutline width={SIDEBAR_WIDTH} onItemClick={goToPageNumber} />

				<div
					className='absolute inset-0 transition-all duration-300'
					style={{
						left: showSidebar ? SIDEBAR_WIDTH : '0',
					}}
				>
					{isReady && (
						<RenderPages containerRef={containerRef} pageSizes={pageSizes} />
					)}
				</div>
			</Document>

			{showCreateImage && (
				<CreateImage
					onClose={() => setShowCreateImage(false)}
					sourceImage={canvasesToImage({
						canvas1: pageOneRef.current,
						canvas2: pageTwoRef.current,
						pagesToDisplay: bookState.pagesToDisplay,
					})}
				/>
			)}
		</div>
	)
}

export default memo(PdfViewer)

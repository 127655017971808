import styles from './DropToUploadMessage.module.css'

const DropToUploadMessage = ({
  show = false,
  message = 'Drop to Upload...',
}) => {
  if (show) {
    return (
      <div className={styles.DropToUploadMessage}>
        <div className={styles.Message}>{message}</div>
      </div>
    )
  } else {
    return <></>
  }
}

export default DropToUploadMessage

import { useFormContext } from 'react-hook-form'
import Label from '../../../FormComponents/Label'

const MapGridStyle = () => {
	const { register } = useFormContext()

	return (
		<div className='center flex align-middle'>
			<Label className='mt-3 w-1/3'>Opacity</Label>
			<div className='w-2/3 pt-3'>
				<input
					type='range'
					{...register('grid.alpha')}
					min='0'
					max='1'
					step='0.1'
					className='w-32'
				/>
			</div>
		</div>
	)
}

export default MapGridStyle

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import menuContentClasses from './menuContentClasses'

type Props = { children: React.ReactNode }

const MenuContent = ({ children }: Props) => {
	return (
		<DropdownMenu.Content
			sideOffset={5}
			collisionPadding={20}
			className={menuContentClasses}
			style={{
				width: 'max-content',
				minWidth: '10rem',
			}}
		>
			{children}
		</DropdownMenu.Content>
	)
}

export default MenuContent

import { useEffect, useState } from 'react'
import { socket } from '../../contexts/socket'
import useGetUserById from '../../hooks/useGetUserById'

const classNames = 'my-1 text-center text-xs text-gray-700/80'

const TypingIndicator = () => {
	const { fetchUser } = useGetUserById()
	const [typingUsers, setTypingUsers] = useState([])
	const [preText, setPreText] = useState('')

	useEffect(() => {
		if (typingUsers.length === 0) return
		if (typingUsers.length === 1) {
			const name = fetchUser(typingUsers[0]).userProfile.name
			setPreText(name + ' is')
		}
		if (typingUsers.length > 1) {
			setPreText('Multiple people are')
		}
	}, [typingUsers])

	useEffect(() => {
		const handleTyping = (userId: string) => {
			setTypingUsers(prevTypingUsers => {
				if (!prevTypingUsers.includes(userId)) {
					return [...prevTypingUsers, userId]
				}
				return prevTypingUsers
			})
		}

		const handleStopTyping = (userId: string) => {
			setTypingUsers(prevTypingUsers => {
				return prevTypingUsers.filter(user => user !== userId)
			})
		}

		socket.on('typing', handleTyping)
		socket.on('stop typing', handleStopTyping)

		return () => {
			socket.off('typing', handleTyping)
			socket.off('stop typing', handleStopTyping)
		}
	}, [])

	if (typingUsers.length === 0) {
		return null
	}

	return <div className={classNames}>{preText} is typing...</div>
}

export default TypingIndicator

import * as ContextMenu from '@radix-ui/react-context-menu'

type Props = {
	children: React.ReactNode
}

export default function Menu({ children }: Props) {
	return (
		<ContextMenu.Portal>
			<ContextMenu.Content
				alignOffset={5}
				className='z-50 space-y-1 rounded-xl border border-gray-700/50 bg-gray-800 p-1 text-gray-200 shadow-xl'
			>
				{children}
			</ContextMenu.Content>
		</ContextMenu.Portal>
	)
}

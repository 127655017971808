import { User } from 'lucide-react'
import useAssumeCharacter from '../../hooks/useAssumeCharacter'
import useUser from '../../hooks/useUser'
import DocumentWindowHeaderButton from './DocumentWindowHeaderButton'

type Props = {
	characterId: string
}

export default function AssumeAsCharacterButton({ characterId }: Props) {
	const { userId, assumedCharacterId } = useUser()
	const { handleAssumeCharacter, handleUnassumeCharacter } =
		useAssumeCharacter()
	const isAssumed = assumedCharacterId === characterId

	const handleClick = () => {
		if (isAssumed) {
			handleUnassumeCharacter(characterId)
		} else {
			handleAssumeCharacter(characterId, userId)
		}
	}

	return (
		<DocumentWindowHeaderButton
			label={isAssumed ? 'Unassume Character' : 'Assume Character'}
			onClick={handleClick}
			className={isAssumed ? 'bg-white text-black' : ''}
		>
			{isAssumed && <User className='h-5 w-5 self-center' aria-hidden='true' />}
			{!isAssumed && (
				<User className='h-5 w-5 self-center' aria-hidden='true' />
			)}
		</DocumentWindowHeaderButton>
	)
}

import ObjectID from 'bson-objectid'
import { useContext, useState } from 'react'
import GameContext from '../../contexts/game'
import useCreateWeapons from '../../hooks/useCreateWeapons'
import useUser from '../../hooks/useUser'
import { SparklesIcon } from '@heroicons/react/24/solid'
import ToolbarButton from './ToolbarButton'
import Loader from 'react-loader-spinner'

const AIButton = () => {
	const { dispatch } = useContext(GameContext)
	const { handleGeneratorMessage } = useCreateWeapons()
	const { userId } = useUser()
	const [loading, setLoading] = useState(false)
	const { isGM } = useUser()

	const handleAITest = async () => {
		const selectedText = window.getSelection()?.toString()

		if (!selectedText) {
			alert('Please select text first.')
			return
		}

		setLoading(true)
		console.log('selectedText', selectedText.length)

		const AIResults = await handleGeneratorMessage(`${selectedText}`)
		setLoading(false)
		console.log('AIResults', JSON.parse(AIResults))

		const weapons = JSON.parse(AIResults).weapons

		// dispatch each weapon to the store
		weapons.forEach((weapon: any) => {
			dispatch({
				type: 'ADD_DOCUMENT',
				payload: {
					document: {
						_id: ObjectID().toString(),
						parentId: 'library',
						type: 'weapon',
						version: 1,
						creator: userId,
						access: 'private',
						accessList: [],
						values: weapon,
					},
				},
			})
		})
	}

	if (!isGM) return null

	return (
		<ToolbarButton
			label='Create Weapons from Selection'
			onClick={handleAITest}
			onMouseDown={e => e.preventDefault()}
		>
			{!loading && <SparklesIcon className='h-5 w-5' />}
			{loading && <Loader type='Oval' color='#666' height={16} width={16} />}
		</ToolbarButton>
	)
}

export default AIButton

import { useMutation, useQuery } from '@apollo/client'
import { useContext, useState } from 'react'
import { IResource } from '../../../../../../shared/types/resources'
import GameContext from '../../../../contexts/game'
import {
	GET_RESOURCES_BY_USER,
	UPDATE_RESOURCE,
} from '../../../../graphql/resources'
import useBookSelection from '../../../../hooks/useBookSelection'
import { IBook } from '../../../../interfaces/book'
import ModalWindow from '../../../window/ModalWindow'
import BookListItems from './BookListItems'

type Props = {
	onClose: () => void
	onBookSelected?: (selectedBookId: string) => void
}

const BookPicker = ({ onClose, onBookSelected }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const initialSelection = Object.values(game.books.byId).map(
		(book: IBook) => book.resourceId,
	)
	const { selectedIds, handleSelect, handleDeselectAll } =
		useBookSelection(initialSelection)
	const [creatorResources, setCreatorResources] = useState<IResource[]>([])

	const { loading, error } = useQuery(GET_RESOURCES_BY_USER, {
		fetchPolicy: 'no-cache',
		variables: {
			// @ts-ignore
			userId: game.creator._id,
		},
		onCompleted: data => {
			console.log('GET_RESOURCES_BY_USER', data)
			setCreatorResources(data.getResourcesByUser)
		},
	})

	const [updateResource] = useMutation(UPDATE_RESOURCE)

	const handleBookSelected = (id: string, selected: boolean) => {
		handleSelect(id, selected)
		if (selected && onBookSelected) {
			onBookSelected(id)
		}
	}

	const handleSelectAll = () => {
		const ids = creatorResources.map(resource => resource._id)
		for (const id of ids) {
			handleSelect(id, true)
		}
	}

	const handleCancel = () => {
		onClose()
	}

	const resourceToBook = (resource: IResource): IBook | null => {
		if (!resource) {
			console.error('Attempted to convert undefined resource to book')
			return null
		}
		return {
			_id: resource._id,
			title: resource.name,
			creator: resource.creator,
			access: 'private',
			accessList: [],
			resourceId: resource._id,
		}
	}

	const handleDone = async () => {
		console.log('handleDone', selectedIds, creatorResources)
		const booksArray = selectedIds
			.map(id => creatorResources.find(resource => resource._id === id))
			.filter(resource => resource !== undefined)
			.map(resourceToBook)
			.filter(book => book !== null)

		const books = booksArray.reduce((acc, book) => {
			acc[book._id] = book
			return acc
		}, {} as Record<string, IBook>)

		// Update each selected resource
		for (const id of selectedIds) {
			const resource = creatorResources.find(resource => resource._id === id)
			if (resource) {
				const usedInGames = resource.usedInGames.includes(game._id)
					? resource.usedInGames.filter(gameId => gameId !== game._id)
					: [...resource.usedInGames, game._id]

				const resourceInput = { ...resource, usedInGames }
				// delete createdAt and updatedAt fields
				delete resourceInput.createdAt
				delete resourceInput.updatedAt

				await updateResource({
					fetchPolicy: 'no-cache',
					variables: {
						resourceInput,
					},
				})
			}
		}

		dispatch({
			type: 'SET_BOOKS',
			payload: {
				books,
			},
		})

		onClose()
	}

	const arraysAreEqual = (arr1: string[], arr2: string[]) =>
		arr1.length === arr2.length && arr1.every(value => arr2.includes(value))

	return (
		<ModalWindow
			id='book-picker'
			title='Choose Game Books'
			onClose={onClose}
			size='large'
			enableClickOutside={true}
			headerChildren={
				<div className='mr-2 flex space-x-4'>
					<button onClick={handleCancel}>Cancel</button>
					{!arraysAreEqual(selectedIds, initialSelection) && (
						<button onClick={handleDone}>Done</button>
					)}
				</div>
			}
		>
			{loading && <div>Loading...</div>}
			{error && <div>Error...</div>}
			{!loading && !error && (
				<div className='pt-4'>
					<BookListItems
						resources={creatorResources}
						selectMode={true}
						selectedIds={selectedIds}
						onSelect={handleBookSelected}
					/>
					<div className='absolute bottom-0 left-0 flex w-full items-center justify-between border-t border-gray-800 p-4'>
						<div className='text-gray-500'>
							<p>{selectedIds.length} selected</p>
						</div>
						<div className='space-x-4'>
							<button onClick={handleDeselectAll}>Select None</button>
							<button onClick={handleSelectAll}>Select All</button>
						</div>
					</div>
				</div>
			)}
		</ModalWindow>
	)
}

export default BookPicker

import MenuItem from './MenuItem'

type Props = {
	userId: string
	setEditProfile: (value: boolean) => void
}

export default function EditProfileMenuItem({ setEditProfile }: Props) {
	return (
		<MenuItem
			onSelect={() => {
				console.log('edit profile')
				setEditProfile(true)
			}}
		>
			Edit Profile
		</MenuItem>
	)
}

import { useContext } from 'react'
import { IDocument } from '../../../../../shared/types/document'
import GameContext from '../../../contexts/game'
import MenuItem from '../../ContextMenu/Item'

type Props = {
	document: IDocument
}

const SetSceneMenuItem = ({ document }: Props) => {
	const { dispatch } = useContext(GameContext)

	const handleSetScene = () => {
		dispatch({
			type: 'SET_ACTIVE_SCENE',
			payload: {
				sceneId: document._id,
			},
		})
	}

	return <MenuItem onSelect={() => handleSetScene()}>Set Scene</MenuItem>
}

export default SetSceneMenuItem

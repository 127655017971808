import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import useFitText from 'use-fit-text'
import { GET_ASSET } from '../../../graphql/games'
import useTheme from '../../../hooks/useTheme'
import { IGameCard } from '../../../interfaces/game'
import GameLogo from '../../interface/GameLogo'

export interface IGameCardProps {
	game: IGameCard
	className?: string
}

export default function GameCard({ game, className }: IGameCardProps) {
	const [getAsset, { data, error }] = useLazyQuery(GET_ASSET, {
		variables: {
			gameId: game._id,
			assetId: game.coverImage,
		},
	})
	const coverImageUrl = data?.getAsset?.fileurl
	const { primary } = useTheme()
	const { fontSize, ref } = useFitText({
		maxFontSize: 500,
	})

	useEffect(() => {
		if (error) {
			console.error('Error fetching asset:', error)
		}
	}, [error])

	useEffect(() => {
		if (game.coverImage && !coverImageUrl) {
			getAsset()
		}
	}, [game.coverImage, coverImageUrl, getAsset])

	const backgroundStyle = coverImageUrl
		? {
				backgroundImage: `url(${coverImageUrl})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
		  }
		: {}

	const scrimStyle =
		coverImageUrl && !game.hideLogo
			? {
					backgroundImage:
						'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.65))',
			  }
			: {}

	return (
		<div
			className={twMerge(
				'aspect-[1.65] w-full overflow-hidden rounded-2xl bg-gray-900 text-center shadow-xl transition-all duration-300',
				className,
			)}
			style={backgroundStyle}
		>
			<div
				id='scrim'
				className='flex h-full flex-col justify-center p-8'
				style={scrimStyle}
			>
				{!game.hideLogo &&
					(game.logo ? (
						<GameLogo
							gameId={game._id}
							assetId={game.logo}
							title={game.title}
						/>
					) : (
						<div
							ref={ref}
							className='max-h-[80%] overflow-hidden font-semibold uppercase tracking-wide text-white'
							style={{
								fontFamily: 'Rubik, sans-serif',
								textShadow: '0 4px 6px rgba(0,0,0,0.5)',
								color: primary,
								fontSize,
							}}
						>
							{game.title}
						</div>
					))}
			</div>
		</div>
	)
}

import { IDocument } from '../../../../../shared/types/document'
import useAssumeCharacter from '../../../hooks/useAssumeCharacter'
import useUser from '../../../hooks/useUser'
import MenuItem from '../../ContextMenu/Item'

type Props = {
	document: IDocument
}

const AssumeCharacterMenuItem = ({ document }: Props) => {
	const { handleAssumeCharacter, handleUnassumeCharacter } =
		useAssumeCharacter()
	const { userId, assumedCharacterId } = useUser()

	if (document._id === assumedCharacterId) {
		return (
			<MenuItem onSelect={() => handleUnassumeCharacter(document._id)}>
				Unassume Character
			</MenuItem>
		)
	}

	return (
		<MenuItem onSelect={() => handleAssumeCharacter(document._id, userId)}>
			Assume Character
		</MenuItem>
	)
}

export default AssumeCharacterMenuItem

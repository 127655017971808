const Title = ({ className, children }) => {
	return (
		<div
			className={`text-lg font-semibold text-white lg:text-xl ${
				className || ''
			}`}
		>
			{children}
		</div>
	)
}

export default Title

import { useFormContext } from 'react-hook-form'
import Label from '../../../FormComponents/Label'

const MapGridSize = () => {
	const { register, watch } = useFormContext()
	const size = watch('grid.size')

	return (
		<div className='center flex align-middle'>
			<Label className='mt-3 w-1/3'>Size ({size})</Label>
			<div className='w-2/3 pt-3'>
				<input
					type='range'
					min='0.1'
					max='50'
					step='0.1'
					{...register('grid.size')}
					className='w-full'
				/>
			</div>
		</div>
	)
}

export default MapGridSize

import { Settings as SettingsIcon } from 'lucide-react'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'
import Settings from '../settings/Settings'

export default function SettingsButton() {
	const { isGM } = useUser()
	const [isOpen, setIsOpen] = useState(false)

	if (!isGM) return null

	return (
		<>
			<ToolButton
				tooltip='Settings'
				isOpen={isOpen}
				onClick={() => setIsOpen(true)}
			>
				<SettingsIcon className='h-5 w-5' aria-hidden='true' />
			</ToolButton>

			{isOpen &&
				createPortal(
					<Settings onClose={() => setIsOpen(false)} />,
					document.body,
				)}
		</>
	)
}

import { useContext } from 'react'
import UsersContext from '../../contexts/users'
import Overlapper from '../Overlapper'
import UserHead from './UserHead'

export default function UserHeads() {
	const { usersState } = useContext(UsersContext)

	if (!usersState || !usersState.users) return null

	return (
		<Overlapper
			className='-mt-2 flex-shrink-0 space-x-2'
			hoverScale={1.2}
			centered
		>
			{[
				...usersState.users.map(user => (
					<div
						key={user.userId}
						className={`transition-opacity duration-300 ${
							user.online ? 'opacity-100' : 'opacity-40 hover:opacity-100'
						}`}
					>
						<UserHead user={user} />
					</div>
				)),
			]}
		</Overlapper>
	)
}

import ObjectId from 'bson-objectid'
import { useContext } from 'react'
import request from 'superagent'
import AuthContext from '../contexts/auth' // Assuming you need user context for file uploads

const useFileUpload = () => {
	const { authState } = useContext(AuthContext)

	const uploadFile = async file => {
		const ACCEPTED_MIME_TYPES = [
			'image/jpeg',
			'image/png',
			'image/gif',
			'image/bmp',
			'image/webp',
			'video/mp4',
			'video/webm',
			'application/pdf',
			// ... Add other supported MIME types here
		]

		if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
			console.error('Invalid file type')
			throw new Error('Unsupported file type')
		}

		const userId = authState.userId || 'anonymous'
		const formData = new FormData()
		formData.append('file', file)

		try {
			const uploadResponse = await request.post('/api/upload').send(formData)
			const fileurl = uploadResponse.body.url

			return {
				_id: ObjectId().toHexString(),
				name: file.name,
				fileurl,
				filesize: file.size,
				filetype: file.type,
				width: 0,
				height: 0,
				creator: userId,
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				usedInGames: [],
				thumbnailurl: '',
			}
		} catch (error) {
			console.error('File upload error', error)
			throw new Error('File upload error')
		}
	}

	return uploadFile
}

export default useFileUpload

import { useCallback } from 'react'
import { IBook } from '../interfaces/book'
import RPGBookCategoryDescriptions from '../utils/bookcategories'
import useExtractMetaData from './useExtractBookMetadata'

function useExtractMetadataFromBook() {
	const { requestMetadataExtraction } = useExtractMetaData()

	const prepareMetadataExtractionPrompt = useCallback(
		(text: string, currentBookFields: Partial<IBook>): string => {
			// Extract first 10 and last 5 pages (assuming 3000 characters per page)
			const firstPages = text.slice(0, 30000)
			const lastPages = text.slice(-15000)
			const sampleText = firstPages + '\n...\n' + lastPages

			const prompt = `
        Analyze the following book sample and fill in the metadata fields:
        
        ${sampleText}

        Current book fields:
        ${JSON.stringify(currentBookFields, null, 2)}

        Available RPG book categories:
        ${JSON.stringify(RPGBookCategoryDescriptions, null, 2)}

        Please fill in or update the following fields based on the sample text and return as JSON. Use the exact field names provided:

        {
          "title": "string",
          "description": "string",
          "category": "One of the RPGBookCategory values",
          "game": "string (e.g. D&D, Pathfinder, Trail of Cthulhu, Alien, etc.)",
          "ruleSystem": "string (e.g. D&D, Pathfinder, GUMSHOE, Year Zero Engine, etc.)",
          "authors": ["string"],
          "contributingArtists": ["string"],
          "publisher": "string",
          "year": "string",
          "edition": "string",
          "isbn": "string",
          "pageCount": number,
          "language": "string",
          "copyright": {
            "year": "string",
            "owner": "string"
          },
          "series": "string",
          "seriesNumber": number,
          "relatedBooks": ["string"],
          "requiredBooks": ["string"],
          "tags": ["string"],
          "contentWarnings": ["string"],
          "playerCount": {
            "min": number,
            "max": number
          },
          "recommendedAgeRange": {
            "min": number,
            "max": number
          },
          "playTime": {
            "min": number,
            "max": number,
          },
          "tableOfContents": [
            {
              "chapterTitle": "string",
              "pageNumber": "string"
            }
          ],
          "errata": [
            {
              "version": "string",
              "url": "string",
              "date": "YYYY-MM-DD"
            }
          ]
        }
        Return only the JSON object with the filled fields. If you can't determine a field, omit it from the JSON. Do not include fields that are not in this list (e.g., _id, creator, access, accessList, resourceId, lastUpdated).

        Do not wrap the JSON in backticks.
      `
			return prompt
		},
		[],
	)

	const mergeMetadata = useCallback(
		(
			currentBookFields: Partial<IBook>,
			extractedMetadata: Partial<IBook>,
		): Partial<IBook> => {
			return {
				...currentBookFields,
				...extractedMetadata,
			}
		},
		[],
	)

	const extractMetadata = useCallback(
		async (
			text: string,
			currentBookFields: Partial<IBook>,
		): Promise<Partial<IBook>> => {
			const prompt = prepareMetadataExtractionPrompt(text, currentBookFields)

			try {
				const response = await requestMetadataExtraction({
					prompt,
				})
				const extractedMetadata = JSON.parse(response.response)
				return mergeMetadata(currentBookFields, extractedMetadata)
			} catch (error) {
				console.error('Error extracting metadata:', error)
				throw error
			}
		},
		[prepareMetadataExtractionPrompt, requestMetadataExtraction, mergeMetadata],
	)

	return {
		extractMetadata,
	}
}

export default useExtractMetadataFromBook

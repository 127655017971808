import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

interface MetaTagsProps {
	title?: string
	description?: string
	image?: string
	type?: string
}

const MetaTags = ({
	title,
	description,
	image,
	type = 'website',
}: MetaTagsProps) => {
	const location = useLocation()
	const baseUrl = window.location.origin
	const currentUrl = baseUrl + location.pathname

	useEffect(() => {
		// Update standard meta tags
		document.title = title ? `${title} | Realms Inc` : 'Realms Inc'

		// Find or create meta description
		let metaDescription = document.querySelector('meta[name="description"]')
		if (!metaDescription) {
			metaDescription = document.createElement('meta')
			metaDescription.setAttribute('name', 'description')
			document.head.appendChild(metaDescription)
		}
		metaDescription.setAttribute(
			'content',
			description || 'Your tabletop home online.',
		)

		// Update Open Graph tags
		const metaTags = {
			'og:title': title || 'Realms Inc',
			'og:description': description || 'Your tabletop home online.',
			'og:image': image || `${baseUrl}/assets/favicon@2x.png`,
			'og:url': currentUrl,
			'og:type': type,
		}

		Object.entries(metaTags).forEach(([property, content]) => {
			let metaTag = document.querySelector(`meta[property="${property}"]`)
			if (!metaTag) {
				metaTag = document.createElement('meta')
				metaTag.setAttribute('property', property)
				document.head.appendChild(metaTag)
			}
			metaTag.setAttribute('content', content)
		})
	}, [title, description, image, type, currentUrl])

	return null
}

export default MetaTags

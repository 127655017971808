import { useContext, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import DocumentMisc from './components/DocumentMisc'
import Documents from './components/Documents'
import EventManager from './components/EventManager'
import Sockets from './components/Sockets'
import ToolsManager from './components/ToolsManager'
import Users from './components/Users'
import DocumentPopout from './components/document/DocumentPopout'
import NoDocument from './components/documentApp/NoDocument'
import DragAndDropManager from './components/draganddrop/DragAndDropManager'
import Windows from './components/window/Windows'
import { initialGameState } from './config'
import AuthContext from './contexts/auth'
import GameContext from './contexts/game'
import { SocketContext, socket } from './contexts/socket'
import GameReducer from './reducers/game'

const DocumentApp = () => {
	const [game, dispatch] = useReducer(GameReducer, initialGameState)
	const { authState } = useContext(AuthContext)
	const { documentId } = useParams()
	const document = game.documents.byId[documentId]

	if (!authState.userId) {
		console.error('Error: Authstate user id missing...', authState)
		return (
			<div className='text-white'>
				Auth state User ID not found... Try to refresh or sign out and in again.
			</div>
		)
	}

	if (!documentId)
		return <div className='text-white'>No document ID found...</div>

	return (
		<GameContext.Provider value={{ game, dispatch }}>
			<SocketContext.Provider value={socket}>
				<Sockets>
					<EventManager>
						<DragAndDropManager>
							<ToolsManager>
								<Windows>
									<Documents>
										<Users>
											{!document && <NoDocument />}
											{!game._id && (
												<div className='text-white'>Game not found...</div>
											)}
											{document && <DocumentPopout />}
										</Users>
									</Documents>
								</Windows>
							</ToolsManager>
						</DragAndDropManager>
					</EventManager>
					<DocumentMisc />
				</Sockets>
			</SocketContext.Provider>
		</GameContext.Provider>
	)
}

export default DocumentApp

import { useContext } from 'react'
import { IDocument } from '../../../shared/types/document'
import GameContext from '../contexts/game'
import useGetAssetById from './useGetAssetById'

interface Props {
	document: IDocument // The actor document
}

const useGetTokenAsset = ({ document }: Props) => {
	const { game } = useContext(GameContext)

	// Look up parent document's token or thumbnail
	const parentDoc = document.parentId
		? game.documents.byId[document.parentId]
		: null
	const parentAssetId = parentDoc
		? parentDoc.values.token || parentDoc.values.thumbnail
		: null

	// Use the parent's asset ID
	const { asset: tokenAsset } = useGetAssetById(parentAssetId)

	return { tokenAsset }
}

export default useGetTokenAsset

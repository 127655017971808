import Button from './Button'
import { twMerge } from 'tailwind-merge'

const PrimaryButton = ({ className = '', ...props }) => (
  <Button
    className={twMerge(
      'bg-red-600 dark:bg-red-700 hover:bg-red-700 dark:hover:bg-red-600 focus:ring-red-500 dark:focus:ring-red-500 text-white',
      className
    )}
    {...props}
  />
)

export default PrimaryButton

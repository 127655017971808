import { PdfViewerState } from '../interfaces/book'

export const getInitialState = (id: string): PdfViewerState => ({
	name: 'Loading...',
	currentPage: 1,
	numPages: null,
	renderedPages: [],
	onRenderSuccess: () => {},
	pagesToDisplay: parseInt(
		localStorage.getItem(`${id}_pagesToDisplay`) || '2',
		10,
	),
	isCoverAlone: JSON.parse(
		localStorage.getItem(`${id}_isCoverAlone`) || 'false',
	),
	searchText: '',
	fitWidth: false,
	fitPage: true, // Shared line
	pageSize: {
		width: 595, // Default to A4 width in pixels at 72 DPI
		height: 842, // Default to A4 height in pixels at 72 DPI
	},
	isOutlineVisible: JSON.parse(
		localStorage.getItem(`${id}_outlineVisible`) || 'false',
	),
	isThumbnailsVisible: JSON.parse(
		localStorage.getItem(`${id}_thumbnailVisible`) || 'false',
	),
	loading: [],
	zoomLevel: parseFloat(localStorage.getItem(`${id}_zoomLevel`) || '1'),
})

import { useCallback, useContext, useEffect } from 'react'
import GameContext from '../contexts/game'
import useUser from './useUser'

const useTokenDeletion = (
	selectedTokenIds: string[],
	clearSelection: () => void,
) => {
	const { isGM } = useUser()
	const { game, dispatch } = useContext(GameContext)

	const handleDeleteSelectedTokens = useCallback(() => {
		if (!isGM || selectedTokenIds.length === 0) return

		selectedTokenIds.forEach(tokenId => {
			const actor = game.documents.byId[tokenId]
			if (!actor || actor.type !== 'actor') return

			dispatch({
				type: 'DELETE_DOCUMENT',
				payload: {
					documentId: tokenId,
				},
			})
		})

		clearSelection()
	}, [isGM, selectedTokenIds, game.documents.byId, dispatch, clearSelection])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (
				(event.key === 'Delete' || event.key === 'Backspace') &&
				selectedTokenIds.length > 0
			) {
				handleDeleteSelectedTokens()
			}
		}

		window.addEventListener('keydown', handleKeyDown)
		return () => window.removeEventListener('keydown', handleKeyDown)
	}, [selectedTokenIds, handleDeleteSelectedTokens])

	return { handleDeleteSelectedTokens }
}

export default useTokenDeletion

import { useMutation } from '@apollo/client'
import { useContext, useState } from 'react'
import { ResourceContext } from '../../../../contexts/resources'
import { DELETE_RESOURCES } from '../../../../graphql/resources'
import useBookSelection from '../../../../hooks/useBookSelection'
import useDeleteEmbeddings from '../../../../hooks/useDeleteEmbeddings'
import ResourceDropzone from '../../../resources/ResourceDropZone'
import BookHeader from './BookHeader'
import BookListItems from './BookListItems'

const BookList = () => {
	const { resources, dispatchResource } = useContext(ResourceContext)
	const [search, setSearch] = useState('')
	const [selectMode, setSelectMode] = useState(false)
	const { selectedIds, handleSelect, handleDeselectAll } =
		useBookSelection<string>()
	const { deleteEmbeddings } = useDeleteEmbeddings()
	const [deleteResources] = useMutation(DELETE_RESOURCES)

	const filteredResources = Object.fromEntries(
		Object.entries(resources).filter(([, resource]) =>
			resource.name.toLowerCase().includes(search.toLowerCase()),
		),
	)

	const handleAddToGame = () => {
		dispatchResource({ type: 'ADD_TO_GAME', payload: selectedIds })

		setSelectMode(false)
	}

	const handleDelete = () => {
		if (
			window.confirm(
				`Delete the ${
					selectedIds.length === 1
						? 'selected book'
						: `${selectedIds.length} selected books`
				}?`,
			)
		) {
			deleteResources({
				variables: {
					resourceIds: selectedIds,
				},
			})

			selectedIds.forEach(async bookId => {
				await deleteEmbeddings(bookId)
			})

			dispatchResource({
				type: 'REMOVE_RESOURCES',
				payload: { resourceIds: selectedIds },
			})

			setSelectMode(false)
		}
	}

	const handleSelectAll = () => {
		const allResourceIds = Object.keys(resources)
		allResourceIds.forEach(id => handleSelect(id, true))
	}

	return (
		<ResourceDropzone>
			<BookHeader
				onSearch={setSearch}
				selectMode={selectMode}
				setSelectMode={setSelectMode}
			/>

			<div className='mt-8'>
				{Object.keys(filteredResources).length === 0 ? (
					<p className='my-20 text-center'>No Books</p>
				) : (
					<BookListItems
						resources={filteredResources}
						selectMode={selectMode}
						selectedIds={selectedIds}
						onSelect={handleSelect}
					/>
				)}
			</div>

			{selectMode && (
				<div className='fixed bottom-0 left-0 right-0 flex h-16 items-center justify-between  bg-gray-800 px-4 text-white'>
					<div className='m-auto max-w-4xl'>
						<div className='space-x-4'>
							<button
								className=''
								onClick={handleAddToGame}
								disabled={selectedIds.length === 0}
							>
								Add to Game
							</button>
							<button
								className=''
								onClick={handleDelete}
								disabled={selectedIds.length === 0}
							>
								Delete
							</button>
						</div>
						<div className='space-x-4'>
							<button className='' onClick={handleSelectAll}>
								Select All
							</button>
							<button className='' onClick={handleDeselectAll}>
								Select None
							</button>
						</div>
					</div>
				</div>
			)}
		</ResourceDropzone>
	)
}

export default BookList

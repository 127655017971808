import { useContext } from 'react'
import ModalWindow from '../window/ModalWindow'
import GameContext from '../../contexts/game'

type Props = {
	onClose: () => void
	onSelect: (documentId: string) => void
}

const DocumentPicker = ({ onClose, onSelect }: Props) => {
	const { game } = useContext(GameContext)

	return (
		<ModalWindow
			id='document-picker'
			title='Pick Document'
			size='small'
			onClose={onClose}
		>
			{game.documents.allIds.map(documentId => {
				const document = game.documents.byId[documentId]
				return (
					<div key={documentId} onClick={() => onSelect(documentId)}>
						{document.values.name}
					</div>
				)
			})}
		</ModalWindow>
	)
}

export default DocumentPicker

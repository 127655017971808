import { useContext } from 'react'
import GameContext from '../../contexts/game'

const defaultSystem = {
	repository: '',
	name: '',
	version: '',
	description: '',
	author: '',
	license: '',
	collections: [],
	grid: {
		type: 'square',
		size: 10,
		unitSize: 1,
		unit: '"',
	},
}

const SystemInfo: React.FC = () => {
	const { game } = useContext(GameContext)
	let { system } = game
	system = { ...defaultSystem, ...system }
	const { name, version, description, author } = system

	if (!name)
		return (
			<div className='mb-4 mt-2 text-sm text-gray-500'>
				No system currently installed. To install a system import from a public
				git repository containing a properly formatted system.
			</div>
		)

	return (
		<div className='p-4 text-white'>
			<p className='text-sm'>
				{name} {version}
			</p>
			<p className='text-sm text-gray-500'>By {author}</p>
			<p className='mt-4 text-sm text-gray-500'>{description}</p>
		</div>
	)
}

export default SystemInfo

import { FunctionComponent, RefObject } from 'react'
import Popper from '@popperjs/core'
import Popover from './Popover'

interface TooltipProps {
	open: boolean
	anchor: RefObject<HTMLElement>
	children: React.ReactNode
	placement: Popper.Placement
}

const Tooltip: FunctionComponent<TooltipProps> = ({
	open,
	anchor,
	children,
	...props
}) => {
	return (
		<Popover
			id='tooltip'
			open={open}
			anchor={anchor}
			showArrow={false}
			className='z-50 w-auto px-2 text-xs'
			{...props}
		>
			{children}
		</Popover>
	)
}

export default Tooltip

// Line width constants
export const MAX_LINE_WIDTH = 0.5
export const MIN_LINE_WIDTH = 0.25
export const DRAW_LINE_ALPHA = 0.8
export const CURSOR_PREVIEW_RADIUS = 0.2

// Speed constants
export const MAX_DRAWING_SPEED = 0.03 // Speed at which line is thinnest
export const MIN_DRAWING_SPEED = 0.00005 // Speed at which line is thickest

// Smoothing constants
export const WIDTH_SMOOTHING_FACTOR = 0.6 // How much to blend with previous width (0-1)
export const WIDTH_CHANGE_FACTOR = 0.4 // How much to blend with new width (1 - WIDTH_SMOOTHING_FACTOR)

// Polyline width
export const POLYLINE_WIDTH = MAX_LINE_WIDTH * 0.75

// Pressure constants
export const DEFAULT_PRESSURE = 0.5
export const MIN_PRESSURE_WIDTH = MIN_LINE_WIDTH
export const MAX_PRESSURE_WIDTH = MAX_LINE_WIDTH

// Preview constants
export const PREVIEW_ALPHA = 0.8

// Time constants
export const DOUBLE_CLICK_TIME = 300

// Polyline constants
export const SNAP_DISTANCE = 0.5
export const SNAP_PREVIEW_COLOR = '#00ff00' // Green color for snap preview

import { Plus } from 'lucide-react'
import { useContext, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import GameContext from '../../contexts/game'
import { generateUUID } from '../../utils/generateUUID'
import Button from '../FormComponents/Button'
import Input from '../Input'
import Popover from '../Popover'
import ToolbarControlButton from '../toolbar/ControlButton'
import TooltipWrapper from '../TooltipWrapper'

export default function AddTurnOrderItemButton() {
	const { dispatch } = useContext(GameContext)
	const [isOpen, setIsOpen] = useState(false)
	const buttonRef = useRef<HTMLButtonElement>(null)

	const {
		register,
		handleSubmit,
		reset,
		formState: { isDirty },
	} = useForm()

	const handleAddItem = (data: { itemName: string }) => {
		const name = data.itemName

		dispatch({
			type: 'ADD_TO_TURN_ORDER',
			payload: {
				item: {
					id: generateUUID(),
					name,
					initiative: 0,
					isActive: false,
					visibility: true,
					isTurn: false,
					identified: false,
					documentId: '',
					relationship: 'neutral',
				},
				index: 0,
			},
		})

		setIsOpen(false) // Close the popover after adding
		reset() // Reset form fields
	}

	return (
		<>
			<ToolbarControlButton ref={buttonRef} onClick={() => setIsOpen(true)}>
				<TooltipWrapper label='Add item to turn tracker'>
					<Plus className='h-6 w-6 self-center' aria-hidden='true' />
				</TooltipWrapper>
			</ToolbarControlButton>

			<Popover
				id='simple-add-item-popover'
				open={isOpen}
				anchor={buttonRef}
				onClose={() => setIsOpen(false)}
				placement='bottom'
				className='w-72 bg-gray-900 p-1'
			>
				<form className='flex space-x-1' onSubmit={handleSubmit(handleAddItem)}>
					<Input
						type='text'
						className='flex-1 pt-0'
						placeholder='Name...'
						{...register('itemName', { required: true })}
						onKeyDown={event => {
							if (event.key === 'Enter') {
								handleSubmit(handleAddItem)(event)
							}
						}}
					/>
					<Button
						type='submit'
						className='flex-0 mt-0 w-16 leading-4'
						disabled={!isDirty}
					>
						Add
					</Button>
				</form>
			</Popover>
		</>
	)
}

import { gql } from '@apollo/client'

export const UPDATE_AVATAR = gql`
	mutation UpdateAvatar($avatar: String!, $userId: ID!) {
		updateAvatar(avatarInput: { avatar: $avatar, userId: $userId }) {
			_id
			avatar
		}
	}
`

export const UPDATE_USER_PROFILE = gql`
	mutation UpdateUserProfile($email: String!, $name: String!) {
		updateUserProfile(userProfileInput: { email: $email, name: $name }) {
			email
			name
		}
	}
`

export const UPDATE_USER_PASSWORD = gql`
	mutation UpdateUserPassword(
		$oldPassword: String!
		$newPassword: String!
		$newPasswordConfirmation: String!
	) {
		updateUserPassword(
			userPasswordInput: {
				oldPassword: $oldPassword
				newPassword: $newPassword
				newPasswordConfirmation: $newPasswordConfirmation
			}
		) {
			_id
		}
	}
`

export const DELETE_USER = gql`
	mutation DeleteUser {
		deleteUser {
			email
		}
	}
`

export const AM_I_AUTHED = gql`
	query AmIAuthed {
		amIAuthed {
			user {
				_id
				name
				email
				avatar
				isAdmin
			}
			token
		}
	}
`

import { useMutation } from '@apollo/client'
import { socket } from '../../../contexts/socket'
import { DELETE_GAME } from '../../../graphql/games'
import DangerMenuItem from '../../dropdownmenu/DangerMenuItem'

type Props = {
	gameId: string
}

const DeleteGame = ({ gameId }: Props) => {
	const [deleteGame] = useMutation(DELETE_GAME, {
		onCompleted: () => {
			socket.emit('userDataUpdated', gameId)
			// Add any additional refetch or state update logic here
		},
	})

	const handleDelete = () => {
		const confirmed = window.confirm(`Delete the game? This cannot be undone.`)

		if (!confirmed) {
			return
		}

		deleteGame({
			variables: { gameId: gameId },
		})
	}

	return <DangerMenuItem onSelect={handleDelete}>Delete Game</DangerMenuItem>
}

export default DeleteGame

import { FC, useContext, useState } from 'react'
import Dropzone from 'react-dropzone'
import request from 'superagent'
import GameContext from '../../contexts/game'
import useItemFilter from '../../hooks/UseItemFilter'
import useAssetUploader from '../../hooks/useAssetUploader'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useGetAssetById from '../../hooks/useGetAssetById'
import DropToUploadMessage from '../DropToUploadMessage'
import ProgressWindow from '../ProgressWindow'

interface StageDropZoneProps {
	children: React.ReactNode
}

const StageDropZone: FC<StageDropZoneProps> = ({ children }) => {
	const { game, dispatch } = useContext(GameContext)
	const [fileDragOver, setFileDragOver] = useState<boolean>(false)
	const [uploadProgress, setUploadProgress] = useState<number>(0)

	const activeScene = useGetActiveScene()
	const { asset: mapAsset } = useGetAssetById(activeScene?.values?.mapId)
	const assetUploader = useAssetUploader()
	const { checkAccess } = useItemFilter()
	const hasUploadPermission = checkAccess(activeScene)

	const dropHandler = async (file: File[]) => {
		setFileDragOver(false)

		const oldMapUrl = mapAsset?.fileurl
		let interval

		if (oldMapUrl) {
			const confirmation = window.confirm('Replace the current map?')
			if (!confirmation) return
		}

		try {
			// Define the number of seconds to complete the upload
			const numberOfSeconds = 20
			const intervalTime = (numberOfSeconds * 1000) / 100 // 100 steps to reach 100%

			// Initialize upload progress to 0
			setUploadProgress(0)

			// Increment progress at calculated interval time
			interval = setInterval(() => {
				setUploadProgress(prevProgress => {
					if (prevProgress >= 100) {
						clearInterval(interval)
						return 100
					}
					return prevProgress + 1
				})
			}, intervalTime)

			const asset = await assetUploader(file[0], game._id)

			// Clear the interval once the upload is done
			clearInterval(interval)

			// Delete old map from S3
			if (oldMapUrl) {
				request.post('/api/delete').send({ key: oldMapUrl })
			}

			dispatch({
				type: 'UPDATE_DOCUMENT',
				payload: {
					updatedDocument: {
						...activeScene,
						values: {
							...activeScene.values,
							mapId: asset._id,
						},
					},
				},
			})

			// Set progress to 100% if not already
			setUploadProgress(0)
		} catch (error) {
			console.error(error)
			clearInterval(interval) // Clear interval in case of error
			setUploadProgress(0) // Reset progress to 0
		}
	}

	if (!hasUploadPermission) return <>{children}</>

	return (
		<Dropzone
			onDragEnter={() => {
				setFileDragOver(true)
			}}
			onDragLeave={() => {
				setFileDragOver(false)
			}}
			onDrop={dropHandler}
		>
			{({ getRootProps }) => (
				<div {...getRootProps()}>
					{children}

					<DropToUploadMessage show={fileDragOver} />

					<ProgressWindow title='Uploading Map' progress={uploadProgress} />
				</div>
			)}
		</Dropzone>
	)
}

export default StageDropZone

import { Swords } from 'lucide-react'
import { useGame } from '../../hooks/useGame'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'

export default function TurnTrackerButton() {
	const { game, dispatch } = useGame()
	const { isGM } = useUser()
	const isShowing = game.turnTracker.show
	const isRunning = game.turnTracker.currentRound > 0

	if (!isGM) return null

	const handleToggleTurnTracker = () => {
		if (isShowing) {
			if (isRunning) {
				const confirmHide = window.confirm(
					'Hiding the turn tracker will stop it and reset the round counter. Are you sure?',
				)
				if (!confirmHide) return
			}
			dispatch({ type: 'HIDE_TURN_TRACKER' })
			if (isRunning) {
				dispatch({ type: 'STOP_TURN_TRACKER' })
			}
		} else {
			dispatch({ type: 'SHOW_TURN_TRACKER' })
			// dispatch({ type: 'HIDE_LIBRARY' })
			// dispatch({ type: 'HIDE_BOOKS' })
			// dispatch({ type: 'HIDE_SYRINSCAPE' })
		}
	}

	return (
		<ToolButton
			tooltip={'Turn Tracker'}
			isOpen={isShowing}
			onClick={handleToggleTurnTracker}
		>
			<Swords className='h-5 w-5' aria-hidden='true' />
		</ToolButton>
	)
}

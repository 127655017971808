import { Minus, Plus } from 'lucide-react'
import { forwardRef, useRef, useState } from 'react'
import Toolbar from './interface/toolbar/Toolbar'
import ToolButton from './interface/toolbar/ToolButton'
import Tooltip from './Tooltip'

interface StepperProps {
	value: number
	onChange: (value: number) => void
	min?: number
	max?: number
	step?: number
	tooltip?: string
}

const Stepper = forwardRef<HTMLDivElement, StepperProps>(
	(
		{ value, onChange, min = -Infinity, max = Infinity, step = 1, tooltip },
		ref,
	) => {
		const [isFocused, setIsFocused] = useState(false)
		const [isHovered, setIsHovered] = useState(false)
		const stepperRef = useRef<HTMLDivElement>(null)

		const increment = () => {
			const newValue = Math.min(value + step, max)
			onChange(newValue)
		}

		const decrement = () => {
			const newValue = Math.max(value - step, min)
			onChange(newValue)
		}

		const displayValue = isFocused
			? value.toString()
			: value > 0
			? `+${value}`
			: value.toString()

		const stepperContent = (
			<Toolbar
				ref={node => {
					// Forward the ref to the outer div
					if (typeof ref === 'function') {
						ref(node)
					} else if (ref) {
						ref.current = node
					}
					stepperRef.current = node
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<ToolButton onClick={decrement}>
					<Minus className='h-5 w-5' />
				</ToolButton>
				<input
					type='text'
					value={displayValue}
					onChange={e => {
						const newValue = e.target.value.replace(/^(\+?)/, '')
						onChange(Number(newValue))
					}}
					onFocus={() => setIsFocused(true)}
					onBlur={() => setIsFocused(false)}
					className='h-full w-8 bg-transparent text-center text-sm text-gray-800 focus:outline-none'
				/>
				<ToolButton onClick={increment}>
					<Plus className='h-5 w-5' />
				</ToolButton>
			</Toolbar>
		)

		return (
			<>
				{tooltip ? (
					<Tooltip open={isHovered} anchor={stepperRef} placement='top'>
						{tooltip}
					</Tooltip>
				) : null}
				{stepperContent}
			</>
		)
	},
)

Stepper.displayName = 'Stepper'

export default Stepper

import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import FlagCheckbox from './FlagCheckbox'

const AIGamemaster = () => {
	const { register, watch, setValue } = useForm({
		defaultValues: {
			flags: {
				aiGamemaster: false,
			},
		},
	})

	useEffect(() => {
		const storedValue = localStorage.getItem('aiGamemasterEnabled')
		setValue('flags.aiGamemaster', storedValue === 'true')
	}, [setValue])

	useEffect(() => {
		const subscription = watch(value => {
			localStorage.setItem(
				'aiGamemasterEnabled',
				value.flags.aiGamemaster.toString(),
			)
		})

		return () => subscription.unsubscribe()
	}, [watch])

	return (
		<FlagCheckbox
			label='AI Gamemaster (Experimental, requires reload)'
			id='aiGamemaster'
			className='mt-0 flex-grow'
			register={register}
		/>
	)
}

export default AIGamemaster

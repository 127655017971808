import ObjectID from 'bson-objectid'
import { ActorRelationship, IActor } from '../../../shared/types/actor'
import { IDocument } from '../../../shared/types/document'
import useCollectionDefinition from './useGetCollection'
import useGetDocumentById from './useGetDocumentById'

// Return both the create function and the created actor if documentId is provided
const useDocumentToActor = (documentId?: string) => {
	const { getCollectionDefinition } = useCollectionDefinition()
	const { document } = documentId
		? useGetDocumentById(documentId)
		: { document: null }

	const getDefaultRelationship = (documentType: string): ActorRelationship => {
		const collection = getCollectionDefinition(documentType)
		return collection?.relationship || 'neutral'
	}

	const createActor = (sourceDocument: IDocument): IActor => {
		return {
			_id: ObjectID().toHexString(),
			type: 'actor',
			parentId: sourceDocument._id,
			creator: sourceDocument.creator,
			version: 1,
			access: sourceDocument.access,
			accessList: [...sourceDocument.accessList],
			values: {
				actorType: 'unique',
				state: 'active',
				name: sourceDocument.values.name || 'Unnamed Actor',
				nameIsSecret: false,
				locations: [],
				relationship: getDefaultRelationship(sourceDocument.type),
			},
		}
	}

	return {
		createActor,
		actor: document ? createActor(document) : null,
	}
}

export default useDocumentToActor

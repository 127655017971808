import { Container } from '@pixi/react'
import { Viewport as PixiViewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useCallback } from 'react'
import { TokenMenuState } from '../../hooks/useStageMenus'
import useUser from '../../hooks/useUser'
import DrawLayer from './DrawLayer'
import Map from './Map'
import Tabletop from './Tabletop'
import Tokens from './Tokens'

interface StageContentProps {
	viewport: PixiViewport
	setTokenMenu: (state: TokenMenuState | null) => void
	setMapMenu: (state: { x: number; y: number } | null) => void
	selectedTokenIds: string[]
	handleTokenSelect: (id: string, isShiftKey: boolean) => void
	clearSelection: () => void
	activeTokenId: string | null
}

const StageContent = ({
	viewport,
	setTokenMenu,
	setMapMenu,
	selectedTokenIds,
	handleTokenSelect,
	clearSelection,
	activeTokenId,
}: StageContentProps) => {
	const { isGM } = useUser()

	const handlePointerDown = useCallback(
		(e: PIXI.FederatedPointerEvent) => {
			// Handle map right-click
			if (e.button === 2 && isGM) {
				e.stopPropagation()
				const globalPosition = new PIXI.Point(e.globalX, e.globalY)
				console.log('Showing map menu at', globalPosition)
				setMapMenu({ x: globalPosition.x, y: globalPosition.y })
				return
			}

			// Handle background left-click
			if (e.button === 0) {
				clearSelection()
			}
		},
		[isGM, setMapMenu, clearSelection],
	)

	return (
		<Container eventMode='static' pointerdown={handlePointerDown}>
			<Tabletop />
			<Map viewport={viewport} />
			<DrawLayer viewport={viewport} />
			<Tokens
				onShowMenu={setTokenMenu}
				viewport={viewport}
				activeTokenId={activeTokenId}
				selectedTokenIds={selectedTokenIds}
				onTokenSelect={handleTokenSelect}
			/>
		</Container>
	)
}

export default StageContent

import { useFormContext } from 'react-hook-form'
import Label from '../../../FormComponents/Label'

const MapGridEnabled = () => {
	const { register } = useFormContext()

	return (
		<div className='center flex align-middle'>
			<Label className='mt-3 w-1/3'>Show Grid</Label>
			<div className='w-2/3 pt-3'>
				<input type='checkbox' {...register('grid.enabled')} />
			</div>
		</div>
	)
}

export default MapGridEnabled

import React from 'react'
import { twMerge } from 'tailwind-merge'
import BodyText from '../elements/BodyText'
import ButtonLink from '../elements/ButtonLink'
import Card from '../elements/Card'
import Eyebrow from '../elements/Eyebrow'
import Title from '../elements/Title'

interface HeroSectionProps {
	backgroundImage: string
	eyebrow: string
	title: string
	body: React.ReactNode
	buttonText: string
	buttonLink: string
	gradientPosition: 'left' | 'right'
	textColor?: string
	reversed?: boolean
}

const HeroSection: React.FC<HeroSectionProps> = ({
	backgroundImage,
	eyebrow,
	title,
	body,
	buttonText,
	buttonLink,
	gradientPosition,
	textColor = 'white',
	reversed = false,
}) => {
	const gradientStyle = {
		background: `radial-gradient(circle at ${
			gradientPosition === 'left' ? '30%' : '80%'
		} 50%, transparent 10%, rgba(0, 0, 0, 0.4) 70%)`,
	}

	return (
		<Card
			className='mt-0'
			innerClassName={twMerge(
				'text-center md:max-w-full md:flex items-center h-[450px]',
				reversed && 'flex-row-reverse',
			)}
			style={{
				backgroundImage: `url(${backgroundImage})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
			}}
		>
			<div className='absolute inset-0' style={gradientStyle}></div>
			<div className='relative z-10 w-1/2 px-8'>
				<Eyebrow>{eyebrow}</Eyebrow>
				<Title>{title}</Title>
				<BodyText>{body}</BodyText>
				<ButtonLink
					to={buttonLink}
					className={`mx-auto w-fit rounded-full bg-[#fff]/20 px-6 py-3 text-${textColor} backdrop-blur-xl`}
				>
					{buttonText}
				</ButtonLink>
			</div>
			<div className='relative z-10 w-1/2'>
				{/* Placeholder for potential image or content */}
			</div>
		</Card>
	)
}

export default HeroSection

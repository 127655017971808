import React, { ReactNode, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { INTERFACE_BACKGROUND_COLOR } from '../constants'

interface ToolbarProps extends React.HTMLAttributes<HTMLDivElement> {
	children: ReactNode
	className?: string
	style?: React.CSSProperties
}

const Toolbar = forwardRef<HTMLDivElement, ToolbarProps>(
	({ children, className, style, ...rest }, ref) => {
		return (
			<div
				className={twMerge(
					'pointer-events-auto flex h-14 w-fit flex-shrink-0 items-center justify-center space-x-1 rounded-full p-1',
					className,
				)}
				ref={ref}
				style={{ backgroundColor: INTERFACE_BACKGROUND_COLOR, ...style }}
				{...rest}
			>
				{children}
			</div>
		)
	},
)

Toolbar.displayName = 'Toolbar'

export default Toolbar

import { Reducer } from 'react'
import { IWindowID } from '../interfaces/game'

interface Action {
	type: string
	payload: IWindowID
}

const DocumentsReducer: Reducer<string[], Action> = (state, action) => {
	const payload = action.payload
	const documentId = payload ? payload.documentId : null

	switch (action.type) {
		case 'CLOSE_ALL_DOCUMENTS':
			return []

		case 'OPEN_DOCUMENT':
			if (state.includes(documentId)) return state
			return [...state, documentId]

		case 'CLOSE_DOCUMENT':
			return state.filter(doc => doc !== documentId)

		default:
			return state
	}
}

export default DocumentsReducer

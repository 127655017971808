import React from 'react'
import BookItem from '../../../books/BookItem'

interface BookListItemsProps {
	resources: { [key: string]: any }
	selectMode?: boolean
	selectedIds?: string[]
	onSelect?: (id: string, selected: boolean) => void
}

const BookListItems: React.FC<BookListItemsProps> = ({
	resources,
	selectMode,
	selectedIds = [],
	onSelect,
}) => (
	<ul className='grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6'>
		{Object.entries(resources).map(([id, resource]) => (
			<BookItem
				key={id}
				resource={resource}
				selectMode={selectMode}
				selected={selectedIds.includes(resource._id)}
				onSelect={selected => onSelect && onSelect(resource._id, selected)}
			/>
		))}
	</ul>
)

export default BookListItems

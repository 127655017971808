import React, { useContext } from 'react'
import Separator from '../../ContextMenu/Separator'
import MenuCheckboxItem from '../../ContextMenu/CheckboxItem'
import GameContext from '../../../contexts/game'

interface AccessControlProps {
	access: 'private' | 'public'
	id: string // e.g. document id or book id
	handleSharePrivate: (id: string) => void
	handleSharePublic: (id: string) => void
}

const AccessControl: React.FC<AccessControlProps> = ({
	access,
	id,
	handleSharePrivate,
	handleSharePublic,
}) => {
	const { game } = useContext(GameContext)
	const hasPlayers = game.users.length > 0

	if (!hasPlayers) {
		return null
	}

	return (
		<>
			<Separator />

			<MenuCheckboxItem
				checked={access === 'private'}
				onCheckedChange={() => handleSharePrivate(id)}
			>
				Private
			</MenuCheckboxItem>
			<MenuCheckboxItem
				checked={access === 'public'}
				onCheckedChange={() => handleSharePublic(id)}
			>
				Public
			</MenuCheckboxItem>
		</>
	)
}

export default AccessControl

import { useContext } from 'react'
import GameContext from '../../contexts/game'
import useUser from '../../hooks/useUser'
import GMSessionStage from './GMSessionStage'
import PlayerSessionStage from './PlayerSessionStage'

const SessionStage = () => {
	const { game } = useContext(GameContext)
	const { userId, isGM } = useUser()

	const isShowing = game.userSettings[userId]?.showStage || false

	if (!isShowing) return null

	if (isGM) return <GMSessionStage />
	return <PlayerSessionStage />
}

export default SessionStage

import { useContext } from 'react'
import GameContext from '../contexts/game'
import { IGameContext } from '../interfaces/game'

export function useGame(): IGameContext {
	const context = useContext(GameContext)

	if (!context) {
		throw new Error('useGame must be used within a GameProvider')
	}

	return context
}

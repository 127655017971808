import { X } from 'lucide-react'
import TooltipWrapper from '../TooltipWrapper'
import { windowButtonStyles } from '../window/Button'

interface CloseButtonProps {
	onClick: () => void
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClick }) => {
	return (
		<TooltipWrapper label='Close'>
			<button
				className={windowButtonStyles}
				onClick={onClick}
				onTouchEnd={onClick}
			>
				<X className='h-5 w-5' aria-hidden='true' />
			</button>
		</TooltipWrapper>
	)
}

export default CloseButton

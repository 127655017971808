import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { generateUUID } from '../utils/generateUUID'
import GameContext from './game'

interface Memory {
	id: string
	content: string
	importance: number
}

interface MemoryState {
	memories: Memory[]
}

// Define initialState
const initialState: MemoryState = {
	memories: [],
}

type Action =
	| { type: 'ADD_MEMORY'; payload: Omit<Memory, 'id'> }
	| { type: 'UPDATE_MEMORY'; payload: Memory }
	| { type: 'DELETE_MEMORY'; payload: string }
	| { type: 'RESET_MEMORIES' }
	| { type: 'SET_MEMORIES'; payload: Memory[] }

type MemoryContextType = {
	state: MemoryState
	dispatch: React.Dispatch<Action>
}

export const MemoryContext = createContext<MemoryContextType | undefined>(
	undefined,
)

function memoryReducer(state: MemoryState, action: Action): MemoryState {
	switch (action.type) {
		case 'ADD_MEMORY': {
			const newMemory: Memory = { ...action.payload, id: generateUUID() }
			return { ...state, memories: [...state.memories, newMemory] }
		}
		case 'UPDATE_MEMORY':
			return {
				...state,
				memories: state.memories.map(memory =>
					memory.id === action.payload.id ? action.payload : memory,
				),
			}
		case 'DELETE_MEMORY':
			return {
				...state,
				memories: state.memories.filter(memory => memory.id !== action.payload),
			}
		case 'RESET_MEMORIES':
			return {
				memories: [],
			}
		case 'SET_MEMORIES':
			return { ...state, memories: action.payload }
		default:
			return state
	}
}

export const MemoryProvider: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const [state, dispatch] = useReducer(memoryReducer, initialState)
	const { game } = useContext(GameContext) // Add this line to get the game context

	useEffect(() => {
		if (game?._id) {
			const storedMemories = localStorage.getItem(`memories_${game._id}`)
			if (storedMemories) {
				dispatch({ type: 'SET_MEMORIES', payload: JSON.parse(storedMemories) })
			} else {
				dispatch({ type: 'RESET_MEMORIES' })
			}
		}
	}, [game])

	useEffect(() => {
		// console.log(
		// 	'state.memories updated, writing to local storage',
		// 	state.memories,
		// )
		if (game?._id) {
			localStorage.setItem(
				`memories_${game._id}`,
				JSON.stringify(state.memories),
			)
			dispatch({ type: 'SET_MEMORIES', payload: state.memories })
		}
	}, [state.memories, game])

	return (
		<MemoryContext.Provider value={{ state, dispatch }}>
			{children}
		</MemoryContext.Provider>
	)
}

export function useMemory() {
	const context = useContext(MemoryContext)
	if (context === undefined) {
		throw new Error('useMemory must be used within a MemoryProvider')
	}
	return context
}

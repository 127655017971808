import { twMerge } from 'tailwind-merge'

export interface IUserDotProps extends React.HTMLAttributes<HTMLDivElement> {
	color: string
}

export default function UserDot({
	color,
	style,
	className,
	...rest
}: IUserDotProps) {
	if (!color) return null

	return (
		<div
			{...rest}
			className={twMerge('h-2 w-2 rounded-full', className)}
			style={{ backgroundColor: color, ...style }}
		/>
	)
}

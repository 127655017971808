import React from 'react'

interface ContextBridgeProps<T> {
	children: React.ReactNode
	Context: React.Context<T>
	render: (children: React.ReactElement) => React.ReactElement
}

const ContextBridge = <T,>({
	children,
	Context,
	render,
}: ContextBridgeProps<T>) => {
	return (
		<Context.Consumer>
			{(value: T) =>
				render(<Context.Provider value={value}>{children}</Context.Provider>)
			}
		</Context.Consumer>
	)
}

export default ContextBridge

import { useContext } from 'react'
import GameContext from '../../contexts/game'

const useHandleRemoveAsset = () => {
	const { dispatch } = useContext(GameContext)

	const handleRemoveAsset = (payload: { assetId: string }) => {
		dispatch({
			type: 'REMOVE_ASSET',
			payload,
		})
	}

	return handleRemoveAsset
}

export default useHandleRemoveAsset

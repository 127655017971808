import { useState } from 'react'

const useDeleteEmbeddings = () => {
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [message, setMessage] = useState(null)

	const deleteEmbeddings = async (bookId: string) => {
		const token = localStorage.getItem('token')

		if (!bookId) {
			setError('Book ID is required')
			return
		}

		setLoading(true)
		setError(null)
		setMessage(null)

		try {
			const response = await fetch('/api/deleteEmbeddings', {
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ bookId }),
			})

			const data = await response.json()

			if (response.ok) {
				setMessage(data.message)
			} else {
				setError(data.message)
			}
		} catch (err) {
			setError('Error in processing request')
		} finally {
			setLoading(false)
		}
	}

	return { deleteEmbeddings, loading, error, message }
}

export default useDeleteEmbeddings

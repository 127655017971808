import * as React from 'react'
import { ICollection } from '../../../../shared/types/system'
import useUser from '../../hooks/useUser'
import Popover from '../Popover'
import ListItem from '../views/ListItem'

export interface IAddMenuProps {
	onAddDocument: (type: string) => void
	onAddBook: () => void
	onClose: () => void
	collections: ICollection[]
	open: boolean
	anchorRef: React.RefObject<HTMLElement>
}

export default function AddMenu(props: IAddMenuProps) {
	const { onAddDocument, onAddBook, onClose, collections, open, anchorRef } =
		props
	const { role } = useUser()
	const isGM = role === 'gm'

	const collectionsWithCreatePermission = React.useMemo(() => {
		if (isGM) {
			return collections
		}

		const types = collections.filter(c => c.allowCreate === 'true')

		return types
	}, [collections, isGM])

	return (
		<Popover
			id='add-document-popover'
			open={open}
			onClose={onClose}
			anchor={anchorRef}
			placement='bottom'
			className='w-32'
		>
			{isGM && (
				<>
					<ListItem name='Book' onOpen={onAddBook} />
					<hr className='my-2 border-white/20' />
				</>
			)}

			{collectionsWithCreatePermission.map(
				({ singularName, type, allowCreate }, i) => {
					if (type === 'all') return null

					if (isGM || allowCreate) {
						return (
							<ListItem
								key={`type-${i}`}
								name={singularName}
								onOpen={() => onAddDocument(type)}
							/>
						)
					}

					return null
				},
			)}
		</Popover>
	)
}

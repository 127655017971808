import { useMutation } from '@apollo/client'
import { useContext, useState } from 'react'
import GameContext from '../contexts/game'
import { REMOVE_ASSET } from '../graphql/games'

const useRemoveAsset = () => {
	const { dispatch } = useContext(GameContext)
	const [hookError, setHookError] = useState(null)

	const [removeAsset] = useMutation(REMOVE_ASSET, {
		onCompleted({ removeAsset: assetId }) {
			setHookError(null)

			dispatch({
				type: 'REMOVE_ASSET',
				payload: { assetId },
			})
		},
		onError(error) {
			setHookError(error)
		},
	})

	return { removeAsset, error: hookError }
}

export default useRemoveAsset

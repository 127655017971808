import { ChangeEvent, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps {
	acceptTypes?: string
	name: string
	className?: string
	value?: string
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

const UploadField = forwardRef<HTMLInputElement, IProps>((props, ref) => {
	const acceptTypes = props.acceptTypes
		? props.acceptTypes
		: 'image/png, image/jpeg, image/webp'

	return (
		<input
			className={twMerge(
				// wanted to give this cursor-pointer but it doesn't work
				// because the field will show it but the field's button won't
				'leading-16 h-full w-full rounded-lg',
				props.className,
			)}
			type='file'
			name={props.name}
			defaultValue={props.value}
			accept={acceptTypes}
			ref={ref}
			onChange={props.onChange}
		/>
	)
})

export default UploadField

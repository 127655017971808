import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
	children: React.ReactNode
	name: string
	className?: string
	containerClassName?: string
	defaultValue?: string
	onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
	disabled?: boolean
}

const Dropdown = forwardRef<HTMLSelectElement, IProps>(
	(
		{
			children,
			className = '',
			containerClassName = '',
			defaultValue = '',
			disabled,
			onChange,
			...props
		},
		ref,
	) => {
		return (
			<div className={twMerge('relative flex-1', containerClassName)}>
				<select
					ref={ref}
					className={twMerge(
						'mt-1 block w-full cursor-pointer appearance-none rounded-lg border-none bg-gray-200 py-2 pl-3 pr-10 text-base text-gray-900 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 dark:border-gray-800 dark:bg-gray-800 dark:text-white dark:shadow-none sm:text-sm',
						disabled ? 'cursor-not-allowed opacity-50' : '',
						className,
					)}
					defaultValue={defaultValue}
					onChange={onChange}
					disabled={disabled}
					{...props}
				>
					{children}
				</select>

				<div
					className='pointer-events-none absolute right-4 top-0.5 flex h-full'
					style={{ ...props.style }}
				>
					<ChevronDownIcon className='h-4 w-4 self-center' />
				</div>
			</div>
		)
	},
)

export default Dropdown

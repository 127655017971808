import * as ContextMenu from '@radix-ui/react-context-menu'
import { twMerge } from 'tailwind-merge'

type Props = ContextMenu.ContextMenuItemProps &
	React.RefAttributes<HTMLDivElement>

const MenuItem = ({ className, style, children, ...rest }: Props) => {
	return (
		<ContextMenu.Item
			className={twMerge(
				'cursor-pointer rounded-lg py-1.5 px-2.5 text-sm hover:bg-gray-200 dark:hover:bg-gray-800',
				className,
			)}
			style={{
				minWidth: '100px',
				...style,
			}}
			{...rest}
		>
			{children}
		</ContextMenu.Item>
	)
}

export default MenuItem

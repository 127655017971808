import { useContext, useEffect, useMemo, useReducer } from 'react'
import { useParams } from 'react-router-dom'
import PdfViewerContext from '../../contexts/book'
import GameContext from '../../contexts/game'
import { ResourceContext } from '../../contexts/resources'
import reducer from '../../reducers/book'
import { getInitialState } from '../../utils/getInitialBookState'
import PDFViewer from '../resources/PDFViewer'

const BookPopout = () => {
	const { game } = useContext(GameContext)
	const { resources } = useContext(ResourceContext)
	const { bookId } = useParams()
	const page = new URLSearchParams(window.location.search).get('page')
	const book = game.books.byId[bookId]
	const resource = useMemo(
		() => resources[book.resourceId],
		[resources, book.resourceId],
	)
	const name = resource?.name
	console.log(getInitialState(bookId))
	const [bookState, bookDispatch] = useReducer(reducer, getInitialState(bookId))
	const platformName = 'Realms Inc'

	const setWindowTitle = () => {
		if (name) document.title = name + ' - ' + game.title + ' — ' + platformName
		else document.title = platformName

		document.body.className = 'document'
		document.body.style.backgroundColor = 'black'
		document.documentElement.style.backgroundColor = 'black'

		return () => {
			document.title = platformName
			document.body.className = ''
		}
	}

	useEffect(setWindowTitle, [name])

	useEffect(() => {
		localStorage.setItem(
			`${bookId}_pagesToDisplay`,
			bookState.pagesToDisplay.toString(),
		)
		localStorage.setItem(
			`${bookId}_isCoverAlone`,
			JSON.stringify(bookState.isCoverAlone),
		)
		localStorage.setItem(
			`${bookId}_outlineVisible`,
			JSON.stringify(bookState.isOutlineVisible),
		)
		localStorage.setItem(
			`${bookId}_thumbnailVisible`,
			JSON.stringify(bookState.isThumbnailsVisible),
		)
	}, [
		bookId,
		bookState.pagesToDisplay,
		bookState.isCoverAlone,
		bookState.isOutlineVisible,
		bookState.isThumbnailsVisible,
	])

	if (resource === undefined) return null

	return (
		<PdfViewerContext.Provider value={{ bookState, bookDispatch }}>
			<PDFViewer resource={resource} page={page} isGame={true} />
		</PdfViewerContext.Provider>
	)
}

export default BookPopout

import { IEvent, IEventAction, IEventQueue } from '../interfaces/event'

export default function EventReducer(state: IEventQueue, action: IEventAction) {
  switch (action.type) {
    case 'ADD_EVENT':
      return [...state, action.payload]

    case 'DELETE_EVENT':
      return state.filter((event: IEvent) => event._id !== action.payload)

    default:
      return state
  }
}

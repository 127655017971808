import { useEffect, useRef, useState } from 'react'
import ReactCrop, { Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import './Cropper.css'
import useCroppedImage from './hooks/useCroppedImage'

type Props = {
	src: string
	setCroppedImage: (image: string) => void
	disabled?: boolean
	crop?: Crop
	setCrop?: (crop: Crop) => void
}

function Cropper({
	src,
	setCroppedImage,
	disabled = false,
	crop,
	setCrop,
}: Props) {
	const imgRef = useRef<HTMLImageElement>(null)
	const croppedImage = useCroppedImage({ src, crop })

	useEffect(() => {
		if (disabled) setCrop(undefined)
	}, [disabled])

	useEffect(() => {
		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Enter' && !disabled && croppedImage) {
				setCrop(undefined)
				setCroppedImage(croppedImage)
			}
		}

		window.addEventListener('keydown', handleKeyDown)

		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [disabled, croppedImage, setCroppedImage])

	useEffect(() => {
		if (croppedImage === 'data:') {
			setCroppedImage(undefined)
			return
		}
		setCroppedImage(croppedImage)
	}, [crop])

	const containerRef = useRef(null)
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)

	useEffect(() => {
		const img = new Image()
		img.onload = updateImageSize
		img.src = src

		const handleResize = () => {
			updateImageSize()
		}

		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [src])

	function updateImageSize() {
		if (containerRef.current) {
			const img = new Image()
			img.src = src
			const scaleFactor = Math.min(
				containerRef.current.offsetWidth / img.naturalWidth,
				containerRef.current.offsetHeight / img.naturalHeight,
			)

			setWidth(img.naturalWidth * scaleFactor)
			setHeight(img.naturalHeight * scaleFactor)
		}
	}

	return (
		<div
			ref={containerRef}
			className='absolute inset-0 flex items-center justify-center'
		>
			<ReactCrop
				crop={crop}
				onChange={(_, c) => setCrop(c)}
				onComplete={(_, c) => setCrop(c)}
				disabled={disabled}
			>
				<img
					ref={imgRef}
					src={src}
					style={{
						width: `${width}px`,
						height: `${height}px`,
					}}
				/>
			</ReactCrop>
		</div>
	)
}

export default Cropper

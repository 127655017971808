import { useContext, useEffect, useState } from 'react'
import GameContext from '../contexts/game'
import UsersContext from '../contexts/users'
import { IRegisteredUser } from '../interfaces/users'
import useGetDocumentById from './useGetDocumentById'

type Props = {
	userId: string
	assumedCharacterId?: string // can be used to force a specific character
	fallbackAvatarId?: string // can be used to force a specific fallback avatar
	disableAssumedCharacterLookup?: boolean // can be used to disable assumed character
}

// A function to find a user profile by userId
const findUserProfile = (userId: string, users: IRegisteredUser[]) =>
	users.find(u => u.userId === userId)?.userProfile

export default function useGetAvatarSrc({
	userId,
	assumedCharacterId,
	fallbackAvatarId,
	disableAssumedCharacterLookup = false,
}: Props) {
	const { game } = useContext(GameContext)
	const { usersState } = useContext(UsersContext)
	const user = usersState.users.find(u => u.userId === userId)
	// const { user } = useGetUserById(userId)
	const { fetchDocument } = useGetDocumentById()
	const [avatarSrc, setAvatarSrc] = useState<string | null>(null)

	useEffect(() => {
		let newAvatarSrc: string | null = null

		let assumedId = ''
		if (assumedCharacterId) {
			assumedId = assumedCharacterId
		} else if (
			!disableAssumedCharacterLookup &&
			user?.userSettings?.assumedCharacterId
		) {
			assumedId = user.userSettings.assumedCharacterId
		}

		if (assumedId) {
			// find the thumbnail field name for the collection
			const document = fetchDocument(assumedId)
			const collection = game.system?.collections?.find(
				c => c.type === document?.type,
			)
			let assetId = ''
			collection?.thumbnailField?.forEach(field => {
				if (document.values[field]) {
					assetId = document.values[field]
				}
			})

			// find the asset and get its fileurl
			const asset = game.assets.byId[assetId]
			newAvatarSrc = asset?.fileurl || null
		}

		if (!newAvatarSrc) {
			const userProfile = findUserProfile(userId, usersState.users)
			newAvatarSrc = userProfile?.avatar || null
		}

		if (!newAvatarSrc && fallbackAvatarId) {
			const fallbackAsset = game.assets.byId[fallbackAvatarId]
			newAvatarSrc = fallbackAsset?.fileurl || null
		}

		setAvatarSrc(newAvatarSrc)
	}, [
		userId,
		assumedCharacterId,
		fallbackAvatarId,
		game,
		usersState,
		user,
		disableAssumedCharacterLookup,
	])

	return avatarSrc || ''
}

import { HTMLAttributes, ReactNode, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

interface CardProps extends HTMLAttributes<HTMLDivElement> {
	innerClassName?: string
	children?: ReactNode
}

const Card = forwardRef<HTMLDivElement, CardProps>(
	({ innerClassName, className, children, ...props }, ref) => {
		return (
			<div
				ref={ref}
				{...props}
				className={twMerge(
					'relative m-8 overflow-hidden rounded-2xl bg-gray-800 p-8 text-white',
					className,
				)}
			>
				<div className={twMerge('m-auto max-w-xl', innerClassName)}>
					{children}
				</div>
			</div>
		)
	},
)

export default Card

import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import Button from '../FormComponents/Button'
import FormMessage from '../FormComponents/FormMessage'
import Label from '../FormComponents/Label'
import Input from '../Input'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

interface IFormInputs {
	password: string
	confirmPassword: string
}

const schema = yup.object({
	password: yup
		.string()
		.required('Password is required')
		.min(8, 'Password must be at least 8 characters')
		.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
		.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
		.matches(/[0-9]/, 'Password must contain at least one number')
		.matches(
			/[^A-Za-z0-9]/,
			'Password must contain at least one special character',
		),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password')], 'Passwords must match')
		.required('Please confirm your password'),
})

export default function ResetPassword() {
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')
	const [searchParams] = useSearchParams()
	const navigate = useNavigate()
	const token = searchParams.get('token')

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<IFormInputs>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	})

	useEffect(() => {
		if (!token) {
			navigate('/signin')
		}
	}, [token, navigate])

	const onSubmit = async (data: IFormInputs) => {
		setError('')
		setMessage('')

		try {
			const response = await fetch('/api/password-reset', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ token, newPassword: data.password }),
			})

			const responseData = await response.json()

			if (response.ok) {
				setMessage('Password successfully reset')
				setTimeout(() => navigate('/signin'), 2000)
			} else {
				setError(responseData.message || 'Something went wrong')
			}
		} catch (err) {
			setError('Failed to reset password')
		}
	}

	return (
		<Card className='h-screen'>
			<PageHeader title='Set New Password' className='text-center' />

			<form onSubmit={handleSubmit(onSubmit)} className='space-y-6'>
				<div>
					<Label>New Password</Label>
					<Input
						type='password'
						placeholder='Enter new password'
						inputClassName='dark:bg-gray-900'
						error={errors.password?.message}
						{...register('password')}
					/>
				</div>

				<div>
					<Label>Confirm Password</Label>
					<Input
						type='password'
						placeholder='Confirm new password'
						inputClassName='dark:bg-gray-900'
						error={errors.confirmPassword?.message}
						{...register('confirmPassword')}
					/>
				</div>

				{message && <FormMessage type='success' message={message} />}
				{error && <FormMessage type='error' message={error} />}

				<Button type='submit'>Reset Password</Button>
			</form>
		</Card>
	)
}

import { useContext, useEffect, useState } from 'react'
import AuthContext from '../contexts/auth'
import GameContext from '../contexts/game'
import { IUserSettings } from '../interfaces/game'

type ExpandedUserSettings = IUserSettings & {
	loading: boolean
	isGM: boolean
}

const useUser = () => {
	const { game, dispatch } = useContext(GameContext)
	const { authState } = useContext(AuthContext)
	const { userSettings } = game
	const { userId } = authState

	const [state, setState] = useState<ExpandedUserSettings>({
		loading: true,
		isGM: userSettings[userId]?.role === 'gm' || false,
		...userSettings[userId],
	})

	useEffect(() => {
		let settings = userSettings[userId]
		settings = { ...settings, userId }

		setState(prevState => ({
			...prevState,
			...settings,
			isGM: settings?.role === 'gm',
			loading: false,
		}))
	}, [userSettings[userId], userId])

	const setColor = (newColor: string) => {
		dispatch({
			type: 'UPDATE_USER_COLOR',
			payload: { userId, color: newColor },
		})
	}

	return { ...state, setColor }
}

export default useUser

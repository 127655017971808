import { UserIcon } from '@heroicons/react/24/solid'
import Color from 'color'
import { useMemo } from 'react'
import { twMerge } from 'tailwind-merge'
import useGetUserSettings from '../hooks/useGetUserSettings'
import useTheme from '../hooks/useTheme'

interface AvatarProps {
	color?: string
	src?: string
	className?: string
	style?: React.CSSProperties
	userId?: string
	userName?: string
	noBorder?: boolean
	size?: 'small' | 'medium'
}

const Avatar: React.FC<AvatarProps> = ({
	color,
	src,
	className,
	style,
	userId,
	userName,
	noBorder,
	size = 'small',
}) => {
	const { primary } = useTheme()
	const lighterColor = useMemo(() => Color(color || '#555').string(), [color])
	const { userSettings } = useGetUserSettings(userId)
	const isGM = userSettings?.role === 'gm'

	const getInitials = (name: string) => {
		const nameParts = name.split(' ')
		if (nameParts.length > 1) {
			return (nameParts[0][0] + nameParts[1][0]).toUpperCase()
		} else {
			return name.substring(0, 2).toUpperCase()
		}
	}

	const avatarContent = () => {
		if (src) {
			return null
		}

		let content: string | JSX.Element

		if (userName) {
			content = getInitials(userName)
		} else if (isGM) {
			content = 'GM'
		} else {
			content = (
				<UserIcon className={`h-5 w-5 text-[${primary}]`} aria-hidden='true' />
			)
		}

		return (
			<div
				className={twMerge(
					'font-bold tracking-wider',
					size === 'small' ? 'text-[10px]' : 'text-base',
				)}
				style={{
					color: lighterColor,
				}}
			>
				{content}
			</div>
		)
	}

	return (
		<div
			className={twMerge(
				`relative flex aspect-square flex-none place-items-center justify-center overflow-hidden rounded-full bg-gray-50 text-center leading-10 text-gray-600`,
				size === 'small' ? 'h-7' : 'h-9',
				className,
			)}
			style={{
				backgroundColor: color + '85',
				backgroundImage: src ? `url(${src})` : undefined,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				borderWidth: noBorder ? 0 : 3,
				borderColor: noBorder ? undefined : color,
				...style,
			}}
		>
			{avatarContent()}
		</div>
	)
}

export default Avatar

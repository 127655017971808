import { useContext, useEffect } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { IAsset } from '../../../../../shared/types/asset'
import { IDocument } from '../../../../../shared/types/document'
import GameContext from '../../../contexts/game'
import AssetManager from '../../AssetManager'
import EffectsOverlaySelector from '../../interface/EffectsOverlaySelector'
import ActivateSceneButton from '../ActivateSceneButton'
import SceneName from '../SceneEditor/SceneName'
import SceneSecret from '../SceneEditor/SceneSecret'
import SceneSubtitle from '../SceneEditor/SceneSubtitle'
import MapGridEnabled from './MapGrid/MapGridEnabled'
import MapGridOffset from './MapGrid/MapGridOffset'
import MapGridSize from './MapGrid/MapGridSize'
import MapGridStyle from './MapGrid/MapGridStyle'
import MapGridUnit from './MapGrid/MapGridUnit'
import MapGridColor from './MapGridColor'

type Props = {
	doc: IDocument
}

const MapEditor = ({ doc }: Props) => {
	const { dispatch } = useContext(GameContext)
	const form = useForm({
		defaultValues: {
			...doc.values,
			effectsOverlay: doc.values.effectsOverlay || '',
			grid: {
				enabled: true,
				type: 'square',
				size: 1,
				unitSize: 5,
				unit: 'ft',
				color: '#000000',
				alpha: 0.2,
				x: 0,
				y: 0,
				...doc.values.grid,
			},
		},
	})

	const values = useWatch({
		control: form.control,
	})

	const handleUpdate = () => {
		const updatedDocument = {
			...doc,
			version: doc.version + 1,
			values: {
				...doc.values,
				...values,
			},
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument,
			},
		})
	}

	useEffect(handleUpdate, [values])

	const handleAssetChange = (asset?: IAsset) => {
		const updatedDocument = {
			...doc,
			version: doc.version + 1,
			values: {
				...doc.values,
				mapId: asset?._id,
			},
		}

		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument,
			},
		})
	}

	return (
		<FormProvider {...form}>
			<div>
				<div className='relative'>
					<AssetManager
						assetId={doc.values.mapId}
						onAssetChange={handleAssetChange}
					/>
					{doc.values.mapId && (
						<div className='absolute bottom-4 left-4 z-20'>
							<ActivateSceneButton sceneId={doc._id} />
						</div>
					)}
				</div>
				<div className='p-4'>
					<SceneName doc={doc} />
					<SceneSubtitle doc={doc} />
					<SceneSecret doc={doc} />

					<div className='mt-4'>
						<h3 className='mb-2 font-bold'>Grid Settings</h3>
						<div className='space-y-2'>
							<MapGridEnabled />
							<MapGridSize />
							<MapGridUnit />
							<MapGridStyle />
							<MapGridOffset />
							<MapGridColor />
						</div>
					</div>

					<EffectsOverlaySelector doc={doc} />
				</div>
			</div>
		</FormProvider>
	)
}

export default MapEditor

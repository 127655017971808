import React, { useContext } from 'react'
import GameContext from '../../contexts/game'
import Document from '../Library/Document'
import SectionDivider from '../SectionDivider'
import ListMessage from '../views/ListMessage'

const PlayerSessionStage: React.FC = () => {
	const { game } = useContext(GameContext)
	const stageDocuments = game.stage?.documents || []

	// No-op functions for the required props
	const hoverItem = () => {}
	const moveItem = () => {}

	return (
		<>
			<SectionDivider label='Session Stage' />
			<div className='mt-2 overflow-auto'>
				{stageDocuments.length === 0 ? (
					<ListMessage title='No documents are currently on the stage.' />
				) : (
					<ul>
						{stageDocuments.map((docId, index) => (
							<Document
								key={docId}
								id={docId}
								selectedType='all'
								dragIndex={index}
								hoverIndex={index}
								hoverItem={hoverItem}
								moveItem={moveItem}
							/>
						))}
					</ul>
				)}
			</div>
		</>
	)
}

export default PlayerSessionStage

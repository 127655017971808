import { createContext } from 'react'
import io from 'socket.io-client'
import getServerDetails from '../utils/getServerDetails'

const { origin } = getServerDetails(':3001')
export const socket = io(origin, {
	transports: ['websocket'],
})

export const SocketContext = createContext()

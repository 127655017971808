import { useMutation } from '@apollo/client'
import ObjectId from 'bson-objectid'
import { useContext } from 'react'
import request from 'superagent'
import AuthContext from '../contexts/auth'
import GameContext from '../contexts/game'
import { ADD_ASSET } from '../graphql/games'

interface IDimensions {
	width: number
	height: number
}

const useAssetUploader = () => {
	const { dispatch } = useContext(GameContext)
	const { authState } = useContext(AuthContext)

	const getDimensionsFromDataUrl = (file: File): Promise<IDimensions> => {
		return new Promise(resolve => {
			if (file.type.includes('video')) {
				const url = URL.createObjectURL(file)
				const $video = document.createElement('video')
				$video.src = url
				$video.addEventListener('loadedmetadata', function () {
					resolve({
						width: this.videoWidth,
						height: this.videoHeight,
					})
				})
			} else if (file.type.includes('image')) {
				const reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = e => {
					const image = new Image()
					image.src = e.target?.result as string
					image.onload = e => {
						const image = e.target as HTMLImageElement
						resolve({
							width: image.width || 0,
							height: image.height || 0,
						})
					}
				}
			} else {
				// Default dimensions for non-image, non-video files.
				resolve({
					width: 0,
					height: 0,
				})
			}
		})
	}

	// Mutation to add an asset.
	const [addAsset] = useMutation(ADD_ASSET, {
		onCompleted: data => {
			const { asset } = data.addAsset
			dispatch({ type: 'ADD_ASSET', payload: { asset } })
		},
		onError: error => {
			console.error(error)
			throw new Error('Asset upload error')
		},
	})

	// Upload the file and save the asset details
	const fileHandler = async (file: File, gameId: string) => {
		const ACCEPTED_MIME_TYPES = [
			// Images
			'image/jpeg',
			'image/png',
			'image/gif',
			'image/bmp',
			'image/webp',
			// Videos
			'video/mp4',
			'video/webm',
			// PDF
			'application/pdf',
			// Fonts
			'font/ttf',
			'font/otf',
			'font/woff',
			'font/woff2',
		]

		if (!ACCEPTED_MIME_TYPES.includes(file.type)) {
			console.error(`Invalid file type: ${file.type}`)
			console.log(`Accepted file types are: ${ACCEPTED_MIME_TYPES.join(', ')}`)
			// alert(
			// 	'Unsupported file type. Please upload a valid image or video. Support types include: .jpg, .png, .gif, .bmp, .webp, .mp4, .webm.',
			// )
			// throw new Error(
			// 	'Unsupported file type. Please upload a valid image or video. Support types include: .jpg, .png, .gif, .bmp, .webp, .mp4, .webm.',
			// )
		}

		const formData = new FormData()
		formData.append('file', file)
		formData.append('gameId', gameId)

		const id = ObjectId().toHexString()
		const userId = authState.userId || 'missing'
		const dimensions = await getDimensionsFromDataUrl(file)

		// Upload the file.
		const asset = await request
			.post('/api/upload')
			.send(formData)
			.then(res => {
				return {
					_id: id,
					name: file.name,
					creator: userId,
					fileurl: res.body.url,
					filesize: file.size,
					filetype: file.type,
					width: dimensions.width,
					height: dimensions.height,
				}
			})
			.catch(error => {
				console.error('upload error', error)
				throw new Error('File upload error')
			})

		if (!asset) return

		// Save the asset details using the mutation.
		const op = await addAsset({
			variables: {
				asset,
				gameId: gameId,
			},
		})

		return op.data.addAsset.asset
	}

	return fileHandler
}

export default useAssetUploader

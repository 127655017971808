import { IDocument } from '../../../../shared/types/document'
import { INTERFACE_BRIGHT_GOLD_COLOR } from '../interface/constants'
import BaseType from '../interface/text/BaseType'
import SmallUppercase from '../interface/text/SmallUppercase'
import AccessDots from './AccessDots'

type Props = {
	title: string
	singularName: string
	document: IDocument
}

const DocumentWindowTitle = ({ title, singularName, document }: Props) => {
	return (
		<div className='flex-col'>
			<BaseType className='text-white'>{title}</BaseType>
			<div className='flex space-x-2 text-xs'>
				<SmallUppercase style={{ color: INTERFACE_BRIGHT_GOLD_COLOR }}>
					{singularName}
				</SmallUppercase>
				<AccessDots document={document} />
			</div>
		</div>
	)
}

export default DocumentWindowTitle

import { memo } from 'react'
import ListDivider from '../ListDivider'
import List from './List'

interface DocumentGroup {
	[key: string]: any[]
}

interface DocumentListProps {
	selectedType: string
	groupedDocuments: DocumentGroup
	collectionTypes: any[]
	dragIndex: number
	hoverIndex: number
	hoverItem: (dragId: string, hoverId: string, before?: 'up' | 'down') => void
	moveItem: ({ id, targetId, parentId, before }: any) => void
}

const DocumentList = memo(
	({
		selectedType,
		groupedDocuments,
		collectionTypes,
		dragIndex,
		hoverIndex,
		hoverItem,
		moveItem,
	}: DocumentListProps) => {
		if (selectedType === 'All') {
			return (
				<div className='space-y-2'>
					{Object.entries(groupedDocuments)
						.filter(([_, groupDocs]) => groupDocs.length > 0)
						.map(([type]) => {
							const collection = collectionTypes.find(c => c.type === type)
							return (
								<div key={type}>
									<ListDivider text={collection?.pluralName || type} />
									<List
										parentId='library'
										dragIndex={dragIndex}
										hoverIndex={hoverIndex}
										hoverItem={hoverItem}
										moveItem={moveItem}
										selectedType={type}
									/>
								</div>
							)
						})}
				</div>
			)
		}

		return (
			<List
				parentId='library'
				dragIndex={dragIndex}
				hoverIndex={hoverIndex}
				hoverItem={hoverItem}
				moveItem={moveItem}
				selectedType={selectedType}
			/>
		)
	},
)

DocumentList.displayName = 'DocumentList'

export default DocumentList

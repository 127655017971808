import { PixiComponent, useApp } from '@pixi/react'
import { Viewport as PixiViewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { DisplayObject } from 'pixi.js'
import { FC, ReactNode, useEffect, useRef } from 'react'
import { IAsset } from '../../../../shared/types/asset'
import { useGuide } from '../../contexts/guideContext'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useGetAssetById from '../../hooks/useGetAssetById'
import { useViewportControls } from '../../hooks/useViewportControls'
import { useViewportEvents } from '../../hooks/useViewportEvents'
import { useViewportResize } from '../../hooks/useViewportResize'

export interface ViewportProps {
	children?: ReactNode
	setViewport?: (viewport: PixiViewport) => void
}

export interface PixiComponentViewportProps extends ViewportProps {
	app: PIXI.Application
	mapAsset: IAsset
}

type ViewportInstance = PixiViewport & DisplayObject

const PixiViewportComponent = PixiComponent<
	PixiComponentViewportProps,
	ViewportInstance
>('Viewport', {
	create: ({
		app,
		setViewport,
	}: PixiComponentViewportProps): ViewportInstance => {
		// fix from https://github.com/davidfig/pixi-viewport/issues/438
		const events = new PIXI.EventSystem(app.renderer)
		events.domElement = app.renderer.view as HTMLCanvasElement
		events.resolution = app.renderer.resolution

		const viewport = new PixiViewport({
			events: events,
			ticker: app.ticker,
			allowPreserveDragOutside: true,
			passiveWheel: false,
			screenWidth: window.innerWidth,
			screenHeight: window.innerHeight,
		})

		viewport
			.drag({
				clampWheel: false,
			})
			.wheel({
				percent: 0.1,
				trackpadPinch: true,
				smooth: 3,
			})
			.decelerate()
			.moveCenter(0, 0)
			.setZoom(1)

		setViewport?.(viewport)

		return viewport as ViewportInstance
	},
	applyProps(viewport: ViewportInstance, oldProps, newProps) {
		const { mapAsset: oldMapAsset } = oldProps as PixiComponentViewportProps
		const { mapAsset: newMapAsset } = newProps as PixiComponentViewportProps
		const MAPUNITS = 100
		const SCALEFACTOR = 1.4

		// Only apply these changes if the map asset has changed
		if (newMapAsset && (!oldMapAsset || oldMapAsset._id !== newMapAsset._id)) {
			const scale = viewport.findFit(
				MAPUNITS * SCALEFACTOR,
				MAPUNITS * SCALEFACTOR,
			)
			viewport.clampZoom({
				minScale: 6,
				maxScale: 100,
			})
			viewport.setZoom(scale)
		}
	},
	willUnmount: (viewport: ViewportInstance) => {
		// @ts-ignore Hack to get around pixi-viewport bug when destroying
		viewport.options.noTicker = true
		viewport.destroy({ children: true, texture: true, baseTexture: true })
	},
})

const Viewport: FC<ViewportProps> = props => {
	const scene = useGetActiveScene()
	const { asset: mapAsset } = useGetAssetById(scene?.values?.mapId)
	const ref = useRef<ViewportInstance>(null)
	const { isFollowing, lastGuideUpdate } = useGuide()

	useViewportEvents(ref.current, mapAsset)
	useViewportResize(ref.current)
	useViewportControls(ref.current)

	// Apply guide's viewport when following or updates change
	useEffect(() => {
		if (isFollowing && lastGuideUpdate && ref.current) {
			ref.current.moveCenter(lastGuideUpdate.centerX, lastGuideUpdate.centerY)
			ref.current.setZoom(lastGuideUpdate.scale, true)
		}
	}, [isFollowing, lastGuideUpdate])

	// Prevent context menu
	useEffect(() => {
		const preventContextMenu = (e: Event) => e.preventDefault()
		window.addEventListener('contextmenu', preventContextMenu)
		return () => window.removeEventListener('contextmenu', preventContextMenu)
	}, [])

	return (
		<PixiViewportComponent
			ref={ref}
			app={useApp()}
			mapAsset={mapAsset}
			{...props}
		/>
	)
}

export default Viewport

import ObjectID from 'bson-objectid'
import { useContext } from 'react'
import EventContext from '../../contexts/event'

const useHandleMessage = () => {
	const { dispatchEvent } = useContext(EventContext)

	const handleMessage = (message: string) => {
		// event, so dice can be rolled
		dispatchEvent({
			type: 'ADD_EVENT',
			payload: {
				_id: ObjectID().toHexString(),
				timestamp: new Date(),
				name: 'message',
				data: message,
			},
		})
	}

	return handleMessage
}

export default useHandleMessage

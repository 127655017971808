import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import EventContext from '../../contexts/event'
import GameContext from '../../contexts/game'
import useUser from '../../hooks/useUser'
import { DiceResult } from '../../interfaces/dicebox'
import generateMessage from '../chat/generateMessage'
import DiceTools from './DiceTools'

export default function DiceToolsWrapper() {
	const { game, dispatch } = useContext(GameContext)
	const { eventQueue, dispatchEvent } = useContext(EventContext)
	const { userId, color } = useUser()
	const userIdRef = useRef(userId)
	const [currentRollNotation, setCurrentRollNotation] = useState<
		string | undefined
	>(undefined)

	useEffect(() => {
		userIdRef.current = userId
	}, [userId])

	const addMessage = useCallback(
		(message: string, diceResults: DiceResult) => {
			dispatch({
				type: 'ADD_MESSAGE',
				payload: generateMessage({
					sender: userIdRef.current,
					type: 'dice',
					message,
					diceResults,
				}),
			})
		},
		[dispatch],
	)

	useEffect(() => {
		if (currentRollNotation) {
			console.log('Rolling:', currentRollNotation)
		}
	}, [currentRollNotation])

	const handleEvents = useCallback(() => {
		if (eventQueue.length) {
			const event = eventQueue[0]
			if (event.name === 'roll') {
				console.log('Roll event received:', event.data)
				setCurrentRollNotation(event.data)
				dispatchEvent({ type: 'DELETE_EVENT', payload: event._id })
			}
		}
	}, [eventQueue, dispatchEvent])

	useEffect(() => {
		handleEvents()
	}, [eventQueue, handleEvents])

	const handleColorChange = useCallback(
		(newColor: string) => {
			dispatch({
				type: 'UPDATE_USER_COLOR',
				payload: { userId: userIdRef.current, color: newColor },
			})
		},
		[dispatch],
	)

	const handleRollResults = useCallback(
		(results: DiceResult) => {
			const { type, value } = results
			let message: string

			const formatRoll = (roll: any): string => {
				if (roll.type === 'die') {
					const count = roll.count.value
					const die = roll.die.value
					const rolls = roll.rolls.map((r: any) => r.roll).join(', ')
					return `${count}d${die} (${rolls})`
				} else if (roll.type === 'number') {
					return roll.value.toString()
				} else if (roll.type === 'expressionroll') {
					return formatExpression(roll)
				} else {
					return 'unknown roll type'
				}
			}

			const formatExpression = (expr: any): string => {
				const parts = expr.dice.map((roll: any, idx: number) => {
					let rollString = formatRoll(roll)
					if (idx < expr.ops.length && expr.ops[idx]) {
						rollString += ` ${expr.ops[idx]} `
					}
					return rollString
				})
				return `(${parts.join('')})`
			}

			if (type === 'expressionroll') {
				message = `${formatExpression(results)} = ${value}`
			} else if (type === 'die') {
				message = `${formatRoll(results)} = ${value}`
			} else {
				message = `Unknown roll type: ${value}`
			}

			addMessage(message, results)
			setCurrentRollNotation(undefined)
		},
		[addMessage],
	)

	const handleRollRequest = useCallback((notation: string) => {
		setCurrentRollNotation(notation)
	}, [])

	return (
		<div className='pointer-events-auto mt-2 flex justify-center'>
			<DiceTools
				color={color}
				diceConfig={game.system?.diceConfig}
				onRollResults={handleRollResults}
				onColorChange={handleColorChange}
				showColorPicker={false}
				rollNotation={currentRollNotation}
			/>
			{false && (
				<button
					className='mt-2 rounded bg-blue-500 px-4 py-2 text-white transition-colors hover:bg-blue-600'
					onClick={() => handleRollRequest('20d10!')}
				>
					Roll 20d10 Exploding
				</button>
			)}
		</div>
	)
}

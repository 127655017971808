import { useContext } from 'react'
import { IActorLocation } from '../../../shared/types/actor'
import GameContext from '../contexts/game'

const useActorsInScene = (sceneId: string) => {
	const { game } = useContext(GameContext)

	if (!sceneId) {
		return []
	}

	const actorsInScene = Object.values(game.documents.byId).filter(
		doc =>
			doc?.type === 'actor' &&
			doc?.values?.locations?.some(
				(loc: IActorLocation) => loc?.mapId === sceneId,
			),
	)

	return actorsInScene
}

export default useActorsInScene

import Color from 'color'
import * as React from 'react'
import styled from 'styled-components'
import { twMerge } from 'tailwind-merge'
import useTheme from '../hooks/useTheme'
import LargeUppercase from './interface/text/LargeUppercase'

export interface ISectionDividerProps {
	label: React.ReactNode
	children?: React.ReactNode
	className?: string
}

export default function SectionDivider({
	label,
	children,
	className,
}: ISectionDividerProps) {
	const { primary } = useTheme()
	const fadedPrimaryColor = Color(primary).fade(0.25).string()

	return (
		<div
			className={twMerge('flex flex-shrink-0 flex-row items-center', className)}
			style={{
				color: primary,
			}}
		>
			<SectionDividerContainer
				className='relative my-2 flex flex-auto overflow-hidden'
				color={fadedPrimaryColor}
			>
				<LargeUppercase>{label}</LargeUppercase>
			</SectionDividerContainer>
			{children}
		</div>
	)
}

const SectionDividerContainer = styled.div`
	&:after {
		content: '';
		width: 100%;
		height: 2px;
		background: ${({ color }) => color};
		margin: auto 0.5rem;
		border-radius: 9999px;
		flex: 1;
	}
`

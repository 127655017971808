import React from 'react'

type BaseTypeProps = {
	children: React.ReactNode
	className?: string
}

const BaseType: React.FC<BaseTypeProps> = ({ children, className }) => {
	return (
		<div className={`text-base text-gray-700 ${className}`}>{children}</div>
	)
}

export default BaseType

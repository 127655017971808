import useApi from './useApi'

const useSummarizeText = () => {
	const { request } = useApi()
	const enabled = false

	const summarizeText = async (text: string, bookTitle: string) => {
		// disabled for now
		if (!enabled) return

		const token = localStorage.getItem('token')
		const url = '/api/summarize-text'
		const options = {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({ text, bookTitle }),
		}
		const response = await request(url, options)
		return response
	}

	return summarizeText
}

export default useSummarizeText

import React from 'react'
import Input from '../Input'

interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
	id?: string
}

const SearchInput: React.FC<SearchInputProps> = ({
	id = 'search',
	...props
}) => (
	<Input
		type='search'
		id={id}
		placeholder='Search...'
		className='pt-0'
		inputClassName='bg-gray-200 dark:bg-gray-700 placeholder:text-gray-400 dark:placeholder:text-gray-500 dark:text-gray-200'
		{...props}
	/>
)

export default SearchInput

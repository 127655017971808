import { Message } from '../../../shared/types/aigm'
import useApi from './useApi'

export interface ExtractMetadataResponse {
	response: string
}

export type TModelName = 'claude' | 'gpt4o'

interface ExtractMetadata {
	prompt: string
	systemMessage?: string
	messages?: Message[]
}

const useExtractMetaData = () => {
	const { request } = useApi()

	const requestMetadataExtraction = async ({
		prompt,
		messages = [{ role: 'user', content: prompt }],
	}: ExtractMetadata): Promise<any> => {
		const url = `/api/queryllm/gpt4o`
		const body = JSON.stringify({
			prompt: prompt,
			messages: messages,
			response_format: {
				type: 'json_object',
			},
		})

		try {
			const responseData: ExtractMetadataResponse = await request(url, {
				method: 'POST',
				body: body,
			})

			if (!responseData) {
				throw new Error('Invalid response from LLM')
			}

			return responseData
		} catch (error) {
			console.error('Error querying LLM:', error)
			throw error
		}
	}

	return { requestMetadataExtraction }
}

export default useExtractMetaData

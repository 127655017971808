import { MouseEvent } from 'react'
import { twMerge } from 'tailwind-merge'
import Asset from '../Asset'
import Accessory from './Accessory'

interface ListItemProps {
	name: string
	thumbnail?: string
	accessory?: string
	isSelected?: boolean
	className?: string
	selectedClassName?: string
	onOpen: () => void
	onSelect?: () => void
	onContext?: (e: MouseEvent<HTMLDivElement>) => void
	id?: string
}

const ListItem: React.FC<ListItemProps> = ({
	name,
	thumbnail,
	accessory,
	isSelected = false,
	className,
	selectedClassName = 'bg-white',
	onOpen,
	onSelect,
	onContext,
	...props
}) => {
	const handleOpenContextMenu = (e: MouseEvent<HTMLDivElement>) => {
		e.preventDefault()
		onSelect && onSelect()
		onContext && onContext(e)
	}

	return (
		<div
			className={twMerge(
				'-mx-2 mb-px flex cursor-pointer gap-2 rounded-md p-2 text-gray-300 hover:bg-gray-300/10',
				className,
				isSelected && selectedClassName,
			)}
			onClick={onOpen}
			onContextMenu={onContext && handleOpenContextMenu}
			{...props}
		>
			{thumbnail && (
				<Asset
					assetId={thumbnail}
					height='40px'
					width='40px'
					className='flex-grow-0'
				/>
			)}
			<div className='flex flex-1 flex-col justify-center'>
				<div className='text-sm'>{name}</div>
				{accessory && <Accessory>{accessory}</Accessory>}
			</div>
		</div>
	)
}

export default ListItem

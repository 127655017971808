import { Point } from '../interfaces/draw'

// Smoothing constants
const SMOOTHING_SAMPLE_POINTS = 12
const TENSION = 0.3

// Implements Catmull-Rom spline interpolation for smooth lines
export const smoothLine = (points: Point[]): Point[] => {
	if (points.length < 2) return points

	const result: Point[] = []

	// Add first point
	result.push({ ...points[0] })

	// Generate smooth points between each pair of input points
	for (let i = 0; i < points.length - 1; i++) {
		const p0 = points[Math.max(i - 1, 0)]
		const p1 = points[i]
		const p2 = points[i + 1]
		const p3 = points[Math.min(i + 2, points.length - 1)]

		// Generate more intermediate points for smoother curves
		for (let t = 0; t < 1; t += 1 / SMOOTHING_SAMPLE_POINTS) {
			const t2 = t * t
			const t3 = t2 * t

			// Catmull-Rom spline calculation with reduced tension
			const x =
				0.5 *
				(2 * p1.x +
					(-TENSION * p0.x + TENSION * p2.x) * t +
					(2 * TENSION * p0.x +
						(TENSION - 3) * p1.x +
						(3 - 2 * TENSION) * p2.x -
						TENSION * p3.x) *
						t2 +
					(-TENSION * p0.x +
						(2 - TENSION) * p1.x +
						(TENSION - 2) * p2.x +
						TENSION * p3.x) *
						t3)

			const y =
				0.5 *
				(2 * p1.y +
					(-TENSION * p0.y + TENSION * p2.y) * t +
					(2 * TENSION * p0.y +
						(TENSION - 3) * p1.y +
						(3 - 2 * TENSION) * p2.y -
						TENSION * p3.y) *
						t2 +
					(-TENSION * p0.y +
						(2 - TENSION) * p1.y +
						(TENSION - 2) * p2.y +
						TENSION * p3.y) *
						t3)

			// Smoothly interpolate width
			const width =
				p1.width !== undefined && p2.width !== undefined
					? p1.width + (p2.width - p1.width) * t
					: undefined

			result.push({ x, y, width, timestamp: p1.timestamp })
		}
	}

	// Add last point
	result.push({ ...points[points.length - 1] })

	return result
}

import { FC, useReducer } from 'react'
import ToolsReducer from '../reducers/tools'
import ToolsContext from '../contexts/tools'

interface IProps {
	children: React.ReactNode
}

const ToolsManager: FC<IProps> = ({ children }) => {
	const [toolsState, dispatchTools] = useReducer(ToolsReducer, {
		isSceneSettingsOpen: false,
		mode: 'select',
		draw: {
			tool: 'pen',
		},
		fog: {
			tool: 'polygonAdd',
		},
	})

	return (
		<ToolsContext.Provider value={{ toolsState, dispatchTools }}>
			{children}
		</ToolsContext.Provider>
	)
}

export default ToolsManager

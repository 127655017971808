import { useMutation } from '@apollo/client'
import { UserIcon } from '@heroicons/react/24/solid'
import { FC, useContext, useEffect, useRef, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import request from 'superagent'
import AuthContext from '../../../contexts/auth'
import {
	DELETE_USER,
	UPDATE_AVATAR,
	UPDATE_USER_PROFILE,
} from '../../../graphql/users'
import Avatar from '../../Avatar'
import Caption from '../../Caption'
import Button from '../../FormComponents/Button'
import DangerButton from '../../FormComponents/DangerButton'
import Label from '../../FormComponents/Label'
import UploadField from '../../FormComponents/UploadField'
import Input from '../../Input'
import PasswordEditor from '../../PasswordEditor'
import ModalWindow from '../../window/ModalWindow'

interface IProps {
	onClose: () => void
}

type FieldValues = {
	name?: string
	email?: string
}

const ProfileEditor: FC<IProps> = props => {
	const { authState, authDispatch } = useContext(AuthContext)
	const fileRef = useRef<HTMLInputElement>(null)
	const [updateAvatar] = useMutation(UPDATE_AVATAR)
	const [updateUserProfile] = useMutation(UPDATE_USER_PROFILE)
	const [deleteUser] = useMutation(DELETE_USER)
	const [editPassword, setEditPassword] = useState(false)

	useEffect(() => {
		console.log('authState updated:', authState.userDetails)
	}, [authState])

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<FieldValues>({
		reValidateMode: 'onChange',
		defaultValues: {
			name: authState.userDetails.name,
			email: authState.userDetails.email,
		},
	})

	const handleUpload = () => {
		const file = fileRef.current.files[0]

		const formData = new FormData()
		formData.append('file', file)

		const oldImageUrl = authState.userDetails.avatar
		request
			.post('/api/upload')
			.send(formData)
			.then(res => {
				const avatar = res.body.url

				updateAvatar({
					variables: {
						avatar: avatar,
						userId: authState.userId,
					},
				})
					.then(() => {
						if (oldImageUrl) {
							request.post('/api/delete').send({ key: oldImageUrl })
						}

						authDispatch({ type: 'UPDATE_PROFILE_PICTURE', payload: avatar })
					})
					.catch(error => {
						console.error('Error updating avatar:', error)
					})
			})
			.catch(err => {
				console.log('error', err)
			})
	}

	const handleUpdateProfile: SubmitHandler<FieldValues> = payload => {
		updateUserProfile({
			variables: payload,
		})

		authDispatch({
			type: 'UPDATE_PROFILE',
			payload: {
				userDetails: {
					...payload,
				},
			},
		})

		props.onClose()
	}

	const handleCancel = () => {
		if (isDirty)
			if (
				!window.confirm(
					'Are you sure you want to close? All changes will be lost.',
				)
			)
				return

		props.onClose()
	}

	const handleDeleteAccount = () => {
		if (
			!window.confirm(
				'Are you sure you want to delete your account? This will remove you from all games you have joined, and it will delete all your games. This cannot be undone. There is no ressurect spell!',
			)
		) {
			return
		} else if (
			!window.confirm(
				'One last time... You are about to delete your account. This cannot be undone. All will be lost. Are you sure you want to delete your account?',
			)
		) {
			return
		}

		deleteUser()

		authState.logout()
	}

	return (
		<>
			<ModalWindow
				id='profile-editor'
				title='Edit Profile'
				size='small'
				headerIcon={<UserIcon className='h-5 w-5' aria-hidden='true' />}
				onClose={handleCancel}
				enableClickOutside={false}
			>
				<form onSubmit={handleSubmit(handleUpdateProfile)} className='mt-2'>
					<div className='mt-2 flex items-center'>
						<Label className='mt-0 flex-1' htmlFor='name'>
							Name
						</Label>
						<div className='w-56'>
							<Input
								placeholder='Name...'
								spellCheck={false}
								{...register('name', { required: true })}
							/>
							{errors.name && (
								<Caption className='text-red-500'>Name is required.</Caption>
							)}
						</div>
					</div>

					<div className='mt-2 flex items-center'>
						<Label className='mt-0 flex-1' htmlFor='email'>
							Email
						</Label>
						<div className='w-56'>
							<Input
								placeholder='Email...'
								{...register('email', { required: true })}
							/>
							{errors.email && (
								<Caption className='text-red-500'>Email is required.</Caption>
							)}
						</div>
					</div>

					<div className='mt-2 flex items-center'>
						<Label className='mt-0 flex-1' htmlFor='avatar'>
							Avatar
						</Label>
						<div className='flex w-56 items-center'>
							<Avatar
								src={authState.userDetails.avatar}
								userName={authState.userDetails.name}
								userId={authState.userId}
								className='mr-4'
							/>
							<UploadField
								ref={fileRef}
								name='image'
								onChange={handleUpload}
								className='h-9 bg-white dark:bg-gray-800'
							/>
						</div>
					</div>

					<Button type='submit' className='mt-6'>
						Update Profile
					</Button>

					<Button onClick={() => setEditPassword(true)}>Change Password</Button>

					<DangerButton onClick={handleDeleteAccount}>
						Delete Account
					</DangerButton>
				</form>
			</ModalWindow>

			{editPassword && (
				<PasswordEditor onClose={() => setEditPassword(false)} />
			)}
		</>
	)
}

export default ProfileEditor

const useCreateWeapons = () => {
	const handleGeneratorMessage = async (prompt: string) => {
		const apiResponse = await fetch('/api/aiweapons', {
			method: 'POST',
			body: JSON.stringify({ prompt }),
			headers: { 'Content-Type': 'application/json' },
		})

		const responseData = await apiResponse.json()

		return responseData.response
	}

	return { handleGeneratorMessage }
}

export default useCreateWeapons

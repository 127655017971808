import SoloBanner from '../../../../assets/frontsite/solobanner.png'
import HeroSection from '../HeroSection'

const Solo = () => {
	return (
		<HeroSection
			backgroundImage={SoloBanner}
			eyebrow='Go Solo'
			title='No Party Required'
			body={
				<>
					The AI GM is always prepped,
					<br />
					and has no scheduling issues.
				</>
			}
			buttonText='Get Started →'
			buttonLink='/signup'
			gradientPosition='left'
			reversed
		/>
	)
}

export default Solo

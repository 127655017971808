import { useState } from 'react'

const useItemHover = () => {
	const [isHovered, setIsHovered] = useState(false)

	return {
		onMouseEnter: () => setIsHovered(true),
		onMouseLeave: () => setIsHovered(false),
		isHovered,
	}
}

export default useItemHover

import React from 'react'
import { twMerge } from 'tailwind-merge'
import TooltipWrapper from '../TooltipWrapper'
import { windowButtonStyles } from '../window/Button'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
	label: string
}

const DocumentWindowHeaderButton = ({
	children,
	label,
	className,
	...rest
}: Props) => {
	return (
		<TooltipWrapper label={label}>
			<button className={twMerge(windowButtonStyles, className)} {...rest}>
				{children}
			</button>
		</TooltipWrapper>
	)
}

export default DocumentWindowHeaderButton

import { useCallback, useState } from 'react'

interface UseTokenSelectionReturn {
	selectedTokenIds: string[]
	handleTokenSelect: (id: string, isShiftKey: boolean) => void
	clearSelection: () => void
}

const useTokenSelection = (): UseTokenSelectionReturn => {
	const [selectedTokenIds, setSelectedTokenIds] = useState<string[]>([])

	const handleTokenSelect = useCallback((id: string, isShiftKey: boolean) => {
		setSelectedTokenIds(prev => {
			if (isShiftKey) {
				// If shift is held, only add to selection if not already selected
				return prev.includes(id) ? prev : [...prev, id]
			} else {
				// If shift is not held and token isn't selected, select only this token
				// If token is already selected, keep the selection unchanged
				return prev.includes(id) ? prev : [id]
			}
		})
	}, [])

	const clearSelection = useCallback(() => {
		setSelectedTokenIds([])
	}, [])

	return {
		selectedTokenIds,
		handleTokenSelect,
		clearSelection,
	}
}

export default useTokenSelection

import { Container, Sprite } from '@pixi/react'
import { Viewport } from 'pixi-viewport'
import { useEffect, useState } from 'react'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useGetAssetById from '../../hooks/useGetAssetById'
import Grid from './Grid'

interface Props {
	viewport: Viewport | null
}

const Map = ({ viewport }: Props) => {
	const scene = useGetActiveScene()
	const { asset: mapAsset } = useGetAssetById(scene?.values?.mapId)
	const [width, setWidth] = useState(0)
	const [height, setHeight] = useState(0)
	const x = -(width / 2)
	const y = -(height / 2)

	useEffect(() => {
		if (mapAsset) {
			const aspectRatio = mapAsset.width / mapAsset.height
			let newWidth: number, newHeight: number

			if (mapAsset.width > mapAsset.height) {
				newWidth = 100
				newHeight = 100 / aspectRatio
			} else {
				newHeight = 100
				newWidth = 100 * aspectRatio
			}

			setWidth(newWidth)
			setHeight(newHeight)
		}
	}, [mapAsset])

	if (!mapAsset) return null

	return (
		<Container x={x} y={y}>
			{mapAsset?.fileurl && (
				<Sprite width={width} height={height} image={mapAsset.fileurl} />
			)}
			{scene?.values?.grid?.enabled && (
				<Grid width={width} height={height} viewport={viewport} />
			)}
		</Container>
	)
}

export default Map

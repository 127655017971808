import { useContext } from 'react'
import DocumentsContext from '../../contexts/documents'

const useHandleOpenDocument = () => {
	const { dispatchDocuments } = useContext(DocumentsContext)

	const handleOpenDocument = (payload: { documentId: string }) => {
		dispatchDocuments({
			type: 'OPEN_DOCUMENT',
			payload,
		})
	}

	return handleOpenDocument
}

export default useHandleOpenDocument

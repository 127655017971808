import MageBanner from '../../../../assets/frontsite/magebanner.png'
import HeroSection from '../HeroSection'

const Library = () => {
	return (
		<HeroSection
			backgroundImage={MageBanner}
			eyebrow='Get Organized'
			title='Your Books'
			body={
				<>
					Automatically organized,
					<br />
					and always available.
				</>
			}
			buttonText='Take a Tour →'
			buttonLink='/signup'
			gradientPosition='right'
		/>
	)
}

export default Library

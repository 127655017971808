interface IProps {
	children: React.ReactNode
}

const Footer: React.FC<IProps> = ({ children }) => {
	return (
		<div className='border-top items-center border-t border-gray-200 bg-gray-100  bg-opacity-50 px-2 py-2 text-black dark:border-gray-800 dark:bg-gray-900 dark:text-white'>
			{children}
		</div>
	)
}

export default Footer

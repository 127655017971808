import { FC, useEffect, useReducer } from 'react'
import EventContext from '../contexts/event'
import EventReducer from '../reducers/event'

interface EventManagerProps {
	children: React.ReactNode
}

const EventManager: FC<EventManagerProps> = ({ children }) => {
	const [eventQueue, dispatchEvent] = useReducer(EventReducer, [])

	useEffect(() => {
		if (eventQueue.length > 1)
			console.error('eventQueue is too long!', eventQueue)
	}, [JSON.stringify(eventQueue)]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<EventContext.Provider value={{ eventQueue, dispatchEvent }}>
			{children}
		</EventContext.Provider>
	)
}

export default EventManager

import { useCallback, useContext, useMemo } from 'react'
import GameContext from '../contexts/game'

const useGetDocumentById = (documentId?: string) => {
	const { game } = useContext(GameContext)
	const fetchDocument = useCallback(
		(id: string) => game?.documents?.byId?.[id],
		[game.documents.byId],
	)
	const document = useMemo(
		() => (documentId ? fetchDocument(documentId) : undefined),
		[fetchDocument, documentId],
	)
	return { document, fetchDocument }
}

export default useGetDocumentById

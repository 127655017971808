import React from 'react'
import { UseFormRegister } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import Checkbox from '../../FormComponents/Checkbox'
import Label from '../../FormComponents/Label'

interface FlagCheckboxProps {
	id: string
	className?: string
	defaultChecked?: boolean
	register: UseFormRegister<any>
	label?: string
}

const FlagCheckbox: React.FC<FlagCheckboxProps> = ({
	id,
	className,
	defaultChecked,
	register,
	label,
}) => {
	const displayLabel = label || id.charAt(0).toUpperCase() + id.slice(1)

	return (
		<div className='flex items-center'>
			<Label
				htmlFor={id}
				className={twMerge(
					'flex flex-grow cursor-pointer items-center',
					className,
				)}
			>
				<Checkbox
					id={id}
					className={className}
					defaultChecked={defaultChecked}
					{...register(`flags.${id}`)}
				/>
				<span className='ml-2'>{displayLabel}</span>
			</Label>
		</div>
	)
}

export default FlagCheckbox

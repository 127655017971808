// Helper function to check if a specific class exists in the event target or its parents
export const hasSpecificClassInEventPath = (
	e: MouseEvent,
	specificClass: string,
	stopElement: HTMLElement | null,
): boolean => {
	let target = e.target as HTMLElement
	while (target) {
		if (target.classList && target.classList.contains(specificClass)) {
			return true
		}
		if (target === stopElement) {
			break
		}
		target = target.parentNode as HTMLElement
	}
	return false
}

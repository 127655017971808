// used to indicate wheter a map is available for
// a given scene in library list
import { MapIcon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import GameContext from '../../contexts/game'

type Props = {
	mapId: string
}

const MapIndicator = ({ mapId }: Props) => {
	const { game } = useContext(GameContext)
	const { theme } = game

	return (
		mapId && (
			<MapIcon className='my-0.5 h-3 w-3' style={{ color: theme.secondary }} />
		)
	)
}

export default MapIndicator

import * as ContextMenu from '@radix-ui/react-context-menu'
import { twMerge } from 'tailwind-merge'

type Props = {
	children: React.ReactNode
	className?: string
	checked?: boolean
	onCheckedChange?: (checked: boolean) => void
}

export default function MenuCheckboxItem({
	children,
	className,
	checked,
	onCheckedChange,
}: Props) {
	return (
		<ContextMenu.CheckboxItem
			className={twMerge(
				'cursor-pointer rounded-lg py-1.5 px-2.5 text-sm hover:bg-gray-800 focus:outline-none',
				className,
				'flex',
			)}
			checked={checked}
			onCheckedChange={onCheckedChange}
		>
			<div className='mr-8'>{children}</div>
			<ContextMenu.ItemIndicator className='flex flex-1 justify-end'>
				√
			</ContextMenu.ItemIndicator>
		</ContextMenu.CheckboxItem>
	)
}

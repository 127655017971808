import { useState } from 'react'
import { IDocument } from '../../../shared/types/document'

export interface TokenMenuState {
	position: { x: number; y: number }
	dimensions: { width: number; height: number }
	document: IDocument
}

export interface MapMenuState {
	x: number
	y: number
}

const useStageMenus = () => {
	const [tokenMenu, setTokenMenu] = useState<TokenMenuState | null>(null)
	const [mapMenu, setMapMenu] = useState<MapMenuState | null>(null)

	return {
		tokenMenu,
		mapMenu,
		setTokenMenu,
		setMapMenu,
		closeTokenMenu: () => setTokenMenu(null),
		closeMapMenu: () => setMapMenu(null),
	}
}

export default useStageMenus

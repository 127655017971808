import { TDocumentModes } from '../../../../shared/types/document'
import { ICollection } from '../../../../shared/types/system'
import AssumeAsCharacterButton from '../Library/AssumeAsCharacterButton'
import PopoutButton from '../Library/PopoutButton'
import WindowModeToggle from '../WindowModeToggle'

type Props = {
	documentId: string
	collection: ICollection
	documentMode: TDocumentModes
	setDocumentMode: React.Dispatch<React.SetStateAction<TDocumentModes>>
	handlePop: () => void
}

const HeaderChildren = ({
	documentId,
	collection,
	documentMode,
	setDocumentMode,
	handlePop,
}: Props) => {
	return (
		<div className='flex space-x-2 self-center'>
			{collection?.canAssumeAsCharacter === 'true' && (
				<AssumeAsCharacterButton characterId={documentId} />
			)}
			{collection?.hasEditMode === 'true' && (
				<WindowModeToggle
					documentMode={documentMode}
					setDocumentMode={setDocumentMode}
				/>
			)}
			<PopoutButton handlePop={handlePop} />
		</div>
	)
}

export default HeaderChildren

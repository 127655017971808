import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useCallback, useContext, useRef } from 'react'
import GameContext from '../../contexts/game'
import useGetActiveScene from '../../hooks/useGetActiveScene'
import useGrid from '../../hooks/useGrid'
import { screenToWorld } from '../../utils/coordinates'

type Props = {
	actorId: string
	onRightClick: (
		position: { x: number; y: number },
		dimensions: { width: number; height: number },
	) => void
	onClick?: (isShiftKey: boolean) => void
	onDoubleClick?: () => void
	viewport: Viewport
	isGM: boolean
}

const useTokenInteractions = ({
	actorId,
	onRightClick,
	onClick,
	onDoubleClick,
	viewport,
	isGM,
}: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const activeScene = useGetActiveScene()
	const grid = useGrid()
	const dragPosition = useRef<{
		x: number
		y: number
	} | null>(null)
	const lastSnappedPosition = useRef<{ x: number; y: number } | null>(null)
	const dragOffset = { x: 0, y: 0 }
	let isDragging = false
	let dragData: PIXI.FederatedPointerEvent | null = null
	let tokenElement: PIXI.DisplayObject | null = null
	const dragStartPosition = useRef<{ x: number; y: number } | null>(null)
	const lastClickTime = useRef<number>(0)

	const keepInBounds = useCallback(
		(position: { x: number; y: number }) => {
			const mapAsset = game.assets.byId[activeScene?.values?.mapId]
			if (!mapAsset) return position

			const aspectRatio = mapAsset.width / mapAsset.height
			const mapWidth =
				mapAsset.width > mapAsset.height ? 100 : 100 * aspectRatio
			const mapHeight =
				mapAsset.width > mapAsset.height ? 100 / aspectRatio : 100
			const cellSize = mapWidth / (100 / grid.size)
			const tokenSize = cellSize * 0.8

			return {
				x: Math.max(
					-mapWidth / 2 + tokenSize / 2,
					Math.min(mapWidth / 2 - tokenSize / 2, position.x),
				),
				y: Math.max(
					-mapHeight / 2 + tokenSize / 2,
					Math.min(mapHeight / 2 - tokenSize / 2, position.y),
				),
			}
		},
		[activeScene?.values?.mapId, game.assets.byId, grid.size],
	)

	const handleRightClick = (event: PIXI.FederatedPointerEvent) => {
		const target = event.currentTarget as PIXI.DisplayObject
		if (!target) return

		const screenPos = { x: event.globalX, y: event.globalY }
		const bounds = target.getBounds()

		onRightClick(
			{
				x: screenPos.x,
				y: screenPos.y,
			},
			{
				width: bounds.width,
				height: bounds.height,
			},
		)
	}

	const onPointerDown = (event: PIXI.FederatedPointerEvent) => {
		event.stopPropagation()

		const now = Date.now()
		const timeSinceLastClick = now - lastClickTime.current

		// Check for double click (within 300ms)
		if (timeSinceLastClick < 300) {
			onDoubleClick?.()
			lastClickTime.current = 0 // Reset to prevent triple-click
			return
		}

		lastClickTime.current = now

		if (!isGM) {
			if (onClick) onClick(event.shiftKey)
			return
		}

		if (event.button === 2) {
			handleRightClick(event)
			return
		}

		dragData = event
		isDragging = true

		const dragHandle = event.currentTarget as PIXI.DisplayObject
		tokenElement = dragHandle.parent as PIXI.DisplayObject

		dragStartPosition.current = {
			x: event.globalX,
			y: event.globalY,
		}

		const worldPos = screenToWorld(viewport, event.globalX, event.globalY)
		dragOffset.x = worldPos.x - tokenElement.position.x
		dragOffset.y = worldPos.y - tokenElement.position.y

		window.addEventListener('pointermove', onGlobalPointerMove)
		window.addEventListener('pointerup', onGlobalPointerUp)
	}

	const onGlobalPointerMove = useCallback(
		(event: PointerEvent) => {
			if (!isDragging || !dragData || !tokenElement) return

			event.stopPropagation()

			const worldPos = screenToWorld(viewport, event.clientX, event.clientY)
			const rawPosition = {
				x: worldPos.x - dragOffset.x,
				y: worldPos.y - dragOffset.y,
			}

			const boundedPosition = keepInBounds(rawPosition)
			const currentPosition = grid.enabled
				? grid.snapToGrid(boundedPosition.x, boundedPosition.y)
				: boundedPosition

			dragPosition.current = currentPosition

			if (
				grid.enabled ||
				!lastSnappedPosition.current ||
				Math.abs(lastSnappedPosition.current.x - currentPosition.x) > 0.01 ||
				Math.abs(lastSnappedPosition.current.y - currentPosition.y) > 0.01
			) {
				lastSnappedPosition.current = currentPosition
				tokenElement.position.x = currentPosition.x
				tokenElement.position.y = currentPosition.y
			}
		},
		[keepInBounds, grid, isDragging, dragData, tokenElement],
	)

	const onGlobalPointerUp = (event: PointerEvent) => {
		// Check if this was a click or a drag
		const wasDrag =
			dragStartPosition.current &&
			(Math.abs(event.clientX - dragStartPosition.current.x) > 3 ||
				Math.abs(event.clientY - dragStartPosition.current.y) > 3)

		// If it wasn't a drag, treat it as a click
		if (!wasDrag) {
			onClick?.(event.shiftKey)
		}

		if (wasDrag) {
			// Only update position if it was a drag
			const actor = game.documents.byId[actorId]
			if (!actor || actor.type !== 'actor') return

			const worldPos = screenToWorld(viewport, event.clientX, event.clientY)
			const rawPosition = {
				x: worldPos.x - dragOffset.x,
				y: worldPos.y - dragOffset.y,
			}

			const boundedPosition = keepInBounds(rawPosition)
			const snappedPosition = grid.snapToGrid(
				boundedPosition.x,
				boundedPosition.y,
			)

			const locations = [...(actor.values.locations || [])]
			const locationIndex = locations.findIndex(
				loc => loc.mapId === activeScene?._id,
			)

			if (locationIndex !== -1) {
				locations[locationIndex] = {
					...locations[locationIndex],
					x: snappedPosition.x,
					y: snappedPosition.y,
				}

				dispatch({
					type: 'UPDATE_DOCUMENT',
					payload: {
						updatedDocument: {
							...actor,
							version: actor.version + 1,
							values: {
								...actor.values,
								locations,
							},
						},
					},
				})
			}
		}

		// Cleanup
		isDragging = false
		dragData = null
		tokenElement = null
		dragPosition.current = null
		lastSnappedPosition.current = null
		dragStartPosition.current = null

		window.removeEventListener('pointermove', onGlobalPointerMove)
		window.removeEventListener('pointerup', onGlobalPointerUp)
	}

	return {
		interactionProps: {
			pointerdown: onPointerDown,
		},
		dragPosition: dragPosition.current,
	}
}

export default useTokenInteractions

import { useContext } from 'react'
import useFitText from 'use-fit-text'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import GameLogo from './GameLogo'

export default function GameName() {
	const { game } = useContext(GameContext)
	const { primary } = useTheme()
	const title = game.title || 'Unnamed Game'
	const { fontSize, ref } = useFitText({
		maxFontSize: 200,
	})

	return (
		<>
			{/* <GameCard game={game} className='mx-auto max-w-xs' /> */}
			<div
				className='m-auto flex max-w-xs flex-col justify-center py-2 text-center'
				style={{
					fontFamily: 'Rubik, sans-serif',
					textShadow: '0 4px 6px rgba(0,0,0,0.5)',
					color: primary,
				}}
			>
				{(game.system?.logoPath || game.logo) && (
					<GameLogo
						gameId={game._id}
						assetId={game.logo || game.system?.logoPath}
						title={game.title}
					/>
				)}
				{false && (
					<p
						ref={ref}
						className='mt-2 text-center text-2xl font-medium uppercase tracking-wide'
						style={{ fontSize, height: '40px', lineHeight: '40px' }}
					>
						{title}
					</p>
				)}
			</div>
		</>
	)
}

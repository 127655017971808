import { useCallback, useContext, useEffect } from 'react'
import DocumentsContext from '../../contexts/documents'
import { socket } from '../../contexts/socket'
import useUser from '../../hooks/useUser'

const useUnpop = () => {
	const { dispatchDocuments } = useContext(DocumentsContext)
	const { userId } = useUser()

	const handleUnpop = useCallback(
		({ documentId, forUserId }: { documentId: string; forUserId: string }) => {
			console.log('unpop', documentId, forUserId, userId)
			if (forUserId !== userId) return

			dispatchDocuments({
				type: 'OPEN_DOCUMENT',
				payload: { documentId },
			})
		},
		[userId],
	)

	useEffect(() => {
		socket.on('document:unpop', handleUnpop)

		return () => {
			socket.off('document:unpop', handleUnpop)
		}
	}, [handleUnpop])
}

export default useUnpop

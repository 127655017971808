import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import Popover from '../Popover'

interface Props {
	show: boolean
	onClose: () => void
	anchorRef: React.RefObject<HTMLDivElement>
	color: string
	onChange: (color: string) => void
}

const ColorPicker = ({ show, onClose, anchorRef, color, onChange }: Props) => {
	const [currentColor, setCurrentColor] = useState(color)

	const handleColorChange = (color: { hex: string }) => {
		setCurrentColor(color.hex)
		onChange(color.hex)
	}

	false && console.log(currentColor, handleColorChange)

	return (
		<Popover
			id='color-picker'
			open={show}
			anchor={anchorRef}
			onClose={onClose}
			placement='left'
			className='px-0'
			style={{ zIndex: 9999 }}
		>
			<SketchPicker color={currentColor} onChange={handleColorChange} />
		</Popover>
	)
}

export default ColorPicker

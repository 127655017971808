import { useFormContext } from 'react-hook-form'
import { IDocument } from '../../../../../shared/types/document'
import Label from '../../FormComponents/Label'
import Input from '../../Input'

type Props = {
	doc: IDocument
}

const SceneSubtitle = ({ doc }: Props) => {
	const { register } = useFormContext()

	return (
		<div className='center flex align-middle'>
			<Label htmlFor='title' className='mt-3 w-1/3'>
				Subtitle
			</Label>
			<Input
				className='w-2/3'
				placeholder='Subtitle...'
				defaultValue={doc.values.subtitle}
				{...register('subtitle')}
			/>
		</div>
	)
}

export default SceneSubtitle

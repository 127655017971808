import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useContext, useMemo } from 'react'
import { IDocument } from '../../../../../shared/types/document'
import UsersContext from '../../../contexts/users'
import useAssumeCharacter from '../../../hooks/useAssumeCharacter'
import useUser from '../../../hooks/useUser'
import UserDot from '../../UserDot'
import MenuItem from '../../dropdownmenu/MenuItem'
import RadioItem from '../../dropdownmenu/RadioItem'
import SubMenuContent from '../../dropdownmenu/SubMenuContent'
import SubMenuTrigger from '../../dropdownmenu/SubMenuTrigger'

type Props = {
	document: IDocument
}

const AssignCharacter = ({ document }: Props) => {
	const { userId, isGM } = useUser()
	const { usersState } = useContext(UsersContext)
	const { users } = usersState
	const hasPlayers = users.length > 0
	const { handleAssumeCharacter } = useAssumeCharacter()

	// find which user the character is assigned to
	const assignedUser = useMemo(
		() =>
			users.find(user => user.userSettings.assumedCharacterId === document._id),
		[users, document._id],
	)

	const handleAssignCharacter = (assignedUserId: string) => {
		handleAssumeCharacter(document._id, assignedUserId)
	}

	if (document.type !== 'character') return null

	if (!hasPlayers)
		return (
			<MenuItem onSelect={() => handleAssignCharacter(userId)}>
				Claim Character
			</MenuItem>
		)

	return (
		<DropdownMenu.Sub>
			<SubMenuTrigger>Assign Character to...</SubMenuTrigger>
			<SubMenuContent>
				<DropdownMenu.RadioGroup
					value={assignedUser?.userId}
					onValueChange={handleAssignCharacter}
				>
					<RadioItem value={''}>
						<div className='flex'>
							<UserDot color={'#555'} className='mr-2 self-center' />
							No One
						</div>
					</RadioItem>

					{users.map(user => {
						const isMe = user.userId === userId
						return (
							<RadioItem
								key={user.userId}
								value={user.userId}
								disabled={!isGM && !isMe}
							>
								<div className='flex'>
									<UserDot
										color={user.userSettings.color}
										className='mr-2 self-center'
									/>
									{user.userProfile.name} {isMe && '(You)'}
								</div>
							</RadioItem>
						)
					})}
				</DropdownMenu.RadioGroup>
			</SubMenuContent>
		</DropdownMenu.Sub>
	)
}

export default AssignCharacter

import * as ContextMenu from '@radix-ui/react-context-menu'
import { twMerge } from 'tailwind-merge'

type Props = {
	onSelect: () => void
	children: React.ReactNode
	className?: string
}

export default function MenuItem({ children, onSelect, className }: Props) {
	return (
		<ContextMenu.Item
			className={twMerge(
				'cursor-pointer rounded-lg py-1.5 px-2.5 text-sm hover:bg-gray-800 hover:outline-none',
				className,
			)}
			onSelect={onSelect}
			style={{
				minWidth: '100px',
			}}
		>
			{children}
		</ContextMenu.Item>
	)
}

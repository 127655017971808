import { useContext } from 'react'
import WindowsContext from '../../contexts/windows'

type Props = {
	documentId: string
}

const useHandleFrameFocus = ({ documentId }: Props) => {
	const { dispatchWindows } = useContext(WindowsContext)

	const handleFrameFocus = () => {
		dispatchWindows({
			type: 'MOVE_WINDOW_TO_FRONT',
			payload: { documentId },
		})
	}

	return handleFrameFocus
}

export default useHandleFrameFocus

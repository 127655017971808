import { GlobalWorkerOptions } from 'pdfjs-dist'

export const configurePdfWorker = () => {
	if (process.env.NODE_ENV === 'development') {
		GlobalWorkerOptions.workerSrc = new URL(
			'pdfjs-dist/build/pdf.worker.min.mjs',
			import.meta.url,
		).href
	} else {
		GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.4.168/pdf.worker.min.mjs`
	}
}

export { pdfjs } from 'react-pdf'

import { Graphics } from '@pixi/react'
import * as PIXI from 'pixi.js'
import { useCallback } from 'react'
import {
	CURSOR_PREVIEW_RADIUS,
	DRAW_LINE_ALPHA,
} from '../../utils/drawingConstants'

interface Props {
	x: number
	y: number
	color: string
	ref?: React.RefObject<PIXI.Graphics>
}

const CursorPreview = ({ x, y, color, ref }: Props) => {
	const draw = useCallback(
		(g: PIXI.Graphics) => {
			g.clear()
			g.beginFill(parseInt(color.replace('#', '0x')), DRAW_LINE_ALPHA)

			// Draw a detailed circle with 32 sides
			const sides = 32
			const radius = CURSOR_PREVIEW_RADIUS
			const points: number[] = []

			for (let i = 0; i < sides; i++) {
				const angle = (i * 2 * Math.PI) / sides
				points.push(x + radius * Math.cos(angle), y + radius * Math.sin(angle))
			}

			g.drawPolygon(points)
			g.endFill()
		},
		[x, y, color],
	)

	return <Graphics ref={ref} draw={draw} />
}

export default CursorPreview

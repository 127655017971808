import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import GameContext from '../../../contexts/game'
import { ISetFlagPayload } from '../../../interfaces/game'
import FlagCheckbox from './FlagCheckbox'

const Syrinscape = () => {
	const { game, dispatch } = useContext(GameContext)
	const { register, watch } = useForm()

	useEffect(() => {
		const subscription = watch(value => {
			const payload: ISetFlagPayload = {
				flag: 'syrinscape',
				value: value.flags.syrinscape,
			}

			dispatch({
				type: 'SET_FLAG',
				payload,
			})
		})

		return () => {
			subscription.unsubscribe()
		}
	}, [dispatch, watch])

	return (
		<FlagCheckbox
			id='syrinscape'
			className='mt-0 flex-grow'
			defaultChecked={game.flags.syrinscape}
			register={register}
		/>
	)
}

export default Syrinscape

import { useFormContext } from 'react-hook-form'

const MapGridColor = () => {
	const { register, watch, setValue } = useFormContext()
	const currentColor = watch('grid.color')

	return (
		<div className='flex items-center gap-2'>
			<label className='font-medium'>Grid Color:</label>
			<div className='flex gap-2'>
				<label
					className={`
					h-6 w-6 cursor-pointer rounded border-2 
					${currentColor === '#000000' ? 'border-blue-500' : 'border-transparent'}
					hover:border-blue-300
				`}
					onClick={() =>
						setValue('grid.color', '#000000', { shouldDirty: true })
					}
				>
					<input
						type='radio'
						className='hidden'
						{...register('grid.color')}
						value='#000000'
					/>
					<div className='h-full w-full rounded bg-black' />
				</label>

				<label
					className={`
					h-6 w-6 cursor-pointer rounded border-2 
					${currentColor === '#FFFFFF' ? 'border-blue-500' : 'border-transparent'}
					hover:border-blue-300
				`}
					onClick={() =>
						setValue('grid.color', '#FFFFFF', { shouldDirty: true })
					}
				>
					<input
						type='radio'
						className='hidden'
						{...register('grid.color')}
						value='#FFFFFF'
					/>
					<div className='h-full w-full rounded bg-white shadow-[inset_0_0_0_1px_rgba(0,0,0,0.1)]' />
				</label>
			</div>
		</div>
	)
}

export default MapGridColor

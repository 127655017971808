import { forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import TooltipWrapper from '../TooltipWrapper'

const Button = forwardRef<
	HTMLButtonElement,
	React.ButtonHTMLAttributes<HTMLButtonElement> & { label?: string }
>(
	(
		{ type, disabled, className, onClick, style, children, label, ...rest },
		ref,
	) => {
		return (
			<TooltipWrapper label={label}>
				<button
					ref={ref}
					type={type}
					className={twMerge(
						'sm:text-md mt-2 inline-flex w-full justify-center rounded-md border border-transparent bg-gray-900 px-3 py-2 text-sm font-medium text-gray-50 shadow-sm focus:outline-none focus:ring-2 focus:ring-gray-800',
						!disabled && 'hover:bg-gray-700',
						disabled && 'cursor-not-allowed opacity-50',
						className,
					)}
					onClick={onClick}
					disabled={disabled}
					style={style}
					{...rest}
				>
					{children}
				</button>
			</TooltipWrapper>
		)
	},
)

export default Button

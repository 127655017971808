import { Text } from '@pixi/react'
import { TextStyle } from 'pixi.js'
import useGrid from '../../hooks/useGrid'

interface Props {
	text: string
}

export default function TokenLabel(props: Props) {
	const { size } = useGrid()

	return (
		<Text
			anchor={[0.5, 0]}
			resolution={150}
			style={
				new TextStyle({
					trim: true,
					align: 'center',
					fontFamily: 'Inter, sans-serif',
					fontWeight: '600',
					fontSize: 0.18 * size,
					lineHeight: 0.2 * size,
					fill: '#ffffff',
					wordWrap: true,
					wordWrapWidth: 2,
					stroke: '#555555',
					strokeThickness: 0.015 * size,
					dropShadow: false,
					dropShadowColor: '#000000',
					dropShadowAlpha: 0.5,
					dropShadowBlur: 1 * size,
					dropShadowAngle: Math.PI / 3,
					dropShadowDistance: 0.1 * size,
				})
			}
			x={0}
			y={0.45 * size}
			{...props}
		/>
	)
}

import ObjectID from 'bson-objectid'
import { useContext } from 'react'
import AuthContext from '../contexts/auth'
import EventContext from '../contexts/event'
import GameContext from '../contexts/game'
import UsersContext from '../contexts/users'
import { IMessage } from '../interfaces/chat'

const useMessageHandler = () => {
	const { game, dispatch } = useContext(GameContext)
	const { dispatchEvent } = useContext(EventContext)
	const { authState } = useContext(AuthContext)
	const { usersState } = useContext(UsersContext)

	const handleRollMessage = (msg: string) => {
		console.log('handleRollMessage', msg)
		const notation = msg.replace(/^\/r\s|^\/roll\s/, '')

		dispatchEvent({
			type: 'ADD_EVENT',
			payload: {
				_id: ObjectID().toHexString(),
				timestamp: new Date(),
				name: 'roll',
				data: notation,
			},
		})
	}

	const handleNewMessage = (msg: string) => {
		if (!msg) return

		try {
			const isRoll = msg.startsWith('/roll') || msg.startsWith('/r')
			const userId = authState.userId

			if (isRoll) {
				handleRollMessage(msg)
				return
			}

			const { assumedCharacterId } = game.userSettings[userId]
			const user = usersState.users.find(u => u.userId === userId)
			if (!user) throw new Error('User not found')

			const { name, avatar } = user?.userProfile || {}
			const now = Date.now()
			const payload: IMessage = {
				_id: ObjectID().toHexString(),
				sender: userId,
				type: 'message',
				message: msg.trimEnd(),
				access: 'public',
				accessList: [],
				assumedCharacterId: assumedCharacterId,
				fallbackName: name,
				fallbackAvatarId: avatar || null,
				createdAt: now,
				updatedAt: now,
			}

			dispatch({
				type: 'ADD_MESSAGE',
				payload,
			})
		} catch (error) {
			console.error('Error handling new message:', error)
			// Optionally, you can dispatch an error event or handle it as needed
		}
	}

	return handleNewMessage
}

export default useMessageHandler

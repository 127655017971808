import Loader from 'react-loader-spinner'

const PDFLoading = () => (
	<div className='absolute inset-0 flex flex-col items-center justify-center space-y-4 text-gray-600 dark:text-gray-300'>
		<div className='text-lg font-bold'>Loading PDF</div>
		<Loader type='Oval' color='#666' height={30} width={30} />
	</div>
)

export default PDFLoading

import { useCallback, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
	JoinGameMessage,
	LeaveGameMessage,
	RequestBackfillMessage,
} from '../../../shared/types/socket'
import AuthContext from '../contexts/auth'
import { socket } from '../contexts/socket'

const useSocketManager = () => {
	const { gameId } = useParams()
	const { authState } = useContext(AuthContext)
	const { userId } = authState

	const joinGame = useCallback(() => {
		const joinMessage: JoinGameMessage = { gameId, userId }
		socket.emit('join game', joinMessage)
	}, [gameId, userId])

	const leaveGame = useCallback(() => {
		const leaveMessage: LeaveGameMessage = { gameId }
		socket.emit('leave game', leaveMessage)
	}, [gameId])

	const requestBackfill = useCallback((fromVersion: number) => {
		const message: RequestBackfillMessage = { fromVersion }
		socket.emit('requestBackfill', message)
	}, [])

	useEffect(() => {
		const handleConnect = () => {
			// console.log('Connected to server')
			joinGame()
		}

		const handleDisconnect = (reason: string) => {
			console.warn(`Socket disconnected: ${reason}`)
		}

		const handleConnectError = (error: Error) => {
			console.error('Connection error:', error)
		}

		const handleError = (error: any) => {
			console.error('Error from server:', error)
			if (error?.message) {
				alert(error.message)
			}
		}

		const handleJoinGameSuccess = (data: { gameId: string }) => {
			false && console.log('Successfully joined game:', data.gameId)
		}

		socket.on('connect', handleConnect)
		socket.on('disconnect', handleDisconnect)
		socket.on('connect_error', handleConnectError)
		socket.on('error', handleError)
		socket.on('join game success', handleJoinGameSuccess)

		joinGame()

		return () => {
			leaveGame()
			socket.off('connect', handleConnect)
			socket.off('disconnect', handleDisconnect)
			socket.off('connect_error', handleConnectError)
			socket.off('error', handleError)
			socket.off('join game success', handleJoinGameSuccess)
		}
	}, [joinGame, leaveGame])

	return { requestBackfill }
}

export default useSocketManager

import { ChangeEvent, useRef, useState } from 'react'
import useProcessBook from '../../../../hooks/useProcessBook'
import Button from '../../../FormComponents/Button'

const AddBookButton = () => {
	const fileInputRef = useRef<HTMLInputElement>(null)
	const [processingBook, setProcessingBook] = useState(false)
	const { processBook } = useProcessBook()

	const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files
		if (files && files.length > 0) {
			try {
				setProcessingBook(true)
				for (const file of Array.from(files)) {
					const { resource, summary, embeddingsResult } = await processBook(
						file,
					)

					console.log('Processed resource:', {
						resource,
						summary,
						embeddingsResult,
					})

					if (summary) {
						// TODO: Store the summary in the appropriate place
						localStorage.setItem(
							`summary_${resource._id}`,
							JSON.stringify(summary),
						)
						console.log(
							`Book summary stored in localStorage as summary_${resource._id}`,
							{ summary },
						)
					}

					if (embeddingsResult) {
						if (embeddingsResult.success) {
							console.log(
								'Embeddings generated successfully:',
								embeddingsResult.message,
							)
						} else {
							console.warn(
								'Failed to generate embeddings:',
								embeddingsResult.message,
							)
						}
					}

					// TODO: Update the UI or state to reflect the processed book
				}
			} catch (error) {
				console.error('Error processing file(s):', error)
				alert('Error processing file(s)')
			} finally {
				setProcessingBook(false)
				if (fileInputRef.current) {
					fileInputRef.current.value = ''
				}
			}
		}
	}

	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click()
		}
	}

	return (
		<div>
			<Button
				className='w-30 mt-0 bg-gray-700 disabled:cursor-not-allowed disabled:opacity-50'
				onClick={handleClick}
				disabled={processingBook}
			>
				{processingBook ? 'Processing...' : 'Upload PDF(s)'}
			</Button>
			<input
				type='file'
				ref={fileInputRef}
				onChange={handleFileChange}
				style={{ display: 'none' }}
				accept='.pdf'
				multiple
			/>
		</div>
	)
}

export default AddBookButton

import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import request from 'superagent'
import AuthContext from '../../contexts/auth'
import { ADD_PLAYER_WITH_TOKEN } from '../../graphql/games'
import Button from '../FormComponents/Button'
import Label from '../FormComponents/Label'
import Input from '../Input'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

function useGetParam() {
	return new URLSearchParams(useLocation().search)
}

type Props = {
	setIsSignIn: (isSignIn: boolean) => void
}

const InviteSignUp = ({ setIsSignIn }: Props) => {
	const { gameId } = useParams()
	const getParam = useGetParam()
	const inviteToken = getParam.get('inviteToken')
	const navigate = useNavigate()
	const { authState } = useContext(AuthContext)
	const [addPlayerWithToken] = useMutation(ADD_PLAYER_WITH_TOKEN)
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm()

	const handleCreateUserAndJoin = data => {
		const { name, email, password } = data

		request
			.post('/api/createuser')
			.send({
				name: name,
				email: email,
				password: password,
			})
			.then(res => {
				if (!res.ok) {
					if (res.status === 400) {
						alert('Something went wrong.')
					}
					if (res.status === 409) {
						alert('Email address is already in use.')
					}
				}

				if (res.status !== 200 && res.status !== 201) {
					throw new Error('Failed!')
				}

				authState.login(res.body.token, res.body.user)
				localStorage.setItem('token', res.body.token)
				localStorage.setItem('user', res.body.user._id)

				addPlayerWithToken({
					variables: {
						gameId,
						email,
						inviteToken,
					},
				})
					.then(() => {
						navigate(`/game/${gameId}`)
					})
					.catch(error => {
						console.log('Error adding player with token', error)
					})
			})
			.catch(error => {
				alert(error)
				console.log('create user error', error)
			})
	}

	return (
		<Card>
			<PageHeader title='Join Game' className='text-center' />
			<div className='mb-12 text-center'>
				<button
					onClick={() => setIsSignIn(true)}
					className='text-gray-400 underline'
				>
					Already have an account? Sign In
				</button>
			</div>

			<form onSubmit={handleSubmit(handleCreateUserAndJoin)}>
				<Label htmlFor='name'>Name</Label>
				<Input
					placeholder='Name...'
					{...register('name', {
						required: true,
					})}
				/>
				{errors.name && <p className='text-red-500'>Name is required</p>}

				<Label htmlFor='email'>Email</Label>
				<Input
					type='email'
					placeholder='Email...'
					{...register('email', { required: true })}
				/>
				{errors.email && <p className='text-red-500'>Email is required</p>}

				<Label htmlFor='password'>Password</Label>
				<Input
					type='password'
					placeholder='Password...'
					{...register('password', { required: true })}
				/>
				{errors.password && (
					<p className='text-red-500'>Password is required</p>
				)}

				<Button className='mt-8' type='submit'>
					{'Join Game'}
				</Button>
			</form>
		</Card>
	)
}

export default InviteSignUp

export type RPGBookCategory =
	| 'core_rulebook'
	| 'players_guide'
	| 'gamemasters_guide'
	| 'sourcebook'
	| 'expansion_book'
	| 'playtest_material'
	| 'adventure_module'
	| 'adventure_anthology'
	| 'campaign_setting'
	| 'creature_manual'
	| 'creature_compendium'
	| 'lore_book'
	| 'faction_guide'
	| 'timeline_book'
	| 'equipment_guide'
	| 'maps_and_atlases'
	| 'npc_compendium'
	| 'character_folio'
	| 'class_guide'
	| 'game_deck'
	| 'art_book'
	| 'themed_special'
	| 'collectors_edition'
	| 'companion_book'

interface RPGBookCategoryInfo {
	name: string
	description: string
}

const RPGBookCategoryDescriptions: Record<
	RPGBookCategory,
	RPGBookCategoryInfo
> = {
	core_rulebook: {
		name: 'Core Rulebook',
		description:
			'The primary rulebook containing the essential rules and mechanics of the game system.',
	},
	players_guide: {
		name: "Player's Guide",
		description:
			'A book focused on character creation, skills, and options for players.',
	},
	gamemasters_guide: {
		name: "Gamemaster's Guide",
		description:
			'A book offering advice and tools for running games and creating content.',
	},
	sourcebook: {
		name: 'Sourcebook',
		description:
			'A book providing additional content like new classes, races, spells, or equipment.',
	},
	expansion_book: {
		name: 'Expansion Book',
		description:
			'A book that adds new rules, systems, or content to the core game.',
	},
	playtest_material: {
		name: 'Playtest Material',
		description:
			'Experimental or in-development rules and content for testing purposes.',
	},
	adventure_module: {
		name: 'Adventure Module',
		description: 'A pre-written scenario or quest for groups to play through.',
	},
	adventure_anthology: {
		name: 'Adventure Anthology',
		description: 'A collection of shorter adventures or one-shots.',
	},
	campaign_setting: {
		name: 'Campaign Setting',
		description: 'A book detailing a specific world or universe for gameplay.',
	},
	creature_manual: {
		name: 'Creature Manual',
		description: 'A catalog of creatures and opponents for the game.',
	},
	creature_compendium: {
		name: 'Creature Compendium',
		description:
			'An additional collection of creatures, often with more detailed ecology and lore.',
	},
	lore_book: {
		name: 'Lore Book',
		description:
			'A book focusing on the background story, history, and mythology of the game world.',
	},
	faction_guide: {
		name: 'Faction Guide',
		description:
			'A book detailing specific organizations, guilds, or groups within the game world.',
	},
	timeline_book: {
		name: 'Timeline Book',
		description: 'A book focused on the historical events of the game world.',
	},
	equipment_guide: {
		name: 'Equipment Guide',
		description: 'A catalog of weapons, armor, magical items, and other gear.',
	},
	maps_and_atlases: {
		name: 'Maps and Atlases',
		description: 'Detailed geographical resources for game worlds.',
	},
	npc_compendium: {
		name: 'NPC Compendium',
		description: 'A collection of non-player characters for GMs to use.',
	},
	character_folio: {
		name: 'Character Folio',
		description: 'A book providing pre-generated characters or templates.',
	},
	class_guide: {
		name: 'Class Guide',
		description:
			'A detailed guide focusing on specific character classes or archetypes.',
	},
	game_deck: {
		name: 'Game Deck',
		description:
			'A deck of cards used to add random elements or enhance gameplay.',
	},
	art_book: {
		name: 'Art Book',
		description:
			'A book showcasing the visual design and artwork of the game world.',
	},
	themed_special: {
		name: 'Themed Special',
		description: 'A special edition book focused on a specific theme or event.',
	},
	collectors_edition: {
		name: "Collector's Edition",
		description:
			'A special version of a book with additional content or premium features.',
	},
	companion_book: {
		name: 'Companion Book',
		description:
			"A supplementary book that doesn't fit neatly into other categories.",
	},
}

export default RPGBookCategoryDescriptions

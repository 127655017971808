import { useFormContext } from 'react-hook-form'
import Label from '../../../FormComponents/Label'

const MapGridOffset = () => {
	const { register, watch } = useFormContext()
	const gridSize = watch('grid.size') || 1
	const xOffset = watch('grid.x') || 0
	const yOffset = watch('grid.y') || 0

	// Calculate cell size as a fraction of map width (same as Grid.tsx)
	const cellSize = 100 * (gridSize / 100)
	const halfCell = cellSize / 2

	return (
		<>
			<div className='center flex align-middle'>
				<Label className='mt-3 w-1/3'>
					X Offset ({Number(xOffset).toFixed(2)})
				</Label>
				<div className='w-2/3 pt-3'>
					<input
						type='range'
						min={-halfCell}
						max={halfCell}
						step='0.1'
						{...register('grid.x')}
						className='w-full'
					/>
				</div>
			</div>

			<div className='center flex align-middle'>
				<Label className='mt-3 w-1/3'>
					Y Offset ({Number(yOffset).toFixed(2)})
				</Label>
				<div className='w-2/3 pt-3'>
					<input
						type='range'
						min={-halfCell}
						max={halfCell}
						step='0.1'
						{...register('grid.y')}
						className='w-full'
					/>
				</div>
			</div>
		</>
	)
}

export default MapGridOffset

import LibraryButton from '../toolbar/LibraryButton'
import SyrinscapeButton from '../toolbar/SyrinscapeButton'
import TurnTrackerButton from '../toolbar/TurnTrackerButton'
import DiceButton from './DiceButton'
import Toolbar from './toolbar/Toolbar'

interface RightToolsProps {
	showDiceTools: boolean
	setShowDiceTools: (show: boolean) => void
}

const RightTools = ({ showDiceTools, setShowDiceTools }: RightToolsProps) => {
	return (
		<div className='flex flex-row justify-end'>
			<Toolbar>
				{false && (
					<DiceButton
						setShowDiceTools={setShowDiceTools}
						showDiceTools={showDiceTools}
					/>
				)}
				<SyrinscapeButton />
				<TurnTrackerButton />
				{/* <StageButton /> */}
				<LibraryButton />
			</Toolbar>
		</div>
	)
}

export default RightTools

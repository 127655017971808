import Color from 'color'
import { ListTree } from 'lucide-react'
import useTheme from '../../hooks/useTheme'
import ToolbarControlButton from '../toolbar/ControlButton'

interface FilterButtonProps {
	isActive: boolean
	onClick: () => void
}

export default function FilterButton({ isActive, onClick }: FilterButtonProps) {
	const { primary } = useTheme()
	const fadedPrimary = Color(primary).alpha(0.2).string()

	return (
		<ToolbarControlButton
			onClick={onClick}
			label='Filters'
			title='Toggle Filters'
			style={{
				backgroundColor: isActive ? fadedPrimary : 'transparent',
			}}
		>
			<ListTree className='h-5 w-5' />
		</ToolbarControlButton>
	)
}

import { Link } from 'react-router-dom'

type Props = {
	accessRefetch: () => void
}

const NoAccess = ({ accessRefetch }: Props) => {
	return (
		<div className='flex h-screen flex-col items-center justify-center'>
			<div className='text-white'>You do not have access to this game...</div>
			<button onClick={() => accessRefetch()} className='text-white underline'>
				Retry
			</button>
			<div className='text-white underline'>
				<Link to='/profile'>Go to Profile</Link>
			</div>
		</div>
	)
}

export default NoAccess

import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { twMerge } from 'tailwind-merge'

type Props = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLDivElement>,
	HTMLDivElement
> & {
	isFolded: boolean
	toggleFolded: () => void
	className?: string
}

export default function FolderFold({
	isFolded,
	toggleFolded,
	className,
	...props
}: Props) {
	return (
		<div
			className={twMerge(
				'mr-1 -ml-2 h-full flex-initial cursor-pointer self-center rounded-full p-1 transition-all hover:bg-black/10 hover:dark:bg-gray-900',
				isFolded && '-rotate-90',
				className,
			)}
			onClick={toggleFolded}
			{...props}
		>
			<ChevronDownIcon
				className='mt-px h-5 w-5 self-center'
				aria-hidden='true'
			/>
		</div>
	)
}

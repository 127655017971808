import { EllipsisHorizontalCircleIcon } from '@heroicons/react/24/solid'
import { twMerge } from 'tailwind-merge'

type Props = React.ButtonHTMLAttributes<HTMLDivElement> & {}

const BookMenuButton = ({ className, ...rest }: Props) => {
	return (
		<div
			className={twMerge(
				'text-gray-300 hover:text-black dark:text-gray-500 dark:hover:text-white',
				className,
			)}
			{...rest}
		>
			<EllipsisHorizontalCircleIcon className='h-6 w-6' aria-hidden='true' />
		</div>
	)
}

export default BookMenuButton

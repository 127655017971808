import { BookOpen } from 'lucide-react'
import { useGame } from '../../hooks/useGame'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'

const LibraryButton = () => {
	const { dispatch } = useGame()
	const { userId, showLibrary } = useUser()

	const toggleLibrary = () => {
		dispatch({
			type: 'UPDATE_USER_SETTINGS',
			payload: {
				userId,
				userSettings: {
					showLibrary: !showLibrary,
				},
			},
		})
	}

	return (
		<ToolButton
			isOpen={showLibrary}
			tooltip={'Documents'}
			onClick={toggleLibrary}
		>
			<BookOpen className='h-5 w-5' strokeWidth={2} absoluteStrokeWidth />
		</ToolButton>
	)
}

export default LibraryButton

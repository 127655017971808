import { useMemo } from 'react'
import useGetActiveScene from './useGetActiveScene'

const useShowMap = () => {
	const scene = useGetActiveScene()

	const isMapConfigured = Boolean(scene?.values?.mapId)
	const isCoverConfigured = Boolean(scene?.values?.coverId)
	const isShowMapExplicitlySet = scene?.values.showMap === 'true'

	const showMap = useMemo(() => {
		// If showMap is explicitly set to true and a map is present, then show the map.
		if (isShowMapExplicitlySet && isMapConfigured) {
			return 'true'
		}

		// If a map is configured and a cover is not configured, then show the map.
		if (isMapConfigured && !isCoverConfigured) {
			return 'true'
		}

		// If neither of the above conditions is met, then don't show the map.
		return 'false'
	}, [isCoverConfigured, isMapConfigured, isShowMapExplicitlySet])

	const hasBothMapandCover = useMemo(() => {
		// If both a map and a cover are configured, return true.
		return isMapConfigured && isCoverConfigured
	}, [isCoverConfigured, isMapConfigured])

	return { showMap, hasBothMapandCover }
}

export default useShowMap

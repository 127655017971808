import Library from './sections/Features/Library'
import Main from './sections/Features/Main'
import Solo from './sections/Features/Solo'

const HomePage = () => {
	return (
		<>
			<Main />
			<Solo />
			<Library />
			{/* <Dice /> */}
		</>
	)
}

export default HomePage

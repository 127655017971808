import { useContext } from 'react'
import GameContext from '../contexts/game'
import useGetAssetById from './useGetAssetById'
import useGetDocumentById from './useGetDocumentById'

const useGetThumbnailAsset = (id: string) => {
	const { game } = useContext(GameContext)
	const { document } = useGetDocumentById(id)
	const collection = game.system?.collections?.find(
		c => c.type === document.type,
	)
	let thumbnailAssetId = ''
	collection?.thumbnailField?.forEach(field => {
		if (document.values[field]) {
			thumbnailAssetId = document.values[field]
		}
	})
	const { asset } = useGetAssetById(thumbnailAssetId)

	return asset
}

export default useGetThumbnailAsset

import { useContext, useEffect } from 'react'
import GameContext from '../contexts/game'
import UsersContext from '../contexts/users'

const useUpdateUsersSettings = () => {
	const { game } = useContext(GameContext)
	const { usersState, dispatchUsers } = useContext(UsersContext)

	useEffect(() => {
		// go through each user in usersState.users and
		// update their userSettings with the new game.userSettings
		const oldUsers = usersState.users || []
		const updatedUsers = oldUsers.map(user => {
			const userSettings = game.userSettings[user.userId]
			return {
				...user,
				userSettings,
			}
		})

		dispatchUsers({
			type: 'UPDATE_USERS',
			payload: {
				users: [...updatedUsers],
			},
		})
	}, [game.userSettings])
}

export default useUpdateUsersSettings

import { useEffect, useRef, useState } from 'react'
import { Crop } from 'react-image-crop'

type UseCroppedImageProps = {
	src: string
	crop: Crop
}

function useCroppedImage({ src, crop }: UseCroppedImageProps): string | null {
	const imgRef = useRef(new Image())
	const [croppedImageSrc, setCroppedImageSrc] = useState<string | null>(null)

	useEffect(() => {
		imgRef.current.src = src
	}, [src])

	useEffect(() => {
		if (!imgRef.current || !crop) return

		const image = imgRef.current
		const canvas = document.createElement('canvas')
		const scaleX = image.naturalWidth / 100
		const scaleY = image.naturalHeight / 100
		const ctx = canvas.getContext('2d')

		canvas.width = crop.width * scaleX
		canvas.height = crop.height * scaleY

		ctx?.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY,
		)

		setCroppedImageSrc(canvas.toDataURL())
	}, [crop])

	return croppedImageSrc
}

export default useCroppedImage

import { cloneElement, useRef, useState } from 'react'
import Tooltip from './Tooltip'

interface TooltipWrapperProps {
	children: React.ReactElement
	label?: string
}

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({ children, label }) => {
	if (!label) {
		return children
	}

	const [showLabel, setShowLabel] = useState(false)
	const anchorRef = useRef<HTMLButtonElement>(null)

	const childWithProps = cloneElement(children, {
		onMouseEnter: () => setShowLabel(true),
		onMouseLeave: () => setShowLabel(false),
		ref: anchorRef,
	})

	return (
		<>
			{childWithProps}
			<Tooltip open={showLabel} anchor={anchorRef} placement='bottom'>
				{label}
			</Tooltip>
		</>
	)
}

export default TooltipWrapper

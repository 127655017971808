import { Link, Outlet } from 'react-router-dom'

export default function AdminLayout() {
	return (
		<div className='flex min-h-screen bg-gray-100'>
			{/* Sidebar */}
			<div className='w-64 bg-gray-900 p-4'>
				<div className='mb-8'>
					<Link
						to='/'
						className='text-lg font-bold text-white hover:text-gray-300'
					>
						← Back to Site
					</Link>
				</div>
				<nav className='space-y-2'>
					<Link
						to='/admin'
						className='block rounded-lg px-4 py-2 text-gray-300 hover:bg-gray-800'
					>
						Dashboard
					</Link>
					<Link
						to='/admin/users'
						className='block rounded-lg px-4 py-2 text-gray-300 hover:bg-gray-800'
					>
						Users
					</Link>
					<Link
						to='/admin/games'
						className='block rounded-lg px-4 py-2 text-gray-300 hover:bg-gray-800'
					>
						Games
					</Link>
				</nav>
			</div>

			{/* Main content */}
			<div className='flex-1 overflow-auto'>
				<main className='p-8'>
					<Outlet />
				</main>
			</div>
		</div>
	)
}

import { useContext, useEffect, useState } from 'react'
import { IScene } from '../../../../shared/types/scene'
import AuthContext from '../../contexts/auth'
import DocumentsContext from '../../contexts/documents'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import { INTERFACE_BRIGHT_GOLD_COLOR } from './constants'
import LargeUppercase from './text/LargeUppercase'

export default function SceneName() {
	const { game } = useContext(GameContext)
	const { secondary } = useTheme()
	const { authState } = useContext(AuthContext)
	const { userId } = authState
	const { isGM } = useUser()

	const [scene, setScene] = useState<IScene>(null)
	const [sceneName, setSceneName] = useState('Untitled Scene')
	const [sceneSubtitle, setSceneSubtitle] = useState('')
	const [nameIsSecret, setNameIsSecret] = useState<boolean>(false)
	const { activeSceneId } = game.userSettings[userId]
	const activeScene = game.documents.byId[activeSceneId] as IScene
	const { dispatchDocuments } = useContext(DocumentsContext)

	useEffect(() => {
		setScene(activeScene)
		setSceneName(activeScene?.values?.name || 'Untitled Scene')
		setSceneSubtitle(activeScene?.values?.subtitle || '')
		setNameIsSecret(activeScene?.values?.nameIsSecret)
	}, [game.documents, game.userSettings, userId])

	const handleSceneSettingsClick = () => {
		dispatchDocuments({
			type: 'OPEN_DOCUMENT',
			payload: { documentId: scene?._id },
		})
	}

	const insertNonBreakableSpace = text => {
		const words = text.split(' ')
		if (words.length > 1) {
			words[words.length - 2] += '\u00A0' + words.pop()
		}
		return words.join(' ')
	}

	if (!scene || (nameIsSecret && !isGM)) return null

	return (
		<LargeUppercase
			className='mb-1 max-w-xl text-center'
			style={{
				textShadow: '0 4px 14px rgba(0,0,0,0.3), 0 1px 2px rgba(0,0,0,0.3)',
				fontWeight: 800,
			}}
		>
			{isGM ? (
				<>
					<p
						className='mx-20 cursor-pointer text-2xl tracking-wide hover:underline'
						style={{ color: INTERFACE_BRIGHT_GOLD_COLOR }}
						onClick={handleSceneSettingsClick}
					>
						{nameIsSecret
							? `(${insertNonBreakableSpace(sceneName)})`
							: insertNonBreakableSpace(sceneName)}
					</p>
					{sceneSubtitle && (
						<p style={{ color: secondary }}>
							{nameIsSecret
								? `(${insertNonBreakableSpace(sceneSubtitle)})`
								: insertNonBreakableSpace(sceneSubtitle)}
						</p>
					)}
				</>
			) : (
				<>
					<p
						className='mx-20 text-3xl tracking-wide'
						style={{ color: INTERFACE_BRIGHT_GOLD_COLOR }}
					>
						{nameIsSecret
							? 'Unnamed Scene'
							: insertNonBreakableSpace(sceneName)}
					</p>
					{!nameIsSecret && sceneSubtitle && (
						<p style={{ color: secondary }}>
							{insertNonBreakableSpace(sceneSubtitle)}
						</p>
					)}
				</>
			)}
		</LargeUppercase>
	)
}

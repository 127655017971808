import { twMerge } from 'tailwind-merge'

interface IProps {
	className?: string
	children: string | undefined
}

const Caption = ({ className, children }: IProps) => {
	return (
		<div className={twMerge('mt-1 text-xs text-gray-500', className)}>
			{children}
		</div>
	)
}

export default Caption

import { useContext, useMemo } from 'react'
import GameContext from '../contexts/game'
import { getAuxServerDetails } from '../utils/getServerDetails'

interface UseFrameUrlProps {
	documentId: string
}

const useFrameUrl = ({ documentId }: UseFrameUrlProps) => {
	const { game } = useContext(GameContext)
	const { origin: frameOrigin } = getAuxServerDetails()

	const frameUrl = useMemo(() => {
		const baseUrl = new URL(frameOrigin)
		baseUrl.searchParams.append('id', documentId)

		const isDevelopmentModeActive = JSON.parse(
			localStorage.getItem(`isDevelopmentModeActive_${game._id}`) || 'false',
		)
		const developmentOrigin = localStorage.getItem(
			`developmentOrigin_${game._id}`,
		)

		if (isDevelopmentModeActive && developmentOrigin) {
			baseUrl.searchParams.append('developmentOrigin', developmentOrigin)
		}

		return baseUrl.toString()
	}, [frameOrigin, documentId, game._id])

	return frameUrl
}

export default useFrameUrl

// temporary parking spot for things that need to run based on game context; shouldn't be a JSX component, fix later...
import { useContext, useEffect } from 'react'
import GameContext from '../contexts/game'

const DocumentMisc = () => {
	const { game } = useContext(GameContext)
	const name = 'Realms Inc'

	const setWindowTitle = () => {
		if (game?.title) document.title = game.title + ' — ' + name
		else document.title = name

		// add a classname to body
		document.body.className = 'document'

		return () => {
			document.title = name
			document.body.className = ''
		}
	}

	useEffect(setWindowTitle, [game.title])
	// useFixOverflow()

	return null
}

export default DocumentMisc

// these hooks rely on the UsersContext, so they need to be wrapped
// in the component that provides the context, otherwise they would
// be in Users.tsx
import useEmitOnlineStatus from '../hooks/useEmitOnlineStatus'
import { useGameUsersQuery } from '../hooks/useGameUsersQuery'
import useUpdateUsersSettings from '../hooks/useUpdateUsersSettings'
import useUsersSocketListeners from '../hooks/useUsersSocketListeners'

type Props = {
	children: React.ReactNode
}

const UsersCompanion = ({ children }: Props) => {
	useEmitOnlineStatus()
	useGameUsersQuery()
	useUsersSocketListeners()
	useUpdateUsersSettings()

	return <>{children}</>
}

export default UsersCompanion

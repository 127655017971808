import React, { useContext } from 'react'
import { useDrop } from 'react-dnd'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import ItemTypes from '../draganddrop/ItemTypes'
import Document from '../Library/Document'
import SectionDivider from '../SectionDivider'
import ListMessage from '../views/ListMessage'
import ClearStageButton from './ClearStageButton'

const GMSessionStage: React.FC = () => {
	const { game, dispatch } = useContext(GameContext)
	const stageDocuments = game.stage?.documents || []

	const [{ isOver }, drop] = useDrop(() => ({
		accept: ItemTypes.DOCUMENT,
		drop: (item: { id: string }) => {
			if (!stageDocuments.includes(item.id)) {
				dispatch({
					type: 'ADD_DOCUMENT_TO_STAGE',
					payload: { documentId: item.id },
				})
			}
		},
		collect: monitor => ({
			isOver: !!monitor.isOver(),
		}),
	}))

	const moveItem = ({ id, targetId, _parentId, before }) => {
		const newOrder = [...stageDocuments]
		const oldIndex = newOrder.indexOf(id)
		const targetIndex = newOrder.indexOf(targetId)

		newOrder.splice(oldIndex, 1)
		newOrder.splice(before ? targetIndex : targetIndex + 1, 0, id)

		dispatch({
			type: 'SET_STAGE_DOCUMENTS',
			payload: { documents: newOrder },
		})
	}

	return (
		<>
			<SectionDivider label='Session Stage' children={<ClearStageButton />} />
			<div
				ref={drop}
				className={twMerge(
					'mt-2 overflow-auto',
					isOver ? 'bg-blue-100' : 'bg-transparent',
				)}
			>
				{stageDocuments.length === 0 ? (
					<ListMessage title='Drop documents here to make them easily accessible during the session.' />
				) : (
					<ul>
						{stageDocuments.map((docId, index) => (
							<Document
								key={docId}
								id={docId}
								dragIndex={index}
								hoverIndex={index}
								hoverItem={() => {}}
								moveItem={moveItem}
								selectedType='all'
							/>
						))}
					</ul>
				)}
			</div>
		</>
	)
}

export default GMSessionStage

import * as ContextMenu from '@radix-ui/react-context-menu'
import { useContext } from 'react'
import { IDocument } from '../../../../shared/types/document'
import GameContext from '../../contexts/game'
import UsersContext from '../../contexts/users'
import useHandleShareDocumentWith from '../../hooks/useHandleShareDocumentWith'
import useUser from '../../hooks/useUser'
import Menu from '../ContextMenu/Menu'
import Separator from '../ContextMenu/Separator'
import AccessCheckbox from './DocumentMenu/AccessCheckbox'
import AccessControl from './DocumentMenu/AccessControl'
import AssignCharacter from './DocumentMenu/AssignCharacter'
import DeleteMenuItem from './DocumentMenu/DeleteMenuItem'
import RenameMenuItem from './DocumentMenu/RenameMenuItem'
import SetSceneMenuItem from './DocumentMenu/SetSceneMenuItem'
import ItemInformation from './ItemInformation'

export interface IDocumentMenuProps {
	document: IDocument
	children: React.ReactNode
	onOpenChange?: (open: boolean) => void
}

export default function DocumentMenu({
	document,
	children,
	onOpenChange,
}: IDocumentMenuProps) {
	const { access } = document
	const accessList = document.accessList
	const { game } = useContext(GameContext)
	const { theme } = game
	const { usersState } = useContext(UsersContext)
	const { isGM } = useUser()
	const collection = game.system.collections?.find(
		t => t.type === document.type,
	)
	const isPrivate = access === 'private'
	const { handleSharePublic, handleSharePrivate, handleShareWith } =
		useHandleShareDocumentWith()

	return (
		<ContextMenu.Root onOpenChange={onOpenChange}>
			<ContextMenu.Trigger>{children}</ContextMenu.Trigger>

			<Menu>
				<div className='px-2'>
					<ItemInformation
						document={document}
						className='cursor-default'
						style={{
							color: theme.secondary,
						}}
					/>
				</div>

				<Separator />

				<DeleteMenuItem document={document} />
				<RenameMenuItem document={document} />
				{/* <AddToMapMenuItem document={document} /> */}

				{collection.type === 'scene' && (
					<>
						<Separator />

						<SetSceneMenuItem document={document} />
					</>
				)}

				{collection.canAssumeAsCharacter === 'true' && (
					<AssignCharacter document={document} />
				)}
				<AccessControl
					access={access}
					id={document._id}
					handleSharePrivate={handleSharePrivate}
					handleSharePublic={handleSharePublic}
				/>
				{isPrivate && usersState.users.length > 0 && <Separator />}
				{isPrivate && (
					<AccessCheckbox
						document={document}
						isGM={isGM}
						accessList={accessList}
						handleShareWith={handleShareWith}
					/>
				)}
			</Menu>
		</ContextMenu.Root>
	)
}

type NodeEnv = 'development' | 'production' | 'staging'
let env: NodeEnv = process.env.NODE_ENV as NodeEnv

if (window.location.hostname === 'newrealms-staging.herokuapp.com') {
	env = 'staging'
}

// returns the origin of the current window, e.g. 'http://localhost:3000'
// devPort is the port used by the development node server
const getServerDetails = (devPort = ':3000') => {
	const protocol = window.location.protocol
	let host = window.location.hostname
	const port = process.env.NODE_ENV === 'development' ? devPort : ''

	// if running on GitHub Codespaces
	if (host.endsWith('.github.dev')) {
		// extract the port from the hostname
		const match = host.match(/-(\d+)\./)
		if (match) {
			// replace the port in the hostname
			const portWithoutColon = port.replace(':', '')
			host = host.replace(`-${match[1]}`, `-${portWithoutColon}`)
		}
	}

	const origin = `${protocol}//${host}${port}`

	return {
		protocol: protocol,
		host: host,
		port: port,
		origin: origin,
	}
}
export default getServerDetails

export const getAuxServerDetails = (proxy = false) => {
	// react dev server
	const auxPort = ':3003'
	// node dev server (proxies for git forwarding)
	const proxyPort = ':3002'
	const port = proxy ? proxyPort : auxPort
	const protocol = window.location.protocol
	const host = window.location.hostname

	// either 'http://localhost:3000' or 'https://newrealms-aux-staging.herokuapp.com'
	let origin = `${protocol}//${host}${port}`

	if (env === 'staging') {
		origin = 'https://newrealms-aux-staging.herokuapp.com'
	} else if (env === 'production') {
		origin = 'https://newrealms-aux.herokuapp.com'
	}

	return {
		protocol: protocol,
		host: host,
		port: port,
		origin: origin,
	}
}

import useGetDocumentById from '../../hooks/useGetDocumentById'
import ItemThumbnail from '../Library/ItemThumbnail'

type Props = {
	documentId: string
}

const Document = ({ documentId }: Props) => {
	const { document } = useGetDocumentById(documentId)
	const { name } = document.values

	return (
		<div className='flex items-center'>
			<ItemThumbnail id={documentId} className='h-8' />
			<div>{name}</div>
		</div>
	)
}

export default Document

import { twMerge } from 'tailwind-merge'
import MenuItem from './Item'

type Props = {
	children: React.ReactNode
	onSelect: () => void
	className?: string
}

export default function MenuItemDanger({
	children,
	onSelect,
	className,
}: Props) {
	return (
		<MenuItem
			className={twMerge(
				'text-red-600 hover:bg-red-500/10 dark:text-red-600 dark:hover:bg-red-500/10',
				className,
			)}
			onSelect={onSelect}
		>
			{children}
		</MenuItem>
	)
}

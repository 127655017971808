import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import { ResourceContext } from '../contexts/resources'
import { DELETE_RESOURCE } from '../graphql/resources'
import useDeleteEmbeddings from './useDeleteEmbeddings'

const useDeleteResource = () => {
	const { dispatchResource } = useContext(ResourceContext)
	const [deleteResourceFromDB] = useMutation(DELETE_RESOURCE)
	const { deleteEmbeddings } = useDeleteEmbeddings()

	const deleteResource = (resourceId: string) => {
		deleteResourceFromDB({
			variables: { resourceId },
		})

		deleteEmbeddings(resourceId)

		// Delete the localStorage summary for the book
		const summaryKey = `summary_${resourceId}`
		localStorage.removeItem(summaryKey)

		dispatchResource({
			type: 'REMOVE_RESOURCE',
			payload: { resourceId },
		})
	}

	return deleteResource
}

export default useDeleteResource

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useState } from 'react'
import { IResource } from '../../../../shared/types/resources'
import address from '../../config'
import useDeleteResource from '../../hooks/useDeleteResource'
import DangerMenuItem from '../dropdownmenu/DangerMenuItem'
import MenuContent from '../dropdownmenu/MenuContent'
import MenuItem from '../dropdownmenu/MenuItem'
import BookMenuButton from './BookMenuButton'

type Props = {
	resource: IResource
}

const ResourceMenu = ({ resource }: Props) => {
	const [isOpen, setIsOpen] = useState(false)
	const deleteResource = useDeleteResource()

	const handleOpen = () => {
		window.open(`${address}${resource.fileurl}`, '_blank')
	}

	const handleDelete = (event: React.MouseEvent) => {
		event.stopPropagation()
		deleteResource(resource._id)
	}

	const onOpenChange = (open: boolean) => {
		setIsOpen(open)
	}

	return (
		<DropdownMenu.Root onOpenChange={onOpenChange}>
			<DropdownMenu.Trigger>
				<BookMenuButton className={isOpen && 'text-black dark:text-white'} />
			</DropdownMenu.Trigger>

			<MenuContent>
				<MenuItem onClick={handleOpen}>Open in Tab</MenuItem>
				<DangerMenuItem onClick={handleDelete}>Delete</DangerMenuItem>
			</MenuContent>
		</DropdownMenu.Root>
	)
}

export default ResourceMenu

// this is meant to replace the Users context, which isn't updating properly
// THIS DOES NOT WORK CURRENTLY FIX IT OR LOSE IT!
import { useCallback, useContext, useEffect, useState } from 'react'
import UsersContext from '../contexts/users'
import { IRegisteredUser } from '../interfaces/users'

const useGetUserById = (initialUserId?: string) => {
	const { usersState } = useContext(UsersContext)
	const { users } = usersState
	const [user, setUser] = useState<IRegisteredUser | null>(null)

	const fetchUser = useCallback(
		(userId: string): IRegisteredUser | null => {
			const foundUser = users.find(user => user.userId === userId) || null
			if (!foundUser) return null
			setUser(foundUser)
			return foundUser
		},
		[users],
	)

	useEffect(() => {
		if (!initialUserId) return
		const fetchedUser = fetchUser(initialUserId)
		if (fetchedUser) setUser(fetchedUser)
	}, [initialUserId, fetchUser])

	// const [users, setUsers] = useState<IUserProfile[]>([])
	// const [user, setUser] = useState<IRegisteredUser | null>(null)

	// useQuery(GAME_USERS, {
	// 	notifyOnNetworkStatusChange: true,
	// 	variables: {
	// 		gameId: game._id,
	// 	},
	// 	onCompleted: ({ gameUsers }) => {
	// 		setUsers(gameUsers)
	// 	},
	// 	onError: error => {
	// 		console.error(error)
	// 	},
	// })

	// const fetchUser = (userId: string): IRegisteredUser | null => {
	// 	const thisUser = users.find(user => user._id === userId)

	// 	if (!thisUser) return null

	// 	const registeredUser = {
	// 		userId: thisUser._id,
	// 		socketId: '',
	// 		online: false,
	// 		userSettings: game.userSettings.find(
	// 			userSetting => userSetting.userId === thisUser._id,
	// 		),
	// 		userProfile: {
	// 			name: thisUser.name,
	// 			email: thisUser.email,
	// 			avatar: thisUser.avatar,
	// 		},
	// 	}

	// 	setUser(registeredUser)
	// 	return registeredUser
	// }

	// useEffect(() => {
	// 	if (initialUserId) {
	// 		const fetchedUser = fetchUser(initialUserId)
	// 		if (fetchedUser) {
	// 			setUser(fetchedUser)
	// 		}
	// 	}
	// }, [initialUserId, users]) // eslint-disable-line react-hooks/exhaustive-deps

	return { user, fetchUser }
}

export default useGetUserById

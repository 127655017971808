import { gql, useMutation, useQuery } from '@apollo/client'
import { useState } from 'react'

const GET_ALL_GAMES = gql`
	query GetAllGames {
		adminGames {
			_id
			title
			description
			coverImage
			assets {
				byId
				allIds
			}
			creator {
				name
				email
			}
			users
			createdAt
			updatedAt
		}
	}
`

const DELETE_GAMES = gql`
	mutation DeleteGames($gameIds: [ID!]!) {
		deleteGames(gameIds: $gameIds)
	}
`

interface Game {
	_id: string
	title: string
	description: string
	coverImage: string | null
	assets: {
		byId: { [key: string]: any }
		allIds: string[]
	}
	creator: {
		name: string
		email: string
	}
	users: string[]
	createdAt: string
	updatedAt: string
}

const formatDate = (dateString: string) => {
	try {
		// Try parsing as ISO string first
		const date = new Date(dateString)
		if (isNaN(date.getTime())) {
			// If invalid, try parsing as Unix timestamp (in milliseconds)
			const timestampDate = new Date(parseInt(dateString))
			return timestampDate.toLocaleDateString()
		}
		return date.toLocaleDateString()
	} catch {
		return 'Invalid Date'
	}
}

function GameCover({ game }: { game: Game }) {
	if (!game.coverImage || !game.assets.byId[game.coverImage]) {
		return (
			<div className='flex h-12 w-16 items-center justify-center rounded bg-gray-200 text-gray-500'></div>
		)
	}

	const asset = game.assets.byId[game.coverImage]
	const key = asset.fileurl.replace('files/', '')

	return (
		<img
			className='h-12 w-16 rounded object-cover'
			src={`/files/${key}`}
			alt='Game cover'
			onError={e => {
				const target = e.target as HTMLImageElement
				target.onerror = null
				target.parentElement?.replaceChild(
					(() => {
						const div = document.createElement('div')
						div.className =
							'flex h-12 w-16 items-center justify-center rounded bg-gray-200 text-gray-500'
						div.textContent = 'No Cover'
						return div
					})(),
					target,
				)
			}}
		/>
	)
}

export default function AdminGames() {
	const [selectedGames, setSelectedGames] = useState<string[]>([])
	const { data, loading, error } = useQuery<{ adminGames: Game[] }>(
		GET_ALL_GAMES,
	)
	const [deleteGames] = useMutation(DELETE_GAMES, {
		refetchQueries: [{ query: GET_ALL_GAMES }],
	})

	if (loading) return <div>Loading games...</div>
	if (error) return <div>Error loading games</div>

	const games = data?.adminGames || []

	const handleSelectGame = (gameId: string) => {
		setSelectedGames(prev =>
			prev.includes(gameId)
				? prev.filter(id => id !== gameId)
				: [...prev, gameId],
		)
	}

	const handleSelectAll = () => {
		if (selectedGames.length === games.length) {
			setSelectedGames([])
		} else {
			setSelectedGames(games.map(game => game._id))
		}
	}

	const handleDeleteSelected = async () => {
		if (!selectedGames.length) return

		if (
			window.confirm(
				`Are you sure you want to delete ${selectedGames.length} games?`,
			)
		) {
			try {
				await deleteGames({
					variables: { gameIds: selectedGames },
				})
				setSelectedGames([])
			} catch (err) {
				console.error('Error deleting games:', err)
				alert('Failed to delete games')
			}
		}
	}

	return (
		<div>
			<div className='mb-8 flex items-center justify-between'>
				<h1 className='text-2xl font-bold'>Games</h1>
				{selectedGames.length > 0 && (
					<button
						onClick={handleDeleteSelected}
						className='rounded bg-red-600 px-4 py-2 text-white hover:bg-red-700'
					>
						Delete Selected ({selectedGames.length})
					</button>
				)}
			</div>

			<div className='overflow-hidden rounded-lg bg-white shadow'>
				<table className='min-w-full divide-y divide-gray-200'>
					<thead className='bg-gray-50'>
						<tr>
							<th className='px-6 py-3 text-left'>
								<input
									type='checkbox'
									checked={selectedGames.length === games.length}
									onChange={handleSelectAll}
									className='h-4 w-4 rounded border-gray-300'
								/>
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Game
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Creator
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Players
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Created
							</th>
							<th className='px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500'>
								Last Updated
							</th>
						</tr>
					</thead>
					<tbody className='divide-y divide-gray-200 bg-white'>
						{games.map(game => (
							<tr key={game._id} className='hover:bg-gray-50'>
								<td className='whitespace-nowrap px-6 py-4'>
									<input
										type='checkbox'
										checked={selectedGames.includes(game._id)}
										onChange={() => handleSelectGame(game._id)}
										className='h-4 w-4 rounded border-gray-300'
									/>
								</td>
								<td className='whitespace-nowrap px-6 py-4'>
									<div className='flex items-center'>
										<div className='h-12 w-16 flex-shrink-0'>
											<GameCover game={game} />
										</div>
										<div className='ml-4'>
											<div className='text-sm font-medium text-gray-900'>
												{game.title}
											</div>
											<div className='text-sm text-gray-500'>
												{game.description}
											</div>
										</div>
									</div>
								</td>
								<td className='whitespace-nowrap px-6 py-4'>
									<div className='text-sm text-gray-900'>
										{game.creator.name}
									</div>
									<div className='text-sm text-gray-500'>
										{game.creator.email}
									</div>
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{game.users.length}
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{formatDate(game.createdAt)}
								</td>
								<td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
									{formatDate(game.updatedAt)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

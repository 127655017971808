import Button from './FormComponents/Button'
import Input from './Input'
import ModalWindow from './window/ModalWindow'
import { LockClosedIcon } from '@heroicons/react/24/solid'
import { useForm } from 'react-hook-form'
import { useRef } from 'react'
import Caption from './Caption'
import { UPDATE_USER_PASSWORD } from '../graphql/users'
import { useMutation } from '@apollo/client'

export interface IPasswordEditorProps {
	onClose: () => void
}

export default function PasswordEditor({ onClose }: IPasswordEditorProps) {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({ reValidateMode: 'onChange' })
	const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD, {
		onCompleted: () => {
			onClose()
		},
		onError: error => {
			alert(error.message)
			console.error(error)
		},
	})

	const password = useRef({})
	password.current = watch('newPassword', '')

	const onSubmit = async (data: any) => {
		updateUserPassword({
			variables: {
				// userPasswordInput:
				...data,
			},
		})
	}

	const handleCancel = () => {
		onClose()
	}

	const canSubmit =
		errors.oldPassword || errors.newPassword || errors.newPasswordConfirmation
			? false
			: true

	return (
		<ModalWindow
			id='password-editor'
			title='Change Password'
			headerIcon={<LockClosedIcon className='h-5 w-5' aria-hidden='true' />}
			onClose={handleCancel}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Input
					type='password'
					placeholder='Old password...'
					className='mt-4'
					{...register('oldPassword', {
						required: 'You must specify the current password.',
					})}
				/>
				{errors.oldPassword && (
					<Caption className='text-red-500 dark:text-red-800'>
						{errors.oldPassword.message as string}
					</Caption>
				)}

				<Input
					type='password'
					placeholder='New password...'
					className='mt-6'
					{...register('newPassword', {
						required: 'You must specify a password',
						minLength: {
							value: 8,
							message: 'Password must have at least 8 characters.',
						},
					})}
				/>
				{errors.newPassword && (
					<Caption className='text-red-500 dark:text-red-800'>
						{errors.newPassword.message as string}
					</Caption>
				)}

				<Input
					type='password'
					placeholder='New password again...'
					className='mt-2'
					{...register('newPasswordConfirmation', {
						validate: value =>
							value === password.current || 'The passwords do not match.',
					})}
				/>
				{errors.newPasswordConfirmation && (
					<Caption className='text-red-500 dark:text-red-800'>
						{errors.newPasswordConfirmation.message as string}
					</Caption>
				)}

				<Button
					type='submit'
					className='mt-8'
					disabled={canSubmit ? false : true}
				>
					Change Password
				</Button>
			</form>
		</ModalWindow>
	)
}

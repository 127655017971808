import { useContext } from 'react'
import GameContext from '../../contexts/game'

const useHandleSetScene = () => {
	const { dispatch } = useContext(GameContext)

	const handleSetScene = (payload: { sceneId: string }) => {
		dispatch({
			type: 'SET_ACTIVE_SCENE',
			payload,
		})
	}

	return handleSetScene
}

export default useHandleSetScene

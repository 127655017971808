import { FC, useReducer } from 'react'
import UsersContext from '../contexts/users'
import UsersReducer from '../reducers/users'
import UsersCompanion from './UsersCompanion'
import useCheckUserInGame from '../hooks/useCheckUserInGame'

interface IProps {
	children: React.ReactNode
}

const Users: FC<IProps> = ({ children }) => {
	const [usersState, dispatchUsers] = useReducer(UsersReducer, { users: [] })
	useCheckUserInGame()

	return (
		<UsersContext.Provider value={{ usersState, dispatchUsers }}>
			<UsersCompanion>{children}</UsersCompanion>
		</UsersContext.Provider>
	)
}

export default Users

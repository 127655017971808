import React from 'react'
import { IDocument } from '../../../../../shared/types/document'
import { useGame } from '../../../hooks/useGame'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import DangerMenuItem from '../../dropdownmenu/DangerMenuItem'

interface RemoveActorMenuItemProps {
	doc: IDocument
	onClose: () => void
}

const RemoveActorMenuItem: React.FC<RemoveActorMenuItemProps> = ({
	doc,
	onClose,
}) => {
	const { dispatch } = useGame()
	const activeScene = useGetActiveScene()

	const handleRemoveFromScene = () => {
		dispatch({
			type: 'REMOVE_ACTOR_FROM_SCENE',
			payload: {
				sceneId: activeScene?._id,
				actorId: doc._id,
			},
		})
		onClose()
	}
	return (
		<DangerMenuItem onSelect={handleRemoveFromScene}>
			Remove from Scene
		</DangerMenuItem>
	)
}
export default RemoveActorMenuItem

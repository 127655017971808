import Shadow from '../../assets/maps/shadowscrim.png'
import './Scrim.css'

const Scrim = () => {
	return (
		<div
			className='canvasscrim pointer-events-none fixed top-0 left-0 right-0 bottom-0 z-20 opacity-60'
			style={{
				mixBlendMode: 'multiply',
				backgroundSize: '100% 100%',
				backgroundPosition: 'center',
				backgroundImage: `url(${Shadow})`,
			}}
		>
			{/* <div
				className='canvasscrim pointer-events-none fixed top-0 left-0 right-0 bottom-0'
				style={{
					mixBlendMode: 'multiply',
					backgroundColor: 'hsla(42, 87%, 62%, 1)',
				}}
			></div> */}
		</div>
	)
}

export default Scrim

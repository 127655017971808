import { IGame } from './interfaces/game'
import getServerDetails from './utils/getServerDetails'

const { origin: serverOrigin } = getServerDetails(':3001')
export default serverOrigin

export const initialGameState: IGame = {
	_id: '',
	title: '',
	description: '',
	coverImage: '',
	logo: '',
	hideLogo: false,
	creator: '',
	tabletop: '',
	log: {
		byId: {},
		allIds: [],
	},
	users: [],
	userSettings: {},
	documents: {
		byId: {},
		allIds: [],
	},
	books: {
		byId: {},
		allIds: [],
	},
	assets: {
		byId: {},
		allIds: [],
	},
	drawing: {
		size: '5',
		color: '#ff0000',
	},
	flags: {
		syrinscape: false,
	},
	theme: {
		primary: '#D09A3A',
		secondary: '#7A6E65',
	},
	turnTracker: {
		show: false,
		currentRound: 0,
		currentTurnIndex: 0,
		turnOrder: {
			byId: {},
			allIds: [],
		},
	},
	syrinscape: {
		sessionId: '',
		volume: 1,
		show: false,
		savedSoundsets: [],
	},
	inviteToken: '',
	version: 0,
}

export const FOLDER_COLLECTION = {
	singularName: 'Folder',
	pluralName: 'Folders',
	description: 'A folder to organize documents',
	type: 'folder',
	allowCreate: 'false',
	acceptTypes: [],
	hasEditMode: 'false',
}

// This component captures the drag events, calculates the relative coordinates
// then sends to the iframe via postMessage. Then the iframe can fire fake
// mouse events to the document. This is the only way to simulate a drag and
// drop event in an iframe. The iframe is sandboxed so it can't access the
// parent document, so we have to use postMessage to communicate.

import React, { useContext, useEffect, useRef } from 'react'
import { DropTargetMonitor, useDrop } from 'react-dnd'
import { twMerge } from 'tailwind-merge'
import { DragStateContext } from '../../contexts/dragdrop'
import { getAuxServerDetails } from '../../utils/getServerDetails'
import ItemTypes from './ItemTypes'

type Props = {
	src: string
	displayMode?: 'iframe' | 'window'
	messageTarget: Window | null
	setMessageTarget: React.Dispatch<React.SetStateAction<Window | null>>
	onClose: () => void
}

const DroppableFrame: React.FC<Props> = ({
	src,
	displayMode = 'iframe',
	setMessageTarget,
	onClose,
}) => {
	const iframeRef = useRef<HTMLIFrameElement>(null)
	const containerRef = useRef<HTMLDivElement>(null)
	const { dragState } = useContext(DragStateContext)
	const { origin: frameOrigin } = getAuxServerDetails()
	let popupWindow: Window | null = null

	const postMessageToFrame = (targetWindow: Window | null, message: any) => {
		if (!targetWindow) return

		targetWindow.postMessage(
			{
				source: 'App',
				...message,
			},
			frameOrigin,
		)
	}

	const handleEvent = (
		monitor: DropTargetMonitor,
		message: string,
		extraData: any = {},
	) => {
		const clientOffset = monitor.getClientOffset()
		if (!clientOffset) return

		const rect = containerRef.current?.getBoundingClientRect()
		const targetWindow =
			displayMode === 'iframe'
				? (iframeRef as React.MutableRefObject<HTMLIFrameElement> | null)
						?.current?.contentWindow
				: popupWindow

		const relativeX = clientOffset.x - (rect?.left || 0)
		const relativeY = clientOffset.y - (rect?.top || 0)
		postMessageToFrame(targetWindow, {
			message,
			pointer: {
				x: relativeX,
				y: relativeY,
			},
			...extraData,
		})
	}

	const handleDragOver = (monitor: DropTargetMonitor) => {
		handleEvent(monitor, 'onDragOver')
	}

	const handleDrop = (item: { id: string }, monitor: DropTargetMonitor) => {
		handleEvent(monitor, 'onDrop', { data: { documentId: item.id } })
	}

	const [, drop] = useDrop({
		accept: [ItemTypes.DOCUMENT],
		drop: handleDrop,
		hover: (_, monitor) => handleDragOver(monitor), // pass the monitor
	})

	useEffect(() => {
		if (displayMode === 'window') {
			popupWindow = window.open(src, '_blank', 'width=800,height=600')
			setMessageTarget(popupWindow)

			if (!popupWindow) {
				console.error('Failed to open popup window')
				return
			}

			const handleBeforeUnload = () => {
				if (onClose) {
					onClose()
				}
			}
			popupWindow.addEventListener('beforeunload', handleBeforeUnload)

			return () => {
				if (popupWindow && !popupWindow.closed) {
					popupWindow.close()
				}
			}
		} else if (displayMode === 'iframe') {
			setMessageTarget(
				(iframeRef as React.MutableRefObject<HTMLIFrameElement> | null)?.current
					?.contentWindow,
			)
		} else {
			setMessageTarget(null)
		}
	}, [src, displayMode])

	drop(containerRef)

	return (
		<div ref={containerRef} className='relative h-full w-full'>
			{displayMode === 'iframe' ? (
				<>
					<iframe
						src={src}
						id='droppable-iframe'
						title='Frame'
						ref={iframeRef}
						className='h-full w-full rounded-b-xl'
						sandbox='allow-scripts allow-same-origin allow-modals allow-forms'
					/>
					<div
						className={twMerge(
							'absolute top-0 left-0 h-full w-full bg-red-500/80',
							dragState.isDragging ? 'block' : 'hidden',
						)}
						style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}
					/>
				</>
			) : null}
		</div>
	)
}

export default DroppableFrame

import React from 'react'
import { useGuide } from '../../contexts/guideContext'
import useGetUserById from '../../hooks/useGetUserById'
import useTheme from '../../hooks/useTheme'

const GuideOverlay: React.FC = () => {
	const { guidingUserId, isFollowing } = useGuide()
	const { user: guidingUser } = useGetUserById(guidingUserId)
	const { primary } = useTheme()

	if (!guidingUserId || !isFollowing || !guidingUser) {
		return null
	}

	return (
		<div
			className='pointer-events-none fixed inset-0 transition-all duration-1000 ease-in-out'
			style={{
				boxShadow: `inset 0 0 0 4px  ${
					guidingUser?.userSettings?.color || primary
				}`,
				opacity: 1,
			}}
		/>
	)
}

export default GuideOverlay

import { useMutation } from '@apollo/client'
import { useContext } from 'react'
import GameContext from '../../../contexts/game'
import { REMOVE_PLAYER } from '../../../graphql/games'
import useGetUserById from '../../../hooks/useGetUserById'
import MenuItemDanger from '../../ContextMenu/ItemDanger'

type Props = {
	userId: string
}

export default function RemovePlayerMenuItem({ userId }: Props) {
	const { game, dispatch } = useContext(GameContext)
	const { user } = useGetUserById(userId)
	const gameId = game._id

	const [removePlayer] = useMutation(REMOVE_PLAYER, {
		onCompleted(data) {
			const userId = data.removePlayer

			dispatch({
				type: 'REMOVE_USER',
				payload: {
					userId,
				},
			})
		},
		onError(error) {
			alert(error)
		},
	})

	const handleSelect = () => {
		const confirm = window.confirm(
			`Are you sure you want to remove ${user?.userProfile.name} from the game?`,
		)
		if (!confirm) return
		removePlayer({
			variables: { userId, gameId },
		})
	}

	return (
		<MenuItemDanger
			className='cursor-pointer rounded-lg py-1.5 px-2.5 text-sm hover:bg-gray-200 dark:hover:bg-gray-800'
			onSelect={handleSelect}
		>
			Remove Player
		</MenuItemDanger>
	)
}

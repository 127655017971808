import { FC, useContext, useEffect, useReducer } from 'react'
import DocumentsContext from '../contexts/documents'
import WindowsContext from '../contexts/windows'
import DocumentsReducer from '../reducers/documents'

interface KeeperProps {
	children: React.ReactNode
}

const Keeper: FC<KeeperProps> = ({ children }) => {
	const { dispatchWindows } = useContext(WindowsContext)
	const [openDocuments, dispatchDocuments] = useReducer(DocumentsReducer, [])

	useEffect(() => {
		openDocuments.forEach(documentId => {
			dispatchWindows({
				type: 'OPEN_WINDOW',
				payload: {
					documentId,
				},
			})
		})
	}, [dispatchWindows, openDocuments])

	return (
		<>
			<DocumentsContext.Provider value={{ openDocuments, dispatchDocuments }}>
				{children}
			</DocumentsContext.Provider>
		</>
	)
}

export default Keeper

import React from 'react'
import { useGame } from '../../../hooks/useGame'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import DangerMenuItem from '../../dropdownmenu/DangerMenuItem'

type Props = {
	onSelect: () => void
}

const RemoveAllTokensMenuItem: React.FC<Props> = ({ onSelect }) => {
	const { dispatch } = useGame()
	const { _id: sceneId } = useGetActiveScene()

	const handleSelect = () => {
		if (!confirm('Are you sure you want to remove all tokens from this scene?'))
			return

		dispatch({ type: 'REMOVE_ALL_ACTORS_FROM_SCENE', payload: { sceneId } })
		onSelect()
	}

	return (
		<DangerMenuItem onSelect={handleSelect}>Remove All Tokens</DangerMenuItem>
	)
}

export default RemoveAllTokensMenuItem

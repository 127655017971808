import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react'
import {
	GuideStatusUpdateMessage,
	ViewportUpdateMessage,
} from '../../../shared/types/socket'
import useUser from '../hooks/useUser'
import { socket } from './socket'

interface GuideContextType {
	guidingUserId: string | null
	setGuidingUserId: (userId: string | null) => void
	isGuiding: boolean
	isFollowing: boolean
	setIsFollowing: (isFollowing: boolean) => void
	lastGuideUpdate: ViewportUpdateMessage | null
}

export const GuideContext = createContext<GuideContextType | undefined>(
	undefined,
)

export const GuideProvider: React.FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [guidingUserId, setGuidingUserId] = useState<string | null>(null)
	const [isFollowing, setIsFollowing] = useState<boolean>(false)
	const [lastGuideUpdate, setLastGuideUpdate] =
		useState<ViewportUpdateMessage | null>(null)
	const { userId } = useUser()

	const isGuiding = guidingUserId === userId

	useEffect(() => {
		const handleGuideStatusUpdate = (update: GuideStatusUpdateMessage) => {
			setGuidingUserId(update.isGuiding ? update.userId : null)
			if (update.isGuiding && update.userId !== userId) {
				// Automatically follow when someone else becomes a guide
				setIsFollowing(true)
				setLastGuideUpdate(null)
			} else if (!update.isGuiding) {
				// Stop following when the guide stops
				setIsFollowing(false)
				setLastGuideUpdate(null)
			}
		}

		const handleViewportUpdate = (update: ViewportUpdateMessage) => {
			if (update.userId === guidingUserId) {
				setLastGuideUpdate(update)
			}
		}

		socket.on('guideStatusUpdate', handleGuideStatusUpdate)
		socket.on('viewportUpdate', handleViewportUpdate)

		return () => {
			socket.off('guideStatusUpdate', handleGuideStatusUpdate)
			socket.off('viewportUpdate', handleViewportUpdate)
		}
	}, [userId, guidingUserId])

	return (
		<GuideContext.Provider
			value={{
				guidingUserId,
				setGuidingUserId,
				isGuiding,
				isFollowing,
				setIsFollowing,
				lastGuideUpdate,
			}}
		>
			{children}
		</GuideContext.Provider>
	)
}

export const useGuide = () => {
	const context = useContext(GuideContext)
	if (context === undefined) {
		throw new Error('useGuide must be used within a GuideProvider')
	}
	return context
}

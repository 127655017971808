import { Music } from 'lucide-react'
import { useGame } from '../../hooks/useGame'
import useUser from '../../hooks/useUser'
import ToolButton from '../interface/toolbar/ToolButton'

export default function SyrinscapeButton() {
	const { game, dispatch } = useGame()
	const { isGM } = useUser()
	const isShowing = game.syrinscape.show

	if (!isGM) return null
	if (!game.flags.syrinscape) return null

	const handleToggleSyrinscape = () => {
		if (isShowing) {
			dispatch({ type: 'HIDE_SYRINSCAPE' })
		} else {
			dispatch({ type: 'SHOW_SYRINSCAPE' })
		}
	}

	return (
		<ToolButton
			tooltip={isShowing ? 'Hide Syrinscape' : 'Show Syrinscape'}
			isOpen={isShowing}
			onClick={handleToggleSyrinscape}
		>
			<Music className='h-5 w-5' aria-hidden='true' />
		</ToolButton>
	)
}

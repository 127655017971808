import { useContext } from 'react'
import AuthContext from '../../../contexts/auth'
import ResourcesProvider from '../../ResourcesProvider'
import BookList from './elements/BookList'

const BooksPage = () => {
	const { authState } = useContext(AuthContext)

	return (
		<ResourcesProvider userId={authState.userId}>
			<BookList />
		</ResourcesProvider>
	)
}

export default BooksPage

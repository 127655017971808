import { FC } from 'react'
import { twMerge } from 'tailwind-merge'

interface IProps {
	children: React.ReactNode
	className?: string
}

const HeaderTitle: FC<IProps> = ({ children, className }) => {
	return (
		<h3
			className={twMerge(
				'my-1 inline-block select-none align-middle text-base text-white',
				className,
			)}
		>
			{children}
		</h3>
	)
}

export default HeaderTitle

// SidebarToggle.tsx
import { Bars3Icon } from '@heroicons/react/24/solid'
import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import PdfViewerContext from '../../contexts/book'
import ToolbarButton from './ToolbarButton'

const SidebarToggle = () => {
	const { bookState, bookDispatch } = useContext(PdfViewerContext)
	const { isOutlineVisible } = bookState

	const toggleOutline = () => {
		bookDispatch({
			type: 'SET_OUTLINE_VISIBLE',
			payload: !isOutlineVisible,
		})
		localStorage.setItem('outlineVisible', JSON.stringify(!isOutlineVisible))
	}

	return (
		<div className='flex space-x-px'>
			<ToolbarButton
				label='Show Outline'
				onClick={toggleOutline}
				className={twMerge(
					'flex w-9 items-center justify-center hover:bg-[#A37F4322]',
					isOutlineVisible ? 'bg-gray-300 text-black' : '',
				)}
				style={{
					color: '#A37F43',
				}}
			>
				<Bars3Icon className='h-5 w-5' />
			</ToolbarButton>
			{/* <ToolbarButton
				label='Show Thumbnails'
				onClick={toggleThumbnail}
				className={twMerge(
					'rounded-l-none',
					isThumbnailVisible ? 'bg-gray-300 text-black' : '',
				)}
			>
				<Squares2X2Icon className='h-5 w-5' />
			</ToolbarButton> */}
		</div>
	)
}

export default SidebarToggle

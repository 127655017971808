import { useEffect, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'
import ColorPicker from './ColorPicker'

interface Props {
	color: string
	colorName: string
	setEnableClickOutside: (enable: boolean) => void
	onChange?: (color: string) => void // Add this line
	className?: string // Add this line
}

const ColorSwatch = ({
	color,
	setEnableClickOutside,
	onChange,
	className,
}: Props) => {
	const [show, setShow] = useState(false)
	const [currentColor, setCurrentColor] = useState(color)
	const anchorRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		// prevent color picker clicks from closing e.g. the parent modal
		if (setEnableClickOutside) {
			setEnableClickOutside(!show)
		}
	}, [show, setEnableClickOutside])

	const handleColorChange = (newColor: string) => {
		setCurrentColor(newColor)
		if (onChange) {
			onChange(newColor)
		}
	}

	return (
		<div
			ref={anchorRef}
			className={twMerge(
				'h-8 w-10 cursor-pointer rounded-full border border-black',
				className,
			)}
			style={{
				backgroundColor: currentColor,
			}}
			onClick={() => setShow(true)}
		>
			<ColorPicker
				show={show}
				onClose={() => setShow(false)}
				anchorRef={anchorRef}
				color={currentColor}
				onChange={handleColorChange}
			/>
		</div>
	)
}

export default ColorSwatch

import { useContext, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { TDocumentModes } from '../../../../shared/types/document'
import { DocumentUnpopMessage } from '../../../../shared/types/socket'
import GameContext from '../../contexts/game'
import { socket } from '../../contexts/socket'
import useGetCollectionName from '../../hooks/useGetCollectionName'
import useUser from '../../hooks/useUser'
import { getAuxServerDetails } from '../../utils/getServerDetails'
import DocumentWindowMenuButton from '../Library/MenuButton'
import DocumentWindowTitle from '../Library/Title'
import Menu from '../documentmenu/Menu'
import DroppableFrame from '../draganddrop/DroppableFrame'
import WindowInner from '../window/WindowInner'
import HeaderChildren from './HeaderChildren'
import useDocumentMessaging from './useDocumentMessaging'

const DocumentPopout = () => {
	const { game } = useContext(GameContext)
	const { documentId } = useParams()
	const document = game.documents.byId[documentId]
	const { singularName } = useGetCollectionName(document)
	const title = document.values?.name || `Untitled ${singularName}`
	const collection =
		game.system?.collections?.find(t => t.type === document.type) || null
	const [documentMode, setDocumentMode] = useState<TDocumentModes>('view')
	const { origin: frameOrigin } = getAuxServerDetails()
	const [messageTarget, setMessageTarget] = useState<Window | null>(null)
	const { userId } = useUser()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const developmentOrigin = queryParams.get(`developmentOrigin_${game._id}`)

	const onClose = () => {}

	// set up post message communication with the iframe
	useDocumentMessaging({
		documentId,
		messageTarget,
		documentMode,
		onClose,
	})

	const handlePop = () => {
		const message: DocumentUnpopMessage = {
			documentId,
			forUserId: userId,
		}
		socket.emit('document:unpop', message)
		window.close()
	}

	const constructURL = (origin: string) => {
		const url = new URL(`${origin}?id=${documentId}`)
		if (developmentOrigin) {
			// Append developmentOrigin without the game-specific key for the iframe
			url.searchParams.append('developmentOrigin', developmentOrigin)
		}
		return url.toString()
	}

	const headerChildren = (
		<HeaderChildren
			documentId={documentId}
			collection={collection}
			documentMode={documentMode}
			setDocumentMode={setDocumentMode}
			handlePop={handlePop}
		/>
	)

	const body = (
		<DroppableFrame
			src={constructURL(frameOrigin)}
			messageTarget={messageTarget}
			setMessageTarget={setMessageTarget}
			onClose={onClose}
		/>
	)

	const menuButton = (
		<Menu document={document}>
			<DocumentWindowMenuButton />
		</Menu>
	)

	return (
		<WindowInner
			title={DocumentWindowTitle({ title, singularName, document })}
			headerChildren={headerChildren}
			className=' bg-gray-900/70 '
			bodyClassName='p-0'
			onClose={onClose}
			headerIcon={menuButton}
			showCloseButton={false}
		>
			{body}
		</WindowInner>
	)
}

export default DocumentPopout

import { useContext } from 'react'
import { IDocument } from '../../../../../shared/types/document'
import GameContext from '../../../contexts/game'
import useGetActiveScene from '../../../hooks/useGetActiveScene'
import MenuItem from '../../dropdownmenu/MenuItem'

type Props = {
	document: IDocument
}

const SetScene = ({ document }: Props) => {
	const { dispatch } = useContext(GameContext)
	const activeScene = useGetActiveScene()
	const sceneId = activeScene ? activeScene._id : null
	const isScene = document.type === 'scene'
	const isCurrentScene = sceneId === document._id

	const handleSetScene = () => {
		dispatch({
			type: 'SET_ACTIVE_SCENE',
			payload: {
				sceneId: document._id,
			},
		})
	}

	if (!isScene) return null

	return (
		<MenuItem onSelect={handleSetScene} disabled={isCurrentScene}>
			Set Scene {isCurrentScene && '(Current)'}
		</MenuItem>
	)
}

export default SetScene

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useContext } from 'react'
import { ActorRelationship, IActor } from '../../../../../shared/types/actor'
import GameContext from '../../../contexts/game'
import RadioItem from '../../dropdownmenu/RadioItem'
import Separator from '../../dropdownmenu/Separator'
import SmallUppercase from '../../interface/text/SmallUppercase'

interface Props {
	doc: IActor
	onClose: () => void
}

const RelationshipMenuItem = ({ doc, onClose }: Props) => {
	const { dispatch } = useContext(GameContext)

	const handleValueChange = (value: ActorRelationship) => {
		dispatch({
			type: 'UPDATE_DOCUMENT',
			payload: {
				updatedDocument: {
					...doc,
					version: doc.version + 1,
					values: {
						...doc.values,
						relationship: value,
					},
				},
			},
		})
		onClose()
	}

	return (
		<>
			<div className='px-2 py-1.5'>
				<SmallUppercase>Relationship</SmallUppercase>
			</div>
			<DropdownMenu.RadioGroup
				value={doc.values.relationship}
				onValueChange={handleValueChange}
			>
				<RadioItem value={'friend'}>Friend</RadioItem>
				<RadioItem value={'neutral'}>Neutral</RadioItem>
				<RadioItem value={'foe'}>Foe</RadioItem>
			</DropdownMenu.RadioGroup>
			<Separator />
		</>
	)
}

export default RelationshipMenuItem

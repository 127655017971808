import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import React from 'react'
import { IDocument } from '../../../../shared/types/document'
import DocumentMenuContent from './MenuContent'

type Props = {
	document: IDocument
	children: React.ReactElement
}

const Menu = ({ document, children }: Props) => {
	const [isOpen, setIsOpen] = React.useState(false)

	const handleTriggerClick = (e: React.MouseEvent) => {
		e.stopPropagation()
	}

	return (
		<DropdownMenu.Root modal={false} onOpenChange={open => setIsOpen(open)}>
			<DropdownMenu.Trigger
				className='dropdownMenu focus:outline-none'
				onClick={handleTriggerClick}
			>
				{React.cloneElement(React.Children.only(children), { isOpen })}
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					side='right'
					align='start'
					sideOffset={5}
					className='z-50'
					onInteractOutside={e => {
						e.preventDefault()
						setIsOpen(false)
					}}
				>
					<DocumentMenuContent document={document} />
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	)
}

export default Menu

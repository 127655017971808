import React from 'react'

interface ResourceInfoProps {
	name: string
	pageNumber: number
	numPages: number | null
	pagesToDisplay: number
}

const ResourceInfo: React.FC<ResourceInfoProps> = ({
	name,
	pageNumber,
	numPages,
	pagesToDisplay,
}) => {
	const currentPage =
		pagesToDisplay === 1 ? pageNumber : `${pageNumber - 1}-${pageNumber}`

	return (
		<div className='pl-2'>
			<div className='text-sm font-semibold' style={{ color: '#A37F43' }}>
				{name}
			</div>
			{numPages && (
				<div className='text-xs' style={{ color: 'rgba(163, 127, 67, 0.75)' }}>
					Page {currentPage} of {numPages}
				</div>
			)}
			{!numPages && (
				<div className='text-xs' style={{ color: 'rgba(163, 127, 67, 0.5)' }}>
					Loading...
				</div>
			)}
		</div>
	)
}

export default ResourceInfo

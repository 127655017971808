import { TAccess } from '../../../../shared/types/document'
import {
	DiceMessage,
	IMessage,
	IMessageType,
	OtherMessage,
} from '../../interfaces/chat'
import { DiceResult } from '../../interfaces/dicebox'
import { generateUUID } from '../../utils/generateUUID'

type Props = {
	sender: string
	message: string
	type?: IMessageType
	access?: TAccess
	accessList?: string[]
	diceResults?: DiceResult
}

const generateMessage = ({
	sender,
	message,
	type = 'message' as IMessageType,
	access = 'public' as TAccess,
	accessList = [],
	diceResults,
}: Props): IMessage => {
	const now = Date.now()

	if (type === 'dice' && diceResults) {
		return {
			_id: generateUUID(),
			sender,
			type,
			access,
			accessList,
			message,
			diceResults,
			createdAt: now,
			updatedAt: now,
		} as DiceMessage
	}

	return {
		_id: generateUUID(),
		sender,
		type,
		access,
		accessList,
		message,
		createdAt: now,
		updatedAt: now,
	} as OtherMessage
}

export default generateMessage

import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { twMerge } from 'tailwind-merge'
import useResourceUpload from '../../hooks/useResourceUpload'
import Card from '../pages/elements/Card'

interface ResourceDropzoneProps {
	children: React.ReactNode
}

const ResourceDropzone = ({ children }: ResourceDropzoneProps) => {
	const [fileDragOver, setFileDragOver] = useState<boolean>(false)
	const uploadResource = useResourceUpload()

	const dropHandler = async (files: File[]) => {
		setFileDragOver(false)
		for (const file of files) {
			try {
				await uploadResource(file) // Upload the file
				console.log('File uploaded successfully')
			} catch (error) {
				alert('Error uploading file')
				console.error('File upload error:', error)
			}
		}
	}

	return (
		<Dropzone
			onDragEnter={() => {
				setFileDragOver(true)
			}}
			onDragLeave={() => {
				setFileDragOver(false)
			}}
			onDrop={dropHandler}
		>
			{({ getRootProps }) => (
				<Card
					{...getRootProps()}
					className={twMerge(
						'cursor-default xl:rounded-none',
						fileDragOver && 'dark:bg-red-950 bg-red-100 ',
					)}
					innerClassName='max-w-7xl '
				>
					{children}
				</Card>
			)}
		</Dropzone>
	)
}

export default ResourceDropzone

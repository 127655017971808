import React from 'react'
import { Page } from 'react-pdf'

type PdfPageProps = {
	pageNumber: number
	calculatedSize: { width: number; height: number }
	onRenderSuccess?: (pageNumber: number) => void
}

const PdfPage: React.FC<PdfPageProps> = ({
	pageNumber,
	calculatedSize,
	onRenderSuccess,
}) => {
	const { width, height } = calculatedSize

	const loadingElement = (
		<div
			className='flex animate-pulse items-center justify-center border border-red-600 bg-gray-200'
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		>
			Loading page {pageNumber}...
		</div>
	)

	// .page-container keeps the page from momentarily collapsing while
	// rendering, causing the intersection observer to mis-fire
	return (
		<div
			className='page-container'
			style={{
				width: `${width}px`,
				height: `${height}px`,
			}}
		>
			<Page
				className='page transition-all duration-300'
				canvasBackground='#ffffff'
				loading={loadingElement}
				pageNumber={pageNumber}
				width={width}
				height={height}
				onRenderSuccess={() => onRenderSuccess?.(pageNumber)}
			/>
		</div>
	)
}

export default PdfPage

import { memo } from 'react'
import MediaCrossfade from '../../canvas/ImageCrossFade'
import { videoSources } from './videoData'

interface VideoOverlayProps {
	videoName: string
}

const VideoOverlay = memo(({ videoName }: VideoOverlayProps) => {
	const { src, opacity } = videoSources[videoName] || videoSources.atmosphere

	return (
		<div
			className='pointer-events-none fixed inset-0'
			style={{ opacity, zIndex: 500 }}
		>
			<MediaCrossfade
				mediaUrl={src}
				type='video'
				videoProps={{
					muted: true,
					loop: true,
					autoPlay: true,
					playsInline: true,
				}}
			/>
		</div>
	)
})

VideoOverlay.displayName = 'VideoOverlay'

export default VideoOverlay

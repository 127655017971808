import { IDragReducerAction, IDragState } from '../interfaces/dragdrop'

const dragdropReducer = (state: IDragState, action: IDragReducerAction) => {
	switch (action.type) {
		case 'SET_DRAGGING':
			return { ...state, isDragging: action.payload }
		default:
			return state
	}
}

export default dragdropReducer

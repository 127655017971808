import GameContext from '../../../contexts/game'
import { useContext } from 'react'
import useGetUserById from '../../../hooks/useGetUserById'
import MenuItem from './MenuItem'

type Props = {
	userId: string
}

const UnassignCharacterMenuItem = ({ userId }: Props) => {
	const { dispatch } = useContext(GameContext)
	const { user } = useGetUserById(userId)

	const handleSelect = () => {
		const { assumedCharacterId } = user?.userSettings || {}
		if (assumedCharacterId) {
			dispatch({
				type: 'UNASSUME_CHARACTER',
				payload: { characterId: assumedCharacterId },
			})
		}
	}

	const shouldRender =
		user && user.userSettings && user.userSettings.assumedCharacterId
	if (!shouldRender) return

	return <MenuItem onSelect={handleSelect}>Unassign Character</MenuItem>
}

export default UnassignCharacterMenuItem

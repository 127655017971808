import { useContext, useEffect } from 'react'
import GameContext from '../../contexts/game'

type Props = {
	documentId: string
	frameIsLoaded: boolean
	onClose: () => void
}

const useHandleDelete = ({ documentId, frameIsLoaded, onClose }: Props) => {
	const { game } = useContext(GameContext)

	const handleDelete = () => {
		if (!frameIsLoaded) return

		const doc = game.documents.byId[documentId]
		if (!doc) onClose()
	}

	// if the document is deleted, close the window
	useEffect(handleDelete, [JSON.stringify(game.documents)])
}

export default useHandleDelete

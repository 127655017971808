import { useContext } from 'react'
import UsersContext from '../../../contexts/users'
import MenuCheckboxItem from '../../ContextMenu/CheckboxItem'
import UserDot from '../../UserDot'

const AccessCheckbox = ({ document, isGM, accessList, handleShareWith }) => {
	const { usersState } = useContext(UsersContext)

	return (
		<>
			{usersState.users.map(user => {
				const isChecked = accessList.includes(user.userId)
				if (isGM) {
					return (
						<MenuCheckboxItem
							key={user.userId}
							checked={isChecked}
							onCheckedChange={() =>
								handleShareWith(document._id, user.userId, true)
							}
						>
							<div className='flex'>
								<UserDot
									color={user.userSettings.color}
									className='mr-2 self-center'
								/>
								{user.userProfile.name}
							</div>
						</MenuCheckboxItem>
					)
				}
				return null
			})}
		</>
	)
}

export default AccessCheckbox

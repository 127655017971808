const ItemTypes = {
	TURN_ORDER_ITEM: 'turnOrderItem',
	DOCUMENT: 'document',
	FOLDER: 'folder',
	USER: 'user',
	BOOK: 'book',
	SESSION_STAGE_ITEM: 'SESSION_STAGE_ITEM',
}

export default ItemTypes

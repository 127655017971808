import { useMutation, useQuery } from '@apollo/client'
import { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import AuthContext from '../../contexts/auth'
import {
	ADD_PLAYER_WITH_TOKEN,
	HAS_ACCESS_TO_GAME,
	VALIDATE_INVITE_TOKEN,
} from '../../graphql/games'
import InviteSignIn from './InviteSignIn'
import InviteSignUp from './InviteSignUp'
import { socket } from '../../contexts/socket'

function useGetParam() {
	return new URLSearchParams(useLocation().search)
}

const JoinFromInvite = () => {
	const { authState } = useContext(AuthContext)
	const { userDetails } = authState
	const { email } = userDetails
	const { gameId } = useParams()
	const getParam = useGetParam()
	const inviteToken = getParam.get('inviteToken')
	const [isSignIn, setIsSignIn] = useState(false)
	const navigate = useNavigate()
	const [addingToGame, setAddingToGame] = useState(false)

	const [addPlayerWithToken] = useMutation(ADD_PLAYER_WITH_TOKEN, {
		refetchQueries: [{ query: HAS_ACCESS_TO_GAME, variables: { gameId } }],
		onCompleted: () => {
			socket.emit('userDataUpdated', { gameId })
			navigate(`/game/${gameId}`)
		},
		onError: error => {
			console.error('ADD_PLAYER_WITH_TOKEN.onError', error)
		},
	})

	const { loading, data } = useQuery(VALIDATE_INVITE_TOKEN, {
		variables: {
			gameId,
			inviteToken,
		},
	})

	const {
		loading: accessLoading,
		error: accessError,
		data: accessData,
	} = useQuery(HAS_ACCESS_TO_GAME, {
		variables: { gameId },
	})

	// If user is already signed in,
	// add them to the game and redirect to game page
	useEffect(() => {
		if (!addingToGame && email) {
			setAddingToGame(true)
			addPlayerWithToken({
				variables: {
					gameId,
					email,
					inviteToken,
				},
			})
		}
	}, [email])

	if (loading) {
		return <div>Validating token...</div>
	}

	if (!data.validateInviteToken) {
		alert(
			'The token on this invite is invalid. Please contact the game owner to get a new invite.',
		)
		navigate('/')
	}

	if (accessLoading) {
		return <div>Checking access to game...</div>
	}

	if (accessError) {
		console.error(accessError)
		return <div>Error...</div>
	}

	if (accessData.hasAccessToGame) {
		navigate(`/game/${gameId}`)
		return <div>Redirecting to game...</div>
	}

	// If the user is already signed in, don't show the sign in/up form
	if (email) {
		return <div className='text-white'>Adding you to the game...</div>
	}

	return isSignIn ? (
		<InviteSignIn setIsSignIn={setIsSignIn} />
	) : (
		<InviteSignUp setIsSignIn={setIsSignIn} />
	)
}

export default JoinFromInvite

import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
import { INTERFACE_BACKGROUND_COLOR } from './constants'

interface PanelProps {
	children: ReactNode
	className?: string
	header?: ReactNode
	footer?: ReactNode
}

const Panel = ({ children, className, header, footer }: PanelProps) => {
	return (
		<div
			className={twMerge(
				'pointer-events-auto flex flex-1 flex-col overflow-y-auto overflow-x-hidden shadow-lg',
				className,
			)}
			style={{ backgroundColor: INTERFACE_BACKGROUND_COLOR }}
		>
			{header && (
				<div
					className='px-6 pt-6 pb-3'
					style={{ backgroundColor: INTERFACE_BACKGROUND_COLOR }}
				>
					{header}
				</div>
			)}

			<div className='flex-1 space-y-4 overflow-auto px-6'>{children}</div>

			{footer && (
				<div
					className='px-6 pb-6 pt-3'
					style={{ backgroundColor: INTERFACE_BACKGROUND_COLOR }}
				>
					{footer}
				</div>
			)}
		</div>
	)
}

export default Panel

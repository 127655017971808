interface CanvasImageProps {
	canvas1: HTMLCanvasElement
	canvas2: HTMLCanvasElement | null
	pagesToDisplay: number
}

export default function canvasesToImage({
	canvas1,
	canvas2,
	pagesToDisplay,
}: CanvasImageProps): string | null {
	if (pagesToDisplay === 1 || !canvas2) {
		return canvas1.toDataURL()
	} else {
		const combinedCanvas = document.createElement('canvas')
		const ctx = combinedCanvas.getContext('2d')

		combinedCanvas.width = canvas1.width + (canvas2 ? canvas2.width : 0)
		combinedCanvas.height = Math.max(
			canvas1.height,
			canvas2 ? canvas2.height : 0,
		)

		ctx?.drawImage(canvas1, 0, 0)
		if (canvas2) {
			ctx?.drawImage(canvas2, canvas1.width, 0)
		}

		return combinedCanvas.toDataURL()
	}
}

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import menuItemClasses from './menuItemClasses'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { twMerge } from 'tailwind-merge'

const SubMenuTrigger = ({ children, ...rest }) => {
	return (
		<DropdownMenu.SubTrigger
			className={twMerge(menuItemClasses, 'flex items-center justify-between')}
			{...rest}
		>
			{children}
			<ChevronRightIcon className='ml-2 h-4 w-4' />
		</DropdownMenu.SubTrigger>
	)
}

export default SubMenuTrigger

import React from 'react'
import { useGuide } from '../../contexts/guideContext'
import useGetUserById from '../../hooks/useGetUserById'
import useTheme from '../../hooks/useTheme'
import useUser from '../../hooks/useUser'
import SmallUppercase from './text/SmallUppercase'

const GuideNotification: React.FC = () => {
	const { guidingUserId, isFollowing, setIsFollowing } = useGuide()
	const { user: guidingUser } = useGetUserById(guidingUserId)
	const { userId } = useUser()
	const { primary } = useTheme()
	const isGuiding = guidingUserId === userId

	if (!guidingUserId || !guidingUser) {
		return null
	}

	const handleFollowToggle = () => {
		setIsFollowing(true)
	}

	const ActionButton = () => {
		if (isGuiding || isFollowing) {
			return null
		}

		return (
			<SmallUppercase
				className='cursor-pointer rounded-full border border-black bg-black px-4 py-2'
				style={{ color: primary }}
				onClick={handleFollowToggle}
			>
				Follow
			</SmallUppercase>
		)
	}

	return (
		<div
			className='mb-2 flex h-10 flex-row items-center justify-between rounded-full p-1'
			style={{ backgroundColor: primary }}
		>
			<SmallUppercase
				className='mx-2'
				style={{
					color: 'black',
				}}
			>
				{isGuiding
					? 'You are guiding the map'
					: `${guidingUser.userProfile.name} is guiding the map${
							isFollowing ? ' (Following)' : ''
					  }`}
			</SmallUppercase>
			<ActionButton />
		</div>
	)
}

export default GuideNotification

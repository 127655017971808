import Color from 'color'
import styled from 'styled-components'
import useTheme from '../hooks/useTheme'
import SmallUppercase from './interface/text/SmallUppercase'

interface ListDividerProps {
	text: string
}

const ListDivider = ({ text }: ListDividerProps) => {
	const { primary } = useTheme()
	const fadedPrimaryColor = Color(primary).fade(0.25).string()

	return (
		<div className='flex justify-center py-1'>
			<Container className='inline-flex items-center' color={fadedPrimaryColor}>
				<Line className='flex-1' color={fadedPrimaryColor} />
				<SmallUppercase style={{ color: primary }} className='px-2 text-xs'>
					{text}
				</SmallUppercase>
				<Line className='flex-1' color={fadedPrimaryColor} />
			</Container>
		</div>
	)
}

const Container = styled.div`
	position: relative;
	text-align: center;
	width: 100%;
	display: flex;
	align-items: center;
	min-height: 24px;
`

const Line = styled.div`
	height: 2px;
	background: ${({ color }) => color};
	border-radius: 9999px;
`

export default ListDivider

import { Point } from '../interfaces/draw'
import {
	DEFAULT_PRESSURE,
	MAX_DRAWING_SPEED,
	MAX_LINE_WIDTH,
	MAX_PRESSURE_WIDTH,
	MIN_DRAWING_SPEED,
	MIN_LINE_WIDTH,
	MIN_PRESSURE_WIDTH,
} from './drawingConstants'

export const calculateLineWidth = (
	point: Point,
	prevPoint: Point,
	pressure: number = DEFAULT_PRESSURE,
): number => {
	if (!prevPoint || !point.timestamp || !prevPoint.timestamp) {
		// Use pressure directly when starting a stroke
		return (
			MIN_PRESSURE_WIDTH + (MAX_PRESSURE_WIDTH - MIN_PRESSURE_WIDTH) * pressure
		)
	}

	const distance = Math.sqrt(
		Math.pow(point.x - prevPoint.x, 2) + Math.pow(point.y - prevPoint.y, 2),
	)
	const timeDiff = point.timestamp - prevPoint.timestamp
	const speed = distance / timeDiff

	// Calculate speed-based width
	const normalizedSpeed = Math.min(
		Math.max(
			(speed - MIN_DRAWING_SPEED) / (MAX_DRAWING_SPEED - MIN_DRAWING_SPEED),
			0,
		),
		1,
	)
	const easedSpeed = 1 - Math.pow(1 - normalizedSpeed, 2)
	const speedBasedWidth =
		MAX_LINE_WIDTH - easedSpeed * (MAX_LINE_WIDTH - MIN_LINE_WIDTH)

	// Blend speed-based width with pressure-based width
	const pressureWidth =
		MIN_PRESSURE_WIDTH + (MAX_PRESSURE_WIDTH - MIN_PRESSURE_WIDTH) * pressure
	return (speedBasedWidth + pressureWidth) / 2
}

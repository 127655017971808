import { useParams } from 'react-router-dom'

export default function AdminGameDetail() {
	const { id } = useParams()

	return (
		<div>
			<h1 className='text-2xl font-bold'>Game Details</h1>
			<p>Game ID: {id}</p>
			{/* Add game details/management */}
		</div>
	)
}

import React from 'react'
import { twMerge } from 'tailwind-merge'
import chatbotAvatar from '../../assets/avatars/gm.png'
import { IMessage } from '../../interfaces/chat'
import Avatar from '../Avatar'
import SmallUppercase from '../interface/text/SmallUppercase'
import MessageBubble from './Bubble'
import MessageMenu from './MessageMenu'

interface ChatbotMessageProps {
	message: IMessage
	onDelete: () => void
	showSender?: boolean
	className?: string
	style?: React.CSSProperties
}

const ChatbotMessage: React.FC<ChatbotMessageProps> = ({
	message,
	onDelete,
	className,
	style,
	showSender,
}) => {
	const chatbotName = 'AI GM'

	return (
		<MessageMenu onDelete={onDelete}>
			<div
				id={message._id}
				className={twMerge(
					'max-w-4/6 message inline-block transition-all duration-200',
					showSender ? 'mt-2 mb-2' : 'mt-1',
					className,
				)}
				style={style}
			>
				<div className='flex'>
					<Avatar src={chatbotAvatar} className={showSender ? '' : 'hidden'} />
					<div className={!showSender ? 'ml-9' : ''}>
						{showSender && (
							<SmallUppercase className='ml-2 mb-1'>
								{chatbotName}
							</SmallUppercase>
						)}
						<MessageBubble message={message} />
					</div>
				</div>
			</div>
		</MessageMenu>
	)
}

export default ChatbotMessage

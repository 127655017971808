import { Viewport as PixiViewport } from 'pixi-viewport'
import { useEffect } from 'react'
import { ViewportUpdateMessage } from '../../../shared/types/socket'
import { useGuide } from '../contexts/guideContext'
import { socket } from '../contexts/socket'
import useUser from './useUser'

export function useViewportEvents(
	viewport: PixiViewport | null,
	mapAsset: any,
) {
	const { isGuiding, isFollowing, setIsFollowing, guidingUserId } = useGuide()
	const { activeSceneId, userId } = useUser()

	// Guide emission effect
	useEffect(() => {
		if (!viewport || !isGuiding || !mapAsset) return

		const emitViewportUpdate = () => {
			const update: ViewportUpdateMessage = {
				userId,
				mapId: activeSceneId,
				centerX: viewport.center.x,
				centerY: viewport.center.y,
				scale: viewport.scale.x,
			}
			socket.emit('viewportUpdate', update)
		}

		emitViewportUpdate()
		viewport.on('moved', emitViewportUpdate)
		viewport.on('zoomed', emitViewportUpdate)

		return () => {
			viewport.off('moved', emitViewportUpdate)
			viewport.off('zoomed', emitViewportUpdate)
		}
	}, [viewport, isGuiding, mapAsset, activeSceneId])

	// Following interaction effect
	useEffect(() => {
		if (!viewport || !isFollowing) return

		const handleUserInteraction = () => {
			if (isFollowing) {
				setIsFollowing(false)
			}
		}

		viewport.on('drag-start', handleUserInteraction)
		viewport.on('pinch-start', handleUserInteraction)
		viewport.on('wheel', handleUserInteraction)

		return () => {
			viewport.off('drag-start', handleUserInteraction)
			viewport.off('pinch-start', handleUserInteraction)
			viewport.off('wheel', handleUserInteraction)
		}
	}, [viewport, isFollowing, setIsFollowing])

	// Following updates effect
	useEffect(() => {
		if (!isFollowing || !activeSceneId || !viewport) return

		const handleUpdate = (update: ViewportUpdateMessage) => {
			if (update.mapId === activeSceneId && update.userId === guidingUserId) {
				viewport.moveCenter(update.centerX, update.centerY)
				viewport.setZoom(update.scale, true)
			}
		}

		socket.on('viewportUpdate', handleUpdate)
		return () => {
			socket.off('viewportUpdate', handleUpdate)
		}
	}, [viewport, isFollowing, activeSceneId, guidingUserId])
}

export {}

import { Viewport as PixiViewport } from 'pixi-viewport'
import { useEffect } from 'react'
import { useGuide } from '../contexts/guideContext'

export function useViewportResize(viewport: PixiViewport | null) {
	const { isFollowing, lastGuideUpdate } = useGuide()

	useEffect(() => {
		if (!viewport) return

		const handleResize = () => {
			viewport.screenWidth = window.innerWidth
			viewport.screenHeight = window.innerHeight

			viewport.resize(
				window.innerWidth,
				window.innerHeight,
				viewport.worldWidth,
				viewport.worldHeight,
			)

			if (isFollowing && lastGuideUpdate) {
				viewport.moveCenter(lastGuideUpdate.centerX, lastGuideUpdate.centerY)
				viewport.setZoom(lastGuideUpdate.scale, true)
			}
		}

		window.addEventListener('resize', handleResize)
		handleResize()

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [viewport, isFollowing, lastGuideUpdate])
}

export {}

const Logo = props => {
	return (
		<div {...props}>
			<svg
				width='30'
				height='26'
				alt='The New Realms Logo. A hexagon representing a twenty sided die with the facing side representing a play icon.'
				xmlns='http://www.w3.org/2000/svg'
			>
				<path
					d='M23.077 1.01a2 2 0 00-1.732-1H8.655a2 2 0 00-1.732 1L.578 12a2 2 0 000 2l6.345 10.99a2 2 0 001.732 1h12.69a2 2 0 001.732-1L29.423 14a2 2 0 000-2L23.077 1.01zm-2.78 12.856a1 1 0 000-1.732l-7.8-4.503a1 1 0 00-1.5.866v9.006a1 1 0 001.5.866l7.8-4.503z'
					fill='#000'
					fillRule='evenodd'
					className='fill-current'
				/>
			</svg>
		</div>
	)
}

export default Logo

import { useMutation } from '@apollo/client'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { CREATE_GAME } from '../../graphql/games'
import Label from '../FormComponents/Label'
import Input from '../Input'
import ButtonLink from './elements/ButtonLink'
import ButtonText from './elements/ButtonText'
import Card from './elements/Card'
import PageHeader from './sections/PageHeader'

const NewGame = () => {
	const navigate = useNavigate()

	const {
		register,
		handleSubmit,
		formState: { isDirty },
	} = useForm({
		reValidateMode: 'onChange',
	})

	const [createGame, { loading }] = useMutation(CREATE_GAME, {
		onCompleted: data => {
			navigate(`/game/${data.createGame._id}`)
		},
		onError: error => {
			console.error(error)
		},
	})

	const handleSave: SubmitHandler<FieldValues> = ({ title }) => {
		createGame({
			variables: {
				createGameInput: {
					title,
					description: '',
				},
			},
		})
	}

	return (
		<>
			<Card>
				<ButtonLink className='mb-16 block' to='/profile'>
					← Go Back
				</ButtonLink>
				<PageHeader title='New Game' className='mb-12' />

				<form onSubmit={handleSubmit(handleSave)}>
					<Label>Title</Label>
					<Input
						placeholder='Add a game title...'
						inputClassName='bg-gray-900'
						{...register('title', {
							required: true,
						})}
					/>

					{/* <Label>Description (Optional)</Label>
					<TextArea
						placeholder='Add a game summary...'
						className='bg-gray-900'
						{...register('description')}
					/> */}

					<ButtonText
						type='submit'
						className='float-right mt-8'
						disabled={!isDirty || loading}
					>
						Create Game
					</ButtonText>
				</form>
			</Card>
		</>
	)
}

export default NewGame

import { useContext, useEffect } from 'react'
import { DocumentOpenMessage } from '../../../shared/types/socket'
import DocumentsContext from '../contexts/documents'
import GameContext from '../contexts/game'
import { socket } from '../contexts/socket'
import useItemFilter from './UseItemFilter'
import useUser from './useUser' // Add this import

const useRemoteRequestToOpenDocument = () => {
	const { game } = useContext(GameContext)
	const { documents } = game
	const { dispatchDocuments } = useContext(DocumentsContext)
	const { checkAccess } = useItemFilter()
	const { userId: currentUserId } = useUser()

	useEffect(() => {
		const handleOpenDocument = (message: DocumentOpenMessage) => {
			const { documentId, userIds } = message
			const document = documents.byId[documentId]

			console.log('Received document:open message:', message)
			console.log('Document from state:', document)
			console.log('Current user ID:', currentUserId)
			console.log('User IDs in message:', userIds)

			const hasAccess = checkAccess(document)
			console.log('User has access to document:', hasAccess)

			const userIncluded = userIds.includes(currentUserId)
			console.log('Current user is included in userIds:', userIncluded)

			if (document && hasAccess && userIncluded) {
				console.log('Opening document:', documentId)
				dispatchDocuments({
					type: 'OPEN_DOCUMENT',
					payload: { documentId, userIds },
				})
			} else {
				console.log(
					`Unable to open document ${documentId}:`,
					`Document exists: ${!!document},`,
					`Has access: ${hasAccess},`,
					`User included: ${userIncluded}`,
				)
			}
		}

		socket.on('document:open', handleOpenDocument)

		return () => {
			socket.off('document:open', handleOpenDocument)
		}
	}, [dispatchDocuments, checkAccess, currentUserId, documents])
}

export default useRemoteRequestToOpenDocument

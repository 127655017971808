import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import { Apollo } from './utils/Apollo'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
	<React.StrictMode>
		<Apollo>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Apollo>
	</React.StrictMode>,
)

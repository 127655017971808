import { useCallback, useContext, useEffect, useState } from 'react'
import { IAsset } from '../../../shared/types/asset'
import GameContext from '../contexts/game'

type TReturn = {
	asset: IAsset | null
	fetchAsset: (assetId: string) => IAsset | null
}

const useGetAssetById = (id?: string): TReturn => {
	const { game } = useContext(GameContext)
	const [asset, setAsset] = useState<IAsset | null>(null)

	const fetchAsset = useCallback(
		(assetId: string) => {
			if (!game?.assets) {
				console.warn('assets were not found.')
				setAsset(null)
				return
			}

			if (!game?.assets?.byId?.[assetId]) {
				console.warn(`asset '${assetId}' was not found.`)
				setAsset(null)
				return
			}

			const thisAsset = game.assets.byId[assetId]
			setAsset(thisAsset)

			return thisAsset
		},
		[game.assets],
	)

	useEffect(() => {
		if (id) {
			setAsset(fetchAsset(id))
		}
	}, [game.assets, id, fetchAsset])

	return { asset, fetchAsset }
}

export default useGetAssetById

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { useEffect, useState } from 'react'
import RemoveAllTokensMenuItem from './RemoveAllTokensMenuItem'

interface MapContextMenuProps {
	position: { x: number; y: number } | null
	onClose: () => void
}

export default function MapContextMenu({
	position,
	onClose,
}: MapContextMenuProps) {
	const [open, setOpen] = useState(false)

	// Control open state based on position
	useEffect(() => {
		if (position) {
			setOpen(true)
		} else {
			setOpen(false)
		}
	}, [position])

	if (!position) return null

	return (
		<DropdownMenu.Root
			open={open}
			onOpenChange={open => {
				setOpen(open)
				if (!open) onClose()
			}}
		>
			<DropdownMenu.Trigger asChild>
				<div
					style={{
						position: 'fixed',
						left: position.x,
						top: position.y,
						width: 1,
						height: 1,
						zIndex: 1000,
						pointerEvents: 'none',
					}}
				/>
			</DropdownMenu.Trigger>

			<DropdownMenu.Portal>
				<DropdownMenu.Content
					alignOffset={5}
					className='z-50 space-y-1 rounded-xl border border-gray-700/50 bg-gray-800 p-1 text-gray-200 shadow-xl'
				>
					<RemoveAllTokensMenuItem onSelect={onClose} />
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	)
}

import {
	ArrowUturnLeftIcon,
	ArrowUturnRightIcon,
} from '@heroicons/react/24/solid'
import { useState } from 'react'
import { Crop } from 'react-image-crop'
import { useHandleBase64ImageUpload } from '../../hooks/useHandleBase64ImageUpload'
import ModalWindow from '../window/ModalWindow'
import Cropper from './Cropper'
import DocumentPicker from './DocumentPicker'
import ToolbarButton from './ToolbarButton'

type Props = {
	sourceImage: string
	onClose: () => void
}

const CreateImage = ({ sourceImage, onClose }: Props) => {
	const [image, setImage] = useState<string>(sourceImage)
	const [cropMode, setCropMode] = useState<boolean>(true)
	const handleUpload = useHandleBase64ImageUpload()
	const [showDocumentPicker, setShowDocumentPicker] = useState<boolean>(false)
	const [croppedImage, setCroppedImage] = useState(null)
	const [crop, setCrop] = useState<Crop>()
	const [undoStack, setUndoStack] = useState<string[]>([])
	const [redoStack, setRedoStack] = useState<string[]>([])

	const handleSave = () => {
		setShowDocumentPicker(true)
	}

	const handleFinalUpload = async (documentId: string) => {
		handleUpload({
			name: 'portrait', // name of the 'document.values' key to save the asset id to
			documentId: documentId, // id of the document to save the asset id to
			base64Image: image,
		})
	}

	const removeBackground = async () => {
		try {
			const response = await fetch('/api/remove-bg', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ image }),
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			const data = await response.json()
			handleImageChange(`data:image/png;base64,${data.image}`)
		} catch (error) {
			console.error('Request failed:', error)
		}
	}

	false && setCropMode(false)

	const handleImageChange = (newImage: string) => {
		setUndoStack([...undoStack, image])
		setRedoStack([])
		setImage(newImage)
	}

	const undo = () => {
		if (undoStack.length > 0) {
			setRedoStack([...redoStack, image])
			const newImage = undoStack.pop() as string
			setUndoStack(undoStack)
			setImage(newImage)
		}
	}

	const redo = () => {
		if (redoStack.length > 0) {
			setUndoStack([...undoStack, image])
			const newImage = redoStack.pop() as string
			setRedoStack(redoStack)
			setImage(newImage)
		}
	}

	const DefaultTools = () => (
		<div className='flex justify-between'>
			<div className='flex space-x-1'>
				<ToolbarButton
					onClick={undo}
					className='w-30 px-3'
					label='Undo'
					disabled={undoStack.length === 0}
				>
					<ArrowUturnLeftIcon className='h-5 w-5' />
				</ToolbarButton>
				<ToolbarButton
					onClick={redo}
					className='w-30 px-3'
					label='Redo'
					disabled={redoStack.length === 0}
				>
					<ArrowUturnRightIcon className='h-5 w-5' />
				</ToolbarButton>
			</div>

			<div className='flex space-x-1'>
				{/* <ToolbarButton
								onClick={() => setCropMode(!cropMode)}
								className={cropMode ? 'bg-white text-black' : ''}
							>
								<CropIcon />
							</ToolbarButton> */}

				<ToolbarButton onClick={removeBackground} className='w-30 px-3'>
					Remove Background
				</ToolbarButton>
			</div>
		</div>
	)

	const CroppingTools = () => (
		<div className='flex justify-center'>
			<ToolbarButton
				className='w-30 my-2'
				onClick={() => {
					if (!croppedImage) return
					setCrop(undefined)
					handleImageChange(croppedImage)
				}}
			>
				Perform Crop
			</ToolbarButton>
		</div>
	)

	console.log(crop)

	return (
		<>
			<ModalWindow
				id='cropper'
				title='Add Image to Game'
				size='full'
				onClose={onClose}
				enableClickOutside={showDocumentPicker ? false : true}
				headerChildren={
					<div className='flex space-x-1'>
						<ToolbarButton onClick={onClose} className='w-30 px-3'>
							Cancel
						</ToolbarButton>
						<ToolbarButton onClick={handleSave} className='w-30 px-3'>
							Save to...
						</ToolbarButton>
					</div>
				}
				footerChildren={crop?.width ? <CroppingTools /> : <DefaultTools />}
				// bodyClassName='flex justify-center'
			>
				<Cropper
					src={image}
					disabled={!cropMode}
					setCroppedImage={setCroppedImage}
					crop={crop}
					setCrop={setCrop}
				/>
			</ModalWindow>
			{showDocumentPicker && (
				<DocumentPicker
					onClose={() => setShowDocumentPicker(false)}
					onSelect={documentId => {
						handleFinalUpload(documentId)
						onClose()
					}}
				/>
			)}
		</>
	)
}

export default CreateImage

import { useFormContext } from 'react-hook-form'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import DocumentTypeListItem from './DocumentTypeListItem'

export default function DocumentTypeList() {
	const collectionTypes = useCollectionTypes()
	console.log('Collection types:', collectionTypes)

	const { setValue } = useFormContext()

	const sortedCollectionTypes = [
		{ type: 'All', pluralName: 'All' },
		...collectionTypes.sort((a, b) => a.type.localeCompare(b.type)),
	]
	console.log('Sorted collection types:', sortedCollectionTypes)

	return (
		<div className='mb-4 flex flex-wrap justify-center gap-x-0 gap-y-0.5'>
			{sortedCollectionTypes.map(({ type, pluralName }) => (
				<button key={type} onClick={() => setValue('selection', type)}>
					<DocumentTypeListItem label={pluralName} type={type} />
				</button>
			))}
		</div>
	)
}

import React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import useCollectionTypes from '../../hooks/useCollectionTypes'
import useTheme from '../../hooks/useTheme'
import SmallUppercase from '../interface/text/SmallUppercase'

type Props = {
	label: string | React.ReactNode
	type: string
}

const DocumentTypeListItem = ({ label, type }: Props) => {
	const { primary } = useTheme()
	const { register } = useFormContext()
	const collectionTypes = useCollectionTypes()
	const selectedType = useWatch({
		name: 'selection',
		defaultValue: collectionTypes[0],
	})

	return (
		<li className='inline-block'>
			<input
				className='hidden'
				type='radio'
				value={type}
				id={type}
				{...register('selection')}
			/>
			<label
				htmlFor={type}
				className='flex cursor-pointer items-center px-1.5 transition-colors duration-100'
			>
				<SmallUppercase
					className='mt-px w-full text-center'
					style={{
						color: selectedType === type ? 'black' : primary,
					}}
				>
					{label}
				</SmallUppercase>
			</label>
		</li>
	)
}

export default DocumentTypeListItem

import { useContext } from 'react'
import DocumentsContext from '../../../contexts/documents'
import GameContext from '../../../contexts/game'
import DangerMenuItem from '../../dropdownmenu/DangerMenuItem'

type Props = {
	documentId: string
}

const Delete = ({ documentId }: Props) => {
	const { dispatch } = useContext(GameContext)
	const { dispatchDocuments } = useContext(DocumentsContext)

	const handleDeleteDocument = () => {
		dispatchDocuments({
			type: 'CLOSE_DOCUMENT',
			payload: {
				documentId,
			},
		})

		dispatch({
			type: 'DELETE_DOCUMENT',
			payload: {
				documentId,
			},
		})
	}

	return <DangerMenuItem onSelect={handleDeleteDocument}>Delete</DangerMenuItem>
}

export default Delete

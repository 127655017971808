import BodyText from './elements/BodyText'
import Card from './elements/Card'
import Eyebrow from './elements/Eyebrow'
import Title from './elements/Title'

const AboutPage = () => {
	return (
		<Card>
			<Eyebrow>Exposition Time</Eyebrow>

			<Title>About Us</Title>

			<BodyText>
				<p className='mb-4'>
					New Realms was founded in 2022 by Michael Heilemann in New York,
					merging a lifelong passions for people, games, software and online
					platforms.
				</p>

				<p className='mb-4'>
					Michael was most recently a VP at Squarespace where he worked for over
					ten years, designing and creating products which power millions of
					businesses, seeing it grow from 30 to 1300 people, growing the product
					design team to match, before going public in 2021.
				</p>

				<p className='mb-4'>
					Prior he was at triple-A game studio Io Interactive for 7 years as a
					designer on games like Hitman and Kane &amp; Lynch.
				</p>

				<p className='mb-4'>
					As a teen he ran an RPG-focused BBS from under the desk he and his
					friends played every roleplaying game under the sky at, in his parents
					basement.
				</p>

				<h2 className='mt-8 mb-4 font-semibold'>Our Mission</h2>

				<p className='mb-4'>
					New Realms exists to build an online home for anyone interested in
					tabletop gaming and shared storytelling.
				</p>

				<h2 className='mt-8 mb-4 font-semibold'>Our Principles</h2>

				<p className='mb-4'>
					Whether friends or strangers, we believe people are best when they
					come together, and we believe that games and stories bring people
					together like little else.{' '}
				</p>

				<p className='mb-4'>
					To bring people together, we are building a welcoming place which
					always strives for inclusivity.
				</p>

				<p className='mb-4'>
					We think everyone is creative and our job is to amplify their voices
					and simplify everything in their way.
				</p>

				<p className='mb-4'>
					And we believe that people having fun together is important.
				</p>
			</BodyText>
		</Card>
	)
}

export default AboutPage

import { useContext } from 'react'
import { ISceneGrid } from '../../../shared/types/scene'
import GameContext from '../contexts/game'
import useGetActiveScene from './useGetActiveScene'

const DEFAULT_GRID: ISceneGrid = {
	type: 'square',
	size: 2,
	unitSize: 5,
	unit: 'ft',
	enabled: true,
	color: '#CCCCCC',
	alpha: 0.5,
	x: 0,
	y: 0,
}

export const useGrid = () => {
	const { game } = useContext(GameContext)
	const activeScene = useGetActiveScene()
	const systemGrid = game?.system?.grid

	const grid = {
		...DEFAULT_GRID,
		...systemGrid,
		...activeScene?.values?.grid,
	}

	const getCellSize = () => {
		const mapAsset = game.assets.byId[activeScene?.values?.mapId]
		if (!mapAsset) return 100 / (100 / grid.size)

		const aspectRatio = mapAsset.width / mapAsset.height
		const mapWidth = mapAsset.width > mapAsset.height ? 100 : 100 * aspectRatio
		return mapWidth / (100 / grid.size)
	}

	const snapToGrid = (x: number, y: number) => {
		if (!grid.enabled) return { x, y }

		const cellSize = getCellSize()
		const halfCell = cellSize / 2 // Add this back

		// Get map dimensions for centering calculation
		const mapAsset = game.assets.byId[activeScene?.values?.mapId]
		if (!mapAsset) return { x, y }

		const aspectRatio = mapAsset.width / mapAsset.height
		const mapWidth = mapAsset.width > mapAsset.height ? 100 : 100 * aspectRatio
		const mapHeight = mapAsset.width > mapAsset.height ? 100 / aspectRatio : 100

		// Adjust coordinates to account for centered grid
		const adjustedX = x + mapWidth / 2
		const adjustedY = y + mapHeight / 2

		// Snap to grid intersections by offsetting by half a cell
		const snappedX =
			Math.round((adjustedX - grid.x - halfCell) / cellSize) * cellSize +
			grid.x +
			halfCell
		const snappedY =
			Math.round((adjustedY - grid.y - halfCell) / cellSize) * cellSize +
			grid.y +
			halfCell

		// Convert back to centered coordinates
		return {
			x: snappedX - mapWidth / 2,
			y: snappedY - mapHeight / 2,
		}
	}

	return {
		...grid,
		getCellSize,
		snapToGrid,
	}
}

export default useGrid

import { CheckIcon } from '@heroicons/react/24/solid'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { twMerge } from 'tailwind-merge'
import menuItemClasses from './menuItemClasses'

const CheckboxItem = ({ children, ...rest }) => {
	return (
		<DropdownMenu.CheckboxItem
			className={twMerge(
				menuItemClasses,
				rest.disabled ? 'cursor-not-allowed opacity-50' : '',
			)}
			{...rest}
		>
			<span>{children}</span>
			<DropdownMenu.ItemIndicator>
				<CheckIcon className='h-4 w-4' />
			</DropdownMenu.ItemIndicator>
		</DropdownMenu.CheckboxItem>
	)
}

export default CheckboxItem

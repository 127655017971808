import { Graphics } from '@pixi/react'
import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useCallback, useEffect, useState } from 'react'
import { screenToWorld } from '../../utils/coordinates'
import {
	CURSOR_PREVIEW_RADIUS,
	PREVIEW_ALPHA,
} from '../../utils/drawingConstants'

interface Props {
	viewport: Viewport
	color: string
	isDrawing: boolean
}

const DrawingCursor = ({ viewport, color, isDrawing }: Props) => {
	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 })

	useEffect(() => {
		const handlePointerMove = (e: PointerEvent) => {
			const worldPos = screenToWorld(viewport, e.clientX, e.clientY)
			setCursorPosition(worldPos)
		}

		window.addEventListener('pointermove', handlePointerMove)
		return () => {
			window.removeEventListener('pointermove', handlePointerMove)
		}
	}, [viewport])

	const draw = useCallback(
		(g: PIXI.Graphics) => {
			if (isDrawing) {
				g.clear()
				return
			}

			g.clear()
			g.beginFill(parseInt(color.replace('#', '0x')), PREVIEW_ALPHA)

			// Draw a detailed circle with 32 sides
			const sides = 32
			const points: number[] = []

			for (let i = 0; i < sides; i++) {
				const angle = (i * 2 * Math.PI) / sides
				points.push(
					cursorPosition.x + CURSOR_PREVIEW_RADIUS * Math.cos(angle),
					cursorPosition.y + CURSOR_PREVIEW_RADIUS * Math.sin(angle),
				)
			}

			g.drawPolygon(points)
			g.endFill()
		},
		[color, isDrawing, cursorPosition],
	)

	return <Graphics draw={draw} />
}

export default DrawingCursor

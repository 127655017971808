import { useMutation } from '@apollo/client'
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline'
import { TrashIcon } from '@heroicons/react/24/solid'
import { useContext, useState } from 'react'
import Dropzone from 'react-dropzone'
import { twMerge } from 'tailwind-merge'
import { IAsset } from '../../../shared/types/asset'
import GameContext from '../contexts/game'
import { ADD_ASSET } from '../graphql/games'
import useAssetUploader from '../hooks/useAssetUploader'
import useGetAssetById from '../hooks/useGetAssetById'
import useRemoveAsset from '../hooks/useRemoveAsset'
import Asset from './Asset'
import Translucency from './Translucency'

type Props = {
	assetId: string
	onAssetChange: (asset?: IAsset) => void
}

const AssetManager = ({ assetId, onAssetChange }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const { asset } = useGetAssetById(assetId)
	const assetUrl = asset?.fileurl
	const [isOver, setIsOver] = useState(false)
	const assetUploader = useAssetUploader()
	const { removeAsset } = useRemoveAsset()

	const [addAsset] = useMutation(ADD_ASSET, {
		onCompleted: data => {
			const { asset } = data.addAsset

			dispatch({
				type: 'ADD_ASSET',
				payload: {
					asset,
				},
			})

			onAssetChange(asset)
		},
		onError: error => {
			console.error(error)
		},
	})

	const handleFileDragEnter = () => {
		setIsOver(true)
	}

	const handleFileDragLeave = () => {
		setIsOver(false)
	}

	const handleFileDrop = async (files: File[]) => {
		const asset = await assetUploader(files[0], game._id)

		addAsset({
			variables: {
				gameId: game._id,
				asset,
			},
		})
	}

	const handleRemoveAsset = () => {
		console.log('handleRemoveAsset', assetId, game._id)
		removeAsset({
			variables: {
				gameId: game._id,
				assetId,
			},
		})

		onAssetChange()
	}

	return (
		<Dropzone
			onDragEnter={handleFileDragEnter}
			onDragLeave={handleFileDragLeave}
			onDrop={handleFileDrop}
		>
			{({ getRootProps, getInputProps }) => (
				<div
					className={twMerge(
						'relative flex aspect-video items-center justify-center bg-gray-800/50 transition-all duration-300',
						isOver && 'bg-gray-800',
					)}
					{...getRootProps()}
				>
					{!assetId && (
						<>
							<input {...getInputProps()} />
							<div className='flex flex-col items-center text-xs text-gray-500'>
								<ArrowUpCircleIcon className='h-8 w-8' />
								<p className='mt-2 text-center'>
									Drop image or video here
									<br /> or click to upload
								</p>
							</div>
						</>
					)}

					{assetId && (
						<>
							<div
								className='absolute inset-0 bg-cover bg-center blur-xl'
								style={{
									backgroundImage: `url(${assetUrl})`,
								}}
							/>
							<Asset assetId={assetId} className='z-10' />
							<button
								className={twMerge(
									'absolute bottom-4 right-4 z-20 flex h-8 w-8 items-center justify-center rounded-full p-1 text-white',
									Translucency,
								)}
								onClick={handleRemoveAsset}
							>
								<TrashIcon className='h-4 w-4' />
							</button>
						</>
					)}
				</div>
			)}
		</Dropzone>
	)
}

export default AssetManager

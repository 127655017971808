import useGetActiveScene from '../../../hooks/useGetActiveScene'
import VideoOverlay from './VideoOverlay'

const VideoOverlayWrapper = () => {
	const scene = useGetActiveScene()

	if (!scene) return null

	const { effectsOverlay } = scene.values

	if (effectsOverlay) {
		return <VideoOverlay videoName={effectsOverlay} />
	}

	return null
}

export default VideoOverlayWrapper

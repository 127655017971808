const WindowResizeHandles: React.FC = () => {
	return (
		<>
			<div
				data-dragtype='top'
				className='absolute top-0 z-10 -mt-1 h-2 w-full'
				style={{
					cursor: 'ns-resize',
				}}
			/>
			<div
				data-dragtype='top-right'
				className='absolute top-0 right-0 z-20 -mt-1 -mr-1 h-4 w-4 rounded-full'
				style={{
					cursor: 'nesw-resize',
				}}
			/>
			<div
				data-dragtype='right'
				className='absolute top-0 right-0 z-10 -mr-1 h-full w-2'
				style={{
					cursor: 'ew-resize',
				}}
			/>
			<div
				data-dragtype='bottom-right'
				className='absolute bottom-0 right-0 z-20 -mb-1 -mr-1 h-4 w-4 rounded-full'
				style={{
					cursor: 'nwse-resize',
				}}
			/>
			<div
				data-dragtype='bottom'
				className='absolute bottom-0 z-10 -mb-1 h-2 w-full'
				style={{
					cursor: 'ns-resize',
				}}
			/>
			<div
				data-dragtype='bottom-left'
				className='absolute bottom-0 left-0 z-20 -mb-1 -ml-1 h-4 w-4 rounded-full'
				style={{
					cursor: 'nesw-resize',
				}}
			/>
			<div
				data-dragtype='left'
				className='absolute top-0 left-0 z-10 -ml-1 h-full w-2'
				style={{
					cursor: 'ew-resize',
				}}
			/>
			<div
				data-dragtype='top-left'
				className='absolute top-0 left-0 z-20 -mt-1 -ml-1 h-4 w-4 rounded-full'
				style={{
					cursor: 'nwse-resize',
				}}
			/>
		</>
	)
}

export default WindowResizeHandles

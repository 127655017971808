import { useContext, useEffect, useState } from 'react'
import { MemoryContext } from '../../contexts/MemoryProvider'
import Button from '../FormComponents/Button'
import SectionDivider from '../SectionDivider'

const Scratchpad = () => {
	const [isAIGamemasterEnabled, setIsAIGamemasterEnabled] = useState(false)
	const { state, dispatch } = useContext(MemoryContext)
	const { memories } = state

	useEffect(() => {
		const storedValue = localStorage.getItem('aiGamemasterEnabled')
		setIsAIGamemasterEnabled(storedValue === 'true')
	}, [])

	const handleResetMemories = () => {
		dispatch({ type: 'RESET_MEMORIES' })
	}

	if (!isAIGamemasterEnabled) {
		return null
	}

	return (
		<div>
			<SectionDivider label='AI Memories' />
			<div>
				<p className='h-64 overflow-auto'>
					Memories: {memories.length === 0 ? 'No Memories' : memories.length}
					<Button onClick={handleResetMemories}>Reset</Button>
					{memories
						// .slice()
						// .reverse()
						.map((memory, index) => (
							<li
								key={index}
								className='my-2 border-t border-gray-700 py-2 text-xs'
							>
								{memory.content} (Importance: {memory.importance})
							</li>
						))}
				</p>
			</div>
		</div>
	)
}

export default Scratchpad

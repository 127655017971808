import { useContext } from 'react'
import PdfViewerContext from '../../contexts/book'

const ProgressBar = () => {
	const { bookState } = useContext(PdfViewerContext)
	const { currentPage, numPages } = bookState

	const progress = (currentPage - 1) / (numPages - 1)

	return (
		<div
			className='absolute top-0 left-0 right-0 h-0.5 bg-gray-200 dark:bg-gray-700'
			style={{
				backgroundColor: '#A37F4344',
			}}
		>
			<div
				className='h-full transition-all duration-300 dark:bg-blue-400'
				style={{
					width: `${progress * 100}%`,
					backgroundColor: '#A37F43',
				}}
			></div>
		</div>
	)
}

export default ProgressBar

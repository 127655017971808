import { CheckCircle2, XCircle } from 'lucide-react'
import { useContext } from 'react'
import { twMerge } from 'tailwind-merge'
import GameContext from '../../contexts/game'
import useTheme from '../../hooks/useTheme'
import TooltipWrapper from '../TooltipWrapper'

type Props = {
	id: string
}

const ToggleActiveButton = ({ id }: Props) => {
	const { game, dispatch } = useContext(GameContext)
	const { primary } = useTheme()
	const { isActive } = game.turnTracker.turnOrder.byId[id]

	const handleActive = e => {
		e.stopPropagation()
		dispatch({ type: 'TOGGLE_TURN_TRACKER_ITEM_ISACTIVE', payload: { id } })
	}

	return (
		<TooltipWrapper label={isActive ? 'Active' : 'Inactive'}>
			<button onClick={e => handleActive(e)}>
				{isActive ? (
					<CheckCircle2
						className={twMerge('h-5 w-5')}
						style={{ color: primary }}
					/>
				) : (
					<XCircle
						className={twMerge('h-5 w-5 opacity-50')}
						style={{ color: primary }}
					/>
				)}
			</button>
		</TooltipWrapper>
	)
}

export default ToggleActiveButton

import { useFormContext } from 'react-hook-form'
import Label from '../../../FormComponents/Label'
import Input from '../../../Input'

const MapGridUnit = () => {
	const { register } = useFormContext()

	return (
		<div className='center flex align-middle'>
			<Label className='mt-3 w-1/3'>Unit Size</Label>
			<div className='flex w-2/3 gap-2'>
				<Input type='number' {...register('grid.unitSize')} />
				<select
					{...register('grid.unit')}
					className='rounded-md bg-gray-800 px-2 text-white'
				>
					<option value='ft'>ft</option>
					<option value='m'>m</option>
				</select>
			</div>
		</div>
	)
}

export default MapGridUnit

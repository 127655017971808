import { Power } from 'lucide-react'
import { NavLink } from 'react-router-dom'
import ToolButton from './interface/toolbar/ToolButton'

export default function ExitButton() {
	return (
		<NavLink to='/profile'>
			<ToolButton tooltip='Leave'>
				<Power className='h-5 w-5' aria-hidden='true' />
			</ToolButton>
		</NavLink>
	)
}
